import { useEffect, useRef, useState } from "react";
import { isListEmpty } from "utils/general";
import { ChapterTypeData, SpecificationDocumentSummaryChapter } from "models";
import { DOCUMENT_SUMMARY_TABS } from "utils/constants";
import { isTabLinkedToChapters, isTabLinkedToSpecification } from "utils/files";
import { ChapterStructureFormData } from "types/library";

export type UseChapterSectionProps = {
  chapters: SpecificationDocumentSummaryChapter[];
  filteredChapterTypes: ChapterTypeData[];
  onChange?: Function;
  specificationStructure?: ChapterStructureFormData[];
  tabKey: DOCUMENT_SUMMARY_TABS;
};

const useChapterSection = ({
  chapters,
  filteredChapterTypes,
  onChange,
  specificationStructure,
  tabKey,
}: UseChapterSectionProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const filterChaptersByTabKey = (
    chapters: ChapterTypeData[],
    isChapterLibrary: boolean
  ): string[] =>
    chapters
      ?.filter(chapter => chapter.isChapterLibrary === isChapterLibrary)
      ?.map(({ chapterType }) => chapterType);

  const getSectionsWithFiles = (
    sections: SpecificationDocumentSummaryChapter["sections"]
  ) =>
    sections
      ?.filter(section => !isListEmpty(section.fileIds))
      ?.map(section => ({
        sectionId: section.sectionId,
        fileIds: section.fileIds,
      }));

  const filterChaptersWithFiles = (
    chapters: SpecificationDocumentSummaryChapter[]
  ) =>
    chapters
      ?.map(chapter => {
        const sectionsWithFiles = getSectionsWithFiles(chapter.sections);
        if (!isListEmpty(chapter.fileIds) || !isListEmpty(sectionsWithFiles)) {
          return {
            chapterType: chapter.chapterType,
            sectionIds: sectionsWithFiles,
            fileIds: chapter.fileIds,
          };
        }
        return null;
      })
      .filter(Boolean);

  const getChaptersAndSectionsWithFiles = () => {
    let chaptersToDisplay = [];
    if (isTabLinkedToChapters(tabKey)) {
      chaptersToDisplay = filterChaptersByTabKey(filteredChapterTypes, true);
    } else if (isTabLinkedToSpecification(tabKey)) {
      chaptersToDisplay = filterChaptersByTabKey(filteredChapterTypes, false);
    }

    const filteredChapters = chapters?.filter(({ chapterType }) =>
      chaptersToDisplay?.includes(chapterType)
    );

    return filterChaptersWithFiles(filteredChapters);
  };

  const chaptersAndSectionsWithFiles = useRef(
    getChaptersAndSectionsWithFiles()
  );

  const getFileIdsFromChapters = (chaptersAndSections?: string[]): string[] => {
    if (isListEmpty(chaptersAndSections)) {
      return [];
    }

    const result = new Set<string>();
    for (const item of chaptersAndSections) {
      let foundAsChapter = false;

      for (const chapter of chaptersAndSectionsWithFiles.current) {
        if (chapter.chapterType === item) {
          // Add the chapter's fileIds
          chapter.fileIds.forEach(fileId => result.add(fileId));

          // Add fileIds from its sections
          chapter.sectionIds.forEach(section =>
            section.fileIds.forEach(fileId => result.add(fileId))
          );
          foundAsChapter = true;
          break;
        }
      }

      if (!foundAsChapter) {
        for (const chapter of chaptersAndSectionsWithFiles.current) {
          for (const section of chapter.sectionIds) {
            if (section.sectionId === item) {
              // Add the section's fileIds
              section.fileIds.forEach(fileId => result.add(fileId));
              break;
            }
          }
        }
      }
    }

    return Array.from(result);
  };

  const treeData = chaptersAndSectionsWithFiles.current
    ?.flatMap(item => {
      const chapterTypeData = filteredChapterTypes.find(
        ({ chapterType }) => item.chapterType === chapterType
      );

      if (!chapterTypeData) {
        return [];
      }

      const { chapterName, chapterType } = chapterTypeData;

      // Create the chapter node
      const chapterNode = {
        id: chapterType,
        title: chapterName,
        key: chapterType,
        value: chapterType,
        isLeaf: isListEmpty(item.sectionIds),
      };

      // Create section nodes
      let sectionsTreeData: any[] = [];
      if (!chapterNode.isLeaf) {
        for (const chapter of specificationStructure) {
          item.sectionIds.forEach(sectionId => {
            const sectionWithFile = chapter.sections.find(
              ({ sectionId: structureSectionId }) =>
                structureSectionId === sectionId.sectionId
            );

            if (sectionWithFile) {
              sectionsTreeData.push({
                id: sectionWithFile.sectionId,
                pId: chapterType,
                title: sectionWithFile.sectionName,
                key: sectionWithFile.sectionId,
                value: sectionWithFile.sectionId,
                isLeaf: isListEmpty(sectionWithFile.sections),
              });
            }
          });
        }
      }

      return [chapterNode, ...sectionsTreeData];
    })
    .filter(Boolean);

  const handleSelect = (value: string) => {
    setSelectedItems(prevState => {
      // Find the selected item's parent ID
      const parentItem = treeData.find(item => item.id === value);
      const parentItemId = parentItem?.pId;

      // Find the selected item's children
      const childItems = treeData.filter(item => item.pId === value);
      const childItemIds = childItems.map(item => item.id);

      let updatedState = [...prevState];

      // If the selected item has children
      if (!isListEmpty(childItems)) {
        // Unselect all child items
        updatedState = updatedState.filter(
          item => !childItemIds.includes(item)
        );

        // Toggle parent item selection
        if (updatedState.includes(value)) {
          updatedState = updatedState.filter(item => item !== value);
        } else {
          updatedState.push(value);
        }
      } else {
        // If the selected item is a child
        // Unselect the parent if selected
        if (parentItemId && updatedState.includes(parentItemId)) {
          updatedState = updatedState.filter(item => item !== parentItemId);
        }

        // Toggle child item selection
        if (updatedState.includes(value)) {
          updatedState = updatedState.filter(item => item !== value);
        } else {
          updatedState.push(value);
        }
      }

      return updatedState;
    });
  };

  const handleDeselect = (value: string) => {
    setSelectedItems(prevState =>
      prevState.filter(existingValue => value !== existingValue)
    );
  };

  const handleClear = () => setSelectedItems([]);

  useEffect(() => {
    const fileIdsToDisplay = getFileIdsFromChapters(selectedItems);
    onChange?.(fileIdsToDisplay);
  }, [selectedItems]);

  return {
    treeData,
    selectedItems,
    handleSelect,
    handleDeselect,
    handleClear,
  };
};

export default useChapterSection;
