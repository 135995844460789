import { useIntl } from "react-intl";

import InfiniteScrollSelect from "components/InfiniteScrollSelect";
import { useCompaniesSelect } from "./hooks";
import {
  getPlaceholderMessageKey,
  getEmptyDescriptionKey,
  getOptions,
} from "./utils";
import { messages } from "./messages";
import { SearchProductOriginsProps } from "./types";

const CompanySelect: React.FC<SearchProductOriginsProps> = ({
  type,
  onSelect: onDefaultOnSelect,
  onChange,
  mode,
  values,
  labelInValue = true,
  selectedValues = [],
  excludedValues = [],
  error = false,
  errorMessage = "",
  productSpecifications,
  onClear,
  placeholder,
  notFoundMessage,
  ignoreApplicationTypeIds,
  companyActivityId,
}) => {
  const intl = useIntl();

  const {
    isLoading,
    productOrigins,
    fetchProductOrigin,
    onSearch,
    onDropdownVisibleChange,
    onSelect,
    onBlur,
  } = useCompaniesSelect({
    type,
    productSpecifications,
    onDefaultOnSelect,
    ignoreApplicationTypeIds,
    companyActivityId,
  });

  return (
    <InfiniteScrollSelect
      data-testid="search-productOrigins"
      name="searchProductOrigins"
      filterOption={false}
      placeholder={
        placeholder ??
        intl.formatMessage(messages[getPlaceholderMessageKey(type)])
      }
      onDropdownVisibleChange={onDropdownVisibleChange}
      onSearch={onSearch}
      onSelect={onSelect}
      // @ts-ignore
      onChange={onChange}
      onBlur={onBlur}
      showArrow
      showSearch
      labelInValue={labelInValue}
      options={getOptions({
        options: productOrigins,
        selectedValues: [...selectedValues, ...excludedValues],
      })}
      loading={isLoading}
      notFoundMessage={
        notFoundMessage ??
        intl.formatMessage(messages[getEmptyDescriptionKey(type)])
      }
      mode={mode}
      value={values}
      error={error}
      errorMessage={errorMessage}
      next={fetchProductOrigin}
      onClear={onClear}
    />
  );
};

export default CompanySelect;
