import { useModalVisibility } from "hooks";
import { Modal, SpecificationDocumentSummary } from "components";

const withSpecificationDocumentSummary = Component => props => {
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();
  const { specification, specificationStructure } = props;
  const {
    specificationId,
    tradeItemName,
    companiesDetail,
    productTypeId,
  } = specification;

  return (
    <>
      <Component {...props} onOpenDocumentSummary={onOpenModal} />
      {isModalOpen && (
        <Modal onClose={onCloseModal} showHeader allowScroll>
          <SpecificationDocumentSummary
            specificationId={specificationId}
            tradeItemName={tradeItemName}
            supplierName={companiesDetail?.supplierName}
            productTypeId={productTypeId}
            specificationStructure={specificationStructure}
          />
        </Modal>
      )}
    </>
  );
};

export default withSpecificationDocumentSummary;
