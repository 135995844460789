import { useSelector } from "react-redux";
import { Col, Form, Row } from "antd";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { Button, Paragraph } from "@trace-one/design-system";
import { useFileDownload } from "hooks";
import { formatFileSize, getFileNameWithoutExtension } from "utils/files";
import { selectFileTypeDictionary } from "store/generalData/selectors";
import { FileViewModel } from "viewModels";
import { documentMessages, generalMessages } from "messages";

export type InformationPanelItemProps = {
  item: FileViewModel;
};

const InformationPanelItem: React.FC<InformationPanelItemProps> = ({
  item,
}) => {
  const { formatMessage } = useIntl();
  const fileTypeDictionary = useSelector(selectFileTypeDictionary);

  const { downloading, onDownload } = useFileDownload();

  const { id, fileExtension, name, fileType, size, createdByUserName } = item;
  const fileName = getFileNameWithoutExtension(name);

  return (
    <>
      <Col span={24} data-testid="info-panel-item-form">
        <Form
          name="info-panel-item-form"
          layout="horizontal"
          labelAlign="left"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          labelWrap
        >
          <Form.Item
            name="name"
            label={formatMessage(generalMessages.name)}
            className="mb-1"
          >
            <Paragraph
              className="overflow-wrap"
              data-test-id="info-panel-item-name"
            >
              {fileName}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="extension"
            label={formatMessage(documentMessages.extension)}
            className="mb-1"
          >
            <Paragraph
              className="overflow-wrap"
              data-test-id="info-panel-item-extension"
            >
              {fileExtension?.toLocaleUpperCase()}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="type"
            label={formatMessage(documentMessages.documentType)}
            className="mb-1"
          >
            <Paragraph
              className="overflow-wrap"
              data-test-id="info-panel-item-type"
            >
              {fileTypeDictionary?.[fileType] || fileType}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="size"
            label={formatMessage(documentMessages.size)}
            className="mb-1"
          >
            <Paragraph
              className="overflow-wrap"
              data-test-id="info-panel-item-size"
            >
              {formatFileSize(size)}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="user-name"
            label={formatMessage(generalMessages.userName)}
            className="mb-1"
          >
            <Paragraph
              className="overflow-wrap"
              data-test-id="info-panel-item-username"
            >
              {createdByUserName}
            </Paragraph>
          </Form.Item>
        </Form>
      </Col>

      <Row className={classNames("w-100", "as-flex-end", "jc-center")}>
        <Button
          type="primary"
          loading={downloading}
          onClick={() =>
            onDownload({
              id,
              fileName,
              fileExtension,
            })
          }
        >
          {formatMessage(documentMessages.download)}
        </Button>
      </Row>
    </>
  );
};

export default InformationPanelItem;
