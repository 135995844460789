import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import {
  useSpecificationActions,
  useSpecificationPermissionsFromApi,
} from "hooks";
import { isListEmpty } from "utils/general";
import withDuplicateSpecificationAction from "hocs/withDuplicateSpecificationAction";
import withHistorySpecificationAction from "hocs/withHistorySpecificationAction";
import { getActionsList } from "./utils";
import generalMessages from "messages/general";
import { ActionsButtonProps } from "./types";
import { withSpecificationDocumentSummary } from "hocs";
import styles from "./styles.module.less";

export const ActionsButton: React.FC<ActionsButtonProps> = ({
  specification,
  onOpenSelectSupplierModal,
  onOpenHistoryModal,
  onOpenDocumentSummary,
}) => {
  const intl = useIntl();
  const { permissionList } = useSpecificationPermissionsFromApi({
    specificationId: specification.specificationId,
    state: specification.state,
  });

  const actions = useSpecificationActions(specification, permissionList);

  const actionList = getActionsList({
    actions,
    onOpenSelectSupplierModal,
    onOpenHistoryModal,
    productTypeId: specification?.productTypeId,
    onOpenDocumentSummary,
  });

  return !isListEmpty(actionList) ? (
    <Button
      type="secondary"
      data-test-id="specification-actions-button"
      items={actionList}
      className={styles.actionsMenuButton}
    >
      {intl.formatMessage(generalMessages.actions)}
    </Button>
  ) : null;
};

export default withSpecificationDocumentSummary(
  withHistorySpecificationAction(
    withDuplicateSpecificationAction(ActionsButton)
  )
);
