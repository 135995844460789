import { useContext, useState } from "react";
import classNames from "classnames";
import { Card } from "@trace-one/react-components";
import { Heading, Button } from "@trace-one/design-system";
import { isObjectEmpty } from "utils/general";
import {
  getSectionStatus,
  isValidatedByCompany,
  prepareValidationStatus,
} from "utils/library";
import { SelectedChapterContext } from "pages/Specification/components/Details/components/SelectedChapter/contexts";
import RequirementsContent from "./components/RequirementsContent";
import DocumentsContent from "./components/DocumentsContent";
import SectionContent from "./components/SectionContent";
import UserGuideModal from "components/UserGuideModal";
import { useModalVisibility } from "hooks";
import { SPECIFICATION_SECTION_UNCONTROLLED_BLOCKS } from "utils/constants";
import { SectionProps } from "./types";
import { ChapterStructureBlockData } from "models";
import styles from "./styles.module.less";

const Section: React.FC<SectionProps> = ({ section }) => {
  const {
    currentSectionsRefs,
    specificationPermissions,
    isRetailer,
    isChapterLibrary,
    isChapterOwnedBySupplier,
    retailerCompnayId,
    supplierCompnayId,
    translatorCompanyId,
  } = useContext(SelectedChapterContext);

  const { isModalOpen, onCloseModal, onOpenModal } = useModalVisibility();

  const [sectionBlocks, setSectionBlocks] = useState<
    ChapterStructureBlockData[]
  >(section?.blocks);

  if (isObjectEmpty(section)) return null;

  const {
    sectionId,
    sectionCode,
    sectionName,
    permissions,
    blocks,
    userGuide,
    state,
  } = section;

  const sectionStatus = getSectionStatus({
    state,
    supplierCompnayId,
    retailerCompnayId,
    translatorCompanyId,
  });

  const blocksToDisplay = SPECIFICATION_SECTION_UNCONTROLLED_BLOCKS.includes(
    sectionCode
  )
    ? blocks
    : sectionBlocks;

  const customSectionsContentMap = {
    requirements: (
      <RequirementsContent
        isRetailer={isRetailer}
        permissions={specificationPermissions}
      />
    ),
    documents: <DocumentsContent />,
  };

  const { validatedByCompanyIds } = state || {};

  const validatedBySupplier = isValidatedByCompany({
    validatedByCompanyIds,
    companyId: supplierCompnayId,
  });

  const validationStatus = prepareValidationStatus({
    state: sectionStatus,
    validatedBySupplier,
    isRetailer,
    isChapterLibrary,
    isChapterOwnedBySupplier,
  });

  return (
    <Card
      collapsible
      expand
      data-test-id={`chapter-section-${sectionName}`}
      className={classNames(
        styles.header,
        styles[sectionStatus?.toLowerCase()]
      )}
      title={
        <span
          data-testid="subsection-name"
          ref={currentSectionsRefs[sectionId]}
        >
          <Heading size="s">{sectionName}</Heading>
        </span>
      }
      extra={
        customSectionsContentMap[sectionCode] ? null : (
          <Button
            type="tertiary"
            iconName="comment"
            onClick={onOpenModal}
            disabled={!userGuide}
          />
        )
      }
      data-section-id={section?.sectionId}
    >
      {!!customSectionsContentMap[sectionCode] ? (
        customSectionsContentMap[sectionCode]
      ) : (
        <SectionContent
          sectionId={sectionId}
          sectionCode={sectionCode}
          sectionStatus={sectionStatus}
          validationStatus={validationStatus}
          validatedBySupplier={validatedBySupplier}
          blocks={blocksToDisplay}
          setSectionBlocks={setSectionBlocks}
          permissions={permissions}
          apiBlocks={blocks}
        />
      )}

      {isModalOpen && (
        <UserGuideModal onCancel={onCloseModal} userGuide={userGuide} />
      )}
    </Card>
  );
};

export default Section;
