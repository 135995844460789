import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import FormCard from "../FormCard";
import { FILE_TYPES } from "utils/constants";
import messages from "messages/documents";
import MaterialDocumentUpload from "../MateriaDocumentsUpload";
import { Col, Row } from "antd";
import { useFilesInfo } from "hooks";

export interface AttachmentProps {
  fileIds?: string[];
  displayActions?: boolean;
}

const Attachment: React.FC<AttachmentProps> = ({ fileIds, displayActions }) => {
  const { formatMessage } = useIntl();
  const { files } = useFilesInfo({ fileIds });

  const technicalDataSheetFiles = useMemo(() => {
    return files.filter(
      file => file.fileType === FILE_TYPES.MATERIAL_TECHNICAL_DATA_SHEET
    );
  }, [files]);

  const otherDocuments = useMemo(() => {
    return files.filter(file => file.fileType === FILE_TYPES.MATERIAL_DEFAULT);
  }, [files]);

  return (
    <FormCard title={formatMessage(messages.attachment)} name="attachment">
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <MaterialDocumentUpload
            title={formatMessage(messages.technicalDataSheet)}
            fileType={FILE_TYPES.MATERIAL_TECHNICAL_DATA_SHEET}
            files={technicalDataSheetFiles}
            displayActions={displayActions}
            allFileIds={fileIds}
          />
        </Col>

        <Col span={24}>
          <MaterialDocumentUpload
            title={formatMessage(messages.otherDocuments)}
            fileType={FILE_TYPES.MATERIAL_DEFAULT}
            files={otherDocuments}
            displayActions={displayActions}
            allFileIds={fileIds}
          />
        </Col>
      </Row>
    </FormCard>
  );
};

export default Attachment;
