import useBoolean from "hooks/useBoolean";
import { downloadFile } from "apis/DOCUMENT";
import { downloadFileWithCustomName } from "utils/files";
import { FileData } from "models";

export const useFileDownload = () => {
  const { value: downloading, setTrue, setFalse } = useBoolean(false);

  const onDownload = async ({
    id,
    fileName,
    fileExtension,
  }: {
    id: FileData["id"];
    fileName?: FileData["name"];
    fileExtension?: FileData["fileExtension"];
  }) => {
    if (!id) {
      return;
    }

    try {
      setTrue();
      const { data } = await downloadFile({ id });

      downloadFileWithCustomName({
        data,
        fileType: fileExtension,
        fileName,
      });
    } catch {
    } finally {
      setFalse();
    }
  };

  return {
    downloading,
    onDownload,
  };
};

export default useFileDownload;
