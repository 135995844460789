import { defineMessages } from "react-intl";

const rawMaterialFormMessages = defineMessages({
  rawMaterialDetailsCardTitle: {
    id: "library.rawMaterialForm.details.cardTitle",
    defaultMessage: "Raw material",
  },
  rawMaterialClassification: {
    id: "library.rawMaterialForm.details.classification",
    defaultMessage: "Classification",
  },
  rawMaterialDetailsPart: {
    id: "library.rawMaterialForm.details.part",
    defaultMessage: "Raw material part",
  },
  rawMaterialDetailsFunctions: {
    id: "library.rawMaterialForm.details.functions",
    defaultMessage: "Recommended function(s)",
  },
  rawMaterialDetailsFunctionsPlaceholder: {
    id: "library.rawMaterialForm.details.functions.placeholder",
    defaultMessage: "Add a function",
  },
});

export default rawMaterialFormMessages;
