import { useIntl } from "react-intl";
import { Row } from "antd";
import { Button, Heading, Icon, Paragraph } from "@trace-one/design-system";
import ValidationToggle from "../ValidationToggle";
import TranslationManagement from "components/TranslationManagement";
import { isListEmpty } from "utils/general";
import { messages } from "./messages";
import { TitleProps } from "./types";
import styles from "./styles.module.less";

const Title: React.FC<TitleProps> = ({
  hasValidation = true,
  isValidationDisabled,
  isValidationLoading,
  hasValidationErrors = false,
  hasValidationPermission,
  onValidate = () => {},
  sectionName,
  sectionStatus,
  sectionIcon,
  sectionActions,
  primaryAction,
  isTargetMode,
  isTranslator,
  labelingTranslationLanguages,
  translationId,
  getTranslation,
  getLabelingTranslationLanguages,
  setLabelingTranslationLanguages,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <Row justify="end" align="middle">
        <div className={styles.headerWrapper}>
          <Heading size="m">{sectionName}</Heading>
          {sectionIcon && (
            <div
              className={styles.linkIconLeftSpace}
              data-testid={`icon-${sectionIcon}`}
            >
              <Icon name={sectionIcon} />
            </div>
          )}
          {primaryAction}
        </div>
        {isTranslator && (
          <TranslationManagement.TranslationCompletedToggle
            labelingTranslationLanguages={labelingTranslationLanguages}
            getTranslation={getTranslation}
            getLabelingTranslationLanguages={getLabelingTranslationLanguages}
            translationId={translationId}
            setLabelingTranslationLanguages={setLabelingTranslationLanguages}
          />
        )}
        {!isTranslator && hasValidation && (
          <ValidationToggle
            isValidationDisabled={isValidationDisabled || isTargetMode}
            sectionStatus={sectionStatus}
            validationText={formatMessage(messages.validateAllSections)}
            onValidate={onValidate}
            hasValidationErrors={hasValidationErrors}
            isValidationLoading={isValidationLoading}
            hasValidationPermission={hasValidationPermission}
          />
        )}
        {!isListEmpty(sectionActions) && (
          <Button
            type="tertiary"
            iconName="settings"
            items={sectionActions}
            className={styles.sectionActionsIcon}
            data-testid={`section-actions-button`}
            disabled={isTargetMode}
          />
        )}
      </Row>
      {hasValidationErrors && (
        <Row justify="end">
          <Paragraph color="red">
            {formatMessage(messages.chapterValidationErrorMessage)}
          </Paragraph>
        </Row>
      )}
    </div>
  );
};

export default Title;
