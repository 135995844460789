import { UiSchema } from "@rjsf/core";
import classNames from "classnames";
import { Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import ConditionalWrapper from "components/ConditionalWrapper";
import NutriScoreCard from "components/NutriScoreCard";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import {
  isNutriScoreCalculationStateError,
  isNutriScoreCalculationStateMandatoryFieldsMissing,
} from "utils/nutrition";
import { NUTRISCORE_CALCULATION_STATE } from "utils/constants";
import { NutriScoreData } from "models";
import styles from "../../styles.module.less";

const nutriscoreCalculationStateMessageKeyMap = {
  [NUTRISCORE_CALCULATION_STATE.ERROR.toUpperCase()]: "nutriScoreCalculationCatalogApiError",
  [NUTRISCORE_CALCULATION_STATE.MANDATORY_FIELDS_MISSING.toUpperCase()]: "nutriscoreEmptyMessage",
  [NUTRISCORE_CALCULATION_STATE.PRODUCTION_CHAPTER_NOT_LINKED.toUpperCase()]: "nutriscoreEmptyMessage",
};

export type NutriscoreDetailsProps = {
  displayEmptyState: boolean;
  displayActions: boolean;
  uiSchema: UiSchema;
  nutriscore: NutriScoreData;
  nutriscoreCalculationMissingReason: string;
};

const NutriscoreDetails: React.FC<NutriscoreDetailsProps> = ({
  displayEmptyState,
  nutriscoreCalculationMissingReason,
  nutriscore,
  displayActions,
  uiSchema,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <Row
      justify={displayEmptyState ? "center" : "start"}
      className={classNames(styles["nutri-score"], {
        [styles["nutri-score-empty"]]: displayEmptyState,
      })}
    >
      {displayEmptyState ? (
        <EmptyState
          description={
            <ConditionalWrapper
              condition={
                isNutriScoreCalculationStateError(
                  nutriscoreCalculationMissingReason
                ) ||
                isNutriScoreCalculationStateMandatoryFieldsMissing(
                  nutriscoreCalculationMissingReason
                )
              }
              wrapper={children => (
                <span className="color-red">{children}</span>
              )}
            >
              {getTranslationForKey(
                nutriscoreCalculationStateMessageKeyMap[
                  nutriscoreCalculationMissingReason.toUpperCase()
                ] || "nutriscoreEmptyMessage"
              )}
            </ConditionalWrapper>
          }
        />
      ) : (
        <NutriScoreCard
          nutriScore={nutriscore}
          uiSchema={uiSchema?.nutriscore}
          displayActions={displayActions}
        />
      )}
    </Row>
  );
};

export default NutriscoreDetails;
