import { Typography, ProgressBar } from "@trace-one/design-system";
import { getPercentValue } from "utils/general";
import { getProgressBarColor } from "./utils";
import styles from "./styles.module.less";

export type TranslationCardProps = {
  language: string;
  companyName: string;
  progression: number;
};

const TranslationCard: React.FC<TranslationCardProps> = ({
  language,
  companyName,
  progression,
}) => {
  const percent = getPercentValue(progression);

  return (
    <div className={styles["language-card"]} key={language}>
      <Typography
        variant="heading-xxs"
        color="grey-5"
        className="text-ellipsis"
      >
        {language}
      </Typography>
      <Typography
        variant="body-xs"
        color="grey-5"
        className="mt-0-5 text-ellipsis"
      >
        {companyName}
      </Typography>
      <ProgressBar
        responsive
        color={getProgressBarColor(percent)}
        infoTextColor={percent > 99 ? "green" : "grey-5"}
        percent={percent}
        className="mt-0-5"
      />
    </div>
  );
};

export default TranslationCard;
