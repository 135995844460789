import { useMemo } from "react";
import { isListEmpty } from "utils/general";
import { FileViewModel } from "viewModels";

export interface FilesWithMaterialInfo extends FileViewModel {
  material: {
    name: string;
    type: string;
    recipeType?: string;
  };
}

const useTechnicalInformation = ({ files, ingredients }) => {
  const filesWithMaterialInfo: FilesWithMaterialInfo[] = useMemo(() => {
    const newFileInfo = files.map(file => {
      const material = ingredients.reduce(
        (acc, { fileIds, name, type, recipeType }) => {
          if (!isListEmpty(fileIds) && fileIds?.includes(file.id)) {
            return {
              ...acc,
              name: acc.name ? `${acc.name}, ${name}` : name,
              type,
              recipeType,
            };
          }

          return acc;
        },
        {}
      );

      return {
        ...file,
        material,
      };
    });

    return newFileInfo;
  }, [files, ingredients]);

  return {
    filesWithMaterialInfo: filesWithMaterialInfo.sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
  };
};

export default useTechnicalInformation;
