import { useMemo } from "react";
import { ChapterStructureFormData } from "types/library";
import { SECTION_BLOCK_CODE, SECTION_CODE } from "utils/constants";
import { getParsedData } from "utils/general";

export type useRecipeAllergensDetailsProps = {
  sections: ChapterStructureFormData[];
};

const useRecipeAllergensDetails = ({
  sections,
}: useRecipeAllergensDetailsProps) => {
  const allergensSection = useMemo(
    () =>
      sections.find(
        ({ sectionCode }) => sectionCode === SECTION_CODE.ALLERGENS
      ),

    [JSON.stringify(sections)]
  );

  const {
    crossContaminationFormData,
    crossContaminationUISchema,
    freeFromAllergenFormData,
    freeFromAllergenSchema,
    freeFromAllergenUISchema,
  } = useMemo(() => {
    const crossContaminationBlock = allergensSection?.blocks?.find(
      ({ blockCode }) => blockCode === SECTION_BLOCK_CODE.CROSS_CONTAMINATION
    );

    const freeFromAllergensBlock = allergensSection?.blocks?.find(
      ({ blockCode }) => blockCode === SECTION_BLOCK_CODE.FREE_FROM_ALLERGENS
    );

    return {
      crossContaminationFormData: getParsedData(
        crossContaminationBlock?.dataAsJson
      ),
      crossContaminationUISchema: getParsedData(
        crossContaminationBlock?.uiSchema
      ),
      freeFromAllergenFormData: getParsedData(
        freeFromAllergensBlock?.dataAsJson
      ),
      freeFromAllergenUISchema: getParsedData(freeFromAllergensBlock?.uiSchema),
      freeFromAllergenSchema: getParsedData(freeFromAllergensBlock?.jsonSchema),
    };
  }, [JSON.stringify(allergensSection)]);

  return {
    crossContaminationFormData: crossContaminationFormData?.items || [],
    crossContaminationUISchema: crossContaminationUISchema?.items || {},
    freeFromAllergenFormData: freeFromAllergenFormData?.allergens || [],
    freeFromAllergenSchema: freeFromAllergenSchema?.properties?.allergens || {},
    freeFromAllergenUISchema: freeFromAllergenUISchema?.allergens || {},
  };
};

export default useRecipeAllergensDetails;
