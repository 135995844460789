import { Col, Row } from "antd";
import { Heading } from "@trace-one/design-system";

export type FieldLabelValueProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  className?: string;
};

const FieldLabelValue: React.FC<FieldLabelValueProps> = ({
  label,
  value,
  className,
}) => {
  return (
    <Row {...(className && { className })}>
      <Col span={8}>
        <Heading size="xxs">{label}</Heading>
      </Col>
      <Col>
        <Heading size="xxs">{value}</Heading>
      </Col>
    </Row>
  );
};

export default FieldLabelValue;
