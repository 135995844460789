import useBoolean from "hooks/useBoolean";

const useInputValidation = (
  onChange: Function,
  allowEmptyValue: boolean = false
) => {
  const { value: hasError, setTrue, setFalse } = useBoolean(false);

  const onInputChange = (value: React.ReactNode) => {
    if (!value) {
      setTrue();

      if (allowEmptyValue) {
        onChange?.(value);
      }
    } else {
      onChange?.(value);
      setFalse();
    }
  };

  return {
    hasError,
    onInputChange,
  };
};

export default useInputValidation;
