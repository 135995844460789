import { Tabs, Spinner } from "@trace-one/design-system";
import { LabelingTranslationLaguageData } from "models";
import { isListEmpty } from "utils/general";

export interface LabelingLanguagesTabsProps {
  languages: LabelingTranslationLaguageData[];
  activeLanguage: string;
  onChange?: (value: string) => void;
}

export const LabelingLanguagesTabs = ({
  languages: tabsLanguages,
  activeLanguage,
  onChange,
}: LabelingLanguagesTabsProps) => {
  if (isListEmpty(tabsLanguages)) {
    return (
      <div className="mb-3">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mb-3">
      <Tabs.Mini
        items={tabsLanguages}
        onChange={onChange}
        activeKey={activeLanguage}
      />
    </div>
  );
};
