import { defineMessages } from "react-intl";

const partDropdownMessages = defineMessages({
  rawMaterialDetailsPartPlaceholder: {
    id: "library.rawMaterialForm.details.part.placeholder",
    defaultMessage: "Choose a part",
  },
});

export default partDropdownMessages;
