import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  InputNumber,
  Radio,
  Button,
} from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { Col, Form, Row } from "antd";
import { useSubstanceInformation } from "hooks";
import { SubstanceSelect } from "components";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import {
  findPropertyByKey,
  getFieldTitleFromUiSchemaArrayTemplate,
  getFieldTitleFromUiSchemaObjectTemplate,
} from "utils/general";
import { getInputNumberProps } from "../../utils";
import { PackagingSubstance, PackagingSubstanceFormData } from "models";
import { AREA_CATEGORY_TYPES } from "utils/constants";

const initialSubstanceForm: PackagingSubstance = {
  selectedSubstance: null,
  percentage: null,
  presentAtNanoscale: false,
  presenceAtNanoscale: null,
};

export interface PackagingSubstanceFormProps
  extends ObjectFieldTemplateProps<PackagingSubstanceFormData> {
  substances: PackagingSubstance[];
  setSubstances: React.Dispatch<React.SetStateAction<PackagingSubstance[]>>;
}

export const PackagingSubstanceForm: React.FC<PackagingSubstanceFormProps> = props => {
  const {
    formContext,
    uiSchema,
    schema,
    substances,
    setSubstances,
    formData,
  } = props;

  const { displayActions } = formContext;

  const substanceIdProperty = findPropertyByKey(schema, "substanceId");

  const percentageInputProps = getInputNumberProps(
    findPropertyByKey(schema, "percentage")
  );
  const sizeInputProps = getInputNumberProps(findPropertyByKey(schema, "size"));
  const specificSurfaceInputProps = getInputNumberProps(
    findPropertyByKey(schema, "specificSurface")
  );

  const { validationRequired } = findPropertyByKey(schema, "items") || {};

  const disabledIds = useMemo(() => {
    return [
      ...(substances ?? []).map(substance => substance.selectedSubstance.id),
      ...(formData?.substances ?? []).map(substance => substance.substanceId),
    ];
  }, [substances, formData]);

  const { getTranslationForKey } = useFormSchemaTranslation();
  const {
    isLoading,
    options,
    selectedSubstance,
    onSearch,
    onSelect,
    onClear,
    onDropdownVisibleChange,
  } = useSubstanceInformation({
    areaCategory: substanceIdProperty?.areaCategory as AREA_CATEGORY_TYPES,
    disabledIds,
  });

  const { casNumbers: casNumbersList } = selectedSubstance || {};
  const casNumbers = casNumbersList?.join("; ");

  const [substanceForm, setSubstanceForm] = useState(initialSubstanceForm);
  const [form] = Form.useForm();

  useEffect(() => {
    setSubstanceForm({
      ...substanceForm,
      selectedSubstance,
    });
  }, [selectedSubstance]);

  const { percentage } = substanceForm;

  const hasMandtatoryFieldsFilled = selectedSubstance && percentage !== null;

  const resetSubstanceForm = () => {
    form.setFields([
      {
        name: "percentage",
        value: null,
      },
      {
        name: "size",
        value: null,
      },
      {
        name: "specificSurface",
        value: null,
      },
    ]);
    onClear();
    setSubstanceForm(initialSubstanceForm);
  };
  return (
    <>
      <Typography variant="body-regular" color="grey-5" className="mb-4 mt-1">
        {getTranslationForKey("addSubstanceSubtitle")}
      </Typography>

      <Form layout="vertical" form={form}>
        <SubstanceSelect
          options={options}
          isLoading={isLoading}
          onSearch={onSearch}
          onSelect={onSelect}
          onClear={onClear}
          onDropdownVisibleChange={onDropdownVisibleChange}
          displayActions={displayActions}
          selectedSubstance={selectedSubstance}
          label={getFieldTitleFromUiSchemaArrayTemplate(
            uiSchema.substances,
            "substanceId"
          )}
          placeholder={getTranslationForKey("selectSubstancePlaceholder")}
          width={18}
          data-test-id="substance-name"
          name="substance-name"
        />
        <Row className="mt-2">
          <Col xs={12} md={4}>
            <Typography variant="heading-xxs" color="grey-5">
              {getTranslationForKey("casNumber")}
            </Typography>
          </Col>
          <Col>
            <Typography variant="body-regular" color="grey-5">
              {casNumbers
                ? casNumbers
                : getTranslationForKey("undefinedMessage")}
            </Typography>
          </Col>
        </Row>

        <Form.Item
          className="mt-3"
          name="percentage"
          label={getFieldTitleFromUiSchemaArrayTemplate(
            uiSchema.substances,
            "percentage"
          )}
          rules={[
            {
              required: validationRequired?.includes("percentage"),
              type: "number",
              min: percentageInputProps.min,
              max: percentageInputProps.max,
            },
          ]}
        >
          <InputNumber
            data-testid="packaging-component-percentage"
            placeholder={0}
            {...percentageInputProps}
            step={1}
            onChange={value => {
              setSubstanceForm(substanceForm => ({
                ...substanceForm,
                percentage: value,
              }));
            }}
          />
        </Form.Item>

        <Row>
          <Col md={6}>
            <Typography variant="heading-xxs">
              {getFieldTitleFromUiSchemaArrayTemplate(
                uiSchema.substances,
                "presentAtNanoscale"
              )}
            </Typography>
          </Col>
          <Col>
            <Radio.Group
              value={substanceForm.presentAtNanoscale}
              options={[
                { value: true, label: getTranslationForKey("yes") },
                { value: false, label: getTranslationForKey("no") },
              ]}
              onChange={event => {
                setSubstanceForm(substanceForm => ({
                  ...substanceForm,
                  presentAtNanoscale: event.target.value,
                }));
              }}
            />
          </Col>
        </Row>

        {substanceForm.presentAtNanoscale && (
          <Row className="mt-2">
            <Col md={5}>
              <Form.Item
                name="size"
                label={getFieldTitleFromUiSchemaObjectTemplate(
                  uiSchema?.substances?.items?.presenceAtNanoscale,
                  "size"
                )}
                rules={[
                  {
                    type: "number",
                    min: sizeInputProps.min,
                    max: sizeInputProps.max,
                  },
                ]}
                className="mb-0"
              >
                <InputNumber
                  data-testid="substance-presenceAtNanoscale-size"
                  placeholder={0}
                  {...sizeInputProps}
                  onChange={value => {
                    setSubstanceForm(substanceForm => ({
                      ...substanceForm,
                      presenceAtNanoscale: {
                        ...substanceForm.presenceAtNanoscale,
                        size: value,
                      },
                    }));
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="specificSurface"
                label={getFieldTitleFromUiSchemaObjectTemplate(
                  uiSchema?.substances?.items?.presenceAtNanoscale,
                  "specificSurface"
                )}
                rules={[
                  {
                    type: "number",
                    min: specificSurfaceInputProps.min,
                    max: specificSurfaceInputProps.max,
                  },
                ]}
                className="mb-0"
              >
                <InputNumber
                  data-testid="substance-presenceAtNanoscale-specificSurface"
                  placeholder={0}
                  {...specificSurfaceInputProps}
                  onChange={value => {
                    setSubstanceForm(substanceForm => ({
                      ...substanceForm,
                      presenceAtNanoscale: {
                        ...substanceForm.presenceAtNanoscale,
                        specificSurface: value,
                      },
                    }));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Button
          type="tertiary"
          className="mt-3 mb-3"
          disabled={!hasMandtatoryFieldsFilled}
          onClick={() => {
            setSubstances(substances => [...substances, substanceForm]);
            resetSubstanceForm();
          }}
        >
          {getTranslationForKey("addToSelection")}
        </Button>
      </Form>
    </>
  );
};
