import { defineMessages } from "react-intl";

export const messages = defineMessages({
  attachment: {
    id: "attachment",
    defaultMessage: "Attachment",
  },
  noAttachment: {
    id: "noAttachment",
    defaultMessage: "No attachment",
  },
  technicalDataSheet: {
    id: "technicalDataSheet",
    defaultMessage: "Technical data sheet",
  },
  otherDocuments: {
    id: "otherDocuments",
    defaultMessage: "Other documents",
  },
  documents: {
    id: "documents",
    defaultMessage: "Documents",
  },
  creationDocumentSubtitle: {
    id: "creation.document.subtitle",
    defaultMessage: "Share and ask for documents",
  },
  documentInformationTitle: {
    id: "documentInformation.title",
    defaultMessage: "Document information",
  },
  documentsSelectionTitle: {
    id: "documentInformation.documentsSelection.title",
    defaultMessage: "Documents selection ({numberOfDocumentsSelected})",
  },
  selectADocument: {
    id: "documentInformation.selectADocument",
    defaultMessage: "Select a document to display details",
  },
  download: {
    id: "document.download",
    defaultMessage: "Download",
  },
  extension: {
    id: "document.extension",
    defaultMessage: "Extension",
  },
  documentType: {
    id: "document.documentType",
    defaultMessage: "Document type",
  },
  size: {
    id: "document.size",
    defaultMessage: "Size",
  },
  allDocuments: {
    id: "document.allDocuments",
    defaultMessage: "All documents",
  },
  documentsLinkedToTheSpecification: {
    id: "document.documentsLinkedToTheSpecification",
    defaultMessage: "Documents linked to the specification",
  },
  documentsLinkedToChapters: {
    id: "document.documentsLinkedToChapters",
    defaultMessage: "Documents linked to chapters",
  },
  documentsLinkedToLibraryItems: {
    id: "document.documentsLinkedToLibraryItems",
    defaultMessage: "Documents linked to Library items",
  },

  searchPlaceholder: {
    id: "document.searchByName",
    defaultMessage: "Search a document by name",
  },
});

export default messages;
