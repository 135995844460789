import {
  fetchChapterAllergens,
  fetchSpecificationChapterAllergens,
} from "apis/SPEC";
import useCatalogDictionary from "hooks/useCatalogDictionary";
import { ChapterAllergenData } from "models";
import { useEffect, useState } from "react";
import { CHAPTER_TYPES_API } from "utils/constants";

const useChapterAllergens = ({
  id,
  chapterType,
  isSpecContext,
  shouldCallApi,
}: {
  id: string;
  chapterType: string;
  shouldCallApi: boolean;
  isSpecContext?: boolean;
}) => {
  const [rawMaterialsAllergens, setRawMaterialsAllergens] = useState<
    ChapterAllergenData[]
  >([]);
  const [
    crossContaminationAllergens,
    setCrossContaminationAllergens,
  ] = useState<ChapterAllergenData[]>([]);
  const [categoryItemsIds, setCategoryItemsIds] = useState<string[]>([]);

  const allergensFetcher = isSpecContext
    ? fetchSpecificationChapterAllergens
    : fetchChapterAllergens;

  useCatalogDictionary({ catalogAllergenIds: categoryItemsIds });

  const fetchAllergens = async () => {
    const result = await Promise.allSettled([
      Promise.resolve(
        allergensFetcher({
          id,
          chapterType,
          unintentionalPresent: false,
        })
      ),

      Promise.resolve(
        allergensFetcher({
          id,
          chapterType,
          unintentionalPresent: true,
        })
      ),
    ]);

    const [
      rawMaterialsAllergensPromise,
      crossContaminationAllergensPromise,
    ] = result;

    if (rawMaterialsAllergensPromise.status === "fulfilled") {
      setRawMaterialsAllergens(rawMaterialsAllergensPromise.value?.data);
    }

    if (crossContaminationAllergensPromise.status === "fulfilled") {
      setCrossContaminationAllergens(
        crossContaminationAllergensPromise.value?.data
      );
    }
  };

  useEffect(() => {
    if (chapterType === CHAPTER_TYPES_API.production && shouldCallApi) {
      fetchAllergens();
    }
  }, [chapterType, shouldCallApi]);

  useEffect(() => {
    if (
      Array.isArray(rawMaterialsAllergens) &&
      Array.isArray(crossContaminationAllergens)
    ) {
      setCategoryItemsIds(
        [...rawMaterialsAllergens, ...crossContaminationAllergens].map(
          ({ allergenId }) => allergenId
        )
      );
    }
  }, [rawMaterialsAllergens?.length, crossContaminationAllergens?.length]);

  return {
    crossContaminationAllergens,
    rawMaterialsAllergens,
    fetchAllergens,
  };
};

export default useChapterAllergens;
