import { ChapterSectionData } from "models";
import {
  ChapterStructureFormData,
  LibraryObjectType,
  MaterialType,
  RecipeType,
  SectionState,
} from "types/library";
import { CompositeAllergens } from "components/Library/components/MaterialForm/components/BoughtDetails/components/Allergens/hooks/useAllergensTable/types";
import {
  LIBRARY_CHAPTER_TYPE,
  LIBRARY_OBJECTS_PAGES,
  LIBRARY_OBJECT_STATES,
  LIBRARY_OBJECT_TYPES,
  LIBRARY_REQUIREMENT_TYPES,
  MATERIAL_TYPES,
  PRODUCT_TYPES,
  RECIPE_TYPES,
  REFLIST_IDS,
} from "./constants";
import { isListEmpty } from "./general";
import { isClaimTypeHealth, isClaimTypeMarketing } from "./claim";
import { isStateValidatedBySupplier } from "./specifications";
import { MaterialAllergenViewModel } from "viewModels";
import { PageType } from "components/Library/components/types";

export const getMessageKeyForType = (type: string): string => {
  switch (type) {
    case "raw-materials":
    case "rawMaterial":
      return "rawMaterial";
    case "composites":
      return "composite";
    case "chapters":
      return "chapter";
    case "materials":
    case "material":
      return "material";
    case "template":
    case "templates":
      return "template";
    case "claim":
    case "claims":
      return "claim";
    default:
      return "requirement";
  }
};

export const isLibraryObjectLocked = (state: string): boolean =>
  state === LIBRARY_OBJECT_STATES.LOCKED;

export const isLibraryObjectDraft = (state: string): boolean =>
  state === LIBRARY_OBJECT_STATES.DRAFT;

export const isLibraryObjectPublished = (state: string): boolean =>
  state === LIBRARY_OBJECT_STATES.PUBLISHED;

export const isLibraryObjectChaptersType = (
  type: LibraryObjectType
): boolean => {
  const chapterTypes: LibraryObjectType[] = [
    LIBRARY_OBJECT_TYPES.CHAPTERS,
    LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS,
    LIBRARY_OBJECT_TYPES.PRODUCTION,
    LIBRARY_OBJECT_TYPES.TESTING,
  ];

  return chapterTypes.includes(type);
};

export const isLibraryObjectPackagingSystemsType = (type: string): boolean =>
  type === LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS;

export const isLibraryObjectTemplatesType = (type: string) =>
  type === LIBRARY_OBJECT_TYPES.TEMPLATES ||
  isProductTypeFAndV(type) ||
  isProductTypeFood(type);

export const isLibraryObjectClaimType = (type: string): boolean =>
  type === LIBRARY_OBJECT_TYPES.CLAIMS ||
  isClaimTypeHealth(type) ||
  isClaimTypeMarketing(type);

export const isLibraryObjectRequirementType = (type: string) =>
  [
    "requirement",
    LIBRARY_OBJECT_TYPES.REQUIREMENTS,
    LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS,
    LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS,
  ].includes(type);

export const isLibraryObjectMaterialType = (materialType: string) =>
  isMaterialRawMaterialType(materialType) ||
  isMaterialCompositeType(materialType) ||
  isMaterialMaterialsType(materialType) ||
  isMaterialAdditiveType(materialType) ||
  isMaterialProcessingAidType(materialType);

export const isMaterialRawMaterialType = (materialType: string) =>
  materialType === MATERIAL_TYPES.RAW_MATERIAL;

export const isMaterialCompositeType = (materialType: string) =>
  materialType === MATERIAL_TYPES.COMPOSITE ||
  isMaterialCompositeBoughtToThirdParty(materialType) ||
  isMaterialCompositeMadeInHouse(materialType);

export const isMaterialAdditiveType = (materialType: string) =>
  materialType === MATERIAL_TYPES.ADDITIVE;

export const isMaterialProcessingAidType = (materialType: string) =>
  materialType === MATERIAL_TYPES.PROCESSING_AID;

export const isMaterialMaterialsType = (materialType: string) =>
  materialType === LIBRARY_OBJECT_TYPES.MATERIALS;

export const isMaterialCompositeBoughtToThirdParty = (recipeType: string) =>
  recipeType === RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY;

export const isMaterialCompositeMadeInHouse = (recipeType: string) =>
  recipeType === RECIPE_TYPES.MADE_IN_HOUSE;

export const isLibraryRequirementTypeDocument = (type: string): boolean =>
  type === LIBRARY_REQUIREMENT_TYPES.DOCUMENT;

export const isLibraryRequirementTypeText = (type: string): boolean =>
  type === LIBRARY_REQUIREMENT_TYPES.TEXT;

export const isLibraryObjectRequirement = (requirementType: string) =>
  isLibraryRequirementTypeDocument(requirementType) ||
  isLibraryRequirementTypeText(requirementType);

export const isLibraryObjectTesting = (type: string) =>
  type === LIBRARY_CHAPTER_TYPE.TESTING;

export const isLibraryObjectProduction = (type: string) =>
  type === LIBRARY_CHAPTER_TYPE.PRODUCTION;

export const isLibraryObjectFinishedProduct = (type: string) =>
  type === LIBRARY_CHAPTER_TYPE.FINISHED_PRODUCT;

export const isLibraryObjectChapterSubType = (type: string) =>
  isLibraryObjectTesting(type) ||
  isLibraryObjectProduction(type) ||
  isLibraryObjectFinishedProduct(type);

export const prepareRecipeAllergens = (
  allergens: Pick<
    MaterialAllergenViewModel,
    | "allergenId"
    | "presentForIngredientIds"
    | "unintentionalPresentForIngredientIds"
  >[]
) => {
  let ingredientAllergens: CompositeAllergens = {};

  allergens.forEach(
    ({
      allergenId,
      presentForIngredientIds,
      unintentionalPresentForIngredientIds,
    }) => {
      ingredientAllergens[allergenId] = {
        allergenId,
        presentForIngredientIds,
        unintentionalPresentForIngredientIds,
      };
    }
  );

  return ingredientAllergens;
};

export const getIsValidationDisabled = ({
  isRetailer,
  sectionStatus,
  isChapterOwnedBySupplier,
  validatedBySupplier,
}: {
  isRetailer: boolean;
  sectionStatus: string;
  isChapterOwnedBySupplier: boolean;
  validatedBySupplier: boolean;
}) => {
  if (isRetailer && sectionStatus === "validatedByRetailer") {
    return true;
  }

  if (!isRetailer) {
    if (isStateValidatedBySupplier(sectionStatus)) {
      return true;
    }

    if (isChapterOwnedBySupplier && validatedBySupplier) {
      return true;
    }

    if (!isChapterOwnedBySupplier && sectionStatus === "validatedByRetailer") {
      return true;
    }
  }

  return false;
};

export const prepareValidationStatus = ({
  state,
  validatedBySupplier,
  isRetailer,
  isChapterOwnedBySupplier,
  isChapterLibrary,
  sections,
}: {
  state: string;
  validatedBySupplier: boolean;
  isRetailer?: boolean;
  isChapterOwnedBySupplier?: boolean;
  isChapterLibrary?: boolean;
  sections?: ChapterStructureFormData["sections"];
}) => {
  if (state !== "validatedByRetailer") return state;

  if (validatedBySupplier) return "validatedByRetailer";

  if (!validatedBySupplier) {
    if (
      !isRetailer &&
      isChapterLibrary &&
      !isChapterOwnedBySupplier &&
      state === "validatedByRetailer"
    ) {
      return "validatedByRetailer";
    }

    if (
      !isRetailer &&
      isChapterLibrary &&
      isChapterOwnedBySupplier &&
      !isListEmpty(sections) &&
      sections
        ?.filter(
          section =>
            !["requirements", "documents"].includes(section.sectionCode)
        )
        .every(section => section.validatedBySupplier)
    ) {
      return "validatedByRetailer";
    }

    if (!isRetailer && !isChapterLibrary && state === "validatedByRetailer") {
      return "validatedByRetailer";
    }
  }

  return "validatedByRetailerWithoutSupplier";
};

export const isValidatedByCompany = ({
  validatedByCompanyIds,
  companyId,
}: {
  validatedByCompanyIds: string[];
  companyId: string;
}) => {
  if (isListEmpty(validatedByCompanyIds)) return false;

  return validatedByCompanyIds.includes(companyId);
};

export const getHeaderStatusName = ({
  state,
  isRetailer,
  isTranslator,
}: {
  state: string;
  isRetailer?: boolean;
  isTranslator?: boolean;
}) => {
  if (state !== "validated") return state;

  if (isRetailer) return "validatedByRetailer";
  if (isTranslator) return "validatedByTranslator";

  return "validatedBySupplier";
};

export const getSectionStatus = ({
  state,
  retailerCompnayId,
  supplierCompnayId,
  translatorCompanyId,
}: {
  retailerCompnayId: string;
  supplierCompnayId: string;
  translatorCompanyId: string;
  state: SectionState;
}) => {
  if (!state) return;

  const { state: chapterState, validatedByCompanyIds = [] } = state || {};

  if (chapterState === "validatedByRetailer") return chapterState;

  let validatedBy = undefined;

  if (validatedByCompanyIds.includes(supplierCompnayId)) {
    validatedBy = "validatedBySupplier";
  }

  if (validatedByCompanyIds.includes(retailerCompnayId)) {
    validatedBy = "validatedByRetailer";
  }
  if (validatedByCompanyIds.includes(translatorCompanyId)) {
    validatedBy = "validatedByTranslator";
  }

  if (!validatedBy) {
    validatedBy = chapterState;
  }

  return validatedBy;
};

export const updateInfoForStructure = ({
  structure,
  sections,
  validatedByRetailer = false,
}: {
  structure: ChapterStructureFormData[];
  sections: ChapterSectionData[];
  validatedByRetailer?: boolean;
}): ChapterStructureFormData[] =>
  structure?.map(currentStructure => {
    const currentSection = sections?.find(
      currentSection => currentSection.sectionId === currentStructure.sectionId
    );

    const state =
      currentStructure?.sectionCode === "requirements" ||
      currentStructure?.sectionCode === "documents"
        ? currentStructure?.state
        : currentSection?.state;

    return {
      ...currentStructure,
      permissions: currentSection?.permissions,
      state,
      sections: updateInfoForStructure({
        structure: currentStructure?.sections,
        sections,
        validatedByRetailer,
      }),
      ...(!isListEmpty(currentStructure?.blocks) && {
        blocks: currentStructure?.blocks?.map(currentBlock => {
          const currentSectioBlock = currentSection?.blocks?.find(
            ({ blockId }) => blockId === currentBlock.blockId
          );

          return {
            ...currentBlock,
            dataAsJson: currentSectioBlock?.blockJson,
            readOnlyFieldIds: currentSectioBlock?.readOnlyFieldIds,
          };
        }),
      }),
    };
  });

export const getMaterialApiType = ({
  materialType,
  recipeType,
}: {
  materialType: MaterialType;
  recipeType?: RecipeType;
}) => {
  if (isMaterialRawMaterialType(materialType)) {
    return LIBRARY_OBJECT_TYPES.RAW_MATERIALS;
  }

  if (isMaterialAdditiveType(materialType)) {
    return LIBRARY_OBJECT_TYPES.ADDITIVES;
  }

  if (isMaterialProcessingAidType(materialType)) {
    return LIBRARY_OBJECT_TYPES.PROCESSING_AIDS;
  }

  if (isMaterialCompositeMadeInHouse(recipeType)) {
    return LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE;
  }

  return LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY;
};

export const isLibraryObjectDetailsPage = (page: PageType): boolean =>
  page === LIBRARY_OBJECTS_PAGES.DETAILS;

export const isLibraryObjectLabelingTranslationPage = (
  page: PageType
): boolean => page === LIBRARY_OBJECTS_PAGES.LABELING_TRANSLATIONS;

export const isLibraryObjectEditPage = (page: PageType): boolean =>
  page === LIBRARY_OBJECTS_PAGES.EDIT;

export const isLibraryObjectCreatePage = (page: PageType): boolean =>
  page === LIBRARY_OBJECTS_PAGES.CREATE;

export const isProductTypeFood = (type: string) =>
  type === REFLIST_IDS.FOOD_PRODUCT_TYPE || type === PRODUCT_TYPES.FOOD;

export const isProductTypeFAndV = (type: string) =>
  type === REFLIST_IDS.F_AND_V_PRODUCT_TYPE || type === PRODUCT_TYPES.F_AND_V;
