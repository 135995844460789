import { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { Alert } from "@trace-one/design-system";
import SpecificationSection from "components/SpecificationSection";
import ManageTranslationButton from "../ManageTranslationButton";
import { ValidationChapterContext } from "contexts";
import { useRbac, useUserInfo } from "hooks";
import { isObjectEmpty } from "utils/general";
import {
  getIsValidationDisabled,
  getHeaderStatusName,
  prepareValidationStatus,
} from "utils/library";
import { isStateValidatedByRetailer } from "utils/specifications";
import { PERMISSIONS } from "utils/constants";
import { TitleProps } from "./types";
import { messages } from "./messages";
import styles from "./styles.module.less";

const Title: React.FC<TitleProps> = ({
  sectionId,
  sectionCode,
  sectionName,
  sectionActions,
  sections,
  chapterSection,
  isChapterLinked,
  isChapterOwned,
  isChapterOwnedBySupplier,
  isChapterLibrary,
  onPrimaryActionClick,
  specificationPermissions,
  isTargetMode,
}) => {
  const { formatMessage } = useIntl();
  const { isRetailer } = useUserInfo();
  const {
    validationErrors,
    validateHandler,
    isSectionValidationLoading,
  } = useContext(ValidationChapterContext);

  const { hasPermission } = useRbac(chapterSection?.permissions);

  const sectionStatus = getHeaderStatusName({
    state: chapterSection?.sectionState,
    isRetailer: chapterSection?.validatedByRetailer,
  });

  const validationStatus = prepareValidationStatus({
    state: sectionStatus,
    validatedBySupplier: chapterSection?.validatedBySupplier,
    isRetailer,
    isChapterOwnedBySupplier,
    isChapterLibrary,
    sections,
  });

  const shouldAlertBeDisplayed =
    isChapterLinked &&
    !isChapterOwned &&
    isStateValidatedByRetailer(sectionStatus) &&
    !chapterSection?.validatedBySupplier &&
    isRetailer;

  const isValidationDisabled = useMemo(
    () =>
      getIsValidationDisabled({
        isRetailer,
        sectionStatus,
        isChapterOwnedBySupplier,
        validatedBySupplier: chapterSection?.validatedBySupplier,
      }),
    [
      isRetailer,
      sectionStatus,
      isChapterOwnedBySupplier,
      chapterSection?.validatedBySupplier,
    ]
  );

  return (
    <>
      <SpecificationSection.Title
        sectionName={sectionName}
        sectionIcon={isChapterLinked ? "linked" : ""}
        sectionStatus={validationStatus}
        sectionActions={sectionActions}
        isTargetMode={isTargetMode}
        onValidate={validateHandler()}
        hasValidationErrors={!isObjectEmpty(validationErrors)}
        isValidationLoading={isSectionValidationLoading(sectionId)}
        isValidationDisabled={isValidationDisabled}
        hasValidationPermission={hasPermission(PERMISSIONS.SECTION.VALIDATE)}
        {...(onPrimaryActionClick && {
          primaryAction: (
            <ManageTranslationButton
              onClick={onPrimaryActionClick}
              sectionCode={sectionCode}
              specificationPermissions={specificationPermissions}
              className="ml-3"
              isTargetMode={isTargetMode}
            />
          ),
        })}
      />
      {shouldAlertBeDisplayed && (
        <Alert
          showIcon
          data-test-id="chapter-validation-banner"
          message={formatMessage(messages.alertMessage)}
          description={formatMessage(messages.alertDescription)}
          type="warning"
          className={styles.alert}
        />
      )}
    </>
  );
};

export default Title;
