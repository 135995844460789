import { useState } from "react";
import { downloadFilesAsZip } from "apis/DOCUMENT";
import { downloadFileWithCustomName } from "utils/files";

export interface UseZipDownloadProps {
  fileIds: string[];
  zipFileName: string;
}

const useZipDownload = ({ fileIds, zipFileName }: UseZipDownloadProps) => {
  const [isZipDownloading, setIsZipDownloading] = useState(false);

  const onDownloadZip = async () => {
    try {
      setIsZipDownloading(true);
      const { data } = await downloadFilesAsZip({
        fileIds,
        zipName: zipFileName,
      });

      downloadFileWithCustomName({
        data,
        fileType: "zip",
        fileName: zipFileName,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsZipDownloading(false);
    }
  };

  return {
    onDownloadZip,
    isZipDownloading,
  };
};

export default useZipDownload;
