import {
  isMaterialAdditiveType,
  isMaterialCompositeBoughtToThirdParty,
  isMaterialCompositeMadeInHouse,
  isMaterialProcessingAidType,
  isMaterialRawMaterialType,
} from "utils/library";
import BoughtDetails from "../BoughtDetails";
import MadeInHouseDetails from "../MadeInHouseDetails";
import RawMaterialDetails from "../RawMaterialDetails";
import { MaterialDetailsProps } from "./types";
import SubstanceDetails from "../SubstanceDetails";

const MaterialDetails: React.FC<MaterialDetailsProps> = ({
  rawMaterial,
  recipe,
  allergens,
  suppliers,
  materialId,
  materialType,
  recipeType,
  state,
  fileIds,
  files,
  substanceId,
}) => {
  if (isMaterialRawMaterialType(materialType)) {
    return (
      <RawMaterialDetails
        rawMaterial={rawMaterial}
        allergens={allergens}
        suppliers={suppliers}
        materialId={materialId}
        state={state}
        fileIds={fileIds}
      />
    );
  }

  if (
    isMaterialAdditiveType(materialType) ||
    isMaterialProcessingAidType(materialType)
  ) {
    return (
      <SubstanceDetails
        rawMaterial={rawMaterial}
        allergens={allergens}
        suppliers={suppliers}
        materialId={materialId}
        state={state}
        fileIds={fileIds}
        materialType={materialType}
        substanceId={substanceId}
      />
    );
  }

  if (isMaterialCompositeBoughtToThirdParty(recipeType)) {
    return (
      <BoughtDetails
        recipe={recipe}
        allergens={allergens}
        suppliers={suppliers}
        materialId={materialId}
        files={files}
        state={state}
      />
    );
  }

  if (isMaterialCompositeMadeInHouse(recipeType)) {
    return (
      <MadeInHouseDetails
        recipe={recipe}
        allergens={allergens}
        suppliers={suppliers}
        materialId={materialId}
        files={files}
        state={state}
      />
    );
  }

  return null;
};

export default MaterialDetails;
