import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isFAndVOnly, isObjectEmpty } from "utils/general";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectDraft,
  isLibraryObjectLocked,
  isLibraryObjectMaterialType,
  isLibraryObjectPackagingSystemsType,
  isLibraryObjectTemplatesType,
  isProductTypeFAndV,
  isProductTypeFood,
} from "utils/library";
import { LibraryObjectType } from "types/library";
import { LibraryItemActionItemViewModel } from "viewModels";
import { prepareLibraryItemActionItem } from "dtos/libraryItem";
import { ChapterTypeData } from "models";

export const getActionsListForLibraryItem = ({ actions, type, state }) => {
  if (isObjectEmpty(actions)) return [];

  let itemActions = [];

  if (isLibraryObjectMaterialType(type)) {
    if (actions.details) {
      const { name } = actions.details;
      itemActions.push({ ...actions.details, label: name });
    }

    if (actions.edit) {
      const { shortName, name, ...edit } = actions.edit;
      itemActions.push({ ...edit, name, label: name });
    }

    if (actions.createNewVersion) {
      const { name } = actions.createNewVersion;
      itemActions.push({ ...actions.createNewVersion, label: name });
    }
  }

  if (isLibraryObjectTemplatesType(type) && !isLibraryObjectLocked(state)) {
    if (actions.delete) {
      const { name } = actions.delete;
      itemActions.push({ ...actions.delete, label: name });
    }
  }

  if (isLibraryObjectChapterSubType(type)) {
    if (actions.edit) {
      const { shortName } = actions.edit;
      itemActions.push({
        ...actions.edit,
        label: shortName,
        disabled: isLibraryObjectLocked(state),
      });
    }

    if (actions.duplicate) {
      const { shortName } = actions.duplicate;
      itemActions.push({
        ...actions.duplicate,
        label: shortName,
      });
    }

    if (actions.createNewVersion) {
      const { name } = actions.createNewVersion;
      itemActions.push({
        ...actions.createNewVersion,
        label: name,
      });
    }

    if (actions.delete) {
      const { shortName, name, tooltipProps } = actions.delete;
      itemActions.push({
        ...actions.delete,
        label: shortName || name,
        disabled: !isLibraryObjectDraft(state),
        tooltipProps: isLibraryObjectDraft(state) ? tooltipProps : {},
      });
    }
  }

  if (isLibraryObjectClaimType(type) && !isLibraryObjectLocked(state)) {
    if (actions.edit) {
      const { shortName, name } = actions.edit;
      itemActions.push({
        ...actions.edit,
        label: shortName || name,
      });
    }

    if (actions.delete) {
      const { shortName, name, tooltipProps } = actions.delete;
      itemActions.push({
        ...actions.delete,
        label: shortName || name,
        disabled: !isLibraryObjectDraft(state),
        tooltipProps: isLibraryObjectDraft(state) ? tooltipProps : {},
      });
    }
  }

  return itemActions;
};

export const shouldNoSearchResultMessageBeDisplayed = ({
  isListLoading,
  totalCount,
  searchFilterName,
}: {
  isListLoading?: boolean;
  totalCount?: number;
  searchFilterName?: string;
}) => !isListLoading && !!searchFilterName && totalCount === 0;

export const shouldDisplayCreateButton = ({
  hasCreateButton,
  type,
}: {
  hasCreateButton?: boolean;
  type: LIBRARY_OBJECT_TYPES;
}) => hasCreateButton && !isLibraryObjectPackagingSystemsType(type);

export const shouldDisplayActions = ({
  type,
  defaultDisplayActions,
}: {
  type: LibraryObjectType;
  defaultDisplayActions: boolean;
}) => {
  const libraryItemsWithoutActions: LibraryObjectType[] = [
    LIBRARY_OBJECT_TYPES.REQUIREMENTS,
    LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
  ];

  if (libraryItemsWithoutActions.includes(type)) return false;

  return defaultDisplayActions;
};

export const getOnLibraryItemClick = ({
  libraryItem,
  redirectToLibraryDetails,
  redirectToLibraryEdition,
  onItemClick,
}: {
  libraryItem: LibraryItemActionItemViewModel;
  redirectToLibraryDetails: Function;
  redirectToLibraryEdition: Function;
  onItemClick?: Function;
}) => () => {
  if (onItemClick) {
    return onItemClick(libraryItem);
  }

  const { id, type } = prepareLibraryItemActionItem(libraryItem);

  if (isLibraryObjectPackagingSystemsType(libraryItem.type)) {
    return redirectToLibraryEdition({
      id,
      type,
    });
  }

  return redirectToLibraryDetails({
    id,
    type,
  });
};

export const getChaptersForFilter = (chapterTypes: ChapterTypeData[]) =>
  chapterTypes
    .reduce(
      (acc, { isChapterLibrary, chapterType, chapterName, productTypeId }) => {
        if (isChapterLibrary) {
          if (isFAndVOnly) {
            return [
              ...acc,
              isProductTypeFAndV(productTypeId) && {
                label: chapterName,
                value: chapterType,
              },
            ];
          }
          return [
            ...acc,
            isProductTypeFood(productTypeId) && {
              label: chapterName,
              value: chapterType,
            },
          ];
        }
        return acc;
      },
      []
    )
    .filter(Boolean);
