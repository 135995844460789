import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { LabeledValue } from "antd/lib/select";
import { selectLanguageCode } from "store/user/selectors";
import { fetchReferenceListItemsByName } from "apis/RLMD";

export interface UseLanguagesProps {
  preFetch?: boolean;
}

export interface LanguageMap {
  [key: string]: LabeledValue;
}

const useLanguages = ({ preFetch }: UseLanguagesProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [languages, setLanguages] = useState<LabeledValue[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const languagesMap: LanguageMap = useMemo(() => {
    return languages?.reduce((acc, language) => {
      return {
        ...acc,
        [language.value]: language,
      };
    }, {});
  }, [languages]);

  const getLanguages = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchReferenceListItemsByName({
        name: "languages",
        languageCode,
      });

      const languagesOptions = data?.map(lang => {
        return {
          key: lang?.itemCode,
          value: lang?.itemCode,
          label: lang?.text,
        };
      });

      setLanguages(languagesOptions);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  };

  useEffect(() => {
    if (preFetch && !hasLoaded) {
      getLanguages();
    }

    return () => {
      setHasLoaded(false);
    };
  }, [preFetch]);

  const onDropdownVisibleChange = (open: boolean) => {
    if (open && !hasLoaded) {
      getLanguages();
    }
  };

  return {
    languages,
    isLoading,
    hasError,
    onDropdownVisibleChange,
    languagesMap,
  };
};

export default useLanguages;
