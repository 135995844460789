import { useContext } from "react";
import { MenuContext } from "components/SpecificationSection/components/Menu/contexts";
import SectionName from "./components/SectionName";
import { SectionProps } from "./types";
import Status from "components/Status";
import { getSectionStatus } from "utils/library";
import { isChapterTranslationOnHold } from "utils/chapters";
import styles from "./styles.module.less";

const Section: React.FC<SectionProps> = ({
  section,
  parentSectionId,
  parentChapterType,
}) => {
  const {
    onClickSubSection,
    selectedSubSectionId,
    retailerCompnayId,
    supplierCompnayId,
    translatorCompanyId,
    translationStatus,
  } = useContext(MenuContext);

  const sectionName = section.sectionName;
  const state = section?.state;

  const status = getSectionStatus({
    state,
    supplierCompnayId,
    retailerCompnayId,
    translatorCompanyId,
  });

  const isSectionNameDisabled = isChapterTranslationOnHold(translationStatus);

  const sectionState = status?.toLowerCase();

  return (
    <div className={styles.section}>
      <Status.Dot className={styles[sectionState]} />
      <SectionName
        sectionName={sectionName}
        onClick={onClickSubSection(
          parentSectionId,
          section.sectionId,
          parentChapterType
        )}
        isSelected={section.sectionId === selectedSubSectionId}
        disabled={isSectionNameDisabled}
      />
    </div>
  );
};

export default Section;
