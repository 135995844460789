import { Select, Icon, Tooltip } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { MaterialRecipeIngredientViewModel } from "viewModels";
import { useSubstanceFunction } from "./hooks";
import { useInputValidation } from "hooks";
import { isListEmpty, isUuidEmpty } from "utils/general";
import { isMaterialProcessingAidType } from "utils/library";
import libraryMessages from "messages/library";
import messages from "../../messages";
import styles from "../../styles.module.less";

const SubstanceFunction = ({
  ingredient,
  onUpdate: defaultOnUpdate,
  disableActions,
}: {
  ingredient: MaterialRecipeIngredientViewModel;
  onUpdate: Function;
  disableActions?: boolean;
}) => {
  const { formatMessage } = useIntl();

  const { type: materialType, substanceId, functionId } = ingredient;

  const {
    isLoading,
    options,
    selectedSubstanceFunction,
    onSearch,
    onDropdownVisibleChange,
    onSelect,
    onClear,
  } = useSubstanceFunction({
    substanceId,
    substanceFunctionId: functionId,
    materialType,
    onUpdate: defaultOnUpdate,
  });

  const { hasError, onInputChange } = useInputValidation(onSearch);

  const isMaterialProcessingAid = isMaterialProcessingAidType(ingredient?.type);

  return (
    <div
      className={classNames({
        [styles.functionSelectContainer]: !isMaterialProcessingAid,
      })}
    >
      <Select
        name={"substance-function"}
        data-test-id="substance-function"
        data-testid="substance-function"
        filterOption={false}
        allowClear={isMaterialProcessingAid}
        showSearch={true}
        value={
          (isListEmpty(options)
            ? selectedSubstanceFunction?.name
            : selectedSubstanceFunction?.id) || null
        }
        placeholder={formatMessage(messages.functionsPlaceholder)}
        loading={isLoading}
        options={options}
        onChange={isMaterialProcessingAid ? onSearch : onInputChange}
        onSearch={onSearch}
        onClear={onClear}
        onDropdownVisibleChange={onDropdownVisibleChange}
        error={hasError}
        errorMessage={formatMessage(libraryMessages.formRequired)}
        warning={!selectedSubstanceFunction?.id && !isMaterialProcessingAid}
        onSelect={onSelect}
        disabled={isUuidEmpty(substanceId) || disableActions}
      />
      {!disableActions && !isMaterialProcessingAid && (
        <Tooltip
          text={formatMessage(messages.additiveFunctionTooltip)}
          placement="left"
          data-testid="substance-function-tooltip"
        >
          <Icon
            className={styles.tooltipIcon}
            name="more-info"
            color="primary"
            data-testid="substance-function-tooltip-icon"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default SubstanceFunction;
