import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { CompanySelect } from "components";
import FiltersComponent from "components/Filters";
import { useFilters } from "hooks";
import { selectCompanyActivity } from "store/oidc/selectors";
import {
  getOnClearAllFilters,
  getOnClearSearch,
  getOnSearchChange,
  getOnStatusClick,
  getOnStatusRemove,
  getStatuses,
} from "pages/utils";
import { getProductOrigin } from "pages/Specification/utils";
import { isListEmpty } from "utils/general";
import { isChapterTranslationInProgress } from "utils/chapters";
import { Filter } from "types/filters";
import { SelectedStatus } from "components/Filters/components/Statuses/types";
import { TranslationFiltersViewModel } from "viewModels";
import filterMessages from "messages/filters";

export type TranslationFiltersProps = {
  onUpdateFilters: (_: TranslationFiltersViewModel) => void;
  filters: TranslationFiltersViewModel;
};

const TranslationFilters: React.FC<TranslationFiltersProps> = ({
  onUpdateFilters,
  filters,
}) => {
  const { formatMessage } = useIntl();
  const companyActivity = useSelector(selectCompanyActivity);
  const productOrigin = getProductOrigin({
    companyActivity,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const statuses = getStatuses({ type: "translation" });
  const [selectedProductTypes, setSelectedProductTypes] = useState<Filter[]>(
    []
  );
  const [
    selectedSpecificationOwnerCompanyIds,
    setSelectedSpecificationOwnerCompanyIds,
  ] = useState<TranslationFiltersViewModel["specificationOwnerCompanyIds"]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<SelectedStatus[]>(
    []
  );

  const {
    prepareSelectedFilters,
    selectedFilters,
    selectedFiltersList,
    handleChangeSelectedFilters,
  } = useFilters();

  const onSearch = () => {
    onUpdateFilters({
      tradeItemName: searchTerm?.trim(),
    });
  };

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedSpecificationOwnerCompanyIds,
      setCurrentSelectedFilters: setSelectedSpecificationOwnerCompanyIds,
      key: "specificationOwnerCompanyIds",
      nameKey: "retailers",
    });
  }, [selectedSpecificationOwnerCompanyIds]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedProductTypes,
      setCurrentSelectedFilters: setSelectedProductTypes,
      key: "productTypeIds",
      nameKey: "productType",
    });
  }, [selectedProductTypes]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedStatuses,
      setCurrentSelectedFilters: setSelectedStatuses,
      key: "statuses",
      nameKey: "status",
    });
    if (!isListEmpty(selectedStatuses)) {
      onUpdateFilters({
        toggleButtonsValue: "all",
      });
    }
  }, [selectedStatuses]);

  useEffect(() => {
    handleChangeSelectedFilters({
      onFilterSelect: onUpdateFilters,
      selectedFilters,
    });
  }, [selectedFilters]);

  useEffect(() => {
    if (isChapterTranslationInProgress(filters.toggleButtonsValue)) {
      setSelectedStatuses([]);
    }
  }, [filters.toggleButtonsValue]);

  return (
    <FiltersComponent.Container
      filters={[
        {
          label: formatMessage(filterMessages.retailers),
          content: (
            <CompanySelect
              type={productOrigin}
              mode="multiple"
              onChange={setSelectedSpecificationOwnerCompanyIds}
              values={selectedSpecificationOwnerCompanyIds}
              labelInValue
            />
          ),
        },
        {
          label: formatMessage(filterMessages.productType),
          content: (
            <FiltersComponent.ProductTypes
              selectedProductTypes={selectedProductTypes}
              setSelectedProductTypes={setSelectedProductTypes}
            />
          ),
        },
        {
          label: formatMessage(filterMessages.status),
          content: (
            <FiltersComponent.Statuses
              statuses={statuses}
              selectedStatuses={selectedStatuses}
              onStatusRemove={getOnStatusRemove({
                setSelectedStatuses,
              })}
              onStatusClick={getOnStatusClick({
                setSelectedStatuses,
              })}
            />
          ),
        },
      ]}
      onClearFiltersClick={getOnClearAllFilters({
        setSelectedStatuses,
        setSelectedProductTypes,
        setSelectedSpecificationOwnerCompanyIds,
      })}
      onClearSearch={getOnClearSearch({ setSearchTerm })}
      onSearch={onSearch}
      onSearchChange={getOnSearchChange({ setSearchTerm })}
      searchPlaceholder={formatMessage(
        filterMessages.translationFilterSearchPlaceholder
      )}
      searchValue={searchTerm}
      selectedFilters={selectedFiltersList}
    />
  );
};

export default TranslationFilters;
