import { EmptyState, Button } from "@trace-one/design-system";
import { Row } from "antd";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import Spin from "components/Spin";
import { SubstancesTable } from "components";
import { useCatalogDictionary } from "hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { AddSubstancesModal } from "./components/AddSubstancesModal";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import WarningFallback from "components/WarningFallback";
import { PackagingSubstanceFormData } from "models";
import { useSubstanceWidget } from "./hooks";

const SubstanceWidget: React.FC<
  ObjectFieldTemplateProps<PackagingSubstanceFormData>
> = props => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { formData, schema, formContext, uiSchema } = props;
  const { substances = [] } = formData;

  const substanceIds = substances.map(({ substanceId }) => substanceId);

  const {
    isLoading,
    substanceInformationCollection,
    substanceRegulations,
  } = useCatalogDictionary({
    substanceIds,
    shouldFilterIds: false,
    shouldGetSubstanceRegulationData: true,
  });

  const { isWidgetEditable } = formContext;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSubstancesEditable = isWidgetEditable(
    schema?.properties?.substances?.propertyId
  );

  const { restrictedSubstances, unRestrictedSubstances } = useSubstanceWidget({
    substanceInformationCollection,
    substanceRegulations,
    substances,
  });

  if (
    isLoading &&
    isListEmpty(restrictedSubstances) &&
    isListEmpty(unRestrictedSubstances)
  )
    return <Spin />;

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      {isObjectEmpty(formData) ? (
        <Row justify="center">
          <EmptyState
            description={getTranslationForKey("substancesEmptyState")}
          />
        </Row>
      ) : (
        <>
          {!isListEmpty(restrictedSubstances) && (
            <div className="mb-3">
              <SubstancesTable
                title={getTranslationForKey("restrictedSubstances")}
                subtitle={getTranslationForKey(
                  "restrictedSubstancesDescription"
                )}
                data={restrictedSubstances}
                schema={schema}
                uiSchema={uiSchema}
                isRestricted
                isLoading={isLoading}
              />
            </div>
          )}

          {!isListEmpty(unRestrictedSubstances) && (
            <SubstancesTable
              title={getTranslationForKey("unRestrictedSubstances")}
              data={unRestrictedSubstances}
              schema={schema}
              uiSchema={uiSchema}
              isLoading={isLoading}
            />
          )}
        </>
      )}

      <Row justify="center" className="mt-3">
        <Button
          data-testid="add-packaging-substances"
          type="primary"
          disabled={!isSubstancesEditable}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {getTranslationForKey("addSubstances")}
        </Button>

        {isModalOpen && (
          <AddSubstancesModal
            {...props}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </Row>
    </ErrorBoundary>
  );
};

export default SubstanceWidget;
