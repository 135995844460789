import { AxiosRequestConfig } from "axios";
import { axiosInstance } from ".";
import { LibraryObjectData } from "models";
import {
  ClaimData,
  ClaimSentenceTranslation,
  ClaimTypeData,
  RegulatoryHealthClaim,
} from "models/claim";
import { PaginatedList, PaginationParams } from "types/api";
import { CLAIM_TYPES } from "utils/constants";
import { LibraryItemApiType } from "types/library";

const baseUrl = "/api/";

export const fetchClaimTypes = ({ languageCode }: { languageCode: string }) =>
  axiosInstance.get<ClaimTypeData[]>(`/api/claim-types`, {
    params: {
      languageCode,
    },
  });

export const createOrPublishClaim = ({
  claimType,
  payload,
  params,
}: {
  claimType: LibraryItemApiType;
  payload: {
    name: ClaimData["name"];
    claimType: ClaimData["claimType"];
    productTypeId: ClaimData["productTypeId"];
    sentenceTranslations?: ClaimSentenceTranslation[];
    logoFileId: ClaimData["logoFileId"];
    tagIds?: ClaimData["tagIds"];
    requiresSupportingDocuments?: ClaimData["requiresSupportingDocuments"];
    additionalSentenceTranslations?: ClaimSentenceTranslation[];
    regulatoryHealthClaimId?: string;
  };
  params: {
    publish?: boolean;
  };
}) =>
  axiosInstance.post<ClaimData>(`${baseUrl}${claimType}`, payload, {
    params,
  });

export const saveOrPublish = ({
  claimType,
  id,
  payload,
  params,
}: {
  claimType: LibraryItemApiType;
  id: ClaimData["id"];
  payload: {
    name?: ClaimData["name"];
    sentenceTranslations?: ClaimSentenceTranslation[];
    logoFileId: ClaimData["logoFileId"];
    tagIds: ClaimData["tagIds"];
    requiresSupportingDocuments?: ClaimData["requiresSupportingDocuments"];
    additionalSentenceTranslations?: ClaimSentenceTranslation[];
    regulatoryHealthClaimId?: string;
  };
  params: {
    publish?: boolean;
  };
}) =>
  axiosInstance.put<ClaimData>(`${baseUrl}${claimType}/${id}`, payload, {
    params,
  });

export const fetchClaims = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    ownerCompanyId: ClaimData["ownerCompanyId"];
    name?: ClaimData["name"];
    productTypeId?: ClaimData["productTypeId"];
    excludeDrafts?: boolean;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<LibraryObjectData>>(
    `${baseUrl}claims/search`,
    {
      params,
      paramsSerializer,
    }
  );

export const fetchHealthRegulatoryClaims = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams;
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<RegulatoryHealthClaim>>(
    `${baseUrl}health-claims/regulation/search`,
    {
      params,
      paramsSerializer,
    }
  );

export const fetchClaim = ({
  claimType,
  id,
  languageCode,
}: {
  claimType: LibraryItemApiType;
  id: ClaimData["id"];
  languageCode: string;
}) =>
  axiosInstance.get<ClaimData>(`${baseUrl}${claimType}/${id}`, {
    params: { languageCode },
  });

export const fetchClaimCollection = ({
  claimType,
  payload,
}: {
  claimType: CLAIM_TYPES;
  payload: {
    claimIds: ClaimData["id"][];
  };
}) =>
  axiosInstance.post<ClaimData[]>(
    `${baseUrl}${claimType}-claims/collection`,
    payload
  );

export const deleteClaim = ({
  id,
  claimType,
}: {
  id: ClaimData["id"];
  claimType: LibraryItemApiType;
}) => axiosInstance.delete<Promise<void>>(`${baseUrl}${claimType}/${id}`);
