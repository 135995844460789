import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import { PageHeadingInfo } from "types/general";
import { PAGE_HEADING_TYPES, ROUTES } from "utils/constants";
import { messages } from "./messages";

const usePageHeading = () => {
  const { formatMessage } = useIntl();
  const initialState: PageHeadingInfo = {
    title: "",
    illustration: "",
    subtitle: "",
    tabs: [],
    type: PAGE_HEADING_TYPES.LIST,
    currentTab: "",
    onTabClick: () => {},
  };
  const [pageHeadingInfo, setPageHeading] = useState<PageHeadingInfo>(
    initialState
  );

  const isSpecListPage = useRouteMatch(ROUTES.SPECIFICATION_LISTING);
  const isFolderListPage = useRouteMatch(ROUTES.FOLDER_LISTING);
  const isTranslationListPage = useRouteMatch(ROUTES.TRANSLATION_LISTING);
  const isLibraryPage = useRouteMatch(ROUTES.LIBRARY.LISTING);

  useEffect(() => {
    switch (true) {
      case isSpecListPage?.isExact:
      case isTranslationListPage?.isExact:
        setPageHeading({
          title: formatMessage(messages.specificationListTitle),
          subtitle: formatMessage(messages.specificationListSubtitle),
          illustration: "specification",
          type: PAGE_HEADING_TYPES.LIST,
        });
        break;
      case isFolderListPage?.isExact:
        setPageHeading({
          title: formatMessage(messages.folderListingTitle),
          subtitle: formatMessage(messages.folderListingSubtitle),
          illustration: "folder",
          type: PAGE_HEADING_TYPES.LIST,
        });
        break;
      case isLibraryPage?.isExact:
        setPageHeading(previousState => ({
          ...previousState,
          title: formatMessage(messages.libraryTitle),
          subtitle: formatMessage(messages.librarySubtitle),
          illustration: "library",
          type: PAGE_HEADING_TYPES.LIST,
        }));
        break;
      default:
        setPageHeading(initialState);
        break;
    }
  }, [
    isSpecListPage?.isExact,
    isFolderListPage?.isExact,
    isLibraryPage?.isExact,
    isTranslationListPage?.isExact,
  ]);

  const updatePageHeadingInfo = (payload: PageHeadingInfo) => {
    setPageHeading(previousState => ({
      ...previousState,
      ...payload,
    }));
  };

  return {
    pageHeadingInfo,
    updatePageHeadingInfo,
  };
};

export default usePageHeading;
