import { useMemo, useState } from "react";

export interface UseClientPaginationProps<T> {
  items: T[];
  pageSize?: number;
}

export const useClientPagination = <T>({
  items = [],
  pageSize,
}: UseClientPaginationProps<T>) => {
  const [filters, setFilters] = useState({
    pageSize: pageSize ?? 20,
    currentPage: 1,
  });

  const onPaginationChange = (currentPage: number, pageSize: number) => {
    setFilters(previousState => ({
      ...previousState,
      currentPage,
      pageSize,
    }));
  };

  const visibleFiles = useMemo(() => {
    const { currentPage, pageSize } = filters;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return items.slice(startIndex, endIndex);
  }, [filters.currentPage, filters.pageSize, items]);

  return {
    filteredItems: visibleFiles,
    onPaginationChange,
    totalCount: items.length,
    filters,
  };
};

export default useClientPagination;
