import PropTypes from "prop-types";
import classnames from "classnames";
import { Editor } from "@trace-one/react-components";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useTextActions, useWidgetActions } from "../../hooks";
import { isWidgetDisabled } from "../utils";
import widgetStyle from "../styles.module.less";
import componentStyle from "./styles.module.less";

const TextareaWidget = ({
  disabled,
  formContext,
  id,
  onChange,
  onFocus,
  options,
  placeholder,
  readonly,
  value,
  schema,
  configProps,
}) => {
  const {
    displayActions,
    emptyValue,
    isWidgetEditable,
    templateBlockId,
    sectionId,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
    readonlyAsDisabled,
  } = formContext;
  const { propertyId } = schema;

  const isDisabled = isWidgetDisabled({
    isWidgetEditable: isWidgetEditable(propertyId),
    disabled,
    readonly,
    readonlyAsDisabled: !!readonlyAsDisabled,
  });

  const { getTranslationForKey } = useFormSchemaTranslation();

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const { handleBlur, handleFocus, handleTextChange } = useTextActions({
    value,
    onChange,
    onFocus,
    emptyValue: options.emptyValue,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
  });

  return displayActions ? (
    <div
      className={classnames({
        [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
        [componentStyle.hideToolbar]: isDisabled,
      })}
    >
      <Editor
        id={id}
        name={id}
        initData={value}
        placeholder={getTranslationForKey(placeholder)}
        onChange={handleTextChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        readOnly={isDisabled}
        configProps={{ ...configProps, versionCheck: false }} // added versionCheck to suppress upgrade warning; remove after upgrade
        {...(options.height && {
          height: options.height,
        })}
      />
    </div>
  ) : (
    <div
      dangerouslySetInnerHTML={{
        __html: value || emptyValue,
      }}
    />
  );
};

TextareaWidget.propTypes = {
  schema: PropTypes.shape({
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

export default TextareaWidget;
