import cx from "classnames";
import { Form, Divider, Row, Col } from "antd";
import { isListEmpty } from "utils/general";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import ErrorsList from "components/ErrorsList";
import ConditionalWrapper from "components/ConditionalWrapper";
import { useFormItemCols } from "components/SpecificationSection/components/FormSchema/hooks";
import FieldReadOnlyWrapper from "../components/FieldReadOnlyWrapper";
import {
  isSchemaTypeObject,
  hasValidationRequiredFields,
  getErrorsForValidationRequiredFields,
  createSectionDataTestId,
  getLabel,
  getIsFormItemRequired,
} from "./utils";
import styles from "./styles.module.less";

const FieldTemplate = ({
  children,
  classNames,
  formContext,
  hidden,
  id,
  label,
  rawErrors,
  required,
  schema,
  uiSchema,
  registry,
}) => {
  const {
    colon,
    labelCol,
    wrapperCol,
    wrapperStyle,
    setErrors,
    displayActions,
    labelAlign,
    refListDictionary,
    emptyValue,
  } = formContext;

  const { formItemLabelCol, formItemWrapperCol } = useFormItemCols({
    labelCol,
    wrapperCol,
    displayActions,
    uiSchema,
  });

  const { getTranslationForKey } = useFormSchemaTranslation();

  if (hidden) {
    return <div className="field-hidden">{children}</div>;
  }

  if (
    rawErrors &&
    isSchemaTypeObject(schema.type) &&
    hasValidationRequiredFields(schema.validationRequired)
  ) {
    let errors = getErrorsForValidationRequiredFields({
      parentPath: id.replace("_", ".").replace("root", ""),
      rawErrors,
      validationRequiredFields: schema.validationRequired,
    });

    if (!isListEmpty(errors)) {
      setErrors(errors);
    }
  }
  const noWrapWidgets = [
    "ContactsWidget",
    "ConditionalWidget",
    "CompanyCardWidget",
    "ContactWidget",
    "DatePickerSwitchWidget",
    "SiteWidget",
    "RangeToleranceWidget",
  ];

  if (
    noWrapWidgets.findIndex(
      noWrapWidget => noWrapWidget === uiSchema["ui:widget"]
    ) > -1 ||
    // ui:noWrap is deprecated
    uiSchema["ui:noWrap"] === false ||
    uiSchema["ui:no:wrap"] === true ||
    ((schema.type === "array" || schema.type[0] === "array") &&
      schema?.referenceList === undefined)
  ) {
    if (uiSchema["ui:padding:top"] === true) {
      return (
        <div
          className={cx({
            [styles.paddingTop]: uiSchema["ui:padding:top"],
          })}
        >
          {children}
        </div>
      );
    } else {
      return children;
    }
  }

  let dataTestId = null;

  if (id === "root") {
    dataTestId = createSectionDataTestId(uiSchema);
  }

  if (uiSchema["ui:hide"]) {
    return null;
  }

  if (!displayActions) {
    const widgetName =
      children.props?.children[0]?.props?.uiSchema["ui:widget"];
    if (
      widgetName &&
      [
        "SPECLISTRADIOWIDGET",
        "RANGENUMBERWIDGET",
        "COMPACTWIDGET",
        "SWITCHWIDGET",
      ].some(item => item === widgetName.toUpperCase())
    ) {
      return (
        <FieldReadOnlyWrapper
          {...{
            widgetName,
            element: children.props?.children[0],
            title: uiSchema["ui:title"],
            getTranslationForKey,
            refListDictionary,
            emptyValue,
          }}
        />
      );
    }
  }

  return (
    <div
      {...(dataTestId && { "data-testid": dataTestId })}
      className={cx(
        classNames,
        id === "root" ? styles.wrapper : styles.fieldLabel,
        {
          [styles.evenGreyBgColor]: uiSchema["ui:bg:color:grey:even"],
          [styles.paddingTop]: uiSchema["ui:padding:top"],
        }
      )}
    >
      {id === "root" ? (
        children
      ) : (
        <ConditionalWrapper
          condition={uiSchema?.["ui:row:width"]}
          wrapper={children => (
            <Row>
              <Col xs={uiSchema?.["ui:row:width"]}>{children} </Col>
            </Row>
          )}
        >
          <>
            <Form.Item
              colon={colon}
              hasFeedback={
                schema.type !== "array" &&
                schema.type !== "object" &&
                schema.type !== "number"
              }
              {...(!isListEmpty(rawErrors) && {
                validateStatus: "error",
                help: <ErrorsList list={rawErrors} />,
              })}
              htmlFor={id}
              label={getLabel({
                title: uiSchema["ui:title"],
                label,
                getTranslationForKey,
              })}
              labelAlign={labelAlign}
              labelCol={formItemLabelCol}
              wrapperCol={formItemWrapperCol}
              required={getIsFormItemRequired({
                required,
                formItemName: schema?.name,
                validationRequired: registry?.rootSchema?.validationRequired,
              })}
              style={wrapperStyle}
            >
              {children}
            </Form.Item>
            {uiSchema["ui:horizontal:divider"] && <Divider />}
          </>
        </ConditionalWrapper>
      )}
    </div>
  );
};

export default FieldTemplate;
