import { RefObject, createContext } from "react";
import { ChapterStructureData, SpecificationData, TradeItemData } from "models";
import { SpecificationChapter } from "types/specification";
import { EnhancedExtraActions } from "components/SpecificationSection/types";

const SelectedChapterContext = createContext<{
  chapterData: SpecificationChapter;
  specificationId: string;
  specificationName: string;
  specificationHeaderTitle: string;
  supplierId?: string;
  ownerCompanyId?: string;
  ownerUserId?: string;
  specificationStructure?: ChapterStructureData[];
  currentSectionsRefs?: { [key: string]: RefObject<any> };
  chapterType?: string;
  productTypeId: string;
  refetchChapterWithLoading?: Function;
  isChapterLibrary: boolean;
  productData?: TradeItemData;
  specificationPermissions: string[];
  refetchChapter?: () => Promise<void>;
  requirementIds?: SpecificationData["requirementIds"];
  fileIds?: string[];
  specificationState?: SpecificationData["state"];
  isRetailer?: boolean;
  isChapterOwnedBySupplier?: boolean;
  sortRecipeIngredient?: EnhancedExtraActions["sortRecipeIngredient"];
  isTargetMode?: boolean;
  isSpecContact?: boolean;
  isRetailerContact?: boolean;
  retailerCompnayId?: string;
  supplierCompnayId?: string;
  translatorCompanyId?: string;
}>({
  chapterData: {},
  specificationId: "",
  specificationHeaderTitle: "",
  specificationName: "",
  supplierId: "",
  ownerCompanyId: "",
  ownerUserId: "",
  specificationStructure: [],
  currentSectionsRefs: {},
  productTypeId: undefined,
  isChapterLibrary: false,
  productData: {},
  specificationPermissions: [],
  requirementIds: [],
  fileIds: [],
  specificationState: undefined,
  isTargetMode: false,
});

export default SelectedChapterContext;
