import { useRef } from "react";
import { MATERIAL_TYPES, RECIPE_TYPES } from "utils/constants";
import { useMaterialDetails, useScrollToSection } from "../../hooks";
import Suppliers from "../Suppliers";
import Characteristics from "../Characteristics";
import Allergens from "./components/Allergens";
import RecipeTable from "./components/RecipeTable";
import ControlPlans from "../ControlPlans";
import Attachment from "../Attachment";
import { isLibraryObjectLocked } from "utils/library";
import { BoughtDetailsProps } from "./types";

const BoughtDetails: React.FC<BoughtDetailsProps> = ({
  recipe,
  materialId,
  suppliers,
  allergens,
  displayActions,
  files,
  state,
}) => {
  const allergensRef = useRef<HTMLDivElement | null>(null);

  const fileIds = files?.find(fileInfo => fileInfo.materialId === materialId)
    ?.fileId;

  useScrollToSection({ elementRef: allergensRef });

  const {
    categoryItemsDictionary,
    catalogDictionary,
    refListDictionary,
    getCategoryItem,
    updateCategoryItems,
  } = useMaterialDetails({
    suppliers,
    allergens,
    ingredients: recipe.ingredients,
  });

  return (
    <>
      <RecipeTable
        recipe={recipe}
        displayActions={displayActions}
        categoryDictionary={categoryItemsDictionary}
        catalogDictionary={catalogDictionary}
        getCategoryItem={getCategoryItem}
        updateCategoryItems={updateCategoryItems}
        materialId={materialId}
      />
      <Suppliers
        displayActions={displayActions}
        suppliers={suppliers}
        materialType={MATERIAL_TYPES.COMPOSITE}
        materialId={materialId}
        refListDictionary={refListDictionary}
        materialIngredients={recipe.ingredients}
      />
      <div ref={allergensRef}>
        <Allergens
          allergenDictionary={catalogDictionary}
          displayActions={displayActions}
          allergens={allergens}
          ingredients={recipe.ingredients}
        />
      </div>
      <Attachment
        fileIds={fileIds}
        displayActions={displayActions && !isLibraryObjectLocked(state)}
      />
      <Characteristics
        displayActions={displayActions}
        materialId={materialId}
        refListDictionary={refListDictionary}
        materialType={MATERIAL_TYPES.COMPOSITE}
        recipeType={RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY}
      />
      <ControlPlans
        displayActions={displayActions}
        materialId={materialId}
        materialType={MATERIAL_TYPES.COMPOSITE}
        refListDictionary={refListDictionary}
        recipeType={RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY}
      />
    </>
  );
};

BoughtDetails.defaultProps = {
  displayActions: true,
};

export default BoughtDetails;
