import { defineMessages } from "react-intl";

const messages = defineMessages({
  draft: {
    id: "status.draft",
    defaultMessage: "Draft",
  },
  toFill: {
    id: "status.toFill",
    defaultMessage: "To fill",
  },
  inProgress: {
    id: "status.inProgress",
    defaultMessage: "In progress",
  },
  inRevision: {
    id: "status.inRevision",
    defaultMessage: "In revision",
  },
  inRevisionByRetailer: {
    id: "status.inRevisionByRetailer",
    defaultMessage: "In revision",
  },
  inRevisionBySupplier: {
    id: "status.inRevisionBySupplier",
    defaultMessage: "In revision",
  },
  completed: {
    id: "status.completed",
    defaultMessage: "Completed",
  },
  performed: {
    id: "status.performed",
    defaultMessage: "Performed",
  },
  declined: {
    id: "status.declined",
    defaultMessage: "Declined",
  },
  validatedByRetailer: {
    id: "status.validatedByRetailer",
    defaultMessage: "Validated by retailer",
  },
  validatedBySupplier: {
    id: "status.validatedBySupplier",
    defaultMessage: "Validated by supplier",
  },
  validatedByTranslator: {
    id: "status.validatedByTranslator",
    defaultMessage: "Validated by translator",
  },
  validated: {
    id: "status.validated",
    defaultMessage: "validated",
  },
  signedByRetailer: {
    id: "status.signedByRetailer",
    defaultMessage: "Signed by retailer",
  },
  signedBySupplier: {
    id: "status.signedBySupplier",
    defaultMessage: "Signed by supplier",
  },
  published: {
    id: "status.published",
    defaultMessage: "Published",
  },
  locked: {
    id: "status.locked",
    defaultMessage: "Locked",
  },
  waitingForValidation: {
    id: "status.waitingForValidation",
    defaultMessage: "Waiting for validation",
  },
  ready: {
    id: "status.ready",
    defaultMessage: "Ready",
  },
  toStart: {
    id: "status.toStart",
    defaultMessage: "To start",
  },
  onHold: {
    id: "status.onHold",
    defaultMessage: "On Hold",
  },
});

export default messages;
