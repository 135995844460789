import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import initialState from "./initialState";
import {
  fetchUnitsOfMeasure,
  updateCatalogDictionary,
  updateCategoryDictionary,
  updateFileTypeDictionary,
  updateRefListDictionary,
  updateServingDictionary,
} from "./asyncActions";

export const slice = createSlice({
  name: "generalData",
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(PURGE, () => ({ ...initialState }));

    builder.addCase(fetchUnitsOfMeasure.fulfilled, (state, action) => {
      state.unitsOfMeasure = action.payload;
    });

    builder.addCase(updateCategoryDictionary.fulfilled, (state, action) => {
      state.categoryDictionary[action.payload.languageCode] = {
        ...state.categoryDictionary[action.payload.languageCode],
        ...action.payload.list,
      };
    });

    builder.addCase(updateRefListDictionary.fulfilled, (state, action) => {
      state.refListDictionary[action.payload.languageCode] = {
        ...state.refListDictionary[action.payload.languageCode],
        ...action.payload.list,
      };
    });

    builder.addCase(updateCatalogDictionary.fulfilled, (state, action) => {
      state.catalogDictionary[action.payload.languageCode] = {
        ...state.catalogDictionary[action.payload.languageCode],
        ...action.payload.list,
      };
    });

    builder.addCase(updateServingDictionary.fulfilled, (state, action) => {
      state.servingDictionary[action.payload.languageCode] = {
        ...state.servingDictionary[action.payload.languageCode],
        ...action.payload.list,
      };
    });

    builder.addCase(updateFileTypeDictionary.fulfilled, (state, action) => {
      state.fileTypeDictionary[action.payload.languageCode] = {
        ...state.fileTypeDictionary[action.payload.languageCode],
        ...action.payload.list,
      };
    });
  },
});

export default slice.reducer;
