/* istanbul ignore file */

import cleanDeep from "clean-deep";
import {
  MaterialData,
  MaterialRecipeData,
  MaterialIngredientData,
  MaterialAllergenDeclarationData,
  SubstanceData,
} from "models";
import { isListEmpty } from "utils/general";
import {
  MaterialRecipeIngredientViewModel,
  MaterialRecipeViewModel,
  MaterialViewModel,
  SubstanceInformationViewModel,
} from "viewModels";
import { Dictionary } from "types/general";
import { ALLERGEN_DECLARATION_TYPE } from "utils/constants";
import {
  isMaterialAdditiveType,
  isMaterialCompositeBoughtToThirdParty,
  isMaterialCompositeType,
  isMaterialProcessingAidType,
  isMaterialRawMaterialType,
} from "utils/library";
import { MaterialType } from "types/library";

export const prepareIngredientDictionary = (
  ingredients: MaterialIngredientData[]
) =>
  ingredients?.reduce(
    (
      previousState,
      { ingredientId, materialId, name, ingredients: childIngredients, type }
    ) => {
      return {
        ...previousState,
        [ingredientId]: {
          materialId: isMaterialCompositeBoughtToThirdParty
            ? ingredientId
            : materialId,
          name,
          type,
        },
        ...(!isListEmpty(childIngredients) && {
          ...prepareIngredientDictionary(childIngredients),
        }),
      };
    },
    {}
  );

export const prepareBoughtDetailsIngredients = (
  ingredientDictionary: Dictionary<{
    materialId?: string;
    name?: string;
    type?: MaterialType;
  }>
) =>
  Object.entries(ingredientDictionary)?.reduce((acc, curr) => {
    const [ingredientId, ingredient] = curr;

    if (isMaterialRawMaterialType(ingredient?.type)) {
      acc.push({
        ingredientId,
        name: ingredient.name,
        type: ingredient.type,
      });
    }

    return acc;
  }, []);

export const prepareIngredients = (
  ingredientsIds: string[],
  ingredientDictionary: Dictionary<{
    materialId?: string;
    name?: string;
  }>
): MaterialRecipeIngredientViewModel[] => {
  if (!Array.isArray(ingredientsIds)) return undefined;

  let alreadyAddedMaterial: string[] = [];

  return ingredientsIds.reduce((previousState, ingredientId) => {
    const currentIngredient = ingredientDictionary?.[ingredientId];

    if (alreadyAddedMaterial.includes(currentIngredient?.materialId)) {
      return [...previousState];
    }

    alreadyAddedMaterial.push(currentIngredient?.materialId);

    return [
      ...previousState,
      {
        ingredientId,
        name: currentIngredient?.name,
      },
    ];
  }, []);
};

export const prepareMaterialRecipeIngredient = (
  ingredient: MaterialRecipeIngredientViewModel,
  parentIdsPath: string[] = []
) => ({
  ...ingredient,
  parentIdsPath,
  ingredients: ingredient?.ingredients?.map(child =>
    prepareMaterialRecipeIngredient(child, [
      ...parentIdsPath,
      ingredient.ingredientId,
    ])
  ),
});

export const prepareMaterialRecipe = (
  recipe: MaterialRecipeData
): MaterialRecipeViewModel => {
  return {
    ingredients: recipe?.ingredients.map(ingredient => ({
      ...prepareMaterialRecipeIngredient(ingredient),
      firstLevel: true,
    })),
    totalPercentage: recipe?.totalPercentage,
    sortingFilter: recipe?.sortingFilter,
    recipeType: recipe?.recipeType,
  };
};

const prepareAllergenDeclarations = (
  allergens: MaterialAllergenDeclarationData[]
) => {
  return allergens?.reduce((acc, curr) => {
    const presentkey =
      curr?.declaration === ALLERGEN_DECLARATION_TYPE.PRESENT
        ? "presentForIngredientIds"
        : "unintentionalPresentForIngredientIds";

    if (acc.some(item => item.allergenId === curr.allergenId)) {
      acc = acc.map(item => {
        if (item.allergenId === curr.allergenId) {
          return {
            ...item,
            [presentkey]: !isListEmpty(curr.ingredientIds)
              ? [...curr.ingredientIds]
              : [],
          };
        }
        return item;
      });
    } else {
      const allergen = {
        allergenId: curr.allergenId,
        [presentkey]: !isListEmpty(curr.ingredientIds)
          ? [...curr.ingredientIds]
          : [],
      };
      acc.push(allergen);
    }

    return acc;
  }, []);
};

export const prepareMaterialFromApiData = (materialData: MaterialData) => {
  let newMaterial: MaterialViewModel = {};

  newMaterial.materialInformation = {
    id: materialData.id,
    name: materialData.name,
    internalCode: materialData.internalCode,
    tagIds: materialData.tagIds,
    active: materialData.active,
    materialType: materialData.materialType,
    recipeType: materialData?.recipe?.recipeType,
    state: materialData.state,
    ownerCompanyId: materialData.ownerCompanyId,
  };

  newMaterial.substanceId = materialData.substanceId;

  newMaterial.rawMaterial = {
    classificationId: materialData.classificationId,
    partId: materialData.partId,
    functionIds: materialData.functionIds,
  };

  const ingredientDictionary = prepareIngredientDictionary(
    materialData?.recipe?.ingredients
  );

  newMaterial.suppliers = materialData.suppliers?.map(
    ({ ingredientIds, ...supplier }) => ({
      ...supplier,
      ingredients: prepareIngredients(ingredientIds, ingredientDictionary),
    })
  );

  if (isMaterialCompositeBoughtToThirdParty(materialData?.recipe?.recipeType)) {
    newMaterial.allergens = !isListEmpty(materialData?.allergenDeclarations)
      ? [...prepareAllergenDeclarations(materialData?.allergenDeclarations)]
      : [];
  } else {
    newMaterial.allergens = materialData.allergenDeclarations?.map(
      ({ ingredientIds, ...allergen }) => ({
        ...allergen,
        ingredients: prepareIngredients(ingredientIds, ingredientDictionary),
      })
    );
  }

  newMaterial.recipe = prepareMaterialRecipe(materialData?.recipe);

  newMaterial.fileIds = materialData.fileIds || [];
  newMaterial.files = materialData.files || [];

  return newMaterial;
};

export const prepareSubstanceFormDataFromApi = (
  substanceData: SubstanceData
) => {
  const formData: SubstanceInformationViewModel = {};

  formData.eNumber = substanceData?.eNumber;
  formData.casNumbers = !isListEmpty(substanceData?.casNumbers)
    ? substanceData?.casNumbers?.join("; ")
    : undefined;
  formData.ceNumbers = !isListEmpty(substanceData?.ceNumbers)
    ? substanceData?.ceNumbers?.join("; ")
    : undefined;
  formData.synonyms = !isListEmpty(substanceData?.synonyms)
    ? substanceData?.synonyms?.join("; ")
    : undefined;
  formData.molecularFormula = substanceData?.molecularFormula;
  formData.colorIndexes = !isListEmpty(substanceData?.colorIndexes)
    ? substanceData?.colorIndexes?.join("; ")
    : undefined;
  formData.iupacName = substanceData?.iupacName;

  return formData;
};

const getOtherIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[],
  otherIngredients: MaterialRecipeIngredientViewModel[] = []
) => {
  ingredients?.forEach(ingredient => {
    if (!isMaterialProcessingAidType(ingredient?.type)) {
      otherIngredients.push({
        ...ingredient,
        ingredients: getOtherIngredients(ingredient?.ingredients, []),
      });
    } else {
      getOtherIngredients(ingredient?.ingredients, otherIngredients);
    }
  });
  return isListEmpty(otherIngredients) ? null : otherIngredients;
};

const getProcessingAidIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[],
  processingAidIngredients: MaterialRecipeIngredientViewModel[] = [],
  parentName: string = undefined
) => {
  ingredients?.forEach(ingredient => {
    if (isMaterialProcessingAidType(ingredient?.type)) {
      processingAidIngredients.push({
        ...ingredient,
        parentName,
      });
    } else {
      if (!isListEmpty(ingredient?.ingredients)) {
        getProcessingAidIngredients(
          ingredient?.ingredients,
          processingAidIngredients,
          ingredient?.name
        );
      }
    }
  });
  return processingAidIngredients;
};

export const getUniqueFlatlistFromIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[]
): MaterialRecipeIngredientViewModel[] => {
  const uniqueIngredients: MaterialRecipeIngredientViewModel[] = [];

  const traverseIngredients = (
    ingredientList: MaterialRecipeIngredientViewModel[]
  ) => {
    ingredientList.forEach(ingredient => {
      const {
        ingredientId,
        type,
        materialId,
        name,
        ingredients: childIngredients,
      } = ingredient;
      const existingIngredient = uniqueIngredients.find(
        item =>
          (item?.ingredientId === ingredientId ||
            (item?.materialId === materialId && item?.name === name)) &&
          item?.type === type
      );

      if (!existingIngredient) {
        uniqueIngredients.push(ingredient);
      }

      if (childIngredients && !isListEmpty(childIngredients)) {
        traverseIngredients(childIngredients);
      }
    });
  };

  traverseIngredients(ingredients);
  return uniqueIngredients;
};

export const categorizeIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[]
) => {
  return {
    processingAidIngredients: getProcessingAidIngredients(ingredients, []),
    otherIngredients: getOtherIngredients(ingredients, []),
  };
};

export const getCatalogIdsFromIngredients = ({
  ingredients,
}: {
  ingredients: MaterialRecipeIngredientViewModel[];
}) =>
  ingredients?.reduce((acc, ingredient) => {
    if (
      isMaterialAdditiveType(ingredient?.type) ||
      isMaterialProcessingAidType(ingredient?.type)
    ) {
      acc.push(ingredient?.functionId);
    }

    return cleanDeep(acc);
  }, []);

export const getIdsFromIngredients = ({
  ingredients,
  fieldName,
}: {
  ingredients: MaterialRecipeIngredientViewModel[];
  fieldName: keyof MaterialRecipeIngredientViewModel;
}) => {
  const uniqueIds = new Set<string>();

  const traverseIngredients = (
    itemIngredients: MaterialRecipeIngredientViewModel[]
  ) => {
    itemIngredients.forEach(item => {
      if (isMaterialRawMaterialType(item?.type)) {
        uniqueIds.add(item[fieldName] as string);
      } else if (isMaterialCompositeType(item?.type)) {
        traverseIngredients(item?.ingredients);
      }
    });
  };

  traverseIngredients(ingredients);

  return cleanDeep(Array.from(uniqueIds));
};
