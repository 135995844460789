import { sortBy } from "lodash";
import { Dictionary } from "types/general";
import { isListEmpty, isObjectEmpty } from "utils/general";

export const getIngredientNameProps = ({
  ingredientIds = [],
  ingredientNameDictionary = {},
  materialId,
}: {
  ingredientIds: string[];
  ingredientNameDictionary: Dictionary;
  materialId?: string;
}): {
  ingredientName: string;
  sortedIngredientNames: string[];
} => {
  if (materialId) {
    return {
      ingredientName: `${ingredientNameDictionary[materialId]}.`,
      sortedIngredientNames: [],
    };
  }

  if (isListEmpty(ingredientIds) || isObjectEmpty(ingredientNameDictionary)) {
    return { ingredientName: "", sortedIngredientNames: [] };
  }

  const ingredientNames = ingredientIds.reduce((acc: string[], id) => {
    if (ingredientNameDictionary[id]) {
      acc.push(ingredientNameDictionary[id]);
    }
    return acc;
  }, []);

  const sortedIngredientNames = sortBy(ingredientNames);

  const ingredientName = sortedIngredientNames.join(", ") + ".";

  return { ingredientName, sortedIngredientNames };
};
