import {
  SectionData,
  SpecificationContactData,
  TemplateSectionData,
} from "models";
import { CHAPTER_TRANSLATION_STATE } from "./constants";

export const getOnUpdateSectionComment = ({
  dispatch,
  setSectionComment,
}: {
  dispatch: Function;
  setSectionComment: Function;
}) => ({
  comment,
  sectionId,
}: {
  comment: string;
  sectionId: SectionData["sectionId"];
}) => {
  dispatch(
    setSectionComment({
      comment,
      sectionId,
    })
  );
};
export const getOnMoveSection = ({
  dispatch,
  callback,
}: {
  dispatch: Function;
  callback: Function;
}) => (chapterId: string) => ({
  sectionId,
  number,
}: {
  sectionId: SectionData["sectionId"];
  number: number;
}) => {
  if (number !== 0) {
    dispatch(
      callback({
        chapterId,
        sectionId,
        number,
      })
    );
  }
};

export const getOnUpdateSection = ({
  dispatch,
  callback,
}: {
  dispatch: Function;
  callback: Function;
}) => (sectionId: SectionData["sectionId"]) => {
  dispatch(callback(sectionId));
};

export const getVisibleChapters = ({
  chapters,
}: {
  chapters: TemplateSectionData[];
}) => () => chapters.filter(({ visible }) => visible);

export const isChapterTranslationValidated = (state: string) =>
  state === CHAPTER_TRANSLATION_STATE.validated;

export const isChapterTranslationCompleted = (state: string) =>
  state === CHAPTER_TRANSLATION_STATE.completed;

export const isChapterTranslationOnHold = (state: string) =>
  state === CHAPTER_TRANSLATION_STATE.onHold;

export const isChapterTranslationInProgress = (state: string) =>
  state === CHAPTER_TRANSLATION_STATE.inProgress;

export const isChapterTranslationToStart = (state: string) =>
  state === CHAPTER_TRANSLATION_STATE.toStart;

export const getContactIds = (contacts: SpecificationContactData[]) =>
  (contacts ?? []).map(contact => contact.userId);
