import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addFolderContact as addFolderContactApi,
  createFolder,
  createSpecificationDraft,
  createFolderCollaboration,
  createFolderWizard,
  deleteFolder,
  deleteFolderContact,
  deleteFolderCollaboration,
  deleteSpecificationDraft,
  shareFolder as shareFolderApi,
  fetchFolder,
  fetchTemplateById,
  fetchTradeItemSpecifications,
  updateFolderTargetDate,
  updateFolderName,
  updateFolderDescription,
  createFolderProducts,
  deleteFolderProduct,
  updateFolderSpecificationNote,
  updateSpecificationRevision,
  updateFolderRequirements,
  fetchFolderWizard,
  updateWizardCurrentStep,
  validateWizardStep,
  invalidateWizardSteps,
  updateWizardCollaboration,
  updateFolderSpecification,
  fetchSpecification,
  fetchFolderSpecifications,
  updateFolderFilesApi,
  updateFolderProductType,
  updateFolderTemplate,
} from "apis/SPEC";
import { searchUsers, fetchUser } from "apis/CUMD";
import { fetchTradeItems } from "apis/PMD";
import {
  fetchCategoryItems,
  fetchReferenceListsItem,
  fetchReferenceListsItems,
} from "apis/RLMD";
import { AsyncThunkConfig } from "store";
import { prepareTradeItemHelper } from "store/helpers";
import { updateRefListDictionary } from "store/generalData/asyncActions";
import { SPECIFICATION_STATE } from "utils/constants";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { isStateDraft, isStateSignedByRetailer } from "utils/specifications";
import initialState from "./initialState";
import { checkIfFolderIsCreated } from "./utils";
import { OwnerContact } from "store/types";
import {
  FolderData,
  RequirementData,
  SpecificationData,
  SpecificationDraftFileData,
  SpecificationListItemData,
  TemplateData,
  TemplateSectionData,
  TradeItemData,
  UserData,
  WizardData,
} from "models";
import { ProductSpecification, Requirement, State } from "./types";
import { CollaborationData } from "components/CreateCollaboration/types";
import { isCurrentStepValidated } from "utils/wizard";
import { AppliedRequirements } from "viewModels/folderCreation";

export const getContacts = createAsyncThunk<
  OwnerContact[],
  void,
  AsyncThunkConfig
>("folderCreation/getContacts", async (_, { getState }) => {
  const {
    user: { owningCompanyId, userId },
    folderCreation: { contacts },
  } = getState();

  if (isListEmpty(contacts)) {
    const {
      data: { users },
    } = await searchUsers({
      params: {
        isEnabled: true,
        owningCompanyId,
        skip: 0,
        take: 1000,
      },
    });

    return users.filter(user => userId !== user.userId);
  }

  return contacts;
});

export const startFolderCreation = createAsyncThunk<
  {
    folder: FolderData;
    user: UserData;
  },
  void,
  AsyncThunkConfig
>("folderCreation/startFolderCreation", async (_, { getState, dispatch }) => {
  const { user } = getState();

  const { data } = await createFolder();
  dispatch(setFolderWizard(data.folderId));
  return { folder: data, user };
});

export const setFolderWizard = createAsyncThunk<
  WizardData,
  FolderData["folderId"],
  AsyncThunkConfig
>("folderCreation/setFolderWizard", async folderId => {
  const { data } = await createFolderWizard({ folderId });

  return data;
});

export const shareFolder = createAsyncThunk<string[], void, AsyncThunkConfig>(
  "folderCreation/shareFolder",
  async (_, { getState }) => {
    const {
      folderCreation: { folder },
    } = getState();

    await shareFolderApi({
      id: folder.folderId,
    });

    const { data } = await fetchFolderSpecifications({
      id: folder.folderId,
    });

    const specificationIds = data.map(({ specificationId }) => specificationId);

    return specificationIds;
  }
);

export const setInitialFolderDetails = createAsyncThunk<
  State,
  FolderData["folderId"],
  AsyncThunkConfig
>(
  "folderCreation/setInitialFolderDetails",
  async (folderId, { getState, dispatch }) => {
    const {
      user: { userLanguagePreference, owningCompanyId },
    } = getState();

    const { data: folder } = await fetchFolder({
      id: folderId,
      languageCode: userLanguagePreference,
    });

    const { data: user } = await fetchUser({
      id: folder.ownerUserId,
    });

    if (!isStateDraft(folder.state)) {
      return Promise.reject();
    }

    let folderDetails: State = {
      ...initialState,
      requirements: folder.requirementAssociations,
    };

    const getContacts = async () => {
      let contacts = initialState.contacts;

      if (!isListEmpty(folder.contactIds)) {
        try {
          const {
            data: { users },
          } = await searchUsers({
            params: {
              isEnabled: true,
              owningCompanyId,
              skip: 0,
              take: 1000,
            },
          });

          const retailers = users.filter(
            user => user.userId !== folder.ownerUserId
          );
          contacts = retailers.map(user => ({
            ...user,
            isSelected:
              folder.contactIds.findIndex(userId => user.userId === userId) >
              -1,
          }));
        } catch (e) {}
      }

      return contacts;
    };

    const getProducts = async () => {
      let products = [...initialState.products];
      let productSpecifications = { ...initialState.productSpecifications };
      try {
        const { data: specifications } = await fetchFolderSpecifications({
          id: folderId,
        });
        if (!isListEmpty(specifications)) {
          let tradeItemsIds = [];

          specifications.forEach(specification => {
            tradeItemsIds.push(specification.tradeItemId);
            if (!productSpecifications[specification.tradeItemId]) {
              productSpecifications[specification.tradeItemId] = [];
            }

            productSpecifications[specification.tradeItemId].push({
              ...specification,
              folderId,
            });
          });

          const { data: tradeItems } = await fetchTradeItems({
            ownerCompanyId: owningCompanyId,
            ids: tradeItemsIds,
          });

          let categoryIds = [];
          let netContentUnitIds = [];
          let categories = [];
          let netContentUnits = [];

          tradeItems.forEach(({ category, netContentUnit }) => {
            if (category?.categoryItemId) {
              categoryIds.push(category.categoryItemId);
            }
            if (netContentUnit) {
              netContentUnitIds.push(netContentUnit);
            }
          });

          await Promise.all([
            (async () => {
              try {
                if (!isListEmpty(categoryIds)) {
                  const { data } = await fetchCategoryItems({
                    ids: categoryIds,
                    languageCode: userLanguagePreference,
                  });

                  return data;
                } else {
                  return [];
                }
              } catch (error) {
                return [];
              }
            })(),
            (async () => {
              try {
                if (!isListEmpty(netContentUnitIds)) {
                  const { data } = await fetchReferenceListsItems({
                    ids: netContentUnitIds,
                    languageCode: userLanguagePreference,
                  });

                  return data;
                } else {
                  return [];
                }
              } catch (error) {
                return [];
              }
            })(),
          ]).then(result => {
            categories = result[0];
            netContentUnits = result[1];
          });

          products = tradeItems.reduce(
            (previousState, tradeItem) => [
              ...previousState,
              prepareTradeItemHelper({ netContentUnits, categories })(
                tradeItem
              ),
            ],
            products
          );
        }
      } catch (error) {
        return {
          products: [],
          productSpecifications: {},
        };
      }

      return { products, productSpecifications };
    };

    const getTemplate = async () => {
      if (folder.templateId) {
        try {
          const { data } = await fetchTemplateById({
            templateId: folder.templateId,
            languageCode: userLanguagePreference,
            excludeHiddenSections: true,
          });

          return data;
        } catch (e) {
          return initialState.template;
        }
      }
    };

    const getProductType = async () => {
      if (folder.productTypeId) {
        const { data } = await fetchReferenceListsItem({
          languageCode: userLanguagePreference,
          id: folder.productTypeId,
          companyId: owningCompanyId,
        });

        dispatch(
          updateRefListDictionary({
            [data.id]: data.text,
          })
        );
      }
    };

    const getWizard = async () => {
      let wizard = initialState.wizard;

      try {
        const { data } = await fetchFolderWizard({ folderId });
        wizard = data;
      } catch {}

      return wizard;
    };

    await Promise.all([
      getWizard(),
      getContacts(),
      getProducts(),
      getTemplate(),
      getProductType(),
    ]).then(result => {
      const [
        wizard,
        contacts,
        { products, productSpecifications },
        template,
      ] = result;

      folderDetails = {
        ...folderDetails,
        folder,
        template: template ?? initialState?.template,
        chapters: template?.chapters ?? [],
        contacts,
        products,
        productSpecifications,
        requirements: folder.requirementAssociations,
        ownerUserDetails: user,
        wizard,
      };
    });

    return folderDetails;
  }
);

export const setFolderName = createAsyncThunk<
  FolderData,
  FolderData["folderName"],
  AsyncThunkConfig
>("folderCreation/setFolderName", async (name, { getState, dispatch }) => {
  const {
    folderCreation: { folder, wizard },
  } = getState();

  checkIfFolderIsCreated(folder);

  const { data } = await updateFolderName({
    id: folder.folderId,
    name,
  });

  isCurrentStepValidated(wizard.currentStep, wizard.state) &&
    (await dispatch(invalidateSteps()));

  return data;
});

export const setFolderProductType = createAsyncThunk<
  FolderData,
  FolderData["productTypeId"],
  AsyncThunkConfig
>(
  "folderCreation/setFolderProductType",
  async (productTypeId, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await updateFolderProductType({
      folderId: folder.folderId,
      productTypeId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const fetchFolderTemplate = createAsyncThunk<
  TemplateData,
  void,
  AsyncThunkConfig
>("folderCreation/fetchFolderTemplate", async (_, { getState }) => {
  const {
    folderCreation: {
      folder: { templateId },
    },
    user: { userLanguagePreference },
  } = getState();

  if (!templateId) {
    return initialState.template;
  }

  const { data } = await fetchTemplateById({
    templateId,
    languageCode: userLanguagePreference,
    excludeHiddenSections: true,
  });

  return data;
});

export const setFolderTemplate = createAsyncThunk<
  FolderData,
  FolderData["templateId"],
  AsyncThunkConfig
>(
  "folderCreation/setFolderTemplate",
  async (templateId, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    const { data } = await updateFolderTemplate({
      folderId: folder.folderId,
      templateId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const setFolderTargetDate = createAsyncThunk<
  FolderData,
  FolderData["targetDateUtc"],
  AsyncThunkConfig
>(
  "folderCreation/setFolderTargetDate",
  async (targetDateUtc, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await updateFolderTargetDate({
      id: folder.folderId,
      targetDateUtc,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const setFolderDescription = createAsyncThunk<
  FolderData,
  FolderData["folderDescription"],
  AsyncThunkConfig
>(
  "folderCreation/setFolderDescription",
  async (description, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await updateFolderDescription({
      id: folder.folderId,
      description,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const addFolderContact = createAsyncThunk<
  FolderData,
  UserData["userId"],
  AsyncThunkConfig
>("folderCreation/addFolderContact", async (userId, { getState, dispatch }) => {
  const {
    folderCreation: { folder, wizard },
  } = getState();

  checkIfFolderIsCreated(folder);

  const { data } = await addFolderContactApi({
    folderId: folder.folderId,
    userId,
  });

  isCurrentStepValidated(wizard.currentStep, wizard.state) &&
    (await dispatch(invalidateSteps()));

  return data;
});

export const removeFolderContact = createAsyncThunk<
  FolderData,
  UserData["userId"],
  AsyncThunkConfig
>(
  "folderCreation/removeFolderContact",
  async (userId, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await deleteFolderContact({
      folderId: folder.folderId,
      userId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const setFolderProducts = createAsyncThunk<
  FolderData,
  TradeItemData[],
  AsyncThunkConfig
>(
  "folderCreation/setFolderProducts",
  async (products, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await createFolderProducts({
      folderId: folder.folderId,
      tradeItemIds: products.map(({ id }) => id),
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const removeFolderProduct = createAsyncThunk<
  { folder: FolderData; requirements: Requirement[] },
  TradeItemData["id"],
  AsyncThunkConfig
>(
  "folderCreation/removeFolderProduct",
  async (tradeItemId, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await deleteFolderProduct({
      folderId: folder.folderId,
      tradeItemId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return {
      folder: data,
      requirements: data.requirementAssociations,
    };
  }
);

export const fetchExistingSpecificationsForProduct = createAsyncThunk<
  SpecificationListItemData[],
  TradeItemData["id"],
  AsyncThunkConfig
>(
  "folderCreation/fetchExistingSpecificationsForProduct",
  async (productId, { getState }) => {
    const {
      folderCreation: { folder },
      user: { owningCompanyId },
    } = getState();

    const { data: existingSpecifications } = await fetchTradeItemSpecifications(
      {
        tradeItemId: productId,
        ownerCompanyId: owningCompanyId,
      }
    );

    return existingSpecifications.filter(
      ({ state, folderId }) =>
        !isStateDraft(state) || folderId === folder.folderId
    );
  }
);

export const setProductSupplier = createAsyncThunk<
  ProductSpecification,
  {
    tradeItemId: TradeItemData["id"];
    supplier: {
      id: string;
      name: string;
    };
  },
  AsyncThunkConfig
>(
  "folderCreation/setProductSupplier",
  async ({ tradeItemId, supplier }, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data: specification } = await createSpecificationDraft({
      folderId: folder.folderId,
      tradeItemId,
      supplierId: supplier.id,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return {
      ...specification,
      specificationId: specification.draftId,
      state: SPECIFICATION_STATE.draft,
      supplierName: supplier.name,
    };
  }
);

export const removeProductSupplier = createAsyncThunk<
  SpecificationData["id"],
  {
    tradeItemId: TradeItemData["id"];
    specificationId: SpecificationData["id"];
  },
  AsyncThunkConfig
>(
  "folderCreation/removeProductSupplier",
  async ({ specificationId }, { getState, dispatch }) => {
    const {
      folderCreation: { wizard },
    } = getState();

    await deleteSpecificationDraft({
      specificationId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return specificationId;
  }
);

export const setFolderSpecification = createAsyncThunk<
  SpecificationData,
  {
    tradeItemId: TradeItemData["id"];
    specificationId: SpecificationData["id"];
  },
  AsyncThunkConfig
>(
  "folderCreation/setFolderSpecification",
  async ({ tradeItemId, specificationId }, { getState }) => {
    const {
      folderCreation: { folder, productSpecifications },
      user: { userLanguagePreference },
    } = getState();

    checkIfFolderIsCreated(folder);

    let specificationIdCreateFolder = specificationId;
    const currentSpecification = productSpecifications[tradeItemId].find(
      ({ specificationId: id }) => id === specificationId
    );
    if (
      !isObjectEmpty(currentSpecification) &&
      isStateSignedByRetailer(currentSpecification.state)
    ) {
      // revise the specification and use the new specId to create the spec folder association
      const {
        // @ts-ignore
        data: { specificationId: revisedSpecId },
      } = await updateSpecificationRevision({
        id: currentSpecification.specificationId,
      });
      specificationIdCreateFolder = revisedSpecId;
    }

    await updateFolderSpecification({
      specificationId: specificationIdCreateFolder,
      folderId: folder.folderId,
    });

    const { data } = await fetchSpecification({
      id: specificationIdCreateFolder,
      languageCode: userLanguagePreference,
    });

    return data;
  }
);

export const removeProductSpecification = createAsyncThunk<
  void,
  {
    tradeItemId: TradeItemData["id"];
    specificationId: SpecificationData["id"];
  },
  AsyncThunkConfig
>("folderCreation/removeProductSpecification", async ({ specificationId }) => {
  await updateFolderSpecification({
    specificationId,
    folderId: null,
  });
});

export const setFolderSpecificationNote = createAsyncThunk<
  FolderData,
  FolderData["specificationNote"],
  AsyncThunkConfig
>(
  "folderCreation/setFolderSpecificationNote",
  async (specificationNote, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await updateFolderSpecificationNote({
      id: folder.folderId,
      specificationNote,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const getFolderRequirements = createAsyncThunk<
  { folder: FolderData; requirements: Requirement[] },
  void,
  AsyncThunkConfig
>("folderCreation/getFolderRequirements", async (_, { getState }) => {
  const {
    folderCreation: { folder },
    user: { userLanguagePreference },
  } = getState();

  const { data } = await fetchFolder({
    id: folder.folderId,
    languageCode: userLanguagePreference,
  });

  return {
    folder: data,
    requirements: data.requirementAssociations,
  };
});

export const setFolderRequirements = createAsyncThunk<
  { folder: FolderData; requirements: Requirement[] },
  AppliedRequirements,
  AsyncThunkConfig
>(
  "folderCreation/setFolderRequirements",
  async (appliedRequirements, { getState }) => {
    const {
      folderCreation: { folder, chapters },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await updateFolderRequirements({
      folderId: folder.folderId,
      requirementAssociations: Object.values(appliedRequirements).map(
        ({ requirementId, requirementName, tradeItemIds }) => ({
          chapterType: chapters[0].chapterType,
          requirementId,
          tradeItemIds: Array.from(tradeItemIds),
          requirementName,
        })
      ),
    });

    return {
      folder: data,
      requirements: data.requirementAssociations,
    };
  }
);

export const setRequirementChapter = createAsyncThunk<
  FolderData,
  {
    chapterType: TemplateSectionData["chapterType"];
    requirementId: RequirementData["id"];
  },
  AsyncThunkConfig
>(
  "folderCreation/setRequirementChapter",
  async ({ chapterType, requirementId }, { getState }) => {
    const {
      folderCreation: { folder, requirements },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await updateFolderRequirements({
      folderId: folder.folderId,
      requirementAssociations: requirements.map(
        ({
          requirementId: currentRequirementId,
          chapterType: currentChapterType,
          tradeItemIds,
        }) => ({
          requirementId: currentRequirementId,
          chapterType:
            currentRequirementId !== requirementId
              ? currentChapterType
              : chapterType,
          tradeItemIds,
        })
      ),
    });

    return data;
  }
);

export const removeRequirementProduct = createAsyncThunk<
  { folder: FolderData; requirements: Requirement[] },
  {
    requirementId: RequirementData["id"];
    tradeItemId: TradeItemData["id"];
  },
  AsyncThunkConfig
>(
  "folderCreation/removeRequirementProduct",
  async ({ tradeItemId, requirementId }, { getState }) => {
    const {
      folderCreation: { folder, requirements },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await updateFolderRequirements({
      folderId: folder.folderId,
      requirementAssociations: requirements.map(
        ({
          requirementId: currentRequirementId,
          tradeItemIds,
          chapterType,
        }) => ({
          requirementId: currentRequirementId,
          tradeItemIds:
            currentRequirementId !== requirementId
              ? tradeItemIds
              : tradeItemIds.filter(
                  currentTradeItemId => tradeItemId !== currentTradeItemId
                ),
          chapterType,
        })
      ),
    });

    return {
      folder: data,
      requirements: data.requirementAssociations,
    };
  }
);

export const getFolderCollaborations = createAsyncThunk<
  FolderData,
  void,
  AsyncThunkConfig
>("folderCreation/getFolderCollaborations", async (_, { getState }) => {
  const {
    folderCreation: { folder },
    user: { userLanguagePreference },
  } = getState();

  const { data } = await fetchFolder({
    id: folder.folderId,
    languageCode: userLanguagePreference,
  });

  return data;
});

export const setFolderCollaboration = createAsyncThunk<
  FolderData,
  CollaborationData["id"],
  AsyncThunkConfig
>(
  "folderCreation/setFolderCollaboration",
  async (collaborationId, { getState, dispatch }) => {
    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    const { data } = await createFolderCollaboration({
      folderId: folder.folderId,
      collaborationId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const removeFolderCollaborations = createAsyncThunk<
  FolderData,
  FolderData["collaborationIds"],
  AsyncThunkConfig
>(
  "folderCreation/removeFolderCollaborations",
  async (collaborationIds, { getState, dispatch }) => {
    let folderData: FolderData;

    const {
      folderCreation: { folder, wizard },
    } = getState();

    checkIfFolderIsCreated(folder);

    for (let collaborationId of collaborationIds) {
      const { data } = await deleteFolderCollaboration({
        folderId: folder.folderId,
        collaborationId,
      });

      folderData = data;
    }

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return folderData;
  }
);

export const removeFolder = createAsyncThunk<void, void, AsyncThunkConfig>(
  "folderCreation/removeFolder",
  async (_, { getState }) => {
    const {
      folderCreation: { folder },
    } = getState();

    checkIfFolderIsCreated(folder);

    await deleteFolder({
      id: folder.folderId,
    });
  }
);

export const checkIfFolderIsFinalized = createAsyncThunk<
  boolean,
  void,
  AsyncThunkConfig
>("folderCreation/checkIfFolderIsFinalized", async (_, { getState }) => {
  const {
    folderCreation: { folder },
    user: { userLanguagePreference },
  } = getState();

  if (!folder.folderId) return false;

  const {
    data: { state },
  } = await fetchFolder({
    id: folder.folderId,
    languageCode: userLanguagePreference,
  });

  return !isStateDraft(state);
});

export const updateCurrentStep = createAsyncThunk<
  WizardData,
  string,
  AsyncThunkConfig
>("folderCreation/updateCurrentStep", async (step, { getState }) => {
  const {
    folderCreation: { wizard },
  } = getState();

  const { data } = await updateWizardCurrentStep({
    id: wizard.wizardId,
    step,
  });

  return data;
});

export const validateStep = createAsyncThunk<
  WizardData,
  void,
  AsyncThunkConfig
>("folderCreation/validateStep", async (_, { getState }) => {
  const {
    folderCreation: { wizard },
  } = getState();

  const { data } = await validateWizardStep({
    id: wizard.wizardId,
    step: wizard.currentStep,
  });

  return data;
});

export const invalidateSteps = createAsyncThunk<
  WizardData,
  void,
  AsyncThunkConfig
>("folderCreation/invalidateSteps", async (_, { getState }) => {
  const {
    folderCreation: { wizard },
  } = getState();

  const { data } = await invalidateWizardSteps({
    id: wizard.wizardId,
    step: wizard.currentStep,
  });

  return data;
});

export const updateCollaboration = createAsyncThunk<
  WizardData,
  { collaboratingCompanyId: string; isEnabled: boolean },
  AsyncThunkConfig
>(
  "folderCreation/updateCollaboration",
  async ({ collaboratingCompanyId, isEnabled }, { getState }) => {
    const {
      folderCreation: { wizard },
    } = getState();

    const { data } = await updateWizardCollaboration({
      id: wizard.wizardId,
      collaboratingCompanyId,
      isEnabled,
    });

    return data;
  }
);

export const updateFolderFiles = createAsyncThunk<
  FolderData,
  { payload: SpecificationDraftFileData[] },
  AsyncThunkConfig
>("folderCreation/updateFiles", async ({ payload }, { getState }) => {
  const {
    folderCreation: { folder },
  } = getState();

  const { data } = await updateFolderFilesApi({
    id: folder?.folderId,
    payload,
  });

  return data;
});
