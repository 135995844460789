import { defineMessages } from "react-intl";

export const messages = defineMessages({
  boughtToThirdParty: {
    id: "card.object.compositeBoughtToThirdParty",
    defaultMessage: "Composite bought to third party",
  },
  madeInHouse: {
    id: "card.object.compositeMadeInHouse",
    defaultMessage: "Composite made in house",
  },
  rawMaterial: {
    id: "card.object.rawMaterial",
    defaultMessage: "Raw material",
  },
  additive: {
    id: "card.object.additive",
    defaultMessage: "Additive",
  },
  processingAid: {
    id: "card.object.processingAid",
    defaultMessage: "Processing aid",
  },

  document: {
    id: "card.object.requirementFile",
    defaultMessage: "Document",
  },
  text: {
    id: "card.object.requirementText",
    defaultMessage: "Text",
  },

  marketing: {
    id: "card.object.marketing",
    defaultMessage: "Marketing",
  },
  health: {
    id: "card.object.health",
    defaultMessage: "Health",
  },

  packagingSystem: {
    id: "card.object.packagingSystem",
    defaultMessage: "Packaging System",
  },

  food: {
    id: "card.object.food",
    defaultMessage: "Food",
  },
  fAndV: {
    id: "card.object.fAndV",
    defaultMessage: "Fruits and Vegetables",
  },
});
