import { useIntl } from "react-intl";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAppDispatch } from "store";
import {
  selectClaimForm,
  setClaimFormData,
} from "store/claimForm/claimFormSlice";
import { MAX_TEXTAREA_LENGTH } from "utils/constants";
import { libraryMessages } from "messages";
import TranslationEditor from "../TranslationEditor";

export const TranslationTabContent = ({
  selectedTabKey,
  form,
  claimSentencePlaceholder,
  claimSentenceRequired,
}: {
  selectedTabKey: string;
  form: FormInstance;
  claimSentencePlaceholder?: string;
  claimSentenceRequired?: boolean;
}) => {
  const claimForm = useSelector(selectClaimForm);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const translation = claimForm?.sentenceTranslations?.find(
    translation => translation.languageCode === selectedTabKey
  );

  const handleInputValidation = (value: string = "") => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      form.setFields([
        {
          name: selectedTabKey,
          errors: [formatMessage(libraryMessages.formRequired)],
        },
      ]);
    } else if (trimmedValue.length > MAX_TEXTAREA_LENGTH) {
      form?.setFields([
        {
          name: selectedTabKey,
          errors: [
            formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_TEXTAREA_LENGTH,
            }),
          ],
        },
      ]);
    } else {
      form?.setFields([{ name: selectedTabKey, errors: [] }]);
    }
  };

  const onClaimSentenceChange = (value: string, selectedTabKey) => {
    const updatedSentenceTranslations = claimForm?.sentenceTranslations?.map(
      translation => {
        if (translation.languageCode === selectedTabKey) {
          return {
            ...translation,
            text: value,
          };
        }

        return translation;
      }
    );

    dispatch(
      setClaimFormData({
        ...claimForm,
        sentenceTranslations: updatedSentenceTranslations,
      })
    );

    if (claimSentenceRequired) {
      handleInputValidation(value);
    }
  };

  return (
    <Form.Item
      required={claimSentenceRequired}
      wrapperCol={{
        span: 14,
      }}
      name={selectedTabKey}
    >
      <TranslationEditor
        onChange={debounce(value => {
          onClaimSentenceChange(value, selectedTabKey);
        }, 300)}
        selectedTabKey={selectedTabKey}
        text={translation?.text}
        claimSentencePlaceholder={claimSentencePlaceholder}
      />
    </Form.Item>
  );
};
