import { useEffect, useState } from "react";
import { CategoryItemData } from "models";
import {
  useCatalogDictionary,
  useCategoryItems,
  useReferenceListsItems,
} from "hooks";
import { isListEmpty, isUuidEmpty, isObjectEmpty } from "utils/general";
import { getHierarchyCategoryItem } from "utils/hierarchy";
import { getCategoryIdsFromIngredients } from "./utils";
import { getRefListIdsFromSuppliers } from "utils/material";
import {
  MaterialAllergenViewModel,
  MaterialRecipeIngredientViewModel,
  MaterialSupplierViewModel,
  RawMaterialViewModel,
} from "viewModels";
import {
  getCatalogIdsFromIngredients,
  getIdsFromIngredients,
} from "dtos/material";

const useMaterialDetails = ({
  allergens,
  suppliers,
  rawMaterial,
  ingredients,
}: {
  allergens: MaterialAllergenViewModel[];
  suppliers: MaterialSupplierViewModel[];
  rawMaterial?: RawMaterialViewModel;
  ingredients?: MaterialRecipeIngredientViewModel[];
}) => {
  const [categoryItemsIds, setCategoryItemsIds] = useState<string[]>([]);
  const [catalogIds, setCatalogIds] = useState<string[]>([]);
  const [catalogAllergenIds, setCatalogAllergenIds] = useState<string[]>([]);
  const [catalogClassificationIds, setCatalogClassificationIds] = useState<
    string[]
  >([]);
  const [partIds, setPartIds] = useState<string[]>([]);
  const [refListIds, setRefListIds] = useState<string[]>([]);
  const [currentCategoryItems, setCurrentCategoryItems] = useState<
    CategoryItemData[]
  >([]);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  const { categoryItemsDictionary, categoryItems } = useCategoryItems({
    categoryItemsIds,
    shouldFilterIds: !isFirstSearch,
  });
  const { refListDictionary } = useReferenceListsItems({ refListIds });

  const { catalogDictionary } = useCatalogDictionary({
    catalogIds,
    catalogAllergenIds,
    catalogClassificationIds,
    rawMaterialPartIds: partIds,
  });

  const getReflistIds = () => {
    let refListIds: string[] = [];

    refListIds.push.apply(refListIds, getRefListIdsFromSuppliers(suppliers));

    setRefListIds(refListIds);
  };

  const getCatalogIds = () => {
    let catalogIds: string[] = [];

    catalogIds = getCatalogIdsFromIngredients({ ingredients });

    setCatalogIds(catalogIds);
  };

  const getCatalogAllergenIds = () => {
    let catalogAllergenIds: string[] = [];

    catalogAllergenIds = allergens?.map(allergen => allergen.allergenId);

    setCatalogAllergenIds(catalogAllergenIds);
  };

  const getCatalogClassificationIds = () => {
    let catalogClassificationIds: string[] = [];

    catalogClassificationIds = [rawMaterial?.classificationId].filter(Boolean);

    if (!isListEmpty(ingredients)) {
      catalogClassificationIds = [
        ...catalogClassificationIds,
        ...getIdsFromIngredients({
          ingredients,
          fieldName: "classificationId",
        }),
      ];
    }

    setCatalogClassificationIds(catalogClassificationIds);
  };

  const getPartIds = () => {
    let partIds: string[] = [];

    partIds = [rawMaterial?.partId].filter(Boolean);

    if (!isListEmpty(ingredients)) {
      partIds = [
        ...partIds,
        ...getIdsFromIngredients({ ingredients, fieldName: "partId" }),
      ];
    }

    setPartIds(partIds);
  };

  const getCategoryItemsIds = () => {
    let currentCategoryItemsIds: string[] = [];

    if (!isUuidEmpty(rawMaterial?.partId)) {
      currentCategoryItemsIds.push(rawMaterial.partId);
    }

    if (!isListEmpty(rawMaterial?.functionIds)) {
      currentCategoryItemsIds.push.apply(
        currentCategoryItemsIds,
        rawMaterial.functionIds
      );
    }

    currentCategoryItemsIds.push.apply(
      currentCategoryItemsIds,
      getCategoryIdsFromIngredients(ingredients)
    );

    setCategoryItemsIds(currentCategoryItemsIds);
  };

  useEffect(() => {
    setCurrentCategoryItems(previousState => [
      ...previousState,
      ...categoryItems,
    ]);

    if (!isListEmpty(categoryItems)) {
      setIsFirstSearch(false);
    }
  }, [categoryItems]);

  useEffect(() => {
    getReflistIds();
    getCategoryItemsIds();
    getCatalogIds();
    getCatalogAllergenIds();
    getCatalogClassificationIds();
    getPartIds();
  }, [suppliers, allergens, ingredients, rawMaterial]);

  const getCategoryItem = (
    searchCategoryItemId?: CategoryItemData["categoryItemId"]
  ) => {
    return getHierarchyCategoryItem(
      searchCategoryItemId,
      currentCategoryItems,
      categoryItemsDictionary
    );
  };

  const updateCategoryItems = (categoryItem: CategoryItemData) => {
    if (!isObjectEmpty(categoryItem)) {
      setCurrentCategoryItems(previousState => [
        ...previousState,
        categoryItem,
      ]);
    }
  };

  return {
    categoryItemsDictionary,
    refListDictionary,
    catalogDictionary,
    getCategoryItem,
    updateCategoryItems,
  };
};

export default useMaterialDetails;
