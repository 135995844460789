import classNames from "classnames";
import { Row, Col } from "antd";
import RowTitle from "components/RowTitle";
import {
  BusinessNameAndAddressWidget,
  CaliberCategoryWidget,
  ChapterRecipeWidget,
  CompactWidget,
  CompanyWidget,
  ConditionalWidget,
  ConditionalEditableWidget,
  ContactWidget,
  CultivationOriginWidget,
  GuaranteedShelfLifeWidget,
  HierarchyWidget,
  IngredientListWidget,
  LabellingCodesAndDatesWidget,
  NormalizedLabelWidget,
  NutritionDeclarationWidget,
  NutritionWidget,
  OriginWidget,
  PackagingWidget,
  PackagingSystemWidget,
  ProductWidget,
  RangeNumberWidget,
  RangeToleranceWidget,
  RoCompactWidget,
  SeasonalityWidget,
  SharedDocumentsWidget,
  SiteWidget,
  StorageConditionWidget,
  TreatmentWidget,
  VersionWidget,
  NatureWidget,
  PackagingComponentCharacteristicsWidget,
  PackagingGtinWidget,
  PackagingDisplayedComponentWidget,
  TraceabilityWidget,
  NutriscoreWidget,
  SubstanceWidget,
} from "../../widgets";
import { isListEmpty } from "utils/general";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getPropertiesObjectFromArray } from "../utils";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import FieldReadOnlyWrapper from "../../components/FieldReadOnlyWrapper";
import PackagingMaterialCalculationObjectFieldTemplate from "../ObjectFieldTemplates/PackagingMaterialCalculationObjectFieldTemplate";

const ObjectFieldTemplate: React.FC<ObjectFieldTemplateProps> = ({
  idSchema,
  properties,
  schema,
  title,
  uiSchema = {},
  ...props
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const widgets = {
    BusinessNameAndAddressWidget,
    CaliberCategoryWidget,
    ChapterRecipeWidget,
    CompactWidget,
    CompanyWidget,
    ConditionalWidget,
    ConditionalEditableWidget,
    ContactWidget,
    CultivationOriginWidget,
    GuaranteedShelfLifeWidget,
    HierarchyWidget,
    IngredientListWidget,
    LabellingCodesAndDatesWidget,
    NormalizedLabelWidget,
    NutritionDeclarationWidget,
    NutritionWidget,
    OriginWidget,
    PackagingWidget,
    PackagingSystemWidget,
    ProductWidget,
    RangeNumberWidget,
    RangeToleranceWidget,
    RoCompactWidget,
    SeasonalityWidget,
    SharedDocumentsWidget,
    SiteWidget,
    StorageConditionWidget,
    TreatmentWidget,
    VersionWidget,
    NatureWidget,
    PackagingComponentCharacteristicsWidget,
    PackagingGtinWidget,
    PackagingDisplayedComponentWidget,
    TraceabilityWidget,
    NutriscoreWidget,
    SubstanceWidget,
  };

  if (uiSchema["ui:widget"] && widgets[uiSchema["ui:widget"] as string]) {
    const Widget = widgets[uiSchema["ui:widget"] as string];
    return (
      <div>
        <Widget
          idSchema={idSchema}
          properties={properties}
          schema={schema}
          title={title}
          uiSchema={uiSchema}
          {...props}
        />
      </div>
    );
  }

  if (
    uiSchema["ui:widget"] &&
    uiSchema["ui:widget"] ===
      "PackagingSystemComponentMaterialCalculationsWidget"
  ) {
    return (
      <PackagingMaterialCalculationObjectFieldTemplate
        formContext={props.formContext}
        schema={schema}
        uiSchema={uiSchema}
      />
    );
  }

  const rows = uiSchema["ui:rows"] || [];

  const filterHidden = element => {
    const isHidden = element.content.props.uiSchema["ui:widget"] !== "hidden";

    const wasInRows = rows
      .reduce((acc, current) => {
        if (isListEmpty(current.items)) {
          return acc;
        }
        return [...acc, ...current.items];
      }, [])
      .includes(element.name);

    return isHidden && !wasInRows;
  };

  // Used for optimization.
  const propertyObjects = getPropertiesObjectFromArray(properties);

  return (
    <>
      {rows.map((row, index) => {
        return (
          <Row key={index}>
            <Col xs={uiSchema["ui:row:width"] || 24}>
              <Row>
                {row.title && (
                  <Col
                    span={24}
                    className={classNames({
                      "pt-3": uiSchema["ui:padding:top"],
                    })}
                  >
                    <RowTitle
                      title={getTranslationForKey(row.title)}
                      displayActions={props.formContext?.displayActions}
                    />
                  </Col>
                )}
                {row?.items?.map(item => {
                  const element = propertyObjects[item]?.content;

                  if (!element) return null;

                  if (!props.formContext?.displayActions) {
                    const widgetName = element.props?.uiSchema["ui:widget"];
                    if (
                      widgetName &&
                      widgetName.toUpperCase() === "RADIOWIDGET"
                    ) {
                      return (
                        <FieldReadOnlyWrapper
                          key={`${widgetName}ReadOnly`}
                          {...{
                            widgetName,
                            element,
                            title: element.props?.uiSchema["ui:title"],
                            getTranslationForKey,
                            refListDictionary:
                              props.formContext?.refListDictionary,
                            emptyValue: props.formContext?.emptyValue,
                          }}
                        />
                      );
                    }
                  }

                  return element?.props?.uiSchema["ui:noWrap"] === false ||
                    element?.props?.schema["type"] === "array" ? (
                    element
                  ) : (
                    <Col
                      key={item}
                      xs={24}
                      md={
                        props.formContext?.displayActions
                          ? rows[index]?.colWidth?.[item]?.md || 12
                          : 24
                      }
                    >
                      {element}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        );
      })}

      {uiSchema["ui:title"] && (
        <Col
          span={24}
          className={classNames({
            "pt-3": uiSchema["ui:padding:top"],
          })}
        >
          <RowTitle
            title={getTranslationForKey(uiSchema["ui:title"])}
            displayActions={props.formContext?.displayActions}
          />
        </Col>
      )}
      {properties.filter(filterHidden).map(element => {
        const { name, content } = element;
        const contentWithName = { ...content };
        contentWithName.props.schema.name = name;

        return contentWithName;
      })}
    </>
  );
};

export default ObjectFieldTemplate;
