import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetChapterTypes } from "apis/SPEC/chapter-rtk";
import { useGetSpecificationFiles } from "apis/SPEC/specification-rtk";
import { useFilesInfo } from "hooks";
import { selectLanguageCode } from "store/user/selectors";
import {
  getDocumentIdsLinkedToChapters,
  getDocumentIdsLinkedToTheSpecification,
} from "components/SpecificationDocumentSummary/utils";
import { filterListByIds, isObjectEmpty } from "utils/general";

export type UseSpecificationDocumentSummaryProps = {
  specificationId: string;
  productTypeId: string;
};

const useSpecificationDocumentSummary = ({
  specificationId,
  productTypeId,
}: UseSpecificationDocumentSummaryProps) => {
  const languageCode = useSelector(selectLanguageCode);

  const {
    data,
    isLoading: isSpecFileIdsLoading,
    refetch,
  } = useGetSpecificationFiles({
    specificationId,
  });

  const {
    data: chapterTypes = [],
    isLoading: isChapterTypesLoading,
  } = useGetChapterTypes({
    params: { languageCode },
  });

  const filteredChapterTypes = chapterTypes.filter(
    ({ productTypeId: id }) => id === productTypeId
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const specFileIds: string[] = useMemo(() => {
    if (!data) return [];

    const { libraryItemFileIds, chapters } = data;
    const chapterFiles = chapters.reduce((acc, chapter) => {
      const sectionFiles = chapter.sections.reduce((acc, section) => {
        return [...acc, ...section.fileIds];
      }, []);

      return [...acc, ...chapter.fileIds, ...sectionFiles];
    }, []);

    return [...libraryItemFileIds, ...chapterFiles];
  }, [data]);

  const {
    filesWithUserInfo: allDocuments,
    isLoading: isFileInfoLoading,
  } = useFilesInfo({
    fileIds: Array.from(new Set(specFileIds)),
    shouldHaveUserInformation: true,
    useV2: false,
  });

  const documentIdsLinkedToTheSpecification = useMemo(
    () =>
      getDocumentIdsLinkedToTheSpecification({
        documentSummaryData: data,
        chapterTypes: filteredChapterTypes,
      }),
    [filteredChapterTypes, data]
  );

  const documentsLinkedToTheSpecification = useMemo(
    () =>
      filterListByIds({
        list: allDocuments,
        ids: documentIdsLinkedToTheSpecification,
      }),
    [allDocuments, documentIdsLinkedToTheSpecification]
  );

  const documentIdsLinkedToChapters = useMemo(
    () =>
      getDocumentIdsLinkedToChapters({
        documentSummaryData: data,
        chapterTypes: filteredChapterTypes,
      }),
    [specFileIds, data]
  );

  const documentsLinkedToChapters = useMemo(
    () =>
      filterListByIds({ list: allDocuments, ids: documentIdsLinkedToChapters }),
    [allDocuments, documentIdsLinkedToChapters]
  );

  const documentIdsLinkedToLibraryItems = useMemo(() => {
    if (isObjectEmpty(data)) return [];

    const { libraryItemFileIds } = data;
    return Array.from(new Set(libraryItemFileIds));
  }, [specFileIds, data]);

  const documentsLinkedToLibraryItems = useMemo(
    () =>
      filterListByIds({
        list: allDocuments,
        ids: documentIdsLinkedToLibraryItems,
      }),
    [allDocuments, documentIdsLinkedToLibraryItems]
  );

  return {
    isLoading:
      isFileInfoLoading || isSpecFileIdsLoading || isChapterTypesLoading,
    allDocuments,
    documentsLinkedToTheSpecification,
    documentsLinkedToChapters,
    documentsLinkedToLibraryItems,
    chapters: data?.chapters,
    filteredChapterTypes,
  };
};

export default useSpecificationDocumentSummary;
