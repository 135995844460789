import { isListEmpty } from "utils/general";
import { SpecificationContactType } from "utils/constants";

export const getAddContactAction = ({
  isAddActionInProgress,
  setIsAddActionInProgress,
  addContact,
  isRetailer,
}) => async userId => {
  if (isAddActionInProgress) {
    return null;
  }

  setIsAddActionInProgress(true);

  try {
    await addContact({
      userId,
      signatory: false,
      contactType: isRetailer
        ? SpecificationContactType.RETAILER
        : SpecificationContactType.SUPPLIER,
    });
  } catch (_) {
  } finally {
    setIsAddActionInProgress(false);
  }
};

export const getSignatoryContactAction = ({
  isContactActionInProgress,
  signatoryContact,
  setIsContactActionInProgress,
  userId,
  onChange,
  signatory,
}) => async () => {
  if (isContactActionInProgress) {
    return null;
  }

  setIsContactActionInProgress(true);

  try {
    await signatoryContact({ userId, signatory });
    onChange();
  } catch (_) {
  } finally {
    setIsContactActionInProgress(false);
  }
};

export const getRemoveContactAction = ({
  isContactActionInProgress,
  setIsContactActionInProgress,
  removeContact,
  getCollaborationIds,
  userId,
}) => async () => {
  if (isContactActionInProgress) {
    return null;
  }

  setIsContactActionInProgress(true);

  try {
    await removeContact({ userId });
    await getCollaborationIds();
  } catch (_) {
  } finally {
    setIsContactActionInProgress(false);
  }
};

export const getContacts = async ({
  setIsFilterContactsLoading,
  setHasFilterContactsError,
  searchUsers,
  companyId,
  setFilterContacts,
}) => {
  setIsFilterContactsLoading(true);
  setHasFilterContactsError(false);

  try {
    const {
      data: { users },
    } = await searchUsers({
      params: {
        isEnabled: true,
        owningCompanyId: companyId,
        skip: 0,
        take: 1000,
      },
    });

    setFilterContacts(users);
  } catch (_) {
    setHasFilterContactsError(true);
  } finally {
    setIsFilterContactsLoading(false);
  }
};

export const shouldActionsBeDisplayed = ({
  isRemoveContactActionDisabled,
  isSetAsSignatoryActionDisabled,
  isOwnerOfTheSpecification,
}) =>
  !isOwnerOfTheSpecification &&
  (!isRemoveContactActionDisabled || !isSetAsSignatoryActionDisabled);

export const getSignatoryMessageKey = signatory =>
  signatory ? "unsetAsSignatory" : "setAsSignatory";

export const getSupplierSectionFromSpecData = specificationData => {
  const generalChapter = specificationData?.chapters?.find(
    ({ sectionCode }) => sectionCode === "general"
  );

  return isListEmpty(generalChapter?.subSections)
    ? generalChapter?.sections?.find(
        ({ sectionCode }) => sectionCode === "supplier"
      )
    : generalChapter?.subSections?.find(
        ({ sectionCode }) => sectionCode === "supplier"
      );
};
