import { useEffect, useMemo, useState } from "react";
import { orderBy } from "lodash";
import { fetchMaterialsByIdsAndType } from "apis/SPEC";
import { useBoolean, useReferenceListsItems } from "hooks";
import {
  addExtraMaterialsDetailsToIngredients,
  addOriginsToIngredients,
  getExtraMaterialDetails,
  getFlattenIngredients,
  getMaterialIdsByType,
  getUniqueSuppliersWithAggregatedIngredientIds,
} from "../../utils";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { LIBRARY_OBJECT_URL_TYPES } from "utils/constants";
import { getRefListIdsFromSuppliers } from "utils/material";
import {
  MaterialData,
  MaterialIngredientData,
  MaterialSupplierData,
} from "models";
import { RecipeSummaryIngredientViewModal } from "viewModels";

const useRecipeSummary = ({
  recipeIngredients,
}: {
  recipeIngredients: MaterialIngredientData[];
}) => {
  const [ingredientsData, setIngredientsData] = useState<MaterialData[]>([]);
  const [fileIds, setFileIds] = useState<string[]>([]);

  const { value: isMaterialsDetailsLoading, setTrue, setFalse } = useBoolean();
  const materialIdsByType = useMemo(
    () => getMaterialIdsByType(recipeIngredients),
    [recipeIngredients]
  );
  const extraMaterialsDetails = useMemo(
    () => getExtraMaterialDetails(ingredientsData),
    [ingredientsData]
  );
  const flattenIngredients: RecipeSummaryIngredientViewModal[] = useMemo(
    () => getFlattenIngredients(recipeIngredients),
    [recipeIngredients]
  );
  const ingredientsWithAggregatedDetails: RecipeSummaryIngredientViewModal[] = useMemo(
    () =>
      flattenIngredients.map(ingredient =>
        addExtraMaterialsDetailsToIngredients(ingredient, extraMaterialsDetails)
      ),
    [flattenIngredients, extraMaterialsDetails]
  );
  const suppliers: MaterialSupplierData[] = useMemo(
    () =>
      ingredientsWithAggregatedDetails.reduce((acc, { suppliers }) => {
        if (suppliers) {
          acc = [...acc, ...suppliers];
        }

        return acc;
      }, []),
    [ingredientsWithAggregatedDetails]
  );
  const finalIngredients = useMemo(
    () => addOriginsToIngredients(ingredientsWithAggregatedDetails, suppliers),
    [ingredientsWithAggregatedDetails, suppliers]
  );
  const refListIds = getRefListIdsFromSuppliers(suppliers);
  const { refListDictionary, isLoading } = useReferenceListsItems({
    refListIds,
  });

  const uniqueSuppliers: MaterialSupplierData[] = useMemo(
    () => getUniqueSuppliersWithAggregatedIngredientIds(suppliers),
    [suppliers]
  );

  useEffect(() => {
    const fileIds = ingredientsData?.reduce((acc, { fileIds, files }) => {
      if (fileIds && !isListEmpty(fileIds)) {
        return [...acc, ...fileIds];
      }

      if (!isListEmpty(files)) {
        const collectedFilesFromComposite = files.reduce((acc, { fileId }) => {
          if (!isListEmpty(fileId)) {
            return [...acc, ...fileId];
          }

          return acc;
        }, []);

        return [...acc, ...collectedFilesFromComposite];
      }

      return acc;
    }, []);

    const uniqueFiles = Array.from(new Set(fileIds));

    setFileIds(uniqueFiles);
  }, [ingredientsData]);

  useEffect(() => {
    const getMaterialsByIds = async () => {
      const materialTypes = Object.keys(materialIdsByType);

      try {
        setTrue();
        const fetchPromises = materialTypes.map(materialType =>
          fetchMaterialsByIdsAndType({
            materialIds: materialIdsByType[materialType],
            materialType: LIBRARY_OBJECT_URL_TYPES[materialType],
          })
        );

        const result = await Promise.all(fetchPromises);

        const ingredients = result.reduce(
          (acc, cur) => [...acc, ...cur.data],
          []
        );

        setIngredientsData(ingredients);
      } catch (e) {
        setIngredientsData([]);
      } finally {
        setFalse();
      }
    };

    if (!isObjectEmpty(materialIdsByType)) {
      getMaterialsByIds();
    }
  }, [materialIdsByType]);

  return {
    fileIds,
    isMaterialsDetailsLoading: isMaterialsDetailsLoading || isLoading,
    ingredients: orderBy(
      finalIngredients,
      ["aggregatedPercentage"],
      ["desc"]
    ) as RecipeSummaryIngredientViewModal[],
    refListDictionary,
    suppliers: uniqueSuppliers,
  };
};

export default useRecipeSummary;
