import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RadioChangeEvent } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { fetchClaimTypes } from "apis/SPEC";
import { useBoolean, useModalVisibility } from "hooks";
import { useAppDispatch } from "store";
import {
  initialClaimForm,
  selectClaimForm,
  setClaimFormData,
  setClaimFormFile,
} from "store/claimForm/claimFormSlice";
import { selectLanguageCode } from "store/user/selectors";
import { ClaimFormContext } from "components/Library/components/ClaimForm/contexts/ClaimFormContext";
import { REFLIST_IDS } from "utils/constants";
import { isClaimTypeHealth } from "utils/claim";
import { ClaimTypeData } from "models/claim";
import { TagData } from "models";

const useClaimGeneralInfo = ({ form }: { form?: FormInstance }) => {
  const claimForm = useSelector(selectClaimForm);
  const languageCode = useSelector(selectLanguageCode);
  const { setLogoFile } = useContext(ClaimFormContext);
  const [claimTypes, setClaimTypes] = useState([]);
  const [data, setData] = useState<ClaimTypeData[]>([]);
  const [filteredProductTypeId, setFilteredProductTypeId] = useState<string>();
  const { value: isLoading, setTrue, setFalse } = useBoolean();
  const [
    newlySelectedClaimType,
    setNewlySelectedClaimType,
  ] = useState<string>();
  const [
    newlySelectedProductType,
    setNewlySelectedProductType,
  ] = useState<string>();
  const [
    previouslySelectedProductType,
    setPreviouslySelectedProductType,
  ] = useState<string>();
  const {
    isModalOpen: showClaimTypeChangeModal,
    onOpenModal,
    onCloseModal,
  } = useModalVisibility();

  const dispatch = useAppDispatch();

  const {
    generalInfo: { claimType, productTypeId },
  } = claimForm;

  const getClaimOptions = (items: ClaimTypeData[]) =>
    items?.map(({ claimTypeName, claimType }) => ({
      label: claimTypeName,
      value: claimType,
      key: claimType,
    }));

  useEffect(() => {
    const getClaimTypes = async () => {
      try {
        setTrue();
        const { data } = await fetchClaimTypes({ languageCode });
        setData(data);
        setClaimTypes(getClaimOptions(data));
      } catch {
      } finally {
        setFalse();
      }
    };

    getClaimTypes();
  }, []);

  useEffect(() => {
    if (!claimType) return;

    const [selectedClaimData] = data?.filter(
      ({ claimType: selectedClaimType }) => selectedClaimType === claimType
    );

    setFilteredProductTypeId(
      selectedClaimData?.productTypeId === REFLIST_IDS.FOOD_PRODUCT_TYPE
        ? REFLIST_IDS.F_AND_V_PRODUCT_TYPE
        : REFLIST_IDS.FOOD_PRODUCT_TYPE
    );
  }, [claimType]);

  useEffect(() => {
    if (!productTypeId) return;

    const filteredClaimData = data?.filter(
      ({ productTypeId: selectedProductType }) =>
        selectedProductType === productTypeId
    );

    setClaimTypes(getClaimOptions(filteredClaimData));
  }, [productTypeId]);

  const onNameChange = (value: string) => {
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: { ...claimForm.generalInfo, name: value },
      })
    );
  };

  const onClaimTypeChange = (value: string) => {
    if (value && claimType && value !== claimType && productTypeId) {
      setNewlySelectedClaimType(value);
      onOpenModal();
    } else {
      const requiresSupportingDocuments = isClaimTypeHealth(value)
        ? null
        : false;
      dispatch(
        setClaimFormData({
          ...claimForm,
          generalInfo: {
            ...claimForm.generalInfo,
            claimType: value,
            requiresSupportingDocuments,
          },
        })
      );
      form?.setFieldValue(
        "requiresSupportingDocuments",
        requiresSupportingDocuments
      );
    }
  };

  const onProductTypeChange = (value: string) => {
    if (value && productTypeId && value !== productTypeId && claimType) {
      setNewlySelectedProductType(value);
      setPreviouslySelectedProductType(productTypeId);
      onOpenModal();
    } else {
      dispatch(
        setClaimFormData({
          ...claimForm,
          generalInfo: { ...claimForm.generalInfo, productTypeId: value },
        })
      );
    }
  };

  const onConfirmChangeClaimType = () => {
    if (newlySelectedClaimType) {
      const requiresSupportingDocuments = isClaimTypeHealth(
        newlySelectedClaimType
      )
        ? null
        : false;

      dispatch(
        setClaimFormData({
          generalInfo: {
            ...claimForm.generalInfo,
            claimType: newlySelectedClaimType,
            requiresSupportingDocuments: requiresSupportingDocuments,
            regulatoryHealthClaimId: undefined,
          },
          sentenceTranslations: initialClaimForm.sentenceTranslations,
        })
      );
      form?.setFields([
        {
          name: "requiresSupportingDocuments",
          value: requiresSupportingDocuments,
        },
        {
          name: "regulatoryHealthClaimId",
          value: null,
        },
        {
          name: "nutrient",
          value: null,
        },
      ]);
      dispatch(setClaimFormFile(initialClaimForm.file));
      setLogoFile(null);
      setNewlySelectedClaimType(undefined);
    }

    if (newlySelectedProductType) {
      form?.setFieldValue("productTypeId", newlySelectedProductType);
      dispatch(
        setClaimFormData({
          generalInfo: {
            ...claimForm.generalInfo,
            productTypeId: newlySelectedProductType,
          },
          sentenceTranslations: initialClaimForm.sentenceTranslations,
        })
      );
      dispatch(setClaimFormFile(initialClaimForm.file));
      setLogoFile(null);
      setNewlySelectedProductType(undefined);
    }

    onCloseModal();
  };

  const onCancelChangeClaimType = () => {
    if (newlySelectedClaimType) {
      setNewlySelectedClaimType(undefined);
    }
    if (newlySelectedProductType) {
      form?.setFieldValue("productTypeId", previouslySelectedProductType);
      setNewlySelectedProductType(undefined);
    }

    onCloseModal();
  };

  const onTagsChange = (value: Pick<TagData, "tagId" | "tagText">[]) => {
    if (value?.some(tag => !tag?.tagText)) {
      return;
    }

    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...claimForm.generalInfo,
          tags: [...value],
        },
      })
    );
  };

  const onRequiresSupportingDocumentsChange = (event: RadioChangeEvent) => {
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...claimForm.generalInfo,
          requiresSupportingDocuments: event.target.value,
        },
      })
    );
  };

  return {
    isLoading,
    claimTypes,
    filteredProductTypeId,
    onNameChange,
    onClaimTypeChange,
    onProductTypeChange,
    onTagsChange,
    onRequiresSupportingDocumentsChange,
    showClaimTypeChangeModal,
    onConfirmChangeClaimType,
    onCancelChangeClaimType,
  };
};

export default useClaimGeneralInfo;
