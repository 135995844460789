import { useState } from "react";
import classnames from "classnames";
import { KPI } from "@trace-one/design-system";
import styles from "./styles.module.less";

export type KPITabType = {
  content: React.ReactNode;
  title: string;
  kpiValue: number;
  icon?: string;
  selectedIcon?: string;
  tabKey: string;
};

export type KPITabsProps = {
  defaultTabKey: string;
  kpiTabs: KPITabType[];
  itemsPerRow?: 4 | 5;
  className?: string;
};

const KPITabs: React.FC<KPITabsProps> = ({
  kpiTabs,
  defaultTabKey,
  itemsPerRow = 5,
  className,
}) => {
  const [selectedTabKey, setSelectedTabKey] = useState<string>(defaultTabKey);

  const renderTabContent = (): React.ReactNode => {
    const selectedTab = kpiTabs.find(({ tabKey }) => tabKey === selectedTabKey);

    return selectedTab.content;
  };

  const handleKpiOnClick = (tabKey: string) => () => {
    setSelectedTabKey(tabKey);
  };

  return (
    <>
      <div
        className={classnames(
          styles["kpi-tabs-wrapper"],
          styles[`kpi-tab-grid--${itemsPerRow}`],
          {
            [className]: !!className,
          }
        )}
      >
        {kpiTabs.map(({ tabKey, title, kpiValue, selectedIcon, icon }) => {
          const isSelected = selectedTabKey === tabKey;

          return (
            <KPI
              kpiName={title}
              kpiValue={kpiValue}
              kpiNameGraphic={isSelected ? selectedIcon : icon}
              selected={isSelected}
              clickable
              onClick={handleKpiOnClick(tabKey)}
              data-test-id={tabKey}
              data-testid={tabKey}
              key={tabKey}
              className={styles["kpi-tab"]}
            />
          );
        })}
      </div>

      {renderTabContent()}
    </>
  );
};

export default KPITabs;
