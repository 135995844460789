import { useEffect, useState } from "react";
import { useFilesInfo } from "hooks";
import { File } from "models";
import { RequirementInformationViewModel } from "viewModels/requirements";
import { isListEmpty } from "utils/general";
import { FormInstance } from "antd";

const useDocument = ({
  fileId,
  form,
  onUploadFiles,
}: {
  fileId: RequirementInformationViewModel["fileId"];
  form: FormInstance;
  onUploadFiles: Function;
}) => {
  const { files } = useFilesInfo({ fileIds: [fileId] });
  const [currentFile, setCurentFile] = useState<File>(null);

  const onRemove = () => {
    setCurentFile(null);

    form.setFieldValue("fileId", null);
    form.validateFields(["fileId"]);
  };

  const onUploadFile = (file: File) => {
    form.setFieldValue("fileId", file.id);

    onUploadFiles([file]);
    form.validateFields(["fileId"]);
  };

  useEffect(() => {
    if (!isListEmpty(files)) {
      setCurentFile(files[0]);
    }
  }, [files]);

  return {
    currentFile,
    onRemove,
    onUploadFile,
  };
};

export default useDocument;
