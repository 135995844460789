import { Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import classNames from "classnames";
import Item from "./components/Item";
import Header from "./components/Header";
import { MenuContext } from "./contexts";
import { messages } from "./messages";
import { MenuProps } from "./types";
import styles from "./styles.module.less";

const Menu: React.FC<MenuProps> = ({
  sections,
  onClickSection,
  onClickSubSection,
  selectedSectionId,
  selectedSubSectionId,
  style,
  menuExtraActions,
  isMinified,
  toggleMinified,
  retailerCompnayId,
  supplierCompnayId,
  translatorCompanyId,
  translationStatus,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div
      className={classNames(styles.menuWrapper, {
        [styles.menuWrapperMinified]: isMinified,
      })}
      style={style}
    >
      <Header isMinified={isMinified} toggleMinified={toggleMinified} />
      <MenuContext.Provider
        value={{
          selectedSectionId,
          selectedSubSectionId,
          onClickSection,
          onClickSubSection,
          isMinified,
          retailerCompnayId,
          supplierCompnayId,
          translatorCompanyId,
          translationStatus,
        }}
      >
        <div>
          {sections.map(section => (
            <Item key={section.sectionId} section={section} />
          ))}
        </div>

        {menuExtraActions && (
          <div className={styles.menuExtraActionsWrapper}>
            {!isMinified ? (
              <Paragraph size="s" color="grey-4">
                {formatMessage(messages.otherActions)}
              </Paragraph>
            ) : null}

            {menuExtraActions(isMinified)}
          </div>
        )}
      </MenuContext.Provider>
    </div>
  );
};

export default Menu;
