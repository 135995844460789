import { Col, Row } from "antd";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import { getPropertiesObjectFromArray } from "../../templates/utils";

export const TraceabilityWidget: React.FC<ObjectFieldTemplateProps> = props => {
  const { properties, formData, formContext } = props;
  const { hasPermissionToSave } = formContext;
  const propertyObjects = getPropertiesObjectFromArray(properties);
  const { storage, fileIds, ...restOfProperties } = propertyObjects;

  return (
    <Row>
      <Col xs={24}>
        {Object.keys(restOfProperties).map(property => {
          return {
            ...propertyObjects[property]?.content,
            disabled: !hasPermissionToSave,
          };
        })}
        {formData?.sampleStorage && (
          <>
            {{
              ...propertyObjects["storage"]?.content,
              disabled: !hasPermissionToSave,
            }}
          </>
        )}
        <>
          {{
            ...propertyObjects["fileIds"]?.content,
            disabled: !hasPermissionToSave,
          }}
        </>
      </Col>
    </Row>
  );
};

export default TraceabilityWidget;
