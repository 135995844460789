import { useEffect, useState } from "react";
import { fetchClaimCollection } from "apis/SPEC";
import { isListEmpty } from "utils/general";
import { ClaimData } from "models";
import { CLAIM_TYPES } from "utils/constants";

const useClaimCollection = ({ claimIds }: { claimIds: ClaimData["id"][] }) => {
  const [claimCollection, setClaimCollection] = useState<ClaimData[]>([]);

  useEffect(() => {
    if (isListEmpty(claimIds)) {
      setClaimCollection([]);
      return;
    }

    const getClaimCollection = async () => {
      try {
        const payload = {
          claimIds,
        };

        const { data } = await fetchClaimCollection({
          claimType: CLAIM_TYPES.MARKETING,
          payload,
        });

        setClaimCollection(data);
      } catch {}
    };

    getClaimCollection();
  }, [JSON.stringify(claimIds)]);

  return {
    claimCollection,
  };
};

export default useClaimCollection;
