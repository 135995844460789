import { useIntl } from "react-intl";
import { Col, Row, Space } from "antd";
import { Divider, Heading } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { useFilesInfo } from "hooks";
import { isListEmpty } from "utils/general";
import { claimMessages } from "messages";
import styles from "./styles.module.less";

export type ClaimCardReadOnlyProps = {
  name: string;
  claimSentence: string;
  fileIds: string[];
};

const ClaimCardReadOnly: React.FC<ClaimCardReadOnlyProps> = ({
  name,
  claimSentence,
  fileIds,
}) => {
  const { formatMessage } = useIntl();

  const { files } = useFilesInfo({ fileIds });

  return (
    <div className={styles["claims-section__wrapper"]}>
      <Heading size="xxs">{name}</Heading>
      <Divider color="grey-2" height={16} />
      <Space direction="vertical">
        <Heading size="xxs">
          {formatMessage(claimMessages.claimSentenceTitle)}
        </Heading>
        <div
          className="white-space-break"
          dangerouslySetInnerHTML={{
            __html: claimSentence,
          }}
        />
      </Space>

      {!isListEmpty(files) && (
        <Row className="mt-2">
          <Col span={8}>
            <UploadFiles.List fileList={files} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ClaimCardReadOnly;
