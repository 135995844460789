import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios, { CancelToken } from "axios";
import useBoolean from "hooks/useBoolean";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateFileTypeDictionary } from "store/generalData/asyncActions";
import { selectFileTypeDictionary } from "store/generalData/selectors";
import { fetchFileTypes } from "apis/DOCUMENT";
import { getDictionary, isListEmpty } from "utils/general";
import { FileData, FileTypeData } from "models";

const useFileTypes = ({
  fileTypeNames,
}: {
  fileTypeNames?: FileData["fileTypeName"][];
}) => {
  const languageCode = useSelector(selectLanguageCode);
  const fileTypeDictionary = useSelector(selectFileTypeDictionary);

  const [fileTypes, setFileTypes] = useState<FileTypeData[]>([]);
  const isMounted = useRef(true);

  const dispatch = useAppDispatch();
  const { value: loading, setTrue, setFalse } = useBoolean(false);

  const getFileTypes = async (cancelToken: CancelToken) => {
    if (
      isListEmpty(fileTypeNames) ||
      fileTypeNames.every(fileTypeName => fileTypeDictionary?.[fileTypeName])
    ) {
      return;
    }

    try {
      setTrue();
      const { data } = await fetchFileTypes(cancelToken);

      if (isMounted.current) {
        setFileTypes(data);
      }
    } catch {
    } finally {
      if (isMounted.current) {
        setFalse();
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    getFileTypes(source.token);

    return () => {
      source.cancel();
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isListEmpty(fileTypes)) {
      return;
    }

    const result = fileTypes.map(({ fileTypeName, translations }) => {
      const userLanguageTranslation = translations?.find(
        ({ languageCode: translationLanguageCode }) =>
          translationLanguageCode === languageCode
      )?.value;

      return {
        fileTypeName,
        fileTypeTranslation: userLanguageTranslation,
      };
    });

    const updatedfileTypeDictionary = getDictionary({
      data: result,
      key: "fileTypeName",
      value: "fileTypeTranslation",
    });

    if (isMounted.current) {
      dispatch(updateFileTypeDictionary(updatedfileTypeDictionary));
    }
  }, [fileTypes]);

  return {
    loading,
    fileTypeDictionary,
  };
};

export default useFileTypes;
