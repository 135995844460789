import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Tags } from "@trace-one/react-components";
import { Radio } from "@trace-one/design-system";
import { Col, Form, Row } from "antd";
import { FormInstance } from "antd/es/form/Form";
import LibraryItemName from "components/Library/components/LibraryItemName";
import SelectItem from "components/Library/components/SelectItem";
import StepTitle from "components/Library/components/StepTitle";
import ErrorsList from "components/ErrorsList";
import ReflistSelect from "components/ReflistSelect";
import ConfirmationModal from "components/ConfirmationModal";
import { useErrorCodeCheck } from "hooks";
import { useClaimGeneralInfo } from "./hooks";
import { selectRefListDictionary } from "store/generalData/selectors";
import { selectLanguageCode } from "store/user/selectors";
import { selectClaimForm } from "store/claimForm/claimFormSlice";
import {
  APPLICATION_TYPE_IDS,
  ERROR_CODES,
  REFLIST_IDS,
} from "utils/constants";
import { isFAndVOnly } from "utils/general";
import { messages } from "components/Library/components/ChapterForm/messages";
import { PageType } from "../../ClaimForm";
import {
  isLibraryObjectCreatePage,
  isLibraryObjectEditPage,
  isLibraryObjectPublished,
} from "utils/library";
import { isClaimTypeHealth } from "utils/claim";
import { LibraryItemState } from "types/library";
import { tabMessages } from "messages/library";
import { libraryMessages, claimMessages, generalMessages } from "messages";

export interface ClaimGeneralInfoProps {
  form?: FormInstance;
  page?: PageType;
  state?: LibraryItemState;
}

const ClaimGeneralInfo: React.FC<ClaimGeneralInfoProps> = ({
  form,
  page,
  state,
}) => {
  const { formatMessage } = useIntl();

  const languageCode = useSelector(selectLanguageCode);
  const refListDictionary = useSelector(selectRefListDictionary);
  const {
    generalInfo: {
      name,
      claimType,
      productTypeId,
      tags,
      requiresSupportingDocuments,
    },
  } = useSelector(selectClaimForm);
  const {
    isDesiredErrorCode,
    errorResponseData: notFoundTagIds,
  } = useErrorCodeCheck(ERROR_CODES.TAG_NOT_FOUND);

  const {
    isLoading,
    claimTypes,
    filteredProductTypeId,
    onNameChange,
    onClaimTypeChange,
    onProductTypeChange,
    onTagsChange,
    onRequiresSupportingDocumentsChange,
    showClaimTypeChangeModal,
    onConfirmChangeClaimType,
    onCancelChangeClaimType,
  } = useClaimGeneralInfo({ form });

  return (
    <>
      <StepTitle
        title={formatMessage(tabMessages.generalInformation)}
        step={1}
      />
      <LibraryItemName
        onChange={onNameChange}
        disableActions={
          isLibraryObjectEditPage(page) && isLibraryObjectPublished(state)
        }
        form={form}
        disabledOnBlur={true}
        wrapperCol={{ md: 14 }}
        defaultValue={name}
        dataTestId="claim-form-name"
        manualSaveEnabled={true}
      />
      <Row>
        <Col span={14}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                required
                name="claimType"
                label={formatMessage(claimMessages.claimTypeLabel)}
                rules={[{ required: true }]}
              >
                <SelectItem
                  isLoading={isLoading}
                  options={claimTypes}
                  onChange={onClaimTypeChange}
                  selectedValue={claimType}
                  placeholder={formatMessage(
                    claimMessages.claimTypePlaceholder
                  )}
                  disabled={isLibraryObjectEditPage(page)}
                  allowClear={false}
                  data-test-id="claim-form-claim-type"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                name="productTypeId"
                label={formatMessage(messages.productTypeLabel)}
                rules={[{ required: true }]}
              >
                <ReflistSelect
                  refListId={REFLIST_IDS.PRODUCT_TYPES}
                  allowClear={false}
                  disabled={isLibraryObjectEditPage(page)}
                  name="productTypeId"
                  data-test-id="claim-form-product-type"
                  onSelect={onProductTypeChange}
                  value={productTypeId}
                  placeholder={formatMessage(messages.productTypePlaceholder)}
                  shouldCallApi={true}
                  refListDictionary={refListDictionary}
                  filterRefListIds={
                    isFAndVOnly
                      ? [REFLIST_IDS.FOOD_PRODUCT_TYPE]
                      : filteredProductTypeId
                      ? [filteredProductTypeId]
                      : []
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Item
        name="tagIds"
        label={formatMessage(libraryMessages.tagsLabel)}
        wrapperCol={{
          xs: 24,
          md: 20,
        }}
      >
        {(tags || isLibraryObjectCreatePage(page)) && (
          <Tags.Select
            languageCode={languageCode}
            applicationTypeId={APPLICATION_TYPE_IDS.SPEC}
            tagIds={
              isLibraryObjectCreatePage(page)
                ? []
                : tags?.map(tag => {
                    if (typeof tag === "string") return tag;
                    return tag.tagId;
                  })
            }
            onTagListChange={onTagsChange}
            manualSaveEnabled={true}
            notFoundTagIds={notFoundTagIds}
            data-test-id="claim-form-tags"
          />
        )}
        {isDesiredErrorCode &&
          tags?.some(tag => notFoundTagIds?.includes(tag?.tagId)) && (
            <ErrorsList list={[formatMessage(libraryMessages.tagsNotFound)]} />
          )}
      </Form.Item>
      <Form.Item
        name="requiresSupportingDocuments"
        label={formatMessage(claimMessages.requiresSupportingDocument)}
      >
        <Radio.Group
          value={requiresSupportingDocuments}
          display="horizontal"
          onChange={onRequiresSupportingDocumentsChange}
          disabled={
            requiresSupportingDocuments === null || isClaimTypeHealth(claimType)
          }
          options={[
            {
              label: formatMessage(generalMessages.yes),
              value: true,
            },
            {
              label: formatMessage(generalMessages.no),
              value: false,
            },
          ]}
        />
      </Form.Item>

      {showClaimTypeChangeModal && (
        <ConfirmationModal
          onConfirmModal={onConfirmChangeClaimType}
          onCloseModal={onCancelChangeClaimType}
          title={formatMessage(claimMessages.claimTypeModificationTitle)}
          primaryButtonText={formatMessage(generalMessages.confirm)}
          children={
            <>
              <FormattedMessage
                {...claimMessages.claimTypeModificationDescription}
              />
              <br />
              <FormattedMessage
                {...claimMessages.claimTypeModificationStartOverDescription}
              />
            </>
          }
        />
      )}
    </>
  );
};

export default ClaimGeneralInfo;
