import {
  useProduct,
  useRedirect,
  useReferenceListsItems,
  useUserInfo,
} from "hooks";
import { useParams } from "react-router-dom";
import useTranslationDetails from "./useTranslationDetails";
import Header from "components/Header";
import Spin from "components/Spin";
import { ErrorBoundary } from "react-error-boundary";
import { WarningFallback } from "components";
import { getFirstNetContent } from "utils/specifications";
import LabelingLanguagesTabs from "../LabelingLanguagesTabs";
import { SpecificationState } from "types/specification";
import {
  ChapterTranslationState,
  LIBRARY_OBJECTS_PAGES,
} from "utils/constants";
import { getHeaderStatusName } from "utils/library";
import { isChapterTranslationOnHold } from "utils/chapters";
import Configure from "components/Library/components/ChapterForm/components/Configure/ChapterConfigure";
import styles from "./styles.module.less";

export interface ParamTypes {
  specificationId?: string;
}

const TranslationDetails = () => {
  const { specificationId } = useParams<ParamTypes>();

  const {
    tabs,
    translation,
    selectedTranslationId,
    isLoading,
    onLanguageChange,
    isTranslationFetching,
    getTranslation,
    getLabelingTranslationLanguages,
  } = useTranslationDetails({
    specificationId,
  });

  const { redirectToTranslationListing } = useRedirect();
  const { isTranslator } = useUserInfo();

  const { generalInfo, structure, sections, progressState } = translation;
  const { state, productTypeId, specification } = generalInfo || {};

  const { productData } = useProduct({ productId: specification?.tradeItemId });
  const { refListDictionary } = useReferenceListsItems({
    refListIds:
      productData?.netContents?.map(({ unitOfMeasure }) => unitOfMeasure) || [],
  });
  const firstNetContent = getFirstNetContent({
    netContents: productData?.netContents,
    refListDictionary,
  });

  const status = getHeaderStatusName({
    state,
    isTranslator,
  });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <Header.Specification
        onBackClick={redirectToTranslationListing}
        id={specificationId}
        state={status as SpecificationState}
        productTypeId={productTypeId}
        specificationName={`${productData?.itemName} ${
          firstNetContent ? `- ${firstNetContent}` : ""
        }`}
        displayActions={false}
      />
      <Configure
        generalInfo={generalInfo}
        structure={structure}
        sections={sections}
        progressState={progressState}
        page={LIBRARY_OBJECTS_PAGES.LABELING_TRANSLATIONS}
        className={styles["labelling-translation-content-top-space"]}
        isLoading={isTranslationFetching}
        getTranslation={getTranslation}
        getLabelingTranslationLanguages={getLabelingTranslationLanguages}
        productData={productData}
        translationStatus={status as ChapterTranslationState}
        {...(!isChapterTranslationOnHold(state) && {
          languageTabComponent: (
            <LabelingLanguagesTabs
              languages={tabs}
              activeLanguage={selectedTranslationId}
              onChange={onLanguageChange}
            />
          ),
        })}
      />
    </ErrorBoundary>
  );
};

export default TranslationDetails;
