import { useMemo } from "react";
import { useBoolean } from "hooks";
import { completeLabelingTranslations } from "apis/SPEC";
import { LabelingTranslationLaguageData } from "models";
import {
  isChapterTranslationCompleted,
  isChapterTranslationValidated,
} from "utils/chapters";

export type UseTranslationCompletedProps = {
  labelingTranslationLanguages: LabelingTranslationLaguageData[];
  getTranslation?: Function;
  getLabelingTranslationLanguages?: Function;
  translationId?: string;
  setLabelingTranslationLanguages?: Function;
};

const useTranslationCompleted = ({
  labelingTranslationLanguages = [],
  getTranslation,
  getLabelingTranslationLanguages,
  translationId,
  setLabelingTranslationLanguages,
}: UseTranslationCompletedProps) => {
  const {
    value: completing,
    setTrue: setCompletingTrue,
    setFalse: setCompletingFalse,
  } = useBoolean(false);
  const {
    value: checked,
    setTrue: setCheckedTrue,
    setFalse: setCheckedFalse,
  } = useBoolean(false);
  const {
    value: error,
    setTrue: setErrorTrue,
    setFalse: setErrorFalse,
  } = useBoolean(false);

  const {
    translationIds,
    allTranslationsValidated,
    allTranslationsCompleted,
  } = useMemo(
    () => ({
      translationIds: labelingTranslationLanguages
        ?.filter(({ state }) => !isChapterTranslationCompleted(state))
        .map(({ translationId }) => translationId),

      allTranslationsValidated: labelingTranslationLanguages?.every(
        ({ state }) => isChapterTranslationValidated(state)
      ),
      allTranslationsCompleted: labelingTranslationLanguages?.every(
        ({ state }) => isChapterTranslationCompleted(state)
      ),
    }),
    [labelingTranslationLanguages]
  );

  const disabled = useMemo(
    () =>
      !allTranslationsValidated || allTranslationsCompleted || checked || error,
    [allTranslationsValidated, allTranslationsCompleted, checked, error]
  );

  const isChecked = useMemo(() => allTranslationsCompleted || checked, [
    allTranslationsCompleted,
    checked,
  ]);

  const onClick = async () => {
    try {
      setCompletingTrue();
      await completeLabelingTranslations({ translationIds });
      await getTranslation?.(translationId, false);

      const translationLanguages = await getLabelingTranslationLanguages?.();
      setLabelingTranslationLanguages?.(translationLanguages);

      setCheckedTrue();
      setErrorFalse();
    } catch {
      setErrorTrue();
      setCheckedFalse();
    } finally {
      setCompletingFalse();
    }
  };

  return {
    completing,
    checked: isChecked,
    disabled,
    error,
    allTranslationsValidated,
    allTranslationsCompleted,
    onClick,
  };
};

export default useTranslationCompleted;
