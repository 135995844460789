import { useEffect, useState } from "react";
import {
  axiosInstanceWithoutErrorInterception,
  getLabelingTranslations,
} from "apis/SPEC";
import { isSectionCodeLabeling } from "utils/specifications";
import { isProductTypeFood } from "utils/library";
import {
  SpecificationData,
  SpecificationLabelingTranslationData,
} from "models";
import { ChapterStructureFormData } from "types/library";

export type UseLabelingTranslationsProps = {
  specificationId: SpecificationData["id"];
  sectionCode: ChapterStructureFormData["sectionCode"];
  productTypeId: SpecificationData["productTypeId"];
  hasChapterTranslationManagementPermission: boolean;
  languageCode?: string;
};

const useLabelingTranslations = ({
  specificationId,
  sectionCode,
  productTypeId,
  hasChapterTranslationManagementPermission,
  languageCode,
}: UseLabelingTranslationsProps) => {
  const [
    labelingTranslations,
    setLabelingTranslations,
  ] = useState<SpecificationLabelingTranslationData>(null);

  useEffect(() => {
    const fetchLabelingTranslations = async () => {
      try {
        const { data } = await getLabelingTranslations({
          specificationId,
          languageCode,
          axiosInstance: axiosInstanceWithoutErrorInterception,
        });

        setLabelingTranslations(data);
      } catch {}
    };

    if (
      !specificationId ||
      !isSectionCodeLabeling(sectionCode) ||
      !isProductTypeFood(productTypeId) ||
      !hasChapterTranslationManagementPermission
    ) {
      return;
    }

    fetchLabelingTranslations();
  }, [sectionCode, productTypeId, hasChapterTranslationManagementPermission]);

  return labelingTranslations;
};

export default useLabelingTranslations;
