import { Col, Row, SelectProps } from "antd";
import { useIntl } from "react-intl";
import { Label } from "@trace-one/react-components";
import { Select } from "@trace-one/design-system";
import { useInputValidation } from "hooks";
import libraryMessages from "messages/library";
import { MaterialType } from "types/library";
import { EMPTY_VALUE } from "utils/constants";
import styles from "./styles.module.less";
import { SubstanceData } from "models";
import { isListEmpty } from "utils/general";

interface SubstanceSelectProps extends SelectProps {
  isLoading?: boolean;
  displayActions?: boolean;
  materialType?: MaterialType;
  selectedSubstance?: SubstanceData;
  label?: string;
  width?: number;
  "data-test-id"?: string;
  name?: string;
}

const SubstanceSelect: React.FC<SubstanceSelectProps> = ({
  onChange,
  onSearch,
  options,
  isLoading,
  placeholder,
  displayActions,
  selectedSubstance,
  label,
  width,
  "data-test-id": dataTestId,
  name,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { hasError, onInputChange } = useInputValidation(onChange);

  if (!displayActions) {
    return (
      <Row className={styles.readOnlyWrapper}>
        <Col xs={4} className={styles.standardizedNameWrapper}>
          <Label title={label} required />
        </Col>
        <Col className={styles.standardizedNameValue}>
          {selectedSubstance?.name || EMPTY_VALUE}
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col md={width ?? 9} className={styles.standardizedNameWrapper}>
        <Label title={label} required>
          <Select
            name={name || "standardized-name"}
            data-test-id={dataTestId || "standardized-name"}
            filterOption={false}
            allowClear={true}
            showSearch={true}
            value={
              (isListEmpty(options)
                ? selectedSubstance?.name
                : selectedSubstance?.id) || null
            }
            placeholder={placeholder}
            loading={isLoading}
            options={options}
            onChange={onInputChange}
            onSearch={onSearch}
            error={hasError}
            errorMessage={formatMessage(libraryMessages.formRequired)}
            disabled={!displayActions}
            {...rest}
          />
        </Label>
      </Col>
    </Row>
  );
};

export default SubstanceSelect;
