import { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import { Card } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { isEqual } from "lodash";
import { isListEmpty, isObjectEmpty } from "utils/general";
import ChapterSectionContent from "./components/ChapterSectionContent";
import { SubSectionProps } from "./types";
import { ChapterStructureFormDataBlock } from "types/library";
import styles from "./styles.module.less";
import SelectedSectionContext from "../../../../contexts";
import { getSectionStatus } from "utils/library";

const SubSection: React.FC<SubSectionProps> = ({
  subSection,
  currentSectionsRefs,
}) => {
  const [sectionBlocks, setSectionBlocks] = useState<
    ChapterStructureFormDataBlock[]
  >(subSection?.blocks);

  const {
    retailerCompnayId,
    supplierCompnayId,
    translatorCompanyId,
  } = useContext(SelectedSectionContext);

  useEffect(() => {
    if (isEqual(subSection?.blocks, sectionBlocks)) {
      return;
    }

    setSectionBlocks(subSection?.blocks);
  }, [subSection?.blocks]);

  if (isObjectEmpty(subSection)) return null;

  if (isListEmpty(subSection.blocks)) return null;

  const { sectionId, sectionName, sectionCode, state } = subSection;

  const sectionStatus = getSectionStatus({
    state,
    supplierCompnayId,
    retailerCompnayId,
    translatorCompanyId,
  });

  return (
    <Card
      collapsible
      expand
      className={classNames(
        styles.header,
        styles[sectionStatus?.toLowerCase()]
      )}
      title={
        <span
          data-testid="subsection-name"
          ref={currentSectionsRefs[sectionId]}
        >
          <Heading size="s">{sectionName}</Heading>
        </span>
      }
    >
      <ChapterSectionContent
        sectionId={sectionId}
        blocks={sectionBlocks}
        sectionStatus={sectionStatus}
        setSectionBlocks={setSectionBlocks}
        sectionCode={sectionCode}
      />
    </Card>
  );
};

export default SubSection;
