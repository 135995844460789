import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import { Label } from "@trace-one/react-components";
import { Tag } from "@trace-one/design-system";
import CategoryTreeSelect from "components/CategoryTreeSelect";
import FormCard from "../../../FormCard";
import ClassificationDropdown from "../../../ClassificationDropdown";
import PartDropdown from "../../../PartDropdown";
import { useRawMaterialActions } from "./hooks";
import { useMaterialPermissions } from "components/Library/components/MaterialForm/hooks";
import { REFLIST_IDS } from "utils/constants";
import messages from "./messages";
import { RawMaterialProps } from "./types";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const RawMaterial: React.FC<RawMaterialProps> = ({
  displayActions = true,
  rawMaterial,
  categoryItemsDictionary,
  catalogDictionary,
  updateCategoryItems,
}) => {
  const { formatMessage } = useIntl();

  const [classificationId, setClassificationId] = useState<string>(
    rawMaterial?.classificationId
  );
  const [partId, setPartId] = useState<string>(rawMaterial?.partId);

  useEffect(() => setPartId(rawMaterial?.partId), [rawMaterial?.partId]);

  const {
    onChangeClassification,
    onChangePart,
    onSelectFunction,
    onRemoveFunction,
  } = useRawMaterialActions({ setClassificationId, setPartId });

  const { disableActions } = useMaterialPermissions();

  return (
    <FormCard
      className={styles.rawMaterialFormDetailsWrapper}
      title={formatMessage(messages.rawMaterialDetailsCardTitle)}
      name="raw-material"
    >
      <>
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            md={12}
            className={styles.rawMaterialFormDetailsColWrapper}
          >
            <Label
              required
              title={formatMessage(messages.rawMaterialClassification)}
            >
              {displayActions ? (
                <ClassificationDropdown
                  name="raw-material-classification"
                  value={{
                    value: classificationId,
                    label: catalogDictionary?.[classificationId],
                  }}
                  data-testid="raw-material-form-details-classification"
                  onChange={onChangeClassification}
                  onFullChange={updateCategoryItems}
                  disabled={disableActions}
                />
              ) : (
                catalogDictionary?.[classificationId]
              )}
            </Label>
          </Col>
          <Col
            xs={24}
            md={6}
            className={styles.rawMaterialFormDetailsColWrapper}
          >
            <Label title={formatMessage(messages.rawMaterialDetailsPart)}>
              {displayActions ? (
                <PartDropdown
                  name="raw-material-part"
                  value={{
                    value: partId,
                    label: catalogDictionary?.[partId],
                  }}
                  data-testid="raw-material-part"
                  onChange={onChangePart}
                  classificationId={classificationId}
                  disabled={!classificationId}
                />
              ) : (
                catalogDictionary?.[partId]
              )}
            </Label>
          </Col>
        </Row>
        <Row className={styles.rawMaterialFormDetailsFunctionsWrapper}>
          <Col
            xs={24}
            md={22}
            className={styles.rawMaterialFormDetailsColWrapper}
          >
            <Label title={formatMessage(messages.rawMaterialDetailsFunctions)}>
              {displayActions && (
                <>
                  <CategoryTreeSelect
                    name="raw-material-function"
                    allowClear={false}
                    placeholder={formatMessage(
                      messages.rawMaterialDetailsFunctionsPlaceholder
                    )}
                    categoryId={REFLIST_IDS.FUNCTIONS}
                    data-testid="raw-material-form-details-functions"
                    disabled={disableActions}
                    value={[]}
                    onChange={() => {}}
                    onFullChange={onSelectFunction}
                    shouldCurrentValueBeUpdated={false}
                    shouldFirstLevelBeSelectable={false}
                  />
                </>
              )}
              <div
                className={styles.rawMaterialFormDetailsFunctionsTagsWrapper}
              >
                {rawMaterial.functionIds.map(functionId => (
                  <Tag
                    key={`function-${functionId}`}
                    label={categoryItemsDictionary[functionId]}
                    {...(!disableActions &&
                      displayActions && {
                        onClose: () => onRemoveFunction(functionId),
                      })}
                    className="mr-1"
                  />
                ))}
              </div>
            </Label>
          </Col>
        </Row>
      </>
    </FormCard>
  );
};

export default RawMaterial;
