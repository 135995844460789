import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectGeneralData = (state: RootState) => state.generalData;

export const selectUnitsOfMeasure = createSelector(
  _selectGeneralData,
  ({ unitsOfMeasure }) => unitsOfMeasure
);

export const selectCategoryDictionary = (state: RootState) =>
  state.generalData.categoryDictionary[state.user.userLanguagePreference] || {};

export const selectRefListDictionary = (state: RootState) =>
  state.generalData.refListDictionary[state.user.userLanguagePreference] || {};

export const selectCatalogDictionary = (state: RootState) =>
  state.generalData.catalogDictionary[state.user.userLanguagePreference] || {};

export const selectServingDictionary = (state: RootState) =>
  state.generalData.servingDictionary[state.user.userLanguagePreference] || {};

export const selectFileTypeDictionary = (state: RootState) =>
  state.generalData.fileTypeDictionary[state.user.userLanguagePreference] || {};
