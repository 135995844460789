import { defineMessages } from "react-intl";

export const messages = defineMessages({
  seeRecipeSummary: {
    id: "specification.recipeTable.seeRecipeSummary",
    defaultMessage: "See recipe summary",
  },
  recipeSummaryOverlaySubtitle: {
    id: "specification.recipeTable.recipeSummary.subtitle",
    defaultMessage:
      "Here's the summary of your recipe, offering two viewing choices: a global perspective or a more detailed view.",
  },
  recipeSummaryOverlayIngredients: {
    id: "specification.recipeTable.recipeSummary.ingredients",
    defaultMessage: "Ingredients",
  },
  recipeSummaryOverlayIngredientList: {
    id: "specification.recipeTable.recipeSummary.ingredientList",
    defaultMessage: "Ingredient list",
  },
  technicalInformation: {
    id: "specification.recipeSummary.technicalInformation",
    defaultMessage: "Technical information",
  },
  technicalInformationDescrition: {
    id: "specification.recipeSummary.technicalInformationDescrition",
    defaultMessage: "Material technical data sheets",
  },
  certificateTitle: {
    id: "specification.recipeSummary.certificateTitle",
    defaultMessage: "Certificate",
  },
  recipePercentageTitle: {
    id: "specification.recipeSummary.recipePercentageTitle",
    defaultMessage: "% In recipe",
  },
  originsTitle: {
    id: "specification.recipeSummary.originsTitle",
    defaultMessage: "Origin(s)",
  },
  functionTitle: {
    id: "specification.recipeSummary.functionTitle",
    defaultMessage: "Function",
  },
  documentsTitle: {
    id: "documents",
    defaultMessage: "Documents",
  },
  suppliers: {
    id: "specification.recipeSummary.suppliers",
    defaultMessage: "Suppliers",
  },
  supplierList: {
    id: "specification.recipeSummary.supplierList",
    defaultMessage: "Suppliers list",
  },
  allergenDetails: {
    id: "specification.recipeSummary.allergenDetails",
    defaultMessage: "Allergens details",
  },
});
