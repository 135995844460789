import { createContext } from "react";
import { FileViewModel } from "viewModels";

export type SummaryContext<T> = {
  item?: T;
  selectedItems?: T[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<T[]>>;
  setPageItemCount?: React.Dispatch<React.SetStateAction<number>>;
  zipFileName?: string;
};

export type DocumentSummaryPanelContextProps = SummaryContext<FileViewModel>;

const DocumentSummaryPanelContext = createContext<DocumentSummaryPanelContextProps>(
  {
    selectedItems: [],
    setSelectedItems: () => {},
    setPageItemCount: () => {},
    zipFileName: "",
  }
);

export default DocumentSummaryPanelContext;
