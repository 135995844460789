import { PackagingSubstance, PackagingSubstanceFormData } from "models";
import { useState } from "react";

export interface UsePackagingAddSubstanceProps {
  substances: PackagingSubstance[];
  onClose: () => void;
  saveTemplateBlock: Function;
  formData: PackagingSubstanceFormData;
}

export const usePackagingAddSubstance = ({
  onClose,
  substances,
  saveTemplateBlock,
  formData,
}: UsePackagingAddSubstanceProps) => {
  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);

  const onAddSubstances = async () => {
    try {
      setIsSavingInProgress(true);

      await saveTemplateBlock(
        JSON.stringify({
          substances: [
            ...(formData?.substances ?? []),
            ...(substances ?? []).map(
              ({
                selectedSubstance,
                percentage,
                presentAtNanoscale,
                presenceAtNanoscale,
              }) => ({
                substanceId: selectedSubstance.id,
                percentage,
                presentAtNanoscale,
                ...(presenceAtNanoscale ? { presenceAtNanoscale } : {}),
              })
            ),
          ],
        })
      );

      onClose();
    } catch (error) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  return {
    onAddSubstances,
    isSavingInProgress,
  };
};
