import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createMaterial,
  publishMaterial as publishMaterialApi,
  updateMaterialDetails,
  updateMaterialIngredientType,
  addMaterialTags,
  deleteMaterialTag,
  createMaterialSupplier,
  updateMaterialSupplier,
  deleteMaterialSupplier,
  getMaterialCharacteristics as getMaterialCharacteristicsApi,
  updateMaterialCharacteristics as updateMaterialCharacteristicsApi,
  createMaterialSupplierOrigin,
  deleteMaterialSupplierOrigin,
  updateMaterialSupplierOrigin,
  addMaterialAllergens,
  deleteMaterialAllergen,
  updateMaterialAllergen,
  shareMaterial,
  createMaterialIngredientFromMaterial,
  createMaterialIngredientRawMaterial,
  createMaterialIngredientComposite,
  deleteMaterialIngredient,
  updateMaterialIngredient,
  deleteMaterial,
  updateAllergenForBoughtMaterials,
  updateMaterialSupplierIngredient,
  getMaterialControlPlans as getMaterialControlPlansApi,
  updateMaterialControlPlans as updateMaterialControlPlansApi,
  updateMaterialAttachments,
} from "apis/SPEC";
import {
  MaterialAllergenDeclarationData,
  MaterialCharacteristicsSchemaData,
  MaterialControlPlansSchemaData,
  MaterialData,
  MaterialIngredientData,
  MaterialOriginData,
  MaterialRecipeData,
  MaterialSupplierData,
} from "models";
import { AsyncThunkConfig } from "store";
import {
  getMaterialApiType,
  isLibraryObjectLocked,
  isMaterialCompositeBoughtToThirdParty,
  isMaterialCompositeType,
} from "utils/library";
import { isListEmpty } from "utils/general";
import { getLastParentIngredients } from "utils/material";
import {
  checkIfMaterialIsCreated,
  prepareUpdateMaterialPayload,
} from "./utils";
import {
  AddCompositeIngredientBody,
  AddRawMaterialIngredientBody,
  AddMaterialIngredientBody,
} from "types/api";
import { MaterialAllergenViewModel } from "viewModels";

export const updateName = createAsyncThunk<
  MaterialData,
  MaterialData["name"],
  AsyncThunkConfig
>("materialForm/updateName", async (name, { getState }) => {
  if (!name) {
    return;
  }

  const {
    materialForm: {
      materialInformation,
      rawMaterial,
      creationStatus,
      substanceId,
    },
  } = getState();

  if (materialInformation.id) {
    const { data } = await updateMaterialDetails({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      substanceId,
      ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
      name,
    });

    return data;
  }

  if (creationStatus.hasError) {
    const { data } = await createMaterial({
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      name,
      active: materialInformation?.active,
      tagIds: materialInformation?.tagIds,
      internalCode: materialInformation?.internalCode,
    });

    return data;
  }

  return;
});

export const addTag = createAsyncThunk<MaterialData, string, AsyncThunkConfig>(
  "materialForm/addTag",
  async (tagId, { getState }) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    if (materialInformation.id) {
      const { data } = await addMaterialTags({
        materialId: materialInformation.id,
        tagIds: [tagId],
        materialType: getMaterialApiType({
          materialType: materialInformation.materialType,
          recipeType: materialInformation.recipeType,
        }),
      });

      return data;
    }
    return;
  }
);

export const removeTag = createAsyncThunk<
  MaterialData,
  string,
  AsyncThunkConfig
>("materialForm/removeTag", async (tagId, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  if (materialInformation.id) {
    const { data } = await deleteMaterialTag({
      materialId: materialInformation.id,
      tagId: tagId,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
    });
    return data;
  }

  return;
});

export const updateInternalCode = createAsyncThunk<
  MaterialData,
  MaterialData["internalCode"],
  AsyncThunkConfig
>("materialForm/updateInternalCode", async (internalCode, { getState }) => {
  const {
    materialForm: { materialInformation, rawMaterial, substanceId },
  } = getState();

  if (materialInformation.id) {
    const { data } = await updateMaterialDetails({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      substanceId,
      ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
      internalCode,
    });

    return data;
  }

  return;
});

export const updateStatus = createAsyncThunk<
  MaterialData,
  MaterialData["active"],
  AsyncThunkConfig
>("materialForm/updateStatus", async (active, { getState }) => {
  const {
    materialForm: { materialInformation, rawMaterial, substanceId },
  } = getState();

  if (materialInformation.id) {
    const { data } = await updateMaterialDetails({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      substanceId,
      ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
      active,
    });

    return data;
  }

  return;
});

export const updateType = createAsyncThunk<
  MaterialData,
  MaterialData["materialType"],
  AsyncThunkConfig
>("materialForm/updateType", async (materialType, { getState, dispatch }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  await dispatch(resetMaterial());

  if (materialType && !isMaterialCompositeType(materialType)) {
    const { data } = await createMaterial({
      materialType: getMaterialApiType({
        materialType,
        recipeType: materialInformation.recipeType,
      }),
      ...(!!materialInformation?.name && {
        name: materialInformation?.name,
      }),
      active: materialInformation?.active,
      tagIds: materialInformation?.tagIds,
      internalCode: materialInformation?.internalCode,
    });

    return data;
  }
});

export const updateRecipe = createAsyncThunk<
  MaterialData,
  MaterialRecipeData["recipeType"],
  AsyncThunkConfig
>("materialForm/updateRecipe", async (recipeType, { getState, dispatch }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  await dispatch(resetMaterial());

  const { data } = await createMaterial({
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: recipeType,
    }),
    ...(!!materialInformation?.name && {
      name: materialInformation?.name,
    }),
    active: materialInformation?.active,
    tagIds: materialInformation?.tagIds,
    internalCode: materialInformation?.internalCode,
  });

  return data;
});

export const resetMaterial = createAsyncThunk<void, void, AsyncThunkConfig>(
  "chapterForm/resetMaterial",
  async (_, { getState }) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    if (materialInformation.id) {
      await deleteMaterial({
        materialId: materialInformation.id,
        materialType: getMaterialApiType({
          materialType: materialInformation.materialType,
          recipeType: materialInformation.recipeType,
        }),
      });
    }
  }
);

export const updateClassificationId = createAsyncThunk<
  MaterialData,
  MaterialData["classificationId"],
  AsyncThunkConfig
>(
  "materialForm/updateClassificationId",
  async (classificationId, { getState }) => {
    const {
      materialForm: { materialInformation, rawMaterial, substanceId },
    } = getState();

    checkIfMaterialIsCreated(materialInformation);

    const { data } = await updateMaterialDetails({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      substanceId,
      ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
      classificationId,
      partId: null,
    });

    return data;
  }
);

export const updatePartId = createAsyncThunk<
  MaterialData,
  MaterialData["partId"],
  AsyncThunkConfig
>("materialForm/updatePartId", async (partId, { getState }) => {
  const {
    materialForm: { materialInformation, rawMaterial, substanceId },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await updateMaterialDetails({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    substanceId,
    ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
    partId: partId ?? null,
  });

  return data;
});

export const addFunctionId = createAsyncThunk<
  MaterialData,
  string,
  AsyncThunkConfig
>("materialForm/addFunctionId", async (funcId, { getState }) => {
  const {
    materialForm: { rawMaterial, materialInformation, substanceId },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  // check to see if the function is already added
  if (rawMaterial.functionIds.find(id => id === funcId))
    throw new Error("element already exists");

  const { data } = await updateMaterialDetails({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    substanceId,
    ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
    functionIds: [...rawMaterial.functionIds, funcId],
  });

  return data;
});

export const removeFunctionId = createAsyncThunk<
  MaterialData,
  string,
  AsyncThunkConfig
>("materialForm/removeFunctionId", async (funcId, { getState }) => {
  const {
    materialForm: { materialInformation, rawMaterial, substanceId },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const idx = rawMaterial.functionIds.findIndex(id => id === funcId);
  if (idx === -1) {
    throw new Error("element not exists in the array");
  }
  const { data } = await updateMaterialDetails({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    substanceId,
    ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
    functionIds: rawMaterial.functionIds.filter(
      currentFunctionId => currentFunctionId !== funcId
    ),
  });

  return data;
});

export const removeAllergen = createAsyncThunk<
  MaterialData,
  MaterialAllergenDeclarationData,
  AsyncThunkConfig
>("materialForm/removeAllergen", async ({ allergenId }, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await deleteMaterialAllergen({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    allergenId,
  });

  return data;
});

export const updateAllergenPresent = createAsyncThunk<
  MaterialData,
  {
    allergenId: MaterialAllergenViewModel["allergenId"];
    ingredientId?: string;
    declaration?: MaterialAllergenViewModel["declaration"];
    presentIngredientIds?: MaterialAllergenViewModel["presentForIngredientIds"];
    unintentionalPresentIngredientIds?: MaterialAllergenViewModel["unintentionalPresentForIngredientIds"];
  },
  AsyncThunkConfig
>(
  "materialForm/updateMaterialAllergen",
  async (
    {
      declaration,
      allergenId,
      ingredientId,
      presentIngredientIds,
      unintentionalPresentIngredientIds,
    },
    { getState }
  ) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    checkIfMaterialIsCreated(materialInformation);

    const materialId = materialInformation.id;
    const materialType = getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    });

    let materialData: MaterialData;
    if (isMaterialCompositeBoughtToThirdParty(materialInformation.recipeType)) {
      const { data } = await updateAllergenForBoughtMaterials({
        materialId,
        materialType,
        allergenId,
        presentIngredientIds,
        unintentionalPresentIngredientIds,
      });

      materialData = data;
    } else {
      const { data } = await updateMaterialAllergen({
        materialId,
        materialType,
        allergenId,
        declaration,
        ingredientId,
      });

      materialData = data;
    }

    return materialData;
  }
);

export const addAllergens = createAsyncThunk<
  MaterialData,
  MaterialAllergenDeclarationData[],
  AsyncThunkConfig
>("materialForm/addAllergens", async (allergens, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const payload = isMaterialCompositeBoughtToThirdParty(
    materialInformation.recipeType
  )
    ? { allergenIds: allergens.map(allergen => allergen.allergenId) }
    : allergens;

  const { data } = await addMaterialAllergens({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    payload,
  });

  return data;
});

export const createOrigin = createAsyncThunk<
  MaterialData,
  {
    supplierId: MaterialSupplierData["supplierId"];
    typeId: MaterialOriginData["typeId"];
  },
  AsyncThunkConfig
>("materialForm/createOrigin", async ({ supplierId, typeId }, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await createMaterialSupplierOrigin({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    supplierId,
    typeId,
  });

  return data;
});

export const updateOrigin = createAsyncThunk<
  MaterialData,
  {
    supplierId: MaterialSupplierData["supplierId"];
    payloadData: MaterialOriginData;
  },
  AsyncThunkConfig
>(
  "materialForm/updateOrigin",
  async ({ supplierId, payloadData }, { getState }) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    checkIfMaterialIsCreated(materialInformation);

    const { data } = await updateMaterialSupplierOrigin({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      supplierId,
      originId: payloadData.typeId,
      payloadData,
    });
    return data;
  }
);

export const removeOrigin = createAsyncThunk<
  MaterialData,
  {
    supplierId: MaterialSupplierData["supplierId"];
    typeId: MaterialOriginData["typeId"];
  },
  AsyncThunkConfig
>("materialForm/removeOrigin", async ({ supplierId, typeId }, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await deleteMaterialSupplierOrigin({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    supplierId,
    typeId,
  });

  return data;
});

export const createSupplier = createAsyncThunk<
  MaterialData,
  MaterialSupplierData,
  AsyncThunkConfig
>("materialForm/createSupplier", async (value, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await createMaterialSupplier({
    materialId: materialInformation.id,
    name: value?.name,
    code: value?.code,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
  });

  return data;
});

export const updateSupplier = createAsyncThunk<
  MaterialData,
  MaterialSupplierData,
  AsyncThunkConfig
>("materialForm/updateSupplier", async (value, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await updateMaterialSupplier({
    materialId: materialInformation.id,
    supplierId: value.supplierId,
    name: value?.name,
    code: value?.code,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
  });

  return data;
});

export const updateSupplierIngredient = createAsyncThunk<
  MaterialData,
  {
    supplierId: MaterialSupplierData["supplierId"];
    ingredientIds: string[];
  },
  AsyncThunkConfig
>(
  "materialForm/updateSupplierIngredient",
  async ({ supplierId, ingredientIds }, { getState }) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    const { data } = await updateMaterialSupplierIngredient({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      supplierId,
      ingredientIds,
    });

    return data;
  }
);

export const removeSupplier = createAsyncThunk<
  MaterialData,
  { supplierId: MaterialSupplierData["supplierId"] },
  AsyncThunkConfig
>("materialForm/removeSupplier", async ({ supplierId }, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await deleteMaterialSupplier({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    supplierId: supplierId,
  });

  return data;
});

export const updateBoughtIngredientType = createAsyncThunk<
  MaterialData,
  MaterialIngredientData["ingredientId"],
  AsyncThunkConfig
>(
  "materialForm/updateBoughtIngredientType",
  async (ingredientId, { getState }) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    checkIfMaterialIsCreated(materialInformation);

    const { data } = await updateMaterialIngredientType({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      ingredientId,
    });

    return data;
  }
);

export const updateIngredient = createAsyncThunk<
  MaterialData,
  {
    ingredientId: MaterialIngredientData["ingredientId"];
    percentage: MaterialIngredientData["percentage"];
    functionId: MaterialIngredientData["functionId"];
    classificationId?: MaterialIngredientData["classificationId"];
    name?: MaterialIngredientData["name"];
  },
  AsyncThunkConfig
>(
  "materialForm/updateIngredient",
  async (
    { ingredientId, percentage, functionId, classificationId, name },
    { getState }
  ) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    checkIfMaterialIsCreated(materialInformation);

    const { data } = await updateMaterialIngredient({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      ingredientId,
      ...(!!percentage && {
        percentage,
      }),
      functionId,
      classificationId,
      name,
    });

    return data;
  }
);

export const removeIngredient = createAsyncThunk<
  MaterialData,
  {
    ingredientId: MaterialIngredientData["ingredientId"];
    parentIdsPath?: MaterialIngredientData["ingredientId"][];
  },
  AsyncThunkConfig
>(
  "materialForm/removeIngredient",
  async ({ ingredientId, parentIdsPath = [] }, { getState }) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    checkIfMaterialIsCreated(materialInformation);

    const { data } = await deleteMaterialIngredient({
      materialId: materialInformation.id,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
      ingredientId,
    });

    if (!isListEmpty(parentIdsPath)) {
      const parentIngredients = getLastParentIngredients(
        data.recipe.ingredients,
        parentIdsPath
      );

      if (isListEmpty(parentIngredients)) {
        const { data: newData } = await updateMaterialIngredientType({
          materialId: materialInformation.id,
          materialType: getMaterialApiType({
            materialType: materialInformation.materialType,
            recipeType: materialInformation.recipeType,
          }),
          ingredientId: parentIdsPath.slice(-1)[0],
        });

        return newData;
      }
    }

    return data;
  }
);

export const addMadeInHouseIngredient = createAsyncThunk<
  MaterialData,
  AddMaterialIngredientBody,
  AsyncThunkConfig
>("materialForm/addMadeInHouseIngredient", async (ingredient, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await createMaterialIngredientFromMaterial({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    ingredient,
  });

  return data;
});

export const addRawMaterialIngredient = createAsyncThunk<
  MaterialData,
  AddRawMaterialIngredientBody,
  AsyncThunkConfig
>("materialForm/addRawMaterialIngredient", async (ingredient, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await createMaterialIngredientRawMaterial({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    ingredient,
  });

  return data;
});

export const addCompositeIngredient = createAsyncThunk<
  MaterialData,
  AddCompositeIngredientBody,
  AsyncThunkConfig
>("materialForm/addCompositeIngredient", async (ingredient, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await createMaterialIngredientComposite({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    ingredient,
  });

  return data;
});

export const getMaterialCharacteristics = createAsyncThunk<
  MaterialCharacteristicsSchemaData,
  {
    materialId: MaterialData["id"];
    materialType: MaterialData["materialType"];
    recipeType?: MaterialRecipeData["recipeType"];
  },
  AsyncThunkConfig
>(
  "materialForm/getMaterialCharacteristics",
  async ({ materialId, materialType, recipeType }, { getState }) => {
    const {
      user: { userLanguagePreference },
    } = getState();

    const res = await getMaterialCharacteristicsApi({
      materialId,
      materialType: getMaterialApiType({ materialType, recipeType }),
      languageCode: userLanguagePreference,
    });

    return res.data;
  }
);

export const updateMaterialCharacteristics = createAsyncThunk<
  void,
  {
    materialId: MaterialData["id"];
    json: string;
  },
  AsyncThunkConfig
>(
  "materialForm/updateMaterialCharacteristics",
  async ({ materialId, json }, { getState, dispatch }) => {
    const {
      materialForm: { materialInformation },
    } = getState();
    const { materialType, recipeType } = materialInformation;

    checkIfMaterialIsCreated(materialInformation);

    await updateMaterialCharacteristicsApi({
      materialId,
      materialType: getMaterialApiType({ materialType, recipeType }),
      json,
    });

    await dispatch(
      getMaterialCharacteristics({
        materialId,
        materialType,
        recipeType,
      })
    );
  }
);

export const getMaterialControlPlans = createAsyncThunk<
  MaterialControlPlansSchemaData,
  {
    materialId: MaterialData["id"];
    materialType: MaterialData["materialType"];
    recipeType?: MaterialRecipeData["recipeType"];
  },
  AsyncThunkConfig
>(
  "materialForm/getMaterialControlPlans",
  async ({ materialId, materialType, recipeType }, { getState }) => {
    const {
      user: { userLanguagePreference },
    } = getState();

    const { data } = await getMaterialControlPlansApi({
      materialId,
      materialType: getMaterialApiType({ materialType, recipeType }),
      languageCode: userLanguagePreference,
    });

    return data;
  }
);

export const updateMaterialControlPlans = createAsyncThunk<
  void,
  {
    materialId: MaterialData["id"];
    json: string;
  },
  AsyncThunkConfig
>(
  "materialForm/updateMaterialControlPlans",
  async ({ materialId, json }, { getState, dispatch }) => {
    const {
      materialForm: { materialInformation },
    } = getState();
    const { materialType, recipeType } = materialInformation;

    checkIfMaterialIsCreated(materialInformation);

    await updateMaterialControlPlansApi({
      materialId,
      materialType: getMaterialApiType({ materialType, recipeType }),
      json,
    });

    await dispatch(
      getMaterialControlPlans({
        materialId,
        materialType,
        recipeType,
      })
    );
  }
);

export const publishMaterial = createAsyncThunk<void, void, AsyncThunkConfig>(
  "materialForm/publishMaterial",
  async (_, { getState }) => {
    const {
      materialForm: { materialInformation },
    } = getState();

    checkIfMaterialIsCreated(materialInformation);

    if (!isLibraryObjectLocked(materialInformation.state)) {
      await publishMaterialApi({
        materialId: materialInformation.id,
        materialType: getMaterialApiType({
          materialType: materialInformation.materialType,
          recipeType: materialInformation.recipeType,
        }),
      });
    }
  }
);

export const keepAsDraftMaterial = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("materialForm/keepAsDraftMaterial", async (_, { getState }) => {
  const {
    materialForm: { materialInformation },
    user: { owningCompanyId },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  await shareMaterial({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    companyId: owningCompanyId,
  });
});

export const updateAttachments = createAsyncThunk<
  MaterialData,
  string[],
  AsyncThunkConfig
>("materialForm/updateAttachments", async (fileIds, { getState }) => {
  const {
    materialForm: { materialInformation },
  } = getState();

  if (materialInformation.id) {
    const { data } = await updateMaterialAttachments({
      materialId: materialInformation.id,
      fileIds: fileIds,
      materialType: getMaterialApiType({
        materialType: materialInformation.materialType,
        recipeType: materialInformation.recipeType,
      }),
    });

    return data;
  }
  return;
});

export const updateSubstanceId = createAsyncThunk<
  MaterialData,
  MaterialData["substanceId"],
  AsyncThunkConfig
>("materialForm/updateSubstanceId", async (substanceId, { getState }) => {
  const {
    materialForm: { materialInformation, rawMaterial },
  } = getState();

  checkIfMaterialIsCreated(materialInformation);

  const { data } = await updateMaterialDetails({
    materialId: materialInformation.id,
    materialType: getMaterialApiType({
      materialType: materialInformation.materialType,
      recipeType: materialInformation.recipeType,
    }),
    ...prepareUpdateMaterialPayload({ materialInformation, rawMaterial }),
    substanceId,
  });

  return data;
});
