import { useState, useEffect } from "react";
import axios, { CancelTokenSource } from "axios";
import qs from "qs";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import usePagination from "../usePagination";
import useBoolean from "hooks/useBoolean";
import { fetchTradeItems } from "apis/PMD";
import { fetchUsers } from "apis/CUMD";
import { searchChapterTranslations } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { SpecificationListItemViemModel } from "viewModels/specificationList";
import {
  CHAPTER_TRANSLATION_STATE,
  CHAPTER_TYPES_API,
  SPECIFICATION_STATE,
} from "utils/constants";
import { isProductTypeFood } from "utils/library";
import { isListEmpty } from "utils/general";
import { isChapterTranslationInProgress } from "utils/chapters";
import { generalMessages } from "messages";
import { SpecificationListItemData } from "models";
import { TranslationFiltersViewModel } from "viewModels";

const useTranslationPaginatedList = () => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const [specifications, setSpecifications] = useState<
    SpecificationListItemViemModel[]
  >([]);
  const [
    totalNumberOfSpecifications,
    setTotalNumberOfSpecifications,
  ] = useState(0);
  const [source, setSource] = useState<CancelTokenSource>();
  const [filters, setFilters] = useState<TranslationFiltersViewModel>({
    statuses: [],
    productTypeIds: [],
    tradeItemName: "",
    specificationOwnerCompanyIds: [],
    toggleButtonsValue: CHAPTER_TRANSLATION_STATE.all,
  });

  const {
    value: hasError,
    setTrue: setErrorTrue,
    setFalse: setErrorFalse,
  } = useBoolean(false);
  const {
    value: isLoading,
    setTrue: setLoadingTrue,
    setFalse: setLoadingFalse,
  } = useBoolean(false);

  const { pagination, onTableChange, resetCurrentPage } = usePagination();

  const onUpdateFilters = (newFilters: TranslationFiltersViewModel) => {
    setFilters(previousState => ({
      ...previousState,
      ...newFilters,
    }));

    resetCurrentPage();
  };

  const updateTranslationSpecifications = async (
    translationSpecifications: SpecificationListItemData[]
  ) => {
    if (isListEmpty(translationSpecifications)) {
      return;
    }

    const tradeItemIds = Array.from(
      new Set(
        translationSpecifications.map(
          specification => specification.tradeItemId
        )
      )
    );
    const userIds = Array.from(
      new Set(
        translationSpecifications.map(
          specification => specification.ownerUserId
        )
      )
    );

    const [tradeItemsResult, usersResult] = await Promise.allSettled([
      fetchTradeItems({ ids: tradeItemIds }),
      fetchUsers({ ids: userIds }),
    ]);

    const tradeItemsData =
      tradeItemsResult.status === "fulfilled"
        ? tradeItemsResult.value.data
        : [];
    const usersData =
      usersResult.status === "fulfilled" ? usersResult.value.data : [];

    return translationSpecifications?.map(specification => {
      const ownerUser = usersData?.find(
        user => user.userId === specification.ownerUserId
      );
      const tradeItem = tradeItemsData?.find(
        tradeItem => tradeItem.id === specification.tradeItemId
      );

      return {
        ...specification,
        productType: formatMessage(
          isProductTypeFood(specification.productTypeId)
            ? generalMessages.productTypeFood
            : generalMessages.productTypeFruitsAndVegetables
        ),
        ownerCompanyName: ownerUser?.owningCompanyName,
        ownerUserName: `${ownerUser?.userFirstName ?? ""} ${
          ownerUser?.userLastName ?? ""
        }`,
        tradeItemName: tradeItem?.itemName,
        state:
          specification?.state === SPECIFICATION_STATE.validated
            ? SPECIFICATION_STATE.validatedByTranslator
            : specification.state,
      };
    });
  };

  const searchSpecifications = async () => {
    try {
      setLoadingTrue();
      setErrorFalse();

      if (source) {
        source.cancel("cancel");
      }

      const newSource = axios.CancelToken.source();

      setSource(newSource);

      const { current, pageSize } = pagination;
      const skip = current <= 1 ? 0 : (current - 1) * pageSize;

      const states = isChapterTranslationInProgress(filters.toggleButtonsValue)
        ? [filters.toggleButtonsValue]
        : isListEmpty(filters.statuses)
        ? null
        : filters.statuses;

      const params = {
        take: pageSize,
        skip: skip,
        languageCode,
        productTypeIds: filters.productTypeIds,
        tradeItemName: filters.tradeItemName,
        specificationOwnerCompanyIds: filters.specificationOwnerCompanyIds,
        ...(!isListEmpty(states) && {
          states,
        }),
      };

      const { data } = await searchChapterTranslations({
        chapterType: CHAPTER_TYPES_API["labeling"],
        params,
        paramsSerializer: () => qs.stringify(params, { arrayFormat: "comma" }),
        cancelToken: newSource?.token,
      });

      const specificationList = await updateTranslationSpecifications(
        data?.items
      );

      setSpecifications(specificationList);
      setTotalNumberOfSpecifications(data.totalNumberOfItems);
    } catch (e) {
      if (e?.message !== "cancel") {
        setErrorTrue();
      }
    } finally {
      setLoadingFalse();
    }
  };

  useEffect(() => {
    searchSpecifications();
  }, [pagination.current, pagination.pageSize, JSON.stringify(filters)]);

  return {
    specifications,
    totalNumberOfSpecifications,
    isLoading,
    hasError,
    pagination,
    onTableChange,
    resetCurrentPage,
    filters,
    onUpdateFilters,
  };
};

export default useTranslationPaginatedList;
