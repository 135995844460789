import { useContext } from "react";
import { UploadFiles } from "@trace-one/business-components";
import { Row } from "antd";
import { FILE_TYPES } from "utils/constants";
import { FreeFromAllergensArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/FreeFromAllergensArrayFieldTemplate/contexts";
import { useFilesInfo } from "hooks";
import { isObjectEmpty } from "utils/general";

const ProofFile = ({
  allergenId,
  fileId,
}: {
  allergenId: string;
  fileId?: string;
}) => {
  const {
    onUploadFile,
    onRemoveFile,
    displayActions,
    ownerCompanyId,
  } = useContext(FreeFromAllergensArrayFieldTemplateContext);

  const { files } = useFilesInfo({ fileIds: [fileId] });

  if (fileId && !isObjectEmpty(files[0])) {
    const { id, name, size } = files[0];

    return (
      <Row>
        <UploadFiles.Item
          fileId={id}
          fileName={name}
          fileSize={size}
          {...(displayActions && {
            onRemove: onRemoveFile({ allergenId }),
          })}
        />
      </Row>
    );
  }

  return (
    <>
      {displayActions && (
        <UploadFiles.Dragger
          fileList={[]}
          onUploadFiles={onUploadFile({ allergenId })}
          fileType={FILE_TYPES.SPECIFICATION_ATTACHMENT}
          size="large"
          multiple={false}
          ownerCompanyId={ownerCompanyId}
        />
      )}
    </>
  );
};

export default ProofFile;
