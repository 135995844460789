import { useContext, useState, useMemo } from "react";
import { Row } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { selectUpdateStatus } from "store/chapterForm/selectors";
import SpecificationSection from "components/SpecificationSection";
import SelectedSectionContext from "components/Library/components/ChapterForm/components/Configure/components/SelectedSection/contexts";
import { useBlockActions } from "./hooks";
import { useCatalogDictionary, useRbac } from "hooks";
import { getParsedData, isObjectEmpty } from "utils/general";
import {
  isLibraryObjectDetailsPage,
  isLibraryObjectLabelingTranslationPage,
  isLibraryObjectLocked,
} from "utils/library";
import { LIBRARY_OBJECTS_PAGES, PERMISSIONS } from "utils/constants";
import { SectionContentProps } from "./types";
import { getCatalogIdsFromIngredients } from "dtos/material";
import { messages } from "./messages";
import styles from "./styles.module.less";

const ChapterSectionContent: React.FC<SectionContentProps> = ({
  sectionStatus,
  sectionId = null,
  blocks = [],
  setSectionBlocks,
  sectionCode,
}) => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const updateStatus = useSelector(selectUpdateStatus);

  const { hasPermission } = useRbac();

  const {
    validateHandler,
    isSectionValidationLoading,
    selectSectionValidationErrors,
    resetSectionValidationErrors,
    setSectionBlockValidationError,
    fetchRecipeIngredients,
    recipeIngredients,
    sortRecipeIngredient,
    rawMaterialsAllergens,
    crossContaminationAllergens,
    fetchAllergens,
    chapterState,
    page,
    recipeSorter,
    totalPercentageOfRecipeIngredients,
    translationId,
    isTranslationContact,
    getTranslation,
    isTranslator,
    getLabelingTranslationLanguages,
    allTranslationsCompleted,
    productData,
    updateRecipeFiles,
  } = useContext(SelectedSectionContext);

  const {
    onFinish,
    saveTemplateBlock,
    addNutritionAction,
    updateNutritionAction,
    deleteNutritionAction,
    addRecipeIngredient,
    updateRecipeIngredient,
    removeRecipeIngredient,
    updateChapterSites,
    updateChapterCCAllergenRisk,
    addFreeFromAllergens,
    removeFreeFromAllergen,
    updateFreeFromAllergen,
    updateNutritionEnergyCalculation,
  } = useBlockActions({
    sectionId,
    resetSectionValidationErrors,
    setSectionBlocks,
    translationId,
    getTranslation,
    getLabelingTranslationLanguages,
  });

  const catalogIds = useMemo(
    () => getCatalogIdsFromIngredients({ ingredients: recipeIngredients }),
    [recipeIngredients]
  );

  useCatalogDictionary({ catalogIds });

  const sectionValidationErrors = selectSectionValidationErrors(sectionId);
  const setBlockValidationError = setSectionBlockValidationError(sectionId);

  const [isSectionTypingInProgress, setIsSectionTypingInProgress] = useState(
    false
  );

  const isValidationDisabled = useMemo(
    () =>
      isLibraryObjectLocked(chapterState) ||
      updateStatus.inProgress ||
      isSectionTypingInProgress,
    [chapterState, updateStatus.inProgress, isSectionTypingInProgress]
  );

  const libraryPage = pathname.includes("/labeling-translations")
    ? LIBRARY_OBJECTS_PAGES.LABELING_TRANSLATIONS
    : pathname.includes("/edit")
    ? LIBRARY_OBJECTS_PAGES.EDIT
    : LIBRARY_OBJECTS_PAGES.CREATE;

  const showValidationSection = !isLibraryObjectDetailsPage(page);

  const displayActions = isLibraryObjectLabelingTranslationPage(page)
    ? true
    : !isLibraryObjectLocked(chapterState) && !isLibraryObjectDetailsPage(page);

  const hasPermissionToSave = isLibraryObjectLabelingTranslationPage(page)
    ? hasPermission(PERMISSIONS.CHAPTER.TRANSLATION_UPDATE) &&
      isTranslationContact &&
      !allTranslationsCompleted
    : true;

  return (
    <>
      {blocks.map(
        ({ jsonSchema, uiSchema, blockId, dataAsJson, readOnlyFieldIds }) => {
          return (
            <SpecificationSection.Block
              hasPermissionToSave={hasPermissionToSave}
              shouldUseQueue
              key={blockId}
              templateSectionId={sectionId}
              templateBlockId={blockId}
              jsonSchema={getParsedData(jsonSchema)}
              uiSchema={getParsedData(uiSchema)}
              formData={getParsedData(dataAsJson)}
              readOnlyFieldIds={readOnlyFieldIds}
              sectionId={sectionId}
              saveTemplateBlock={saveTemplateBlock(blockId)}
              extraTemplateBlockErrors={sectionValidationErrors[blockId]}
              onFinish={debounce(onFinish, 300)}
              onErrorsChange={setBlockValidationError(blockId)}
              extraActions={{
                addNutritionAction,
                updateNutritionAction,
                deleteNutritionAction,
                addSiteAction: updateChapterSites,
                removeSiteAction: updateChapterSites,
                addRecipeIngredient,
                updateRecipeIngredient,
                removeRecipeIngredient,
                sortRecipeIngredient,
                fetchRecipeIngredients,
                fetchAllergens,
                updateChapterCCAllergenRisk,
                addFreeFromAllergens,
                removeFreeFromAllergen,
                updateFreeFromAllergen,
                updateNutritionEnergyCalculation,
                updateRecipeFiles,
              }}
              chapterData={{
                rawMaterialsAllergens,
                crossContaminationAllergens,
                recipeIngredients,
                recipeSorter,
                totalPercentageOfRecipeIngredients,
              }}
              displayActions={displayActions}
              onChangeTypingInProgress={setIsSectionTypingInProgress}
              page={libraryPage}
              sectionCode={sectionCode}
              isTranslator={isTranslator}
              productData={productData}
              setSectionBlocks={setSectionBlocks}
            />
          );
        }
      )}
      {showValidationSection && (
        <Row justify="space-between" className={styles.validation}>
          <SpecificationSection.ErrorsList errors={sectionValidationErrors} />
          <SpecificationSection.ValidationToggle
            sectionStatus={sectionStatus}
            hasValidationErrors={!isObjectEmpty(sectionValidationErrors)}
            isValidationLoading={isSectionValidationLoading(sectionId)}
            isValidationDisabled={isValidationDisabled}
            hasValidationPermission={hasPermissionToSave}
            onValidate={validateHandler(sectionId)}
            validationText={formatMessage(messages.validationLabel)}
          />
        </Row>
      )}
    </>
  );
};

export default ChapterSectionContent;
