import {
  ObjectFieldTemplateProps,
  Property,
} from "components/SpecificationSection/types";
import { REFLIST_IDS } from "utils/constants";
import { isListEmpty, isObjectEmpty } from "utils/general";

export const getMaxValue = (field: Property) => {
  if (field.exclusiveMaximum !== undefined) {
    return field.exclusiveMaximum - field.multipleOf;
  }
  if (field.maximum !== undefined) {
    return field.maximum;
  }

  return Number.MAX_SAFE_INTEGER;
};

export const getMinValue = (field: Property) => {
  if (field.exclusiveMinimum !== undefined) {
    return field.type && field.type === "integer"
      ? field.exclusiveMinimum + 1
      : field.exclusiveMinimum + field.multipleOf;
  }
  if (field.minimum !== undefined) {
    return field.minimum;
  }

  return Number.MIN_SAFE_INTEGER;
};

export const getInputNumberProps = (properties: Property) => {
  const { multipleOf } = properties;

  const inputNumberProps = {
    min: getMinValue(properties),
    max: getMaxValue(properties),
  };

  if (multipleOf === undefined) {
    return { ...inputNumberProps, precision: 0, step: 1 };
  }

  return {
    ...inputNumberProps,
    precision: multipleOf.toString().split(".")[1].length,
    step: multipleOf,
  };
};

export const getErrorsListFromProperties = (
  widgetProperties: ObjectFieldTemplateProps["properties"]
) => {
  let errorsList: string[] = [];

  if (!isListEmpty(widgetProperties)) {
    errorsList = widgetProperties.reduce(
      (previousState, { content }) => [
        ...previousState,
        ...getErrorsListFromErrorSchema(content?.props?.errorSchema),
      ],
      errorsList
    );
  }

  return errorsList;
};

export const getErrorsListFromErrorSchema = errorSchema =>
  !isObjectEmpty(errorSchema?.__errors) ? [...errorSchema?.__errors] : [];

export const isWidgetDisabled = ({
  isWidgetEditable,
  disabled,
  readonlyAsDisabled,
  readonly,
}: {
  isWidgetEditable?: boolean;
  disabled?: boolean;
  readonlyAsDisabled?: boolean;
  readonly?: boolean;
}) => !isWidgetEditable || disabled || (readonlyAsDisabled && readonly);

export const getArrayIndexFromId = (id: string): number => {
  const matches = /\d+/.exec(id);
  return matches ? parseInt(matches[0], 10) : null;
};

export const addInternationalSystemSourceId = (parentItemIds: string[]) => {
  if (isListEmpty(parentItemIds)) {
    return [];
  }

  return [
    ...new Set([...parentItemIds, REFLIST_IDS.INTERNATIONAL_SYSTEM_SOURCE]),
  ];
};
