/* istanbul ignore file */

import { RcFile } from "antd/lib/upload";
import axios, { CancelToken } from "axios";
import { FileData, FileTypeData } from "models";

export const axiosInstance = axios.create({
  baseURL: window.env.DOCUMENT_URL,
});

export const fetchFilesByIds = ({ ids }: { ids: string[] }) =>
  axiosInstance.post<FileData[]>(`/api/files/collection`, {
    fileIds: ids,
  });

export const fetchFilesByIdsV2 = ({ ids }: { ids: string[] }) =>
  axiosInstance.post<FileData[]>(`/api/v2/Files/Collection`, {
    fileIds: ids,
  });

export const deleteFile = ({ id }: { id: string }) =>
  axiosInstance.delete(`/api/files/${id}`);

export const downloadFile = ({ id }: { id: string }) =>
  axiosInstance.get(`/api/files/${id}/download`, {
    responseType: "blob",
  });

export const fetchFileTypes = (cancelToken?: CancelToken) =>
  axiosInstance.get<FileTypeData[]>(`/api/FileTypes`, { cancelToken });

export const downloadFilesAsZip = ({
  fileIds,
  zipName,
}: {
  fileIds: string[];
  zipName: string;
}) =>
  axiosInstance.post(`/api/files/download-zip`, fileIds, {
    params: {
      zipName,
    },
    responseType: "blob",
  });

export const fetchEmbeddedSigning = ({
  requestId,
  redirectUrl,
}: {
  requestId: string;
  redirectUrl: string;
}) => {
  return axiosInstance.get<string>(
    `/api/signatures/${requestId}/embeddedSigningUrl`,
    {
      params: {
        redirectUrl,
      },
    }
  );
};

export const uploadFile = ({
  file,
  fileType,
}: {
  file: RcFile;
  fileType: string;
}) => {
  if (document) {
    let formData = new FormData();

    formData.append("file", file);

    return axiosInstance.post<FileData>(`/api/v2/Files/upload`, formData, {
      params: { fileType },
      data: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }
};
