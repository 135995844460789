import { useIntl } from "react-intl";
import { isListEmpty } from "utils/general";
import { RecipeSummaryIngredientViewModal } from "viewModels";
import { Dictionary } from "types/general";
import { EMPTY_VALUE } from "utils/constants";
import messages from "messages/general";
import { messages as recipeSummaryMessages } from "../../../../messages";
import libraryMessages from "messages/library";

const useIngredientsListTable = ({
  refListDictionary,
  catalogDictionary,
}: {
  refListDictionary: Dictionary;
  catalogDictionary: Dictionary;
}) => {
  const { formatMessage } = useIntl();

  return [
    {
      title: formatMessage(messages.name),
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: formatMessage(messages.type),
      dataIndex: "type",
      key: "type",
      render: (type: RecipeSummaryIngredientViewModal["type"]) =>
        formatMessage(libraryMessages["type"][type]),
      width: "15%",
    },
    {
      title: formatMessage(recipeSummaryMessages.certificateTitle),
      dataIndex: "",
      key: "certificate",
      width: "15%",
    },
    {
      title: formatMessage(recipeSummaryMessages.recipePercentageTitle),
      dataIndex: "aggregatedPercentage",
      key: "percentage",
      width: "10%",
      render: (
        percentage: RecipeSummaryIngredientViewModal["aggregatedPercentage"]
      ) => `${percentage}%`,
    },
    {
      title: formatMessage(recipeSummaryMessages.originsTitle),
      dataIndex: "suppliers",
      key: "origins",
      width: "15%",
      render: (suppliers: RecipeSummaryIngredientViewModal["suppliers"]) => {
        if (isListEmpty(suppliers)) {
          return EMPTY_VALUE;
        }

        const countryId = suppliers[0].origins?.[0]?.countryId;

        return refListDictionary[countryId] || EMPTY_VALUE;
      },
    },
    {
      title: formatMessage(recipeSummaryMessages.functionTitle),
      dataIndex: "functionIds",
      key: "function",
      width: "15%",
      render: (
        functionIds: RecipeSummaryIngredientViewModal["functionIds"]
      ) => {
        if (isListEmpty(functionIds)) {
          return EMPTY_VALUE;
        }
        const functionTitle: string = functionIds.reduce((acc, cur, index) => {
          acc += `${index === 0 ? "" : ", "}${catalogDictionary[cur]}`;
          return acc;
        }, "");

        return functionTitle;
      },
    },
    {
      title: formatMessage(recipeSummaryMessages.documentsTitle),
      dataIndex: "fileIds",
      key: "documents",
      width: "15%",
      render: (fileIds: RecipeSummaryIngredientViewModal["fileIds"]) =>
        `${fileIds?.length ?? 0}`,
    },
  ];
};

export default useIngredientsListTable;
