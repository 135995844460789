import { useState } from "react";
import { useIntl } from "react-intl";
import { TextArea } from "@trace-one/design-system";
import { claimMessages } from "messages/claim";

export const TranslationEditor = ({
  onChange,
  selectedTabKey,
  text,
  claimSentencePlaceholder,
}: {
  onChange: Function;
  selectedTabKey: string;
  text: string;
  claimSentencePlaceholder?: string;
}) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState(text);

  return (
    <TextArea
      value={value}
      onChange={({ target: { value } }) => {
        setValue(value);
        onChange(value);
      }}
      placeholder={
        claimSentencePlaceholder ??
        formatMessage(claimMessages.claimSentenceLabel)
      }
      key={selectedTabKey}
      rows={5}
      data-test-id={`claim-form-sentence-translation-${selectedTabKey}`}
    />
  );
};
