import { ChapterTypeData, SpecificationDocumentSummaryData } from "models";
import { isListEmpty, isObjectEmpty } from "utils/general";

export const getDocumentIdsLinkedToChapters = ({
  documentSummaryData,
  chapterTypes = [],
}: {
  documentSummaryData: SpecificationDocumentSummaryData;
  chapterTypes: ChapterTypeData[];
}): string[] => {
  if (isObjectEmpty(documentSummaryData) || isListEmpty(chapterTypes))
    return [];

  const uniqueFileIds: Set<string> = new Set();

  const { chapters = [] } = documentSummaryData;

  chapters.forEach(({ chapterType, sections = [] }) => {
    const currentChapter = chapterTypes.find(
      ({ chapterType: type }) => chapterType === type
    );

    if (currentChapter?.isChapterLibrary === true) {
      sections.forEach(section => {
        section.fileIds?.forEach(fileId => uniqueFileIds.add(fileId));
      });
    }
  });

  return Array.from(uniqueFileIds);
};

export const getDocumentIdsLinkedToTheSpecification = ({
  documentSummaryData,
  chapterTypes = [],
}: {
  documentSummaryData: SpecificationDocumentSummaryData;
  chapterTypes: ChapterTypeData[];
}): string[] => {
  if (isObjectEmpty(documentSummaryData) || isListEmpty(chapterTypes)) {
    return [];
  }

  const uniqueFileIds: Set<string> = new Set();

  const { chapters = [] } = documentSummaryData;

  chapters.forEach(({ chapterType, fileIds = [], sections = [] }) => {
    fileIds.forEach(id => uniqueFileIds.add(id));

    const currentChapter = chapterTypes.find(
      ({ chapterType: type }) => chapterType === type
    );

    if (currentChapter?.isChapterLibrary === false)
      sections.forEach(section => {
        section.fileIds?.forEach(fileId => uniqueFileIds.add(fileId));
      });
  });

  return Array.from(uniqueFileIds);
};
