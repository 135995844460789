import classNames from "classnames";
import { Dictionary, UiSchemaInfo } from "types/general";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { ArraySchema } from "components/SpecificationSection/types";
import ProofFile from "./components/ProofFile";
import Actions from "../../components/Actions";
import styles from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/styles.module.less";

const useFreeFromAllergensColumns = ({
  uiSchema,
  schema,
  catalogDictionary,
  displayActions,
}: {
  uiSchema: UiSchemaInfo;
  schema: ArraySchema;
  catalogDictionary: Dictionary;
  displayActions: boolean;
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const columns = [
    {
      title: uiSchema?.items?.id["ui:title"],
      dataIndex: "id",
      render: value => {
        return catalogDictionary[value] || "";
      },
      className: classNames({
        [styles.required]: schema?.items?.validationRequired?.includes("id"),
      }),
    },
    {
      title: uiSchema?.items?.proofFileId?.["ui:title"],
      dataIndex: "proofFileId",
      render: (value, allergen) => {
        return <ProofFile allergenId={allergen?.id} fileId={value} />;
      },
      width: "70%",
      className: classNames({
        [styles.required]: schema?.items?.validationRequired?.includes(
          "proofFileId"
        ),
      }),
    },
  ];

  if (displayActions) {
    columns.push({
      key: "action",
      title: getTranslationForKey("action"),
      fixed: "right",
      dataIndex: "id",
      //@ts-ignore
      width: 100,
      render: value => (
        <div className={styles.actionColumnAlignment}>
          <Actions allergenId={value} />
        </div>
      ),
    });
  }

  return {
    columns,
  };
};

export default useFreeFromAllergensColumns;
