import { useMemo } from "react";
import { Button, Tooltip } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import CharacteristicIngredient from "./components/CharacteristicIngredient";
import { Row, Col, Form } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import RowTitle from "components/RowTitle";
import TrackChanges from "components/TrackChanges";
import CharacteristicIngredientsTranslator from "./components/CharacteristicIngredientsTranslator";
import SwitchWidget from "../SwitchWidget";
import TextareaWidget from "../TextareaWidget";
import { isLibraryObjectProduction } from "utils/library";
import { useIngredientListWidget } from "./hooks";
import { messages } from "./messages";
import { IngredientListWidgetProps } from "./types";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { libraryMessages } from "messages";
import styles from "./styles.module.less";

const IngredientListWidget: React.FC<IngredientListWidgetProps> = ({
  formData,
  schema,
  formContext,
  idSchema,
  uiSchema,
}) => {
  const { formatMessage } = useIntl();
  const {
    isWidgetEditable,
    displayActions,
    labelAlign,
    specificationData,
    extraActions,
    isRetailerContact,
    hasPermissionToSave,
    readOnlyFieldIds,
    isTranslator,
  } = formContext;
  const hasEditPermission = isWidgetEditable();
  const { getTranslationForKey } = useFormSchemaTranslation();

  const colSpans = {
    xs: 24,
    md: 5,
  };

  const isModifiedIngredientListString =
    typeof formData.modifiedIngredientList === "string";

  // only translators have permission to edit original ingredient list
  const isOriginalIngredientListEditable = !readOnlyFieldIds?.includes(
    schema.properties.originalIngredientList["propertyId"]
  );

  const isModifiedIngredientListEditable = !readOnlyFieldIds?.includes(
    schema.properties.modifiedIngredientList["propertyId"]
  );

  const isProductionChapterLinked = useMemo(
    () =>
      specificationData?.chapters?.find(chapter =>
        isLibraryObjectProduction(chapter?.chapterType)
      )?.isLinked,
    [specificationData?.chapters]
  );

  const {
    handleChange,
    resetIngredientList,
    editIngredientList,
  } = useIngredientListWidget({
    formData,
    formContext,
  });

  const userComponentMap = {
    nonTranslator: (
      <>
        <TextareaWidget
          formContext={formContext}
          schema={schema.properties.modifiedIngredientList}
          onChange={handleChange("modifiedIngredientList")}
          disabled={!isModifiedIngredientListEditable}
          readonly={false}
          id={idSchema.$id}
          value={formData.modifiedIngredientList}
          options={{}}
          placeholder=""
          onFocus={() => {}}
          configProps={{
            toolbar: [
              {
                name: "basicstyles",
                items: [
                  "Underline",
                  "Strike",
                  "Italic",
                  "Bold",
                  "Link",
                  "Unlink",
                  "NumberedList",
                  "BulletedList",
                ],
              },
            ],
          }}
        />
        {displayActions && (
          <div className={styles.resetButton}>
            <ConfirmationTooltip
              onConfirmation={resetIngredientList}
              text={formatMessage(messages.resetIngredientListConfirmationText)}
            >
              <Button
                data-testid="edit-ingredient-list-btn"
                type="tertiary"
                disabled={!hasEditPermission}
              >
                {formatMessage(messages.resetButtonTitle)}
              </Button>
            </ConfirmationTooltip>
          </div>
        )}
        <RowTitle
          title={formatMessage(messages.trackChangesTitle)}
          displayActions={displayActions}
        />
        <Row gutter={[10, 0]}>
          <Col>
            <Paragraph size="s" className={styles.added}>
              {formatMessage(messages.addedTextLegend)}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph size="s" className={styles.modified}>
              {formatMessage(messages.modifiedTextLegend)}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph size="s" color="red" className={styles.deleted}>
              {formatMessage(messages.deletedTextLegend)}
            </Paragraph>
          </Col>
        </Row>
        <TrackChanges
          initText={`<p>${formData.originalIngredientList}</p>`}
          modifiedText={formData.modifiedIngredientList}
          className={styles.trackChanges}
        />
      </>
    ),

    translator: (
      <TextareaWidget
        formContext={formContext}
        schema={
          isModifiedIngredientListString
            ? schema.properties.modifiedIngredientList
            : schema.properties.originalIngredientList
        }
        onChange={handleChange(
          isModifiedIngredientListString
            ? "modifiedIngredientList"
            : "originalIngredientList"
        )}
        disabled={
          isModifiedIngredientListString
            ? !isModifiedIngredientListEditable
            : !isOriginalIngredientListEditable
        }
        readonly={false}
        id={idSchema.$id}
        value={
          isModifiedIngredientListString
            ? formData.modifiedIngredientList
            : formData.originalIngredientList
        }
        options={{}}
        placeholder=""
        onFocus={() => {}}
        configProps={{
          toolbar: [
            {
              name: "basicstyles",
              items: [
                "Underline",
                "Strike",
                "Italic",
                "Bold",
                "Link",
                "Unlink",
                "NumberedList",
                "BulletedList",
              ],
            },
          ],
        }}
      />
    ),
  };

  return (
    <>
      <div className={"mb-3"}>
        <RowTitle
          title={formatMessage(libraryMessages.characteristicIngredient)}
        />

        {isTranslator && (
          <CharacteristicIngredientsTranslator
            characteristicIngredients={formData?.characteristicIngredients}
          />
        )}

        {!isTranslator && isProductionChapterLinked && (
          <CharacteristicIngredient
            specificationId={specificationData?.specificationId}
            characteristicIngredients={formData?.characteristicIngredients}
            updateCharacteristicIngredients={
              extraActions?.onUpdateCharacteristicIngredients
            }
            canEdit={isRetailerContact && hasPermissionToSave}
          />
        )}
      </div>

      <RowTitle
        title={
          isModifiedIngredientListString
            ? formatMessage(messages.ingredientListEditTitle)
            : formatMessage(messages.ingredientListReadOnlyTitle)
        }
        displayActions={displayActions}
      />

      <Form.Item
        label={getTranslationForKey(uiSchema?.displayOnPack?.["ui:title"])}
        labelAlign={labelAlign}
        labelCol={colSpans}
        wrapperCol={colSpans}
      >
        <SwitchWidget
          value={formData.displayOnPack}
          formContext={formContext}
          schema={schema.properties.displayOnPack}
          onChange={handleChange("displayOnPack")}
          readonly={false}
          disabled={false}
          options={{}}
        />
      </Form.Item>

      {isOriginalIngredientListEditable ? (
        userComponentMap.translator
      ) : isModifiedIngredientListString ? (
        userComponentMap.nonTranslator
      ) : (
        <>
          <Paragraph
            dangerouslySetInnerHTML={{
              __html:
                formData?.modifiedIngredientList ||
                formData?.originalIngredientList,
            }}
          ></Paragraph>
          <Tooltip
            title={
              !formData.originalIngredientList
                ? formatMessage(messages.tooltipMessage)
                : ""
            }
            placement="bottomLeft"
          >
            {displayActions && (
              <span className={styles.editButtonWrapper}>
                <Button
                  data-testid="edit-ingredient-list-btn"
                  type="primary"
                  onClick={editIngredientList}
                  disabled={
                    !formData.originalIngredientList || !hasEditPermission
                  }
                >
                  {formatMessage(messages.editButtonTitle)}
                </Button>
              </span>
            )}
          </Tooltip>
        </>
      )}
    </>
  );
};

export default IngredientListWidget;
