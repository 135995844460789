import { useMemo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useCurrentSectionsRefs, useUserInfo } from "hooks";
import SpecificationSection from "components/SpecificationSection";
import StepTitle from "components/Library/components/StepTitle";
import EmptyState from "components/Library/components/EmptyState";
import SelectedSection from "./components/SelectedSection";
import {
  getHeaderStatusName,
  isLibraryObjectDetailsPage,
  isLibraryObjectLabelingTranslationPage,
  updateInfoForStructure,
} from "utils/library";
import { isListEmpty } from "utils/general";
import { ConfigureProps } from "./types";
import { ChapterStructureFormData } from "types/library";
import { messages } from "components/Library/components/ChapterForm/messages";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "store/user/selectors";

const HEADER_HEIGHT = 212;

const Configure: React.FC<ConfigureProps> = ({
  structure,
  sections,
  progressState,
  page,
  generalInfo,
  languageTabComponent,
  className,
  isLoading,
  getTranslation,
  getLabelingTranslationLanguages,
  productData,
  translationStatus,
}) => {
  const { formatMessage } = useIntl();

  const { isRetailer, isTranslator, isSupplier } = useUserInfo();
  const owningCompanyId = useSelector(selectOwningCompanyId);

  const [configureStructure, setConfigureStructure] = useState<
    ChapterStructureFormData[]
  >([]);

  const { isStructureListEmpty, selectedSelection } = useMemo(() => {
    if (isListEmpty(configureStructure)) {
      return {
        isStructureListEmpty: true,
        selectedSelection: { sectionId: null, sections: [] },
      };
    }
    return {
      isStructureListEmpty: false,
      selectedSelection: {
        ...configureStructure[0],
        sectionStatus: getHeaderStatusName({
          state: progressState,
          isRetailer,
          isTranslator,
        }),
      },
    };
  }, [JSON.stringify(configureStructure)]);

  const { ownerCompanyId } = generalInfo || {};

  const {
    currentSectionsRefs,
    onUpdateSelectedSectionId,
    selectedSubSectionId,
  } = useCurrentSectionsRefs({
    sections: selectedSelection?.sections,
    heightOfHeader: HEADER_HEIGHT,
  });

  const onClickSubSection = (sectionId: string, subSectionId: string) => () => {
    onUpdateSelectedSectionId(subSectionId);
  };

  const retailerCompnayId = isRetailer ? ownerCompanyId : undefined;
  const supplierCompnayId = isSupplier ? ownerCompanyId : undefined;
  const translatorCompanyId = isTranslator ? owningCompanyId : undefined;

  useEffect(() => {
    setConfigureStructure(
      updateInfoForStructure({
        structure,
        sections,
        validatedByRetailer: isRetailer,
      })
    );
  }, [structure, sections]);

  const showConfigTitle =
    !isLibraryObjectDetailsPage(page) &&
    !isLibraryObjectLabelingTranslationPage(page);

  return (
    <>
      {showConfigTitle && (
        <StepTitle
          title={formatMessage(messages.configureChapterTitle)}
          step={2}
          {...(!isStructureListEmpty && {
            subtitle: formatMessage(messages.configureChapterDescription, {
              chapterTypeName: configureStructure[0]?.sectionName,
              sectionCount: configureStructure[0].sections?.length,
            }),
          })}
        />
      )}

      {!isStructureListEmpty ? (
        <div className={className}>
          <SpecificationSection.Content
            sections={configureStructure}
            onClickSubSection={onClickSubSection}
            selectedSectionId={selectedSelection.sectionId}
            selectedSubSectionId={selectedSubSectionId}
            headerHeight={HEADER_HEIGHT}
            retailerCompnayId={retailerCompnayId}
            supplierCompnayId={supplierCompnayId}
            translatorCompanyId={translatorCompanyId}
            translationStatus={translationStatus}
          >
            <SelectedSection
              selectedSection={selectedSelection}
              currentSectionsRefs={currentSectionsRefs}
              page={page}
              generalInfo={generalInfo}
              languageTabComponent={languageTabComponent}
              retailerCompnayId={retailerCompnayId}
              supplierCompnayId={supplierCompnayId}
              translatorCompanyId={translatorCompanyId}
              isLoading={isLoading}
              getTranslation={getTranslation}
              getLabelingTranslationLanguages={getLabelingTranslationLanguages}
              productData={productData}
            />
          </SpecificationSection.Content>
        </div>
      ) : (
        <EmptyState description={formatMessage(messages.selectChapterType)} />
      )}
    </>
  );
};

export default Configure;
