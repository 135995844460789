import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { LabeledValue } from "antd/lib/select";
import { Paragraph, Picker } from "@trace-one/design-system";
import { filterItemsBySearchValue, isListEmpty } from "utils/general";
import { generalMessages } from "messages";

export type DocumentFilterProps = {
  items?: LabeledValue[];
  onChange?: Function;
  value?: string[];
  title?: React.ReactNode;
};

const Document: React.FC<DocumentFilterProps> = ({
  items = [],
  onChange,
  value,
  title,
}) => {
  const { formatMessage } = useIntl();
  const [filterItems, setFilterItems] = useState(items);

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  const handleSearch = (value: string) =>
    setFilterItems(
      filterItemsBySearchValue(items, value, item => item.label.toString())
    );

  const handleClear = () => onChange([]);

  return (
    <Picker.Filter
      title={title}
      items={filterItems}
      onChange={({ value }) => onChange(value)}
      value={value || []}
      showSearchInput
      onSearch={handleSearch}
      showCheckBoxes
      clearSelection={handleClear}
      renderAlternateContent={
        isListEmpty(filterItems) && (
          <Paragraph className="pl-2 pb-2">
            {formatMessage(generalMessages.noItemFound)}
          </Paragraph>
        )
      }
    />
  );
};

export default Document;
