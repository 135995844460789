import { GeneralInfoData, LibraryObjectData } from "models";

export interface ClaimTypeData {
  claimTypeName: string;
  claimType: string;
  productTypeId: string;
}

export interface ClaimSentenceTranslation {
  languageCode: string;
  text: string;
  isDefault: boolean;
}

export interface ClaimFile {
  id?: string;
  fileName?: string;
  fileSize?: number;
}

export interface ClaimData extends LibraryObjectData {
  generalInfo?: GeneralInfoData;
  sentenceTranslations?: ClaimSentenceTranslation[];
  file?: ClaimFile;
  logoFileId?: string;
}

export enum CLAIM_VALIDATION_RULE_TYPE {
  NUTRIENT = "nutrient",
  SUBSTANCE = "substance",
}

export interface ClaimValidationRule {
  id: string;
  itemType: CLAIM_VALIDATION_RULE_TYPE;
}

export interface RegulatoryHealthClaim {
  id?: string;
  productTypeId?: string;
  sentenceTranslations?: ClaimSentenceTranslation[];
  additionalSentenceTranslations?: ClaimSentenceTranslation[];
  requiresSupportingDocuments?: boolean;
  regulationDomain?: string;
  conditionOfUseTranslations?: ClaimSentenceTranslation[];
  validationRule?: ClaimValidationRule[];
}
