import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { getUniqueListItems, isListEmpty } from "utils/general";
import { FileViewModel } from "viewModels";
import { DocumentFilters } from "types/filters";
import { useFileTypes } from "hooks";

export type UseDocumentSummaryPanelProps = {
  files: FileViewModel[];
};

const useDocumentSummaryPanel = ({
  files = [],
}: UseDocumentSummaryPanelProps) => {
  const initialDocumentFilters = {
    name: null,
    fileTypes: null,
    createdByUserIds: null,
    fileIds: null,
  };

  const [documentFilters, setDocumentFilters] = useState<DocumentFilters>(
    initialDocumentFilters
  );
  const [searchAndFilterResult, setSearchAndFilterResult] = useState<
    FileViewModel[]
  >(null);

  const uniqueFileTypes = getUniqueListItems({
    list: files,
    fieldName: "fileType",
  });
  const { loading: loadingFileTypes, fileTypeDictionary } = useFileTypes({
    fileTypeNames: uniqueFileTypes as string[],
  });

  const documentTypeFilterItems = (uniqueFileTypes as string[])?.map(
    fileTypeName => ({
      key: fileTypeName,
      label: fileTypeDictionary?.[fileTypeName],
      value: fileTypeName,
    })
  );

  const uniqueCreatedByUserIds = getUniqueListItems({
    list: files,
    fieldName: "createdByUserId",
  });

  const createdByUserNameFilterItems = (uniqueCreatedByUserIds as string[]).map(
    id => {
      const createdByUserName = files.find(
        ({ createdByUserId }) => createdByUserId === id
      ).createdByUserName;

      return {
        key: id,
        label: createdByUserName,
        value: id,
      };
    }
  );

  useEffect(() => {
    if (isEqual(documentFilters, initialDocumentFilters)) {
      setSearchAndFilterResult(null);
      return;
    }

    const filterDocuments = () => {
      return files.filter(({ name, fileType, createdByUserId, id }) => {
        return (
          (documentFilters.name
            ? name.toUpperCase().includes(documentFilters.name.toUpperCase())
            : true) &&
          (isListEmpty(documentFilters.fileTypes)
            ? true
            : documentFilters.fileTypes.some(
                filterFileType =>
                  filterFileType.toUpperCase() === fileType.toUpperCase()
              )) &&
          (isListEmpty(documentFilters.createdByUserIds)
            ? true
            : documentFilters.createdByUserIds.some(
                filterUserId =>
                  filterUserId.toUpperCase() === createdByUserId.toUpperCase()
              )) &&
          (isListEmpty(documentFilters.fileIds)
            ? true
            : documentFilters.fileIds.some(
                filterFileId => filterFileId.toUpperCase() === id.toUpperCase()
              ))
        );
      });
    };

    setSearchAndFilterResult(filterDocuments());
  }, [documentFilters, files]);

  const onSearch = (filterType: keyof DocumentFilters) => (
    value: string | string[]
  ) => {
    let formattedValue: string | string[];

    switch (filterType) {
      case "name":
        formattedValue = value === "" ? null : value.toString().trim();
        break;

      case "fileTypes":
      case "createdByUserIds":
      case "fileIds":
        formattedValue = isListEmpty(value as string[]) ? null : value;
        break;

      default:
        return value;
    }

    setDocumentFilters(prevState => ({
      ...prevState,
      [filterType]: formattedValue,
    }));
  };

  return {
    searchAndFilterResult,
    onSearch,
    isDocumentFiltersInitialState: isEqual(
      documentFilters,
      initialDocumentFilters
    ),
    documentFilters,
    loadingFileTypes,
    documentTypeFilterItems,
    createdByUserNameFilterItems,
  };
};

export default useDocumentSummaryPanel;
