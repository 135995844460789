import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import { Button, Datatable } from "@trace-one/design-system";
import MultipleSelectLibraryObjectModal from "components/MultipleSelectLibraryObjectModal/";
import RowTitle from "components/RowTitle";
import RecipeTableFooter from "components/RecipeTableFooter";
import { SeeRecipeSummary } from "components";
import { usePagination } from "hooks";
import { useChapterRecipeWidgetColumns, useRecipeTableActions } from "./hooks";
import { getRowKeyForTable, getTableSize } from "utils/table";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { ChapterRecipeWidgetProps } from "./types";
import { messages } from "./messages";
import libraryMessages from "messages/library";
import { categorizeIngredients } from "dtos/material";
import styles from "./styles.module.less";

const ChapterRecipeWidget: React.FC<ChapterRecipeWidgetProps> = ({
  formContext,
  properties,
}) => {
  const { formatMessage } = useIntl();
  const {
    extraActions,
    isWidgetEditable,
    categoryDictionary,
    catalogDictionary,
    chapterData,
    displayActions,
    emptyValue,
    isTargetMode,
    page,
  } = formContext;

  const { sorter, onTableChange } = usePagination();

  const {
    onAddIngredients,
    onRemoveIngredient,
    onViewMaterialAction,
    onUpdateIngredientPercentage,
    onUpdateIngredientFunction,
    isModalOpen,
    onCloseModal,
    onOpenModal,
    queueLength,
  } = useRecipeTableActions({
    addRecipeIngredient: extraActions?.addRecipeIngredient,
    updateRecipeIngredient: extraActions?.updateRecipeIngredient,
    removeRecipeIngredient: extraActions?.removeRecipeIngredient,
    fetchRecipeIngredients: extraActions?.fetchRecipeIngredients,
    sortRecipeIngredient: extraActions?.sortRecipeIngredient,
    sorter,
    fetchAllergens: extraActions?.fetchAllergens,
  });

  const recipeIngredients = chapterData?.recipeIngredients || [];

  const { otherIngredients, processingAidIngredients } = categorizeIngredients(
    recipeIngredients
  );

  const { columns, processingAidColumns } = useChapterRecipeWidgetColumns({
    onUpdateIngredientPercentage,
    onUpdateIngredientFunction,
    onRemoveIngredient,
    onViewMaterialAction,
    recipeIngredients,
    categoryDictionary,
    catalogDictionary,
    displayActions,
    emptyValue,
    recipeSorter: chapterData?.recipeSorter,
    canSortOtherIngredients: otherIngredients?.length > 1,
    canSortProcessingAidIngredients: processingAidIngredients?.length > 1,
    isTargetMode,
    queueLength,
  });

  const propertyObjects = getPropertiesObjectFromArray(properties);

  return (
    <>
      <Row gutter={[20, 24]} className={styles.ingredientsContainer}>
        <SeeRecipeSummary
          page={page}
          isTargetMode={isTargetMode}
          formContext={formContext}
        />

        <Row>
          <RowTitle title={formatMessage(messages.tableSubtitle)} />
          <Datatable
            tableLayout="fixed"
            indentSize={25}
            columns={columns}
            dataSource={otherIngredients}
            pagination={false}
            onChange={onTableChange}
            rowKey={getRowKeyForTable("ingredient")}
            size={getTableSize(true)}
            footer={
              !isListEmpty(otherIngredients)
                ? () => (
                    <RecipeTableFooter
                      totalPercentage={
                        chapterData?.totalPercentageOfRecipeIngredients
                      }
                      displayActions={displayActions}
                      type={LIBRARY_OBJECT_TYPES.CHAPTERS}
                    />
                  )
                : null
            }
            childrenColumnName="ingredients"
          />
        </Row>

        {!isListEmpty(processingAidIngredients) && (
          <Row>
            <RowTitle
              title={formatMessage(
                libraryMessages?.["processingAidsTableSubtitle"]
              )}
            />
            <Datatable
              tableLayout="fixed"
              indentSize={25}
              columns={processingAidColumns}
              dataSource={processingAidIngredients}
              pagination={false}
              rowKey={ingredient => JSON.stringify(ingredient)}
              className={styles.ingredientsContainer}
              data-test-id="processing-aid-ingredients-table"
              onChange={onTableChange}
            />
          </Row>
        )}
      </Row>

      {displayActions && !isTargetMode && (
        <Row justify="center" className={styles.rowAddButton} gutter={[20, 20]}>
          <Col>
            <Button disabled>
              {formatMessage(messages.createANewMaterial)}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={onOpenModal}
              data-testid="add-material-recipe-table"
              disabled={!isWidgetEditable()}
            >
              {formatMessage(messages.addAnExistingMaterial)}
            </Button>
          </Col>
        </Row>
      )}

      <Row className="mt-5">
        {!isObjectEmpty(propertyObjects) && {
          ...propertyObjects["fileIds"]?.content,
        }}
      </Row>

      {isModalOpen && (
        <MultipleSelectLibraryObjectModal
          title={formatMessage(messages.modalTitle)}
          subTitle={formatMessage(messages.modalSubTitle)}
          type={LIBRARY_OBJECT_TYPES.MATERIALS}
          confirmBtnText={formatMessage(messages.modalConfirmBtnText)}
          onClose={onCloseModal}
          onConfirm={onAddIngredients}
        />
      )}
    </>
  );
};

export default ChapterRecipeWidget;
