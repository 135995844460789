import { useIntl } from "react-intl";
import { Picker } from "@trace-one/design-system";
import { TreeSelect } from "@trace-one/react-components";
import { useChapterSection } from "./hooks";
import { DOCUMENT_SUMMARY_TABS } from "utils/constants";
import { ChapterTypeData, SpecificationDocumentSummaryChapter } from "models";
import { ChapterStructureFormData } from "types/library";
import { filterMessages } from "messages";

export type ChapterSectionProps = {
  chapters: SpecificationDocumentSummaryChapter[];
  filteredChapterTypes: ChapterTypeData[];
  onChange: Function;
  specificationStructure?: ChapterStructureFormData[];
  tabKey: DOCUMENT_SUMMARY_TABS;
};

const ChapterSection: React.FC<ChapterSectionProps> = ({
  chapters,
  filteredChapterTypes,
  onChange,
  specificationStructure,
  tabKey,
}) => {
  const { formatMessage } = useIntl();

  const {
    treeData,
    selectedItems,
    handleSelect,
    handleDeselect,
    handleClear,
  } = useChapterSection({
    chapters,
    filteredChapterTypes,
    onChange,
    specificationStructure,
    tabKey,
  });

  return (
    <Picker.Filter
      title={formatMessage(filterMessages.chapterAndSection)}
      items={[]}
      renderAlternateContent={
        <div className="pb-2 pl-1 pr-1">
          <TreeSelect
            name="chapter-section-filter"
            treeData={treeData}
            value={selectedItems}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onClear={handleClear}
            placeholder={formatMessage(filterMessages.chapterAndSection)}
            multiple
          />
        </div>
      }
    />
  );
};

export default ChapterSection;
