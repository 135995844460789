import { useContext } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import classNames from "classnames";
import { Button, UploadCard } from "@trace-one/design-system";
import { useFileDownload, useZipDownload } from "hooks";
import { getFileNameWithoutExtension } from "utils/files";
import { toggleListItemSelection } from "utils/general";
import { documentMessages, generalMessages } from "messages";
import { DocumentSummaryPanelContext } from "components/DocumentSummaryPanel/contexts";
import { FileViewModel } from "viewModels";

export type InformationPanelItemsProps = {
  items: FileViewModel[];
};

const InformationPanelItems: React.FC<InformationPanelItemsProps> = ({
  items,
}) => {
  const { formatMessage } = useIntl();

  const { selectedItems, setSelectedItems, zipFileName } = useContext(
    DocumentSummaryPanelContext
  );

  const { downloading, onDownload } = useFileDownload();
  const { isZipDownloading, onDownloadZip } = useZipDownload({
    fileIds: items.map(({ id }) => id),
    zipFileName: `${zipFileName} (${selectedItems.length})`,
  });

  const handleClose = (item: FileViewModel) =>
    setSelectedItems(prevState =>
      toggleListItemSelection({
        item,
        list: prevState,
      })
    );

  const handleCancel = () => setSelectedItems([]);

  return (
    <Col
      span={24}
      data-testid="info-panel-items"
      className={classNames(
        "h-100",
        "d-flex",
        "flex-direction-column",
        "jc-space-between"
      )}
    >
      <div className={classNames("overflow-auto", "hide-scrollbar")}>
        {items.map(item => {
          const { id, name, fileExtension } = item;
          return (
            <UploadCard
              key={id}
              fileName={name}
              graphicName={`${fileExtension}-document`}
              onClose={() => handleClose(item)}
              onFileDownload={() =>
                onDownload({
                  id,
                  fileName: getFileNameWithoutExtension(name),
                  fileExtension,
                })
              }
              disabled={downloading || isZipDownloading}
              className={classNames("mb-2", "max-width-unset")}
            />
          );
        })}
      </div>

      <Row justify="center" className="pt-2" gutter={[16, 16]}>
        <Col>
          <Button
            type="secondary"
            disabled={downloading || isZipDownloading}
            onClick={handleCancel}
          >
            {formatMessage(generalMessages.cancel)}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={downloading}
            loading={isZipDownloading}
            onClick={onDownloadZip}
          >
            {formatMessage(documentMessages.download)}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default InformationPanelItems;
