import { Col, Row } from "antd";
import { Typography } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { ErrorBoundary } from "react-error-boundary";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "store/user/selectors";
import { useFilesInfo } from "hooks";
import { FileData } from "models";
import WarningFallback from "components/WarningFallback";
import { FILE_TYPES } from "utils/constants";
import { updateBlockData } from "utils/general";

export const FileUploadTemplate: React.FC<ObjectFieldTemplateProps> = props => {
  const ownerCompanyId = useSelector(selectOwningCompanyId);

  const { formData: fileIds, formContext, uiSchema = {}, schema = {} } = props;
  const {
    saveTemplateBlock,
    isWidgetEditable,
    extraActions,
    templateBlockId,
    setSectionBlocks,
  } = formContext;
  const { validationRequired } = schema;
  const { files } = useFilesInfo({ fileIds });
  const isFileEditable = isWidgetEditable(schema?.propertyId);

  const fileType = schema["fileType"];
  const title = uiSchema["ui:title"];
  const labelWidth = uiSchema["ui:label:col"]?.["md"] || 8;

  const handleFilesUpdate = async (fileIds: string[]) => {
    switch (fileType) {
      case FILE_TYPES.FINAL_PAGE_PROOF:
        await extraActions?.onUpdateFinalPageProof(
          JSON.stringify({
            fileIds,
          })
        );
        break;

      case FILE_TYPES.NUTRITIONAL_ANALYSIS:
        await extraActions?.onUpdateNutritionalAnalysis(
          JSON.stringify({
            fileIds,
          })
        );
        break;

      case FILE_TYPES.MANUFACTURING_DIAGRAM:
        await extraActions?.updateRecipeFiles({
          fileIds,
        });

        setSectionBlocks?.(prevState =>
          updateBlockData({
            blocks: prevState,
            blockId: templateBlockId,
            dataAsJson: JSON.stringify({ fileIds }),
          })
        );

        break;

      default:
        await saveTemplateBlock(
          JSON.stringify({
            fileIds,
          })
        );
        break;
    }
  };

  const onUploadFiles = async (files: FileData[]) => {
    const newFileIds = (files ?? []).map(file => file.id);
    const fileIdsToSend = [...(fileIds ?? []), ...newFileIds];
    await handleFilesUpdate(fileIdsToSend);
  };

  const onRemove = async ({ id }) => {
    const fileIdsToBeSaved = fileIds.filter((fileId: string) => fileId !== id);

    await handleFilesUpdate(fileIdsToBeSaved);
  };

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <Row className="mb-3 w-100">
        {title && (
          <Col md={labelWidth}>
            <Row>
              <Typography variant="heading-xxs" color="grey-5">
                {title}
              </Typography>

              {validationRequired?.includes("fileIds") && (
                <Typography variant="body-xxs" color="red">
                  *
                </Typography>
              )}
            </Row>
          </Col>
        )}
        <Col md={24 - labelWidth}>
          <UploadFiles.Dragger
            multiple
            disabled={!isFileEditable}
            fileList={files}
            onRemove={onRemove}
            onUploadFiles={onUploadFiles}
            fileType={fileType}
            size="large"
            ownerCompanyId={ownerCompanyId}
            disabledFileIds={fileIds}
          />
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default FileUploadTemplate;
