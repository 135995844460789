import { useState, useEffect, useContext } from "react";
import { Row, Col, Form } from "antd";
import { Select, Heading } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectSectionSavingInProgress } from "store/specificationDetails/selectors";
import { searchUsers } from "apis/CUMD";
import { useUserInfo } from "hooks";
import CreateCollaboration from "components/CreateCollaboration";
import {
  APPLICATION_TYPE_IDS,
  REFLIST_IDS,
  SpecificationContactType,
} from "utils/constants";
import { filterOptionsBySelectedValues } from "utils/form";
import { CompanyContext, ContactWidget } from "../../../widgets";
import {
  getAddContactAction,
  getRemoveContactAction,
  getSignatoryContactAction,
  getContacts,
  getSignatoryMessageKey,
  shouldActionsBeDisplayed,
  getSupplierSectionFromSpecData,
} from "./utils";
import { messages } from "./messages";
import styles from "../styles.module.less";

const ContactsArrayFieldTemplate = ({
  items,
  formData,
  schema,
  formContext,
}) => {
  const { formatMessage } = useIntl();
  const { isRetailer } = useUserInfo();

  const [isContactActionInProgress, setIsContactActionInProgress] = useState(
    false
  );
  const [collaborationIds, setCollaborationIds] = useState([]);
  const [filterContacts, setFilterContacts] = useState([]);
  const [isFilterContactsLoading, setIsFilterContactsLoading] = useState(false);
  const [hasFilterContactsError, setHasFilterContactsError] = useState(false);
  const [isAddActionInProgress, setIsAddActionInProgress] = useState(false);
  const { colSpans, companyId } = useContext(CompanyContext);
  const {
    propertyId,
    items: {
      properties: {
        signatory: { propertyId: signatoryPropertyId },
      },
    },
  } = schema;

  const {
    displayActions,
    sectionId,
    isWidgetEditable,
    specificationData,
    extraActions,
  } = formContext;

  const getCollaborationIds = async () => {
    const { data: contacts } = await extraActions?.getSpecificatioContacts();

    const collaborationIds = contacts
      .filter(
        contact =>
          contact.collaborationId &&
          contact.contactType === SpecificationContactType.SUPPLIER
      )
      .map(contact => contact.collaborationId);

    setCollaborationIds(collaborationIds);
  };

  const isSavingInProgress = useSelector(
    selectSectionSavingInProgress(sectionId)
  );

  const supplierSectionData = getSupplierSectionFromSpecData(specificationData);

  const hasEditPermission = isWidgetEditable(propertyId);

  const isAddContactActionDisabled =
    !hasEditPermission || isAddActionInProgress;

  const isRemoveContactActionDisabled = !hasEditPermission;
  const isSetAsSignatoryActionDisabled = !isWidgetEditable(signatoryPropertyId);
  const isSupplierSectionForRetailer =
    isRetailer && sectionId === supplierSectionData?.sectionId;

  useEffect(() => {
    getContacts({
      setIsFilterContactsLoading,
      setHasFilterContactsError,
      searchUsers,
      companyId,
      setFilterContacts,
    });
  }, [companyId]);

  useEffect(() => {
    if (isSupplierSectionForRetailer) {
      getCollaborationIds();
    }
  }, []);

  return (
    <>
      {!isFilterContactsLoading &&
        items &&
        items.map(({ index }) => {
          const { signatory, userId } = formData[index];
          const isOwnerOfTheSpecification =
            userId === specificationData.ownerUserId;

          return (
            <Col {...colSpans} key={`array-item-${index}`}>
              <ContactWidget
                {...(displayActions && {
                  actions: shouldActionsBeDisplayed({
                    isOwnerOfTheSpecification,
                    isRemoveContactActionDisabled,
                    isSetAsSignatoryActionDisabled,
                  })
                    ? [
                        {
                          disabled:
                            isSetAsSignatoryActionDisabled ||
                            isContactActionInProgress,
                          name: formatMessage(
                            messages[getSignatoryMessageKey(signatory)]
                          ),
                          onClick: getSignatoryContactAction(
                            // @ts-ignore
                            {
                              isContactActionInProgress,
                              signatoryContact:
                                extraActions?.setSignatoryContactAction,
                              setIsContactActionInProgress,
                              userId,
                              signatory: !signatory,
                            }
                          ),
                        },
                        {
                          disabled:
                            isRemoveContactActionDisabled ||
                            isContactActionInProgress,
                          name: formatMessage(messages.removeTheContact),
                          onClick: getRemoveContactAction({
                            isContactActionInProgress,
                            setIsContactActionInProgress,
                            removeContact: extraActions?.removeContactAction,
                            getCollaborationIds,
                            userId,
                          }),
                        },
                      ]
                    : [],
                })}
                formContext={formContext}
                formData={formData[index]}
                userData={filterContacts.find(
                  ({ userId }) => userId === formData[index].userId
                )}
              />
            </Col>
          );
        })}

      {displayActions ? (
        isSupplierSectionForRetailer ? (
          <Col span={24}>
            <Heading size="xs" color="primary">
              {formatMessage(messages.title)}
            </Heading>
            <CreateCollaboration
              applicationId={APPLICATION_TYPE_IDS.SPEC}
              parentDataObjectId={specificationData.specificationId}
              parentDataObjectSystem="SPEC"
              roleIds={[REFLIST_IDS.QUALITY_MANAGER_ROLE]}
              companyIdsWithLinkedProducts={[
                // @ts-ignore
                {
                  collaboratingCompanyId: specificationData.supplierId,
                  isCollaborationEnabled: true,
                },
              ]}
              responsibilityIds={[REFLIST_IDS.QUALITY_MANAGER_RESPONSIBILITY]}
              onCreatedCollaboration={async collaborationId => {
                await extraActions?.setSpecificationCollaborationAction([
                  {
                    collaborationId,
                    contactType: SpecificationContactType.SUPPLIER,
                  },
                ]);
                getCollaborationIds();
              }}
              onRemoveCollaborations={
                extraActions?.removeSpecificationCollaborationAction
              }
              existingCollaborationIds={collaborationIds}
              isLoading={isSavingInProgress}
              hideSupplierBar
              skipRemoveCollaborationAPI
              hideCollaborationActions={!hasEditPermission}
              existingContacts={formData}
            />
          </Col>
        ) : (
          <Col span={24}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  className={styles.selectLabelWrapper}
                  colon={false}
                  labelAlign="left"
                  label={formatMessage(messages.addAContact)}
                >
                  <Select
                    name="addAContact"
                    disabled={isAddContactActionDisabled}
                    placeholder={formatMessage(messages.chooseAContact)}
                    loading={isFilterContactsLoading}
                    notFoundMessage={formatMessage(messages.noContact)}
                    value={null}
                    onSelect={getAddContactAction({
                      isAddActionInProgress,
                      setIsAddActionInProgress,
                      addContact: extraActions?.addContactAction,
                      isRetailer,
                    })}
                    options={filterOptionsBySelectedValues({
                      options: filterContacts,
                      selectedValues: formData,
                      optionKey: "userId",
                      selectedValueKey: "userId",
                    }).map(({ userId, userFirstName, userLastName }) => ({
                      value: userId,
                      name: `${userFirstName} ${userLastName}`,
                    }))}
                    {...(hasFilterContactsError && {
                      errorMessage: formatMessage(messages.contactError),
                      error: true,
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )
      ) : null}
    </>
  );
};

export default ContactsArrayFieldTemplate;
