import { Col, Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { useIntl } from "react-intl";
import { useFilesInfo } from "hooks";
import { isListEmpty } from "utils/general";
import { messages } from "pages/Specification/components/Details/messages";

export interface DocumentsContentProps {
  fileIds: string[];
}

const DocumentsContent: React.FC<DocumentsContentProps> = ({ fileIds }) => {
  const { formatMessage } = useIntl();
  const { files } = useFilesInfo({ fileIds });

  return (
    <>
      {isListEmpty(files) ? (
        <Row justify="center">
          <EmptyState heading={formatMessage(messages.noDocument)} />
        </Row>
      ) : (
        <Row>
          <Col span={8}>
            <UploadFiles.List fileList={files} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default DocumentsContent;
