import { isListEmpty, isUuidEmpty } from "./general";
import {
  MaterialRecipeIngredientViewModel,
  MaterialSupplierViewModel,
} from "viewModels";

export const getTotalPercentageRootIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[]
) =>
  ingredients?.reduce((acc, curr) => {
    if (curr.firstLevel) {
      acc += curr.percentage;
    }
    return acc;
  }, 0);

export const getLastParentIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[],
  parentIdsPath: string[] = []
) => {
  const parentIngredients: MaterialRecipeIngredientViewModel[] = parentIdsPath?.reduce(
    (previousData, currentElement) => {
      const parentIngredient = previousData.find(
        ({ ingredientId }) => ingredientId === currentElement
      );

      return parentIngredient?.ingredients || [];
    },
    ingredients
  );

  return parentIngredients;
};

export const getRefListIdsFromSuppliers = (
  suppliers: MaterialSupplierViewModel[] = []
) => {
  let refListIds = [];

  suppliers?.forEach(({ origins }) => {
    if (!isListEmpty(origins)) {
      origins.forEach(origin => {
        refListIds.push.apply(
          refListIds,
          Object.values(origin).filter(value => !isUuidEmpty(value as string))
        );
      });
    }
  });

  return [...new Set(refListIds)];
};
