import { useMemo } from "react";
import { CHAPTER_ICONS } from "utils/constants";
import Section from "./components/Section";
import Title from "./components/Title";
import { ChapterProps } from "./types";
import { getCatalogIdsFromIngredients } from "dtos/material";
import { useCatalogDictionary } from "hooks";

const Chapter: React.FC<ChapterProps> = ({ chapter }) => {
  const {
    sectionName,
    sections,
    chapterType,
    recipeIngredients,
    ...chapterData
  } = chapter;

  const catalogIds = useMemo(
    () => getCatalogIdsFromIngredients({ ingredients: recipeIngredients }),
    [recipeIngredients]
  );

  useCatalogDictionary({ catalogIds });

  return (
    <>
      <Title
        sectionName={sectionName}
        sectionIcon={CHAPTER_ICONS[chapterType]}
      />
      {sections.map(
        ({ sectionId, sectionName, sectionStatus, blocks, sectionCode }) => (
          <Section
            key={sectionId}
            sectionId={sectionId}
            sectionName={sectionName}
            sectionStatus={sectionStatus}
            blocks={blocks}
            sectionCode={sectionCode}
            chapterData={chapterData}
          />
        )
      )}
    </>
  );
};

export default Chapter;
