import { useMemo } from "react";
import { useFilesInfo } from "hooks";
import { File } from "models";
import { useAppDispatch } from "store";
import { UseFilesProps } from "./types";

const useFiles = ({
  files: existingFiles = [],
  chapters = [],
  disabled,
  onUpdate,
}: UseFilesProps) => {
  const fileIds = existingFiles?.map(({ fileId }) => fileId);
  const dispatch = useAppDispatch();
  const { files: filesWithInfo } = useFilesInfo({ fileIds });

  const dropdownOptions = useMemo(() => {
    return chapters?.reduce((acc, chapter) => {
      if (chapter.visible) {
        acc.push({
          label: chapter.name,
          value: chapter.chapterType,
          chapterId: chapter.id,
        });
      }
      return acc;
    }, []);
  }, [chapters]);

  const onUploadFiles = async (selectedFiles: File[]) => {
    if (!onUpdate) return;

    const newFiles = (selectedFiles ?? []).map(({ id }) => ({
      fileId: id,
      chapterType: "general",
    }));

    const payload = [...existingFiles, ...newFiles];

    try {
      await dispatch(
        onUpdate({
          payload,
        })
      );
    } catch (e) {}
  };

  const onRemove = async (fileToRemove: File) => {
    if (!onUpdate) return;

    try {
      const payload = existingFiles?.filter(
        file => file.fileId !== fileToRemove.id
      );

      await dispatch(
        onUpdate({
          payload,
        })
      );
    } catch (e) {}
  };

  const onChangeChapterType = async ({ fileId, selectedValue }) => {
    if (!onUpdate) return;

    const updatedFiles = existingFiles?.map(file => {
      if (file?.fileId === fileId) {
        return {
          ...file,
          chapterType: selectedValue,
        };
      }
      return file;
    });

    try {
      await dispatch(
        onUpdate({
          payload: [...updatedFiles],
        })
      );
    } catch (e) {}
  };

  const filesWithDropdown = useMemo(
    () =>
      filesWithInfo.map(file => ({
        ...file,
        customDropdownProp: {
          options: dropdownOptions,
          value: existingFiles?.find(item => item.fileId === file.id)
            ?.chapterType,
          allowClear: false,
          onChange: onChangeChapterType,
          disabled,
        },
      })),
    [filesWithInfo, existingFiles, dropdownOptions]
  );

  return {
    onUploadFiles,
    onRemove,
    filesWithDropdown,
  };
};

export default useFiles;
