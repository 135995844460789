import { createContext } from "react";
import { MenuProps } from "../types";

const MenuContext = createContext<{
  selectedSectionId?: MenuProps["selectedSectionId"];
  selectedSubSectionId?: MenuProps["selectedSubSectionId"];
  onClickSection?: MenuProps["onClickSection"];
  onClickSubSection?: MenuProps["onClickSubSection"];
  isMinified?: boolean;
  retailerCompnayId?: MenuProps["retailerCompnayId"];
  supplierCompnayId?: MenuProps["supplierCompnayId"];
  translatorCompanyId?: MenuProps["translatorCompanyId"];
  translationStatus?: MenuProps["translationStatus"];
}>({
  selectedSectionId: "",
  selectedSubSectionId: "",
  onClickSection: () => {},
  onClickSubSection: () => {},
});

export default MenuContext;
