import { UiSchema } from "@rjsf/core";
import { Datatable, Typography } from "@trace-one/design-system";
import { useSubstancesTable } from "./hooks";
import RowTitle from "components/RowTitle";
import { ObjectSchema } from "components/SpecificationSection/types";
import { PackagingSubstanceTableViewModel } from "viewModels";

export type SubstancesTableProps = {
  title?: string;
  subtitle?: string;
  data: PackagingSubstanceTableViewModel[];
  schema: ObjectSchema;
  uiSchema: UiSchema;
  isRestricted?: boolean;
  isLoading?: boolean;
};

const SubstancesTable: React.FC<SubstancesTableProps> = ({
  title,
  subtitle,
  data,
  schema,
  uiSchema,
  isRestricted,
  isLoading,
}) => {
  const { columns } = useSubstancesTable({
    schema,
    uiSchema,
    isRestricted,
  });

  return (
    <>
      {title && <RowTitle title={title} />}
      {subtitle && (
        <Typography variant="body-regular" color="grey-5" className="mb-2">
          {subtitle}
        </Typography>
      )}
      <Datatable
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={({ substanceId }) => substanceId}
        loading={isLoading}
      />
    </>
  );
};

export default SubstancesTable;
