import {
  ChapterAllergenData,
  MaterialData,
  MaterialIngredientData,
  MaterialSupplierData,
} from "models";
import { Dictionary } from "types/general";
import { isListEmpty } from "utils/general";
import { isMaterialCompositeType } from "utils/library";
import { RecipeSummaryIngredientViewModal } from "viewModels";

export const getMaterialIdsByType = (ingredients: MaterialIngredientData[]) => {
  const materialIdsByType = {};

  const getMaterialIds = (items: MaterialIngredientData[]) => {
    items.forEach(item => {
      const key = item.recipeType || item.type;

      if (item.materialId) {
        if (!materialIdsByType[key]) {
          materialIdsByType[key] = new Set();
        }
        materialIdsByType[key].add(item.materialId);
      }

      if (item.ingredients) {
        getMaterialIds(item.ingredients);
      }
    });
  };

  getMaterialIds(ingredients);

  Object.keys(materialIdsByType).forEach(key => {
    materialIdsByType[key] = Array.from(materialIdsByType[key]);
  });

  return materialIdsByType;
};

export const getExtraMaterialDetails = (ingredients: MaterialData[]) =>
  ingredients.reduce((acc, { id, suppliers, fileIds, files, materialType }) => {
    fileIds =
      fileIds ??
      (files ?? []).reduce((acc, { fileId }) => {
        if (!isListEmpty(fileId)) {
          return [...acc, ...fileId];
        }

        return acc;
      }, []);

    let updatedSuppliers = [...suppliers];

    if (!isMaterialCompositeType(materialType)) {
      updatedSuppliers = suppliers?.map(supplier => ({
        ...supplier,
        materialId: id,
      }));
    }

    acc[id] = { suppliers: updatedSuppliers, fileIds };
    return acc;
  }, {});

export const addExtraMaterialsDetailsToIngredients = (
  ingredient: RecipeSummaryIngredientViewModal,
  extraMaterialsDictionary: MaterialData
) => {
  let updatedIngredient = { ...ingredient };

  if (
    updatedIngredient.materialId &&
    extraMaterialsDictionary[updatedIngredient.materialId]
  ) {
    updatedIngredient = {
      ...updatedIngredient,
      ...extraMaterialsDictionary[updatedIngredient.materialId],
    };
  }

  return updatedIngredient;
};

export const getFlattenIngredients = (
  recipeIngredients: MaterialIngredientData[]
) => {
  const result = {};

  const processIngredient = (
    ingredient: MaterialIngredientData & {
      firstLevel?: boolean;
      parentIdsPath?: string[];
    }
  ) => {
    const {
      firstLevel,
      relativePercentage,
      percentage,
      ingredientId,
      materialId,
      name,
      ingredients,
      functionId,
      parentIdsPath,
      ...restOfIngredients
    } = ingredient;

    const percent = firstLevel ? percentage ?? 0 : relativePercentage ?? 0;
    const id = materialId ?? ingredientId;

    if (!result[id]) {
      result[id] = {
        ingredientIds: [
          isListEmpty(parentIdsPath) ? materialId : ingredientId,
        ].filter(Boolean),
        name,
        materialId: id,
        aggregatedPercentage: percent,
        functionIds: [functionId],
        parentIdsPath: parentIdsPath ?? [],
        ...restOfIngredients,
      };
    } else {
      result[id].aggregatedPercentage = Number(
        (result[id].aggregatedPercentage + percent).toFixed(3)
      );
      result[id].ingredientIds = [...result[id].ingredientIds, ingredientId];
      result[id].functionIds = [...result[id].functionIds, functionId].filter(
        Boolean
      );
    }

    if (ingredients && Array.isArray(ingredients)) {
      ingredients.forEach(subIngredient => processIngredient(subIngredient));
    }
  };

  recipeIngredients.forEach(ingredient => processIngredient(ingredient));

  return Object.values(result);
};

export const addOriginsToIngredients = (
  ingredients: RecipeSummaryIngredientViewModal[],
  suppliers: MaterialData["suppliers"]
) => {
  return ingredients.map(ingredient => {
    let updatedSuppliers = ingredient.suppliers || [];

    ingredient?.ingredientIds?.forEach(ingredientId => {
      suppliers?.forEach(supplier => {
        if (supplier?.ingredientIds?.includes(ingredientId)) {
          if (
            !updatedSuppliers.some(s => s.supplierId === supplier.supplierId)
          ) {
            updatedSuppliers.push({
              supplierId: supplier.supplierId,
              name: supplier.name,
              code: supplier.code,
              origins: supplier.origins,
            });
          }
        }
      });
    });

    return {
      ...ingredient,
      suppliers: updatedSuppliers,
    };
  });
};

export const getUniqueSuppliersWithAggregatedIngredientIds = (
  suppliers: MaterialSupplierData[] = []
) =>
  suppliers.reduce((acc: MaterialSupplierData[], supplier) => {
    const existingSupplier = acc.find(
      item => item.supplierId === supplier.supplierId
    );

    if (existingSupplier) {
      existingSupplier.ingredientIds = [
        ...new Set([
          ...(existingSupplier.ingredientIds ?? []),
          ...(supplier.ingredientIds ?? []),
        ]),
      ];
    } else {
      acc.push({ ...supplier });
    }

    return acc;
  }, []);

export const getAllergensKpiValue = ({
  rawMaterialsAllergens = [],
  crossContaminationAllergens = [],
  freeFromAllergenFormData = [],
}: {
  rawMaterialsAllergens: ChapterAllergenData[];
  crossContaminationAllergens: ChapterAllergenData[];
  freeFromAllergenFormData: Array<Dictionary<string>>;
}): number => {
  const allergenIds = [
    ...rawMaterialsAllergens.map(({ allergenId }) => allergenId),
    ...crossContaminationAllergens.map(({ allergenId }) => allergenId),
    ...freeFromAllergenFormData.map(({ id }) => id),
  ];

  const uniqueIds = new Set(allergenIds);

  return uniqueIds.size;
};
