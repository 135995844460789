import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { validateSpecificationSection } from "apis/SPEC";
import { ValidationChapterContextProvider } from "contexts";
import Spin from "components/Spin";
import {
  useBoolean,
  useUserInfo,
  useRecipe,
  useCatalogDictionary,
  useSpecificationChaptersData,
  useRbac,
  useLabelingTranslations,
} from "hooks";
import {
  useChapterData,
  useSelectedChapterActions,
  useUnlinkChapter,
} from "./hooks";
import { useTranslationManagement } from "components/TranslationManagement/hooks";
import Sections from "./components/Sections";
import UnlinkChapterModal from "./components/UnlinkChapterModal";
import DetailsAndLinksChapterModal from "components/DetailsAndLinksChapterModal";
import Title from "./components/Title";
import TranslationManagement from "components/TranslationManagement";
import Modal from "components/Modal";
import OnGoingTranslations from "./components/OnGoingTranslations";
import { isProductTypeFood } from "utils/library";
import {
  isLabelingTranslationStateNotStarted,
  isSectionCodeLabeling,
} from "utils/specifications";
import { SelectedChapterContext } from "./contexts";
import { TranslationManagementContext } from "components/TranslationManagement/contexts";
import { SpecificationLabelingTranslationData, TemplateData } from "models";
import { selectLanguageCode } from "store/user/selectors";
import { SelectedChapterProps } from "./types";
import { getCatalogIdsFromIngredients } from "dtos/material";
import { messages } from "./messages";
import { PERMISSIONS } from "utils/constants";
import { TranslationManagementContextProps } from "components/TranslationManagement/contexts/TranslationManagementContext";

const SelectedChapter: React.FC<SelectedChapterProps> = ({
  specificationId,
  specificationName,
  specificationHeaderTitle,
  selectedChapter,
  supplierId,
  ownerCompanyId,
  ownerUserId,
  specificationStructure,
  currentSectionsRefs,
  updateChapterInformation,
  productTypeId,
  productData,
  permissionList,
  refetchSpecificationInformation,
  requirementIds,
  fileIds,
  specificationState,
  setSelectedChapterOwnerCompanyId,
  isTargetMode,
  isSpecContact,
  isRetailerContact,
  retailerCompnayId,
  supplierCompnayId,
  translatorCompanyId,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const [translationTemplate, setTranslationTemplate] = useState<TemplateData>(
    null
  );
  const [
    previousTranslationTemplate,
    setPreviousTranslationTemplate,
  ] = useState<TemplateData>(null);
  const [shouldDeleteOnCancel, setShouldDeleteOnCancel] = useState<boolean>(
    false
  );
  const [
    specificationLabelingTranslationData,
    setSpecificationLabelingTranslationData,
  ] = useState<SpecificationLabelingTranslationData>(null);

  const { isRetailer } = useUserInfo();

  const { sectionCode } = selectedChapter;

  const {
    isLoading: isChapterLoading,
    chapters,
    fetchChapter,
    fetchChapterWithLoading,
  } = useSpecificationChaptersData({
    specificationId,
    specificationStructure,
    specificationState,
    chapterType: selectedChapter?.chapterType,
  });

  const chapterData = chapters?.[0];

  const { refetchChapter, refetchChapterWithLoading } = useChapterData({
    chapterData,
    selectedChapter,
    updateChapterInformation,
    refetchSpecificationInformation,
    fetchChapter,
    fetchChapterWithLoading,
    setSelectedChapterOwnerCompanyId,
  });

  const {
    onUnlinkChapterToSpecification,
    onClose: onCloseUnlinkChapterModal,
    isOpen: isUnlinkModalOpen,
    onOpenUnlinkModal,
  } = useUnlinkChapter({
    specificationId,
    specificationName,
    chapterType: selectedChapter?.chapterType,
    chapterTypeName: selectedChapter?.sectionName,
    afterUnlinkCallback: refetchChapterWithLoading,
  });

  const {
    value: isDetailsAndLinksModalOpen,
    setFalse: onCloseDetailsAndLinksChapterModal,
    setTrue: onOpenDetailsAndLinksModal,
  } = useBoolean(false);

  const {
    value: isTranslationMgmtOverlayOpen,
    setFalse: onCloseTranslationMgmtOverlay,
    setTrue: onOpenTranslationMgmtOverlay,
  } = useBoolean(false);

  const { onClose } = useTranslationManagement({
    specificationId,
    onCloseTranslationMgmtOverlay,
    shouldDeleteOnCancel,
    setSpecificationLabelingTranslationData,
  });

  const { hasPermission } = useRbac(permissionList);

  const labelingTranslations = useLabelingTranslations({
    specificationId,
    sectionCode,
    productTypeId,
    hasChapterTranslationManagementPermission: hasPermission(
      PERMISSIONS.CHAPTER.TRANSLATION_MANAGEMENT
    ),
    languageCode,
  });

  useEffect(() => {
    setSpecificationLabelingTranslationData(labelingTranslations);
  }, [labelingTranslations]);

  const displayPrimaryAction =
    (!specificationLabelingTranslationData?.state ||
      isLabelingTranslationStateNotStarted(
        specificationLabelingTranslationData?.state
      )) &&
    !isChapterLoading &&
    isProductTypeFood(productTypeId);

  const displayOnGoingTranslations =
    isSectionCodeLabeling(selectedChapter?.sectionCode) &&
    specificationLabelingTranslationData?.state &&
    !isLabelingTranslationStateNotStarted(
      specificationLabelingTranslationData?.state
    ) &&
    hasPermission(PERMISSIONS.CHAPTER.TRANSLATION_MANAGEMENT);

  const sectionActions = useSelectedChapterActions({
    permissionList: chapterData?.chapterSection?.permissions,
    isChapterLibrary: selectedChapter?.isChapterLibrary,
    isChapterLinked: selectedChapter?.isLinked,
    onOpenUnlinkModal,
    onOpenDetailsAndLinksModal,
  });

  const {
    recipeIngredients,
    sortRecipeIngredient,
    recipeSorter,
    totalPercentageOfRecipeIngredients,
  } = useRecipe({
    id: specificationId,
    chapterType: selectedChapter?.chapterType,
    shouldCallApi:
      isProductTypeFood(productTypeId) && selectedChapter?.isLinked,
    isSpecContext: true,
  });

  const catalogIds = useMemo(
    () => getCatalogIdsFromIngredients({ ingredients: recipeIngredients }),
    [recipeIngredients]
  );

  useCatalogDictionary({ catalogIds });

  const translationManagementContextValue: TranslationManagementContextProps = useMemo(
    () => ({
      specificationId,
      specificationHeaderTitle,
      translationTemplate,
      setTranslationTemplate,
      previousTranslationTemplate,
      setPreviousTranslationTemplate,
      translationTemplateId:
        specificationLabelingTranslationData?.templateId || null,
      setSpecificationLabelingTranslationData,
      shouldDeleteOnCancel,
      setShouldDeleteOnCancel,
      onCancel: onCloseTranslationMgmtOverlay,
      validatedByRetailer: chapterData?.validatedByRetailer,
    }),
    [
      specificationId,
      specificationHeaderTitle,
      translationTemplate,
      setTranslationTemplate,
      previousTranslationTemplate,
      setPreviousTranslationTemplate,
      specificationLabelingTranslationData,
      setSpecificationLabelingTranslationData,
      shouldDeleteOnCancel,
      setShouldDeleteOnCancel,
      onCloseTranslationMgmtOverlay,
      chapterData?.validatedByRetailer,
    ]
  );

  return (
    <ValidationChapterContextProvider
      onValidate={validateSpecificationSection({
        specificationId,
        chapterType: selectedChapter?.chapterType,
      })}
      afterValidationCallback={refetchChapter}
      chapterId={selectedChapter?.sectionId}
    >
      <Title
        sectionId={selectedChapter?.sectionId}
        sectionCode={selectedChapter?.sectionCode}
        sectionName={selectedChapter?.sectionName}
        chapterSection={chapterData?.chapterSection}
        isChapterLinked={selectedChapter?.isLinked}
        sectionActions={sectionActions}
        isChapterOwned={chapterData?.ownerCompanyId === ownerCompanyId}
        isChapterOwnedBySupplier={chapterData?.ownerCompanyId === supplierId}
        isChapterLibrary={selectedChapter?.isChapterLibrary}
        sections={chapterData?.sections}
        isTargetMode={isTargetMode}
        specificationPermissions={permissionList}
        onPrimaryActionClick={
          displayPrimaryAction ? onOpenTranslationMgmtOverlay : null
        }
      />

      {displayOnGoingTranslations && (
        <OnGoingTranslations
          translations={specificationLabelingTranslationData?.translations}
          isChapterLoading={isChapterLoading}
        />
      )}

      <SelectedChapterContext.Provider
        value={{
          specificationPermissions: permissionList,
          chapterData: {
            ...chapterData,
            recipeSorter,
            recipeIngredients,
            totalPercentageOfRecipeIngredients,
          },
          specificationId,
          specificationName,
          specificationHeaderTitle,
          supplierId,
          ownerCompanyId,
          ownerUserId,
          specificationStructure,
          currentSectionsRefs,
          chapterType: selectedChapter?.chapterType,
          productTypeId,
          refetchChapterWithLoading,
          isChapterLibrary: selectedChapter?.isChapterLibrary,
          productData,
          refetchChapter,
          requirementIds,
          fileIds,
          specificationState,
          isRetailer,
          isChapterOwnedBySupplier: chapterData?.ownerCompanyId === supplierId,
          sortRecipeIngredient,
          isTargetMode,
          isSpecContact,
          isRetailerContact,
          retailerCompnayId,
          supplierCompnayId,
          translatorCompanyId,
        }}
      >
        {isChapterLoading ? (
          <Spin />
        ) : (
          <Sections sections={chapterData?.sections} />
        )}
        {isUnlinkModalOpen && (
          <UnlinkChapterModal
            onCloseModal={onCloseUnlinkChapterModal}
            onConfirmModal={onUnlinkChapterToSpecification}
          />
        )}
        {isDetailsAndLinksModalOpen && (
          <DetailsAndLinksChapterModal
            onCloseModal={onCloseDetailsAndLinksChapterModal}
            chapterId={chapterData.chapterId}
            chapterType={selectedChapter?.chapterType}
            title={formatMessage(messages.detailsAndLinksChapterModalTitle)}
          />
        )}

        <TranslationManagementContext.Provider
          value={translationManagementContextValue}
        >
          {isTranslationMgmtOverlayOpen && (
            <Modal
              onClose={onClose}
              footer={<TranslationManagement.Footer />}
              showHeader
            >
              <TranslationManagement.Content />
            </Modal>
          )}
        </TranslationManagementContext.Provider>
      </SelectedChapterContext.Provider>
    </ValidationChapterContextProvider>
  );
};

export default SelectedChapter;
