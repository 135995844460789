import { SpecificationDocumentSummaryData } from "models";
import { specRtkBaseApi } from "./specRtkApi";

const specificationApi = specRtkBaseApi.injectEndpoints({
  endpoints: build => ({
    getSpecificationFiles: build.query<
      SpecificationDocumentSummaryData,
      {
        specificationId: string;
      }
    >({
      query: ({ specificationId }) => ({
        url: `specifications/${specificationId}/files`,
        method: "GET",
      }),
      providesTags: (_result, _err, { specificationId }) => [
        { type: "SpecificationFiles", specificationId },
      ],
    }),
  }),
});

export const {
  useGetSpecificationFilesQuery: useGetSpecificationFiles,
} = specificationApi;
