import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "@trace-one/business-components";
import { fetchSubstance, searchSubstances } from "apis/CATALOGUE";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateSubstanceId } from "store/materialForm/asyncActions";
import {
  AREA_CATEGORY_TYPES,
  numberOfCharactersForAutoComplete,
} from "utils/constants";
import { MaterialData, SubstanceData } from "models";
import { isListEmpty } from "utils/general";
import useBoolean from "hooks/useBoolean";

const useSubstanceInformation = ({
  areaCategory,
  substanceId,
  disabledIds = [],
  shouldUpdateMaterialDetails,
}: {
  areaCategory: AREA_CATEGORY_TYPES;
  substanceId?: MaterialData["substanceId"];
  disabledIds?: string[];
  shouldUpdateMaterialDetails?: boolean;
}) => {
  const [substanceList, setSubstanceList] = useState<SubstanceData[]>(null);
  const [selectedSubstance, setSelectedSubstance] = useState<SubstanceData>(
    null
  );
  const [searchTerm, setSearchTerm] = useState<string>(null);

  const languageCode = useSelector(selectLanguageCode);

  const clearedSubstanceIdRef = useRef(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const dispatch = useAppDispatch();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);

  const getSubstances = async (searchTerm?: string) => {
    try {
      if (searchTerm === selectedSubstance?.id) return;
      setTrue();

      const { data } = await searchSubstances({
        params: {
          languageCode,
          searchTerm,
          areaCategory,
        },
      });

      setSubstanceList(data);
    } catch (error) {
    } finally {
      setFalse();
    }
  };

  const getSubstance = async () => {
    try {
      const { data } = await fetchSubstance({
        substanceId,
        params: { languageCode },
      });
      setSelectedSubstance(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (
      clearedSubstanceIdRef.current !== substanceId &&
      substanceList?.some(item => item?.id === substanceId)
    ) {
      setSelectedSubstance(
        substanceId
          ? substanceList?.find(item => item?.id === substanceId)
          : null
      );
    } else if (isListEmpty(substanceList) && substanceId) {
      getSubstance();
    }
  }, [substanceList, substanceId]);

  useEffect(() => {
    if (debouncedSearchTerm?.length === 0) {
      getSubstances("");
    }
    if (debouncedSearchTerm?.length >= numberOfCharactersForAutoComplete) {
      getSubstances(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, languageCode]);

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getSubstances("");
    }
  };

  const onSelect = async (selectedValue: string) => {
    try {
      if (shouldUpdateMaterialDetails) {
        await dispatch(updateSubstanceId(selectedValue || null));
      }

      setSelectedSubstance(
        selectedValue
          ? substanceList?.find(item => item?.id === selectedValue)
          : null
      );
    } catch {}
  };

  const onClear = () => {
    clearedSubstanceIdRef.current = selectedSubstance?.id;
    setSelectedSubstance(null);
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const options = useMemo(() => {
    return substanceList?.map(substance => ({
      label: substance?.name,
      name: substance?.name,
      value: substance?.id,
      disabled: disabledIds.includes(substance.id),
    }));
  }, [substanceList]);

  return {
    isLoading,
    options,
    selectedSubstance,
    onSearch,
    onSelect,
    onClear,
    onDropdownVisibleChange,
  };
};

export default useSubstanceInformation;
