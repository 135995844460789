import { useEffect, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { ChangeEventExtra } from "rc-tree-select/lib/interface";
import useBoolean from "hooks/useBoolean";
import { useAppDispatch } from "store";
import { updateCatalogDictionary } from "store/generalData/asyncActions";

export type UseTreeSelectProps = {
  value?: LabeledValue;
  shouldCurrentValueBeUpdated?: boolean;
  onFullChange?: Function;
  onChange?: Function;
  getIsSelectable?: Function;
};

export type TreeItemType<T> = {
  id?: string;
  parentId?: string;
  name?: string;
  hasChildren?: boolean;
  isMatch?: boolean;
  children?: T[];
};

type TreeItemState<T extends TreeItemType<T>> = T[];

const useTreeSelect = <T>({
  value,
  onChange: defaultOnChange,
  onFullChange,
  shouldCurrentValueBeUpdated = true,
  getIsSelectable,
}: UseTreeSelectProps) => {
  const [treeData, setTreeData] = useState<TreeItemState<T>>([]);
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<string[]>([]);
  const [valueWithLabel, setValueWithLabel] = useState<LabeledValue>(value);
  const {
    value: isLoading,
    setTrue: setLoadingTrue,
    setFalse: setLoadingFalse,
  } = useBoolean(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setValueWithLabel(value);
  }, [value]);

  const onTreeExpand = (expandedKeys: string[]) => {
    setTreeExpandedKeys(expandedKeys);
  };

  const onValueWithLabelChange = (changedValue: LabeledValue) => {
    if (shouldCurrentValueBeUpdated) {
      setValueWithLabel(changedValue);
    }
    onFullChange?.(changedValue);
  };

  const onChange = (
    newValue: LabeledValue,
    labelist: React.ReactNode[],
    extra: ChangeEventExtra
  ) => {
    try {
      setLoadingTrue();

      if (!newValue) {
        defaultOnChange(newValue, labelist, extra);
        onValueWithLabelChange(null);
      } else {
        const { label, value } = newValue;

        const result = {
          id: value,
          name: label,
        };

        if (shouldCurrentValueBeUpdated) {
          // @ts-ignore
          prepareTreeData([result]);
        }

        dispatch(
          updateCatalogDictionary({
            [result.id]: result.name as string,
          })
        );

        defaultOnChange(result.id, labelist, extra);
        onValueWithLabelChange({ label, value });
      }
    } finally {
      setLoadingFalse();
    }
  };

  const prepareSearchTreeItemData = <T extends TreeItemType<T>>(item: T) => {
    const { id, parentId, name, hasChildren, isMatch = false } = item;

    return {
      id,
      pId: parentId,
      key: id,
      value: id,
      title: name,
      hasChildren,
      children: [],
      selectable: getIsSelectable(item),
      isLeaf: !hasChildren,
      isMatch,
    };
  };

  const prepareTreeItemData = <T extends TreeItemType<T>>(item: T) => {
    const { id, parentId, name, hasChildren, children } = item;

    return {
      id,
      pId: parentId,
      key: id,
      value: id,
      title: name,
      hasChildren,
      selectable: getIsSelectable(item),
      isLeaf: !hasChildren,
      children,
    };
  };

  const prepareTreeData = (data: T[], shouldOverwrite: boolean = true) => {
    if (!data) return;

    let newTreeData: TreeItemState<T> = data?.map(prepareTreeItemData) as T[];

    if (shouldOverwrite) {
      setTreeData(newTreeData);
    } else {
      setTreeData(prevState => [...prevState, ...newTreeData]);
    }
  };

  return {
    treeExpandedKeys,
    setTreeExpandedKeys,
    onTreeExpand,
    valueWithLabel,
    onValueWithLabelChange,
    onChange,
    treeData,
    setTreeData,
    isLoading,
    setLoadingTrue,
    setLoadingFalse,
    prepareTreeData,
    prepareSearchTreeItemData,
  };
};

export default useTreeSelect;
