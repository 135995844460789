import React from "react";
import { useIntl } from "react-intl";
import { Row } from "antd";
import { Typography, Pagination, EmptyState } from "@trace-one/design-system";
import { useClientPagination } from "hooks";
import SupplierCompanyCard from "./components/SupplierCompanyCard";
import { isListEmpty } from "utils/general";
import { shouldPaginationBeDisplay } from "utils/table";
import { RecipeSummarySupplierViewModel } from "viewModels";
import { Dictionary } from "types/general";
import { generalMessages } from "messages";
import { messages } from "../../messages";
import styles from "./styles.module.less";

export type RecipeSummarySuppliersProps = {
  suppliers: RecipeSummarySupplierViewModel[];
  ingredientNameDictionary: Dictionary<string>;
};

const RecipeSummarySuppliers: React.FC<RecipeSummarySuppliersProps> = ({
  suppliers,
  ingredientNameDictionary,
}) => {
  const { formatMessage } = useIntl();
  const {
    totalCount,
    onPaginationChange,
    filteredItems: visibleSuppliers,
    filters,
  } = useClientPagination<RecipeSummarySupplierViewModel>({ items: suppliers });

  return (
    <>
      <Typography variant="heading-xs" color="grey-5" className="mb-2">
        {formatMessage(messages.supplierList)}
      </Typography>
      {isListEmpty(suppliers) ? (
        <Row justify="center" align="middle">
          <EmptyState heading={formatMessage(generalMessages.noItemFound)} />
        </Row>
      ) : (
        <>
          <div className={styles["suppliers-list-wrapper"]}>
            {visibleSuppliers.map(
              ({ name, code, ingredientIds = [], materialId, supplierId }) => (
                <SupplierCompanyCard
                  name={name}
                  code={code}
                  ingredientIds={ingredientIds}
                  ingredientNameDictionary={ingredientNameDictionary}
                  key={supplierId}
                  materialId={materialId}
                />
              )
            )}
          </div>
          <Row justify="end" className="mt-4">
            <Pagination
              hideOnSinglePage={!shouldPaginationBeDisplay(totalCount)}
              defaultCurrent={1}
              showSizeChanger
              current={filters.currentPage}
              pageSize={filters.pageSize}
              total={totalCount}
              onShowSizeChange={onPaginationChange}
              onChange={onPaginationChange}
            />
          </Row>
        </>
      )}
    </>
  );
};

export default RecipeSummarySuppliers;
