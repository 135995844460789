import { useIntl } from "react-intl";
import { Typography, Datatable } from "@trace-one/design-system";
import { useIngredientsListTable } from "./hooks";
import { useCatalogDictionary } from "hooks";
import { isListEmpty } from "utils/general";
import { Dictionary } from "types/general";
import { RecipeSummaryIngredientViewModal } from "viewModels";
import { messages } from "../../messages";

export type IngredientsListTableProps = {
  ingredients: RecipeSummaryIngredientViewModal[];
  refListDictionary?: Dictionary;
};

const IngredientsListTable: React.FC<IngredientsListTableProps> = ({
  ingredients = [],
  refListDictionary,
}) => {
  const { formatMessage } = useIntl();
  const functionIds: string[] = ingredients.reduce((acc, { functionIds }) => {
    if (!isListEmpty(functionIds)) {
      acc = [...acc, ...functionIds];
    }
    return acc;
  }, []);
  const { catalogDictionary, isLoading } = useCatalogDictionary({
    catalogIds: functionIds,
  });
  const columns = useIngredientsListTable({
    refListDictionary,
    catalogDictionary,
  });

  return (
    <>
      <Typography variant="heading-xs" color="grey-5">
        {formatMessage(messages.recipeSummaryOverlayIngredientList)}
      </Typography>

      <Datatable
        columns={columns}
        dataSource={ingredients}
        pagination={false}
        rowKey={({ materialId }) => materialId}
        className="mt-3"
        isLoading={isLoading}
        data-test-id={"ingredients-list-table"}
      />
    </>
  );
};

export default IngredientsListTable;
