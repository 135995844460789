import { useContext } from "react";
import MaterialList from "components/SpecificationSection/components/FormSchema/components/MaterialList";
import Risk from "./components/Risk";
import { AllergensArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/AllergensArrayFieldTemplate/contexts";
import { UiSchemaInfo } from "types/general";

const useColumns = ({
  uiSchema,
  displayActions,
}: {
  uiSchema: UiSchemaInfo;
  displayActions?: boolean;
}) => {
  const { catalogDictionary, allergenControlledRiskDictionary } = useContext(
    AllergensArrayFieldTemplateContext
  );

  return {
    columns: [
      {
        title: uiSchema?.items?.allergenId["ui:title"],
        dataIndex: "allergenId",
        render: allergenId => catalogDictionary[allergenId],
      },
      {
        title: "From materials",
        dataIndex: "ingredients",
        width: "60%",
        render: materials => {
          return <MaterialList materials={materials} />;
        },
      },
      {
        title: uiSchema?.items?.controlledRisk?.["ui:title"],
        width: "16%",
        render: (_, allergen) => {
          return (
            <Risk
              allergenId={allergen.allergenId}
              value={allergenControlledRiskDictionary?.[allergen.allergenId]}
              areActionsDisabled={!displayActions}
            />
          );
        },
      },
    ],
  };
};

export default useColumns;
