import { Dispatch, SetStateAction } from "react";
import { updateLabelingTranslationBlock } from "apis/SPEC/labeling-translations";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { useAppDispatch } from "store";
import {
  getChapter,
  updateBlock,
  updateSites,
  addChapterIngredient,
  updateChapterIngredient,
  removeChapterIngredient,
  addChapterNutrition,
  updateChapterNutritionData,
  deleteChapterNutrition,
  updateChapterCrossContaminationAllergenRisk,
  addChapterFreeFromAllergens,
  removeChapterFreeFromAllergen,
  updateChapterFreeFromAllergen,
  updateEnergyCalculation,
} from "store/chapterForm/asyncActions";
import { getParsedData } from "utils/general";
import { ChapterStructureFormDataBlock } from "types/library";

const useBlockActions = ({
  sectionId,
  resetSectionValidationErrors,
  setSectionBlocks,
  translationId,
  getTranslation,
  getLabelingTranslationLanguages,
}: {
  sectionId: string;
  resetSectionValidationErrors: Function;
  setSectionBlocks?: Dispatch<
    SetStateAction<ChapterStructureFormDataBlock[] | null>
  >;
  translationId?: string;
  getTranslation?: Function;
  getLabelingTranslationLanguages?: Function;
}) => {
  const dispatch = useAppDispatch();

  const saveTemplateBlock = (blockId: string) => async (blockJson: string) => {
    resetSectionValidationErrors(sectionId);

    setSectionBlocks?.(prevState => {
      return (prevState || []).map(block => {
        if (block.blockId === blockId) {
          const inputData = getParsedData(blockJson);
          const existingData = getParsedData(block.dataAsJson);
          const updatedData = { ...existingData, ...inputData };

          return {
            ...block,
            dataAsJson: JSON.stringify(updatedData),
          };
        }
        return block;
      });
    });

    if (translationId) {
      await updateLabelingTranslationBlock({
        translationId,
        blockId,
        json: blockJson,
      });

      await getTranslation?.(translationId, false);
      await getLabelingTranslationLanguages?.();

      return;
    }

    const { payload, meta } = await dispatch(
      updateBlock({
        blockId,
        blockJson,
      })
    );

    return {
      payload,
      requestStatus: meta.requestStatus,
    };
  };

  const onFinish = () => {
    if (translationId) {
      return;
    }

    dispatch(getChapter());
  };

  const addNutritionAction: EnhancedExtraActions["addNutritionAction"] = async ({
    nutrientIds,
  }) => {
    await dispatch(addChapterNutrition({ nutrientIds }));
  };

  const updateNutritionEnergyCalculation: EnhancedExtraActions["updateNutritionEnergyCalculation"] = async automaticCalculation => {
    await dispatch(updateEnergyCalculation({ automaticCalculation }));
  };

  const updateNutritionAction: EnhancedExtraActions["updateNutritionAction"] = async ({
    nutrientId,
    data,
  }) => {
    //@ts-ignore
    await dispatch(updateChapterNutritionData({ sectionId, nutrientId, data }));
  };

  const deleteNutritionAction: EnhancedExtraActions["deleteNutritionAction"] = async ({
    nutrientId,
  }) => {
    await dispatch(
      deleteChapterNutrition({
        nutrientId,
      })
    );
  };

  const addRecipeIngredient: EnhancedExtraActions["addRecipeIngredient"] = async ({
    materialId,
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(addChapterIngredient({ materialId }));
  };

  const updateRecipeIngredient: EnhancedExtraActions["updateRecipeIngredient"] = async ({
    ingredientId,
    functionId,
    percentage,
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(
      updateChapterIngredient({
        ingredientId,
        functionId,
        percentage,
      })
    );
  };

  const removeRecipeIngredient: EnhancedExtraActions["removeRecipeIngredient"] = async ({
    ingredientId,
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(removeChapterIngredient({ ingredientId }));
  };

  const updateChapterSites = async ({
    currentSiteIds,
  }: {
    currentSiteIds: string[];
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(updateSites(currentSiteIds));
  };

  const updateChapterCCAllergenRisk = async ({
    allergenId,
    controlledRisk,
  }: {
    allergenId: string;
    controlledRisk: boolean;
  }) => {
    await dispatch(
      updateChapterCrossContaminationAllergenRisk({
        allergenId,
        controlledRisk,
      })
    );
  };

  const addFreeFromAllergens = async (allergenIds: string[]) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(
      addChapterFreeFromAllergens({
        allergenIds,
      })
    );
  };

  const removeFreeFromAllergen: EnhancedExtraActions["removeFreeFromAllergen"] = async ({
    allergenId,
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(removeChapterFreeFromAllergen({ allergenId }));
  };

  const updateFreeFromAllergen: EnhancedExtraActions["updateFreeFromAllergen"] = async ({
    allergenId,
    fileId,
  }) => {
    resetSectionValidationErrors(sectionId);

    await dispatch(updateChapterFreeFromAllergen({ allergenId, fileId }));
  };

  return {
    onFinish,
    saveTemplateBlock,
    addNutritionAction,
    updateNutritionAction,
    deleteNutritionAction,
    addRecipeIngredient,
    updateRecipeIngredient,
    removeRecipeIngredient,
    updateChapterSites,
    updateChapterCCAllergenRisk,
    addFreeFromAllergens,
    removeFreeFromAllergen,
    updateFreeFromAllergen,
    updateNutritionEnergyCalculation,
  };
};
export default useBlockActions;
