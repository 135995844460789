import React from "react";
import { UiSchema } from "@rjsf/core";
import NutriscoreDetails from "../NutriscoreDetails";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { NutriscoreFormData } from "models";
import { nutriscorePropertiesName } from "../../NutriscoreWidget";
import FieldLabelValue from "components/SpecificationSection/components/FormSchema/components/FieldLabelAndValue";

export type NutriscoreReadOnlyWidgetProps = {
  uiSchema: UiSchema;
  formData: NutriscoreFormData;
  productTypeOptions: {
    label: string;
    value: string;
  }[];
  displayEmptyState: boolean;
};

const NutriscoreReadOnlyWidget: React.FC<NutriscoreReadOnlyWidgetProps> = ({
  uiSchema,
  formData,
  productTypeOptions,
  displayEmptyState,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { nutriscore, nutriscoreCalculationMissingReason = "" } = formData;

  const displayOnPackUITitle =
    uiSchema?.[nutriscorePropertiesName.displayOnPack]?.["ui:title"];
  const displayOnPackValue = formData?.[nutriscorePropertiesName.displayOnPack];
  const productTypeUITitle =
    uiSchema?.[nutriscorePropertiesName.productType]?.["ui:title"];
  const productTypeValue = formData?.[nutriscorePropertiesName.productType];
  const presenceOfNonNutritiveSweetenersUITitle =
    uiSchema?.["nutriscore"]?.[
      nutriscorePropertiesName.presenceOfNonNutritiveSweeteners
    ]?.["ui:title"];
  const presenceOfNonNutritiveSweetenersValue =
    nutriscore?.[nutriscorePropertiesName.presenceOfNonNutritiveSweeteners];
  const fruitsVegetablesAndLegumesUITitle =
    uiSchema?.[
      nutriscorePropertiesName?.percentageOfFruitsVegetablesAndLegumes
    ]?.["ui:title"];
  const fruitsVegetablesAndLegumesValue =
    formData?.[nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes];

  return (
    <div className="mt-3 mb-2">
      {displayOnPackUITitle && (
        <FieldLabelValue
          label={displayOnPackUITitle}
          value={getTranslationForKey(displayOnPackValue ? "yes" : "no")}
        />
      )}

      {productTypeUITitle && (
        <FieldLabelValue
          label={productTypeUITitle}
          value={
            productTypeOptions?.find(
              option => option.value === productTypeValue
            )?.label ?? "-"
          }
          className="mt-3"
        />
      )}

      {presenceOfNonNutritiveSweetenersUITitle &&
        (presenceOfNonNutritiveSweetenersValue === false ||
          presenceOfNonNutritiveSweetenersValue === true) && (
          <FieldLabelValue
            label={presenceOfNonNutritiveSweetenersUITitle}
            value={getTranslationForKey(
              presenceOfNonNutritiveSweetenersValue ? "yes" : "no"
            )}
            className="mt-3"
          />
        )}

      {fruitsVegetablesAndLegumesUITitle && (
        <FieldLabelValue
          label={fruitsVegetablesAndLegumesUITitle}
          value={fruitsVegetablesAndLegumesValue ?? "-"}
          className="mt-3"
        />
      )}

      <div className="mt-3">
        <NutriscoreDetails
          nutriscore={nutriscore}
          displayActions={false}
          displayEmptyState={displayEmptyState}
          uiSchema={uiSchema}
          nutriscoreCalculationMissingReason={
            nutriscoreCalculationMissingReason
          }
        />
      </div>
    </div>
  );
};

export default NutriscoreReadOnlyWidget;
