import { ClaimSentenceTranslation } from "models";
import { CLAIM_TYPES, LIBRARY_OBJECT_TYPES } from "./constants";

export const isClaimTypeMarketing = (type: string) =>
  type === CLAIM_TYPES.MARKETING ||
  type === LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS;

export const isClaimTypeHealth = (type: string) =>
  type === CLAIM_TYPES.HEALTH || type === LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS;

export const getDefaultTranslationLanguageCode = (
  translations: ClaimSentenceTranslation[]
) => translations?.find(({ isDefault }) => isDefault)?.languageCode || null;

export const getTranslationTextByLanguageCode = ({
  translations,
  languageCode,
}: {
  translations: ClaimSentenceTranslation[];
  languageCode: string;
}) =>
  translations?.find(translation => translation.languageCode === languageCode)
    ?.text || null;
