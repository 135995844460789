import { UploadFiles } from "@trace-one/business-components";
import { FILE_TYPES } from "utils/constants";
import { useFilesInfo } from "hooks/index";
import { getOnLinkFiles, getOnRemoveFileV2 } from "../../utils";
import styles from "components/SpecificationSection/components/FormSchema/widgets/styles.module.less";
import { DMSProps } from "../../types";

const DMS = ({
  photos,
  onChange,
  displayActions,
  ownerCompanyId,
}: DMSProps) => {
  const { files } = useFilesInfo({ fileIds: photos });

  if (!displayActions) {
    return <UploadFiles.List fileList={files} />;
  }

  return (
    <div className={styles.uploadComponentFullWidth}>
      <UploadFiles.Dragger
        fileList={files}
        fileType={FILE_TYPES.CHARACTERISTIC_PHOTO}
        onUploadFiles={getOnLinkFiles({
          onChange,
          photos,
        })}
        onRemove={getOnRemoveFileV2({
          onChange,
          photos,
        })}
        ownerCompanyId={ownerCompanyId}
      />
    </div>
  );
};

export default DMS;
