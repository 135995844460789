import PercentageInput from "components/PercentageInput";
import { getInputNumberProps } from "components/SpecificationSection/components/FormSchema/widgets/utils";
import { ObjectSchema } from "components/SpecificationSection/types";

export type SubstancesTableInputProps = {
  value: number;
  schema: ObjectSchema;
};

const SubstancesTableInput: React.FC<SubstancesTableInputProps> = ({
  value,
  schema,
}) => {
  const inputProps = getInputNumberProps(schema);

  return (
    <PercentageInput
      defaultValue={value}
      keepDefaultValue
      disabled
      {...inputProps}
    />
  );
};

export default SubstancesTableInput;
