import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { useAppDispatch } from "store";
import { selectOwningCompanyId } from "store/user/selectors";
import { setIsRequestPanelOpen } from "store/specificationDetails/specificationDetailsSlice";
import { useRbac } from "hooks";
import {
  getCanRequestForModification,
  isSpecificationSigned,
} from "utils/specifications";
import { PERMISSIONS } from "utils/constants";
import { generalMessages, modificationRequestMessages } from "messages";
import { useSelector } from "react-redux";
import { SpecificationHeaderActionProps } from "../../types";

export interface RequestModificationButtonProps
  extends Pick<
    SpecificationHeaderActionProps,
    | "isTargetMode"
    | "onTargetModeToggle"
    | "selectedChapterOwnerCompanyId"
    | "isSelectedChapterLibraryChapter"
    | "isSelectedChapterLinked"
    | "state"
    | "permissionList"
  > {
  onRequestModificationClick: Function;
  onOpenModificationRequestNotifyModal?: Function;
}

const RequestModificationButton = ({
  onRequestModificationClick,
  isTargetMode,
  onTargetModeToggle,
  onOpenModificationRequestNotifyModal,
  selectedChapterOwnerCompanyId,
  isSelectedChapterLibraryChapter,
  isSelectedChapterLinked,
  state,
  permissionList,
}: RequestModificationButtonProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const ownerCompanyId = useSelector(selectOwningCompanyId);

  const { hasPermission } = useRbac(permissionList);

  const handleRequestModificationClick = async () => {
    try {
      dispatch(setIsRequestPanelOpen(false));
      onTargetModeToggle?.();
      await onRequestModificationClick?.();
    } catch {}
  };

  const canRequestModification = useMemo(
    () =>
      getCanRequestForModification({
        state,
        isSelectedChapterLinked,
        selectedChapterOwnerCompanyId,
        ownerCompanyId,
        isSelectedChapterLibraryChapter,
      }) && hasPermission(PERMISSIONS.SPECIFICATION.UPDATE),
    [
      selectedChapterOwnerCompanyId,
      ownerCompanyId,
      isSpecificationSigned,
      isSelectedChapterLinked,
      state,
    ]
  );

  if (isTargetMode) {
    return (
      <Button
        iconName="close"
        color="amethyst"
        onClick={onOpenModificationRequestNotifyModal}
      >
        {formatMessage(generalMessages.exitAndSave)}
      </Button>
    );
  }

  return (
    <Button.Split
      iconName={"more"}
      onClick={handleRequestModificationClick}
      disabled={!canRequestModification}
      items={[
        {
          key: "see-all-requests",
          label: formatMessage(modificationRequestMessages.seeAllRequests),
          onClick: () => {
            dispatch(setIsRequestPanelOpen(true));
          },
        },
      ]}
      enableDropdown={
        !isSelectedChapterLibraryChapter ||
        (isSelectedChapterLibraryChapter && isSelectedChapterLinked)
      }
    >
      {formatMessage(modificationRequestMessages.requestModification)}
    </Button.Split>
  );
};

export default RequestModificationButton;
