import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import classNames from "classnames";
import { FileCard, Button, Pagination } from "@trace-one/design-system";
import { useClientPagination, useZipDownload } from "hooks";
import { toggleListItemSelection } from "utils/general";
import { shouldPaginationBeDisplay } from "utils/table";
import { DocumentSummaryPanelContext } from "components/DocumentSummaryPanel/contexts";
import { FileViewModel } from "viewModels";
import { generalMessages } from "messages";
import styles from "./styles.module.less";

export type DocumentGridProps = {
  gridItems: FileViewModel[];
};

const DocumentGrid: React.FC<DocumentGridProps> = ({ gridItems }) => {
  const { formatMessage } = useIntl();
  const {
    selectedItems,
    setSelectedItems,
    setPageItemCount,
    zipFileName,
  } = useContext(DocumentSummaryPanelContext);

  const {
    filters,
    onPaginationChange,
    totalCount,
    filteredItems,
  } = useClientPagination({ items: gridItems });

  useEffect(() => {
    setPageItemCount(filteredItems?.length);
  }, [filteredItems?.length]);

  const { onDownloadZip, isZipDownloading } = useZipDownload({
    fileIds: gridItems.map(({ id }) => id),
    zipFileName,
  });

  const handleFileClick = (file: FileViewModel) => () => {
    setSelectedItems(prevState =>
      toggleListItemSelection({
        item: file,
        list: prevState,
      })
    );
  };

  return (
    <>
      <div
        className={classNames(
          styles["document-grid__cards--container"],
          "hide-scrollbar"
        )}
      >
        {filteredItems.map(filteredItem => {
          const { id, name, fileExtension, createdByUserName } = filteredItem;

          return (
            <FileCard
              key={id}
              fileName={name}
              userName={createdByUserName}
              iconName={`${fileExtension}-document`}
              selected={selectedItems?.some(
                ({ id: selectedId }) => id === selectedId
              )}
              onClick={handleFileClick(filteredItem)}
            />
          );
        })}
      </div>

      <Row
        justify="space-between"
        className={classNames(
          styles["document-grid__action-items--container"],
          "mt-4",
          "ai-center"
        )}
      >
        <Col className="pl-6">
          <Button
            loading={isZipDownloading}
            type="tertiary"
            onClick={onDownloadZip}
          >
            {formatMessage(generalMessages.downloadAll)}
          </Button>
        </Col>
        <Col>
          <Pagination
            hideOnSinglePage={!shouldPaginationBeDisplay(totalCount)}
            defaultCurrent={1}
            showSizeChanger
            current={filters.currentPage}
            pageSize={filters.pageSize}
            total={totalCount}
            onShowSizeChange={onPaginationChange}
            onChange={onPaginationChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default DocumentGrid;
