import { useState } from "react";
import { getErrorsBySectionAndBlock } from "./utils";
import { isListEmpty } from "utils/general";
import {
  ValidationErrors,
  IsValidationLoading,
  ValidationResponse,
  SelectSectionValidationErrors,
  SetSectionBlockValidationError,
} from "./types";

const useValidation = ({
  onValidate,
  chapterId,
  afterValidationCallback,
}: {
  onValidate?: (
    sectionId: string
  ) => { data: ValidationResponse | ValidationResponse[] };
  chapterId?: string;
  afterValidationCallback?: Function;
}) => {
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );

  const [
    isValidationLoading,
    setIsValidationLoading,
  ] = useState<IsValidationLoading>({});

  const validateHandler = (sectionId?: string) => async () => {
    try {
      setIsValidationLoading(prev => {
        return { ...prev, [sectionId || chapterId]: true };
      });

      const { data } = await onValidate?.(sectionId);

      let response = Array.isArray(data as ValidationResponse[])
        ? data[0]
        : data;

      const { status, sections } = response;

      if (status === "success") {
        sections.forEach(({ sectionId }) => {
          resetSectionValidationErrors(sectionId);
        });
      }

      if (status === "failure" || status === "partialSuccess") {
        const errorsBySectionAndBlock = getErrorsBySectionAndBlock(sections);

        setValidationErrors(prev => {
          return { ...prev, ...errorsBySectionAndBlock };
        });
      }
    } finally {
      setIsValidationLoading(prev => {
        return { ...prev, [sectionId || chapterId]: false };
      });
      if (typeof afterValidationCallback === "function") {
        afterValidationCallback();
      }
    }
  };

  const resetSectionValidationErrors = (sectionId: string) => {
    setValidationErrors(prev => {
      const { [sectionId]: _, ...rest } = prev;

      return rest;
    });
  };

  const resetValidationErrors = () => {
    setValidationErrors({});
  };

  const isSectionValidationLoading = (sectionId: string) =>
    !!isValidationLoading[sectionId];

  const selectSectionValidationErrors: SelectSectionValidationErrors = sectionId =>
    validationErrors[sectionId] || {};

  const setSectionBlockValidationError: SetSectionBlockValidationError = sectionId => blockId => errors => {
    let newValidationErrors = errors.filter(
      ({ error }) => error !== "apiError"
    );

    if (!isListEmpty(newValidationErrors)) {
      setValidationErrors(previousErrors => {
        let newErrros = { ...previousErrors };
        newErrros[sectionId] = {
          ...newErrros[sectionId],
          [blockId]: [
            ...(!isListEmpty(newErrros[sectionId]?.[blockId])
              ? newErrros[sectionId][blockId]
              : []),
            ...newValidationErrors,
          ],
        };

        return newErrros;
      });
    }
  };

  return {
    validateHandler,
    isSectionValidationLoading,
    selectSectionValidationErrors,
    resetSectionValidationErrors,
    validationErrors,
    setSectionBlockValidationError,
    resetValidationErrors,
  };
};

export default useValidation;
