import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { v4 } from "uuid";
import { fetchReferenceListItems } from "apis/RLMD";
import { useBoolean } from "hooks";
import { NutritionServingData, ReferenceListItemData } from "models";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { selectLanguageCode } from "store/user/selectors";
import { updateRefListDictionary } from "store/generalData/asyncActions";
import { useAppDispatch } from "store";
import generalMessages from "messages/general";
import { GRAM_UNIT_ID, REFLIST_IDS } from "utils/constants";
import {
  EnhancedExtraActions,
  ObjectSchema,
} from "components/SpecificationSection/types";
import { NutritionServingFormData } from "components/SpecificationSection/components/FormSchema/types";
import { NutritionFormData } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { addInternationalSystemSourceId } from "../../../utils";

const useNutritionServingForm = ({
  extraActions,
  hasUpdatePermission,
  schema,
  saveTemplateBlock,
  formData: { servingDefinitions, nutrientFamilies },
  isTranslator,
}: {
  extraActions: EnhancedExtraActions;
  hasUpdatePermission: boolean;
  schema?: ObjectSchema;
  saveTemplateBlock?: Function;
  formData: NutritionFormData;
  isTranslator?: boolean;
}) => {
  const defaultInitialFormData = {
    servingName: undefined,
    servingSize: undefined,
    automaticCalculation: true,
  };

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const defaultUnitRef = useRef<ReferenceListItemData>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [referenceList, setReferenceList] = useState<ReferenceListItemData[]>(
    []
  );
  const [selectedUnit, setSelectedUnit] = useState<ReferenceListItemData>();
  const [selectedServingId, setSelectedServingId] = useState<string>();
  const [
    nutritionServingFormData,
    setNutritionServingFormData,
  ] = useState<NutritionServingFormData>(defaultInitialFormData);
  const { value: isServingAddingOrUpdating, setTrue, setFalse } = useBoolean();
  const servingSizeUnitIdSchemaProperties =
    // @ts-ignore
    schema?.properties?.servingDefinitions?.items?.properties?.size?.properties
      ?.unitId;

  const refListUnitOfMeasure =
    servingSizeUnitIdSchemaProperties?.referenceList?.listId;
  const refListParentItemIds =
    servingSizeUnitIdSchemaProperties?.referenceList?.parentItemIds;

  useEffect(() => {
    onVisibleChange(true);
  }, []);

  useEffect(() => {
    const defaultUnit = referenceList.filter(
      item => item.id === GRAM_UNIT_ID
    )[0];

    if (
      selectedUnit?.id !== defaultUnit?.id &&
      defaultUnitRef.current?.id !== defaultUnit?.id
    ) {
      setSelectedUnit(defaultUnit);
      defaultUnitRef.current = defaultUnit;
    }
  }, [referenceList]);

  const handleServingNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setNutritionServingFormData(prevState => ({
      ...prevState,
      servingName: value as string,
    }));
  };

  const handleServingSizeChange = (value: number) => {
    setNutritionServingFormData(prevState => ({
      ...prevState,
      servingSize: value,
    }));
  };

  const handleAutomaticCalculationChange = (value: boolean) => {
    setNutritionServingFormData(prevState => ({
      ...prevState,
      automaticCalculation: value,
    }));
  };

  const onVisibleChange = async (open: boolean) => {
    if (open) {
      try {
        setIsLoading(true);

        const { data } = await fetchReferenceListItems({
          id: refListUnitOfMeasure ?? REFLIST_IDS.UNITS_OF_MEASURE,
          languageCode,
          parentItemIds: refListParentItemIds
            ? addInternationalSystemSourceId(refListParentItemIds)
            : [
                REFLIST_IDS.MASS,
                REFLIST_IDS.VOLUME,
                REFLIST_IDS.INTERNATIONAL_SYSTEM_SOURCE,
              ],
        });

        setReferenceList(data.referenceListItems);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onChangeUnit = (unitOfMeasure: ReferenceListItemData) => () => {
    const { text, id } = unitOfMeasure;
    dispatch(
      updateRefListDictionary({
        [id]: text,
      })
    );

    setSelectedUnit(unitOfMeasure);
  };

  const getIsPrimaryModalButtonDisabled = () => {
    return (
      formErrors.some(item => !isListEmpty(item.errors)) ||
      !nutritionServingFormData.servingName ||
      !nutritionServingFormData.servingSize ||
      isObjectEmpty(selectedUnit)
    );
  };

  const resetFormAndCloseModal = () => {
    setNutritionServingFormData(defaultInitialFormData);
    setSelectedUnit(defaultUnitRef.current);
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const onPrimaryButtonClick = async () => {
    const servingsWithoutDefault = servingDefinitions
      .filter(({ defaultServing }) => !defaultServing)
      .map(
        ({
          servingId,
          name,
          automaticCalculation,
          size: { unitId, value },
        }) => {
          return {
            servingId,
            name,
            automaticCalculation,
            size: { unitId, value },
          };
        }
      );

    try {
      setTrue();

      if (isEditMode) {
        if (isTranslator) {
          const updateServings = servingDefinitions.map(serving => {
            if (serving.servingId === selectedServingId) {
              return {
                ...serving,
                name: nutritionServingFormData?.servingName,
              };
            }
            return { ...serving };
          });

          const dataJson = JSON.stringify({
            nutrientFamilies,
            servingDefinitions: updateServings,
          });
          await saveTemplateBlock?.(dataJson);
        } else {
          const updatedServings = servingsWithoutDefault.map(serving => {
            if (serving.servingId === selectedServingId) {
              return {
                servingId: serving.servingId,
                name: nutritionServingFormData?.servingName,
                automaticCalculation:
                  nutritionServingFormData?.automaticCalculation,
                size: {
                  unitId: selectedUnit?.id,
                  value: nutritionServingFormData?.servingSize,
                },
              };
            }

            return serving;
          });

          await extraActions?.onUpdateNutritionServing({
            servingData: updatedServings,
          });
        }
      } else {
        const newServingData: NutritionServingData = {
          servingId: v4(),
          name: nutritionServingFormData?.servingName,
          automaticCalculation: nutritionServingFormData?.automaticCalculation,
          size: {
            unitId: selectedUnit?.id,
            value: nutritionServingFormData?.servingSize,
          },
        };

        const updatedServings = [...servingsWithoutDefault, newServingData];
        await extraActions?.onUpdateNutritionServing({
          servingData: updatedServings,
        });
      }
      resetFormAndCloseModal();
    } catch (error) {
    } finally {
      setFalse();
    }
  };

  const onSecondaryButtonClick = () => {
    resetFormAndCloseModal();
  };

  const onEditServing = ({
    servingId,
    name,
    size,
    automaticCalculation,
  }: NutritionServingData) => {
    setIsModalOpen(true);
    setIsEditMode(true);
    setNutritionServingFormData({
      servingName: name,
      servingSize: size.value,
      automaticCalculation,
    });
    setSelectedServingId(servingId);

    const selectedRefList = referenceList?.find(
      item => item.id === size.unitId
    );

    setSelectedUnit(selectedRefList);
  };

  return {
    isModalOpen,
    setIsModalOpen,
    nutritionServingFormData,
    setNutritionServingFormData,
    setFormErrors,
    handleServingNameChange,
    handleServingSizeChange,
    handleAutomaticCalculationChange,
    getIsPrimaryModalButtonDisabled,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    isServingAddingOrUpdating,
    selectedUnit,
    isEditMode,
    onEditServing,
    onClearUnit: () => setSelectedUnit({}),
    isCalculationToggleDisabled: !hasUpdatePermission,
    items: isLoading
      ? [{ label: formatMessage(generalMessages.loading), disabled: true }]
      : referenceList.map(({ text, id }) => ({
          label: text,
          onClick: onChangeUnit({ text, id }),
        })),
    buttonProps: {
      loading: isLoading,
      dropdownProps: {
        onVisibleChange,
      },
    },
  };
};

export default useNutritionServingForm;
