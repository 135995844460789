import TranslationCompletedToggle from "./components/TranslationCompletedToggle";
import TranslationManagementContent from "./components/TranslationManagementContent";
import TranslationManagementFooter from "./components/TranslationManagementFooter";
import TranslationOnHold from "./components/TranslationOnHold";

const TranslationManagement = {
  Content: TranslationManagementContent,
  Footer: TranslationManagementFooter,
  TranslationCompletedToggle,
  TranslationOnHold,
};

export default TranslationManagement;
