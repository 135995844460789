import { useEffect, useState } from "react";
import {
  fetchChapterRecipe,
  fetchSpecificationRecipe,
  sortChapterRecipe,
  sortMaterialRecipe,
  sortSpecificationChapterRecipe,
  updateChapterRecipe,
} from "apis/SPEC";
import { MaterialIngredientData, MaterialRecipeData } from "models";
import { prepareMaterialRecipe } from "dtos/material";
import { useAppDispatch } from "store";
import { getChapter } from "store/chapterForm/asyncActions";
import { CHAPTER_TYPES_API, MATERIAL_TYPES } from "utils/constants";
import { getMaterialApiType } from "utils/library";
import { useRecipeProps } from "./types";
import { Sorter } from "types/pagination";

const useRecipe = ({
  id,
  chapterType,
  isSpecContext,
  shouldCallApi,
  recipeType,
}: useRecipeProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [recipeIngredients, setRecipeIngredients] = useState<
    MaterialIngredientData[]
  >([]);
  const [recipeSorter, setRecipeSorter] = useState<Sorter>(null);
  const [totalPercentageOfRecipeIngredients, setTotalPercentage] = useState<
    MaterialRecipeData["totalPercentage"]
  >(null);

  const dispatch = useAppDispatch();

  const recipeFetcher = isSpecContext
    ? fetchSpecificationRecipe
    : fetchChapterRecipe;

  const sortRecipeFetcher = isSpecContext
    ? sortSpecificationChapterRecipe
    : sortChapterRecipe;

  const fetchRecipeIngredients = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const { data } = await recipeFetcher({
        id,
        chapterType,
      });

      const recipe = prepareMaterialRecipe(data);

      setRecipeIngredients(recipe.ingredients);
      setRecipeSorter(recipe?.sortingFilter);
      setTotalPercentage(recipe?.totalPercentage);
    } catch (error) {
      setHasError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const updateRecipeFiles = async ({ fileIds }): Promise<void> => {
    if (!id) {
      return;
    }

    try {
      setIsLoading(true);
      setHasError(false);

      await updateChapterRecipe({
        id,
        chapterType,
        fileIds,
      });

      dispatch(getChapter());
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const sortRecipeIngredient = async (sort: Sorter) => {
    try {
      setIsLoading(true);
      setHasError(false);

      const { data } = await sortRecipeFetcher({
        id: id,
        chapterType,
        ...sort,
      });

      const recipe = prepareMaterialRecipe(data);

      setRecipeIngredients(recipe?.ingredients);
      setRecipeSorter(recipe?.sortingFilter);
      setTotalPercentage(recipe?.totalPercentage);
    } catch (error) {
      setHasError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const sortMaterialRecipeIngredients = async (sort: Sorter) => {
    if (!recipeType) {
      return;
    }

    try {
      setIsLoading(true);
      setHasError(false);

      const { data } = await sortMaterialRecipe({
        materialId: id,
        materialType: getMaterialApiType({
          materialType: MATERIAL_TYPES.COMPOSITE,
          recipeType,
        }),
        ...sort,
      });

      const recipe = prepareMaterialRecipe(data);

      setRecipeIngredients(recipe?.ingredients);
      setRecipeSorter(recipe?.sortingFilter);
      setTotalPercentage(recipe?.totalPercentage);
    } catch (error) {
      setHasError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chapterType === CHAPTER_TYPES_API.production && shouldCallApi) {
      fetchRecipeIngredients();
    }
  }, [chapterType, shouldCallApi]);

  return {
    isLoading,
    hasError,
    recipeIngredients,
    fetchRecipeIngredients,
    sortRecipeIngredient,
    recipeSorter,
    sortMaterialRecipeIngredients,
    totalPercentageOfRecipeIngredients,
    updateRecipeFiles,
  };
};

export default useRecipe;
