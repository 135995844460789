import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import classNames from "classnames";
import { Col, Row } from "antd";
import { EmptyState, Search } from "@trace-one/design-system";
import DocumentGrid from "../DocumentGrid";
import Spin from "components/Spin";
import WarningFallback from "components/WarningFallback";
import TableHeader from "components/TableHeader";
import InformationPanel from "components/InformationPanel";
import Filters from "components/Filters";
import { useDocumentSummaryPanel } from "./hooks";
import { isListEmpty } from "utils/general";
import { DOCUMENT_SUMMARY_TABS } from "utils/constants";
import { FileViewModel } from "viewModels";
import { DocumentSummaryPanelContext } from "./contexts";
import { DocumentSummaryPanelContextProps } from "./contexts/DocumentSummaryPanelContext";
import { documentMessages, filterMessages, generalMessages } from "messages";
import { ChapterTypeData, SpecificationDocumentSummaryChapter } from "models";
import { ChapterStructureFormData } from "types/library";
import styles from "./styles.module.less";

export type DocumentSummaryPanelProps = {
  files: FileViewModel[];
  tradeItemName: string;
  supplierName: string;
  tabKey?: DOCUMENT_SUMMARY_TABS;
  chapters?: SpecificationDocumentSummaryChapter[];
  filteredChapterTypes?: ChapterTypeData[];
  specificationStructure?: ChapterStructureFormData[];
};

const DocumentSummaryPanel: React.FC<DocumentSummaryPanelProps> = ({
  tradeItemName,
  supplierName,
  files,
  tabKey,
  chapters,
  filteredChapterTypes,
  specificationStructure,
}) => {
  const { formatMessage } = useIntl();

  const [selectedFiles, setSelectedFiles] = useState<FileViewModel[]>([]);
  const [pageItemCount, setPageItemCount] = useState<number>(0);

  const {
    searchAndFilterResult,
    onSearch,
    isDocumentFiltersInitialState,
    loadingFileTypes,
    documentTypeFilterItems,
    documentFilters,
    createdByUserNameFilterItems,
  } = useDocumentSummaryPanel({
    files,
  });

  const gridItems = isDocumentFiltersInitialState
    ? files
    : searchAndFilterResult;

  const documentSummaryPanelContextValue: DocumentSummaryPanelContextProps = useMemo(
    () => ({
      selectedItems: selectedFiles,
      setSelectedItems: setSelectedFiles,
      setPageItemCount,
      zipFileName: `${tradeItemName}-${supplierName}`,
    }),
    [tradeItemName, supplierName, selectedFiles]
  );

  if (loadingFileTypes) {
    return <Spin className="mt-4" />;
  }

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <DocumentSummaryPanelContext.Provider
        value={documentSummaryPanelContextValue}
      >
        <Row className="mt-3">
          <Col span={12}>
            <Search
              placeholder={formatMessage(documentMessages.searchPlaceholder)}
              onSearch={onSearch("name")}
              data-testid="search"
            />
          </Col>
        </Row>
        <Row className="mt-1" gutter={[8, 0]}>
          <Col>
            <Filters.Document
              items={documentTypeFilterItems}
              onChange={onSearch("fileTypes")}
              value={documentFilters.fileTypes}
              title={formatMessage(filterMessages.documentType)}
            />
          </Col>
          <Col>
            <Filters.Document
              items={createdByUserNameFilterItems}
              onChange={onSearch("createdByUserIds")}
              value={documentFilters.createdByUserIds}
              title={formatMessage(filterMessages.uploadedBy)}
            />
          </Col>
          {[
            DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_CHAPTERS,
            DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_SPECIFICATION,
          ].includes(tabKey) && (
            <Col data-testid="filter-chapter-section">
              <Filters.ChapterSection
                chapters={chapters}
                filteredChapterTypes={filteredChapterTypes}
                onChange={onSearch("fileIds")}
                specificationStructure={specificationStructure}
                tabKey={tabKey}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          <Col span={24}>
            {isListEmpty(gridItems) ? (
              <Row justify="center">
                <EmptyState
                  heading={formatMessage(generalMessages.noItemFound)}
                />
              </Row>
            ) : (
              <Col>
                <Row>
                  <TableHeader
                    listResult={formatMessage(
                      generalMessages.listingResultNumber,
                      {
                        current: pageItemCount,
                        total: gridItems.length,
                        libraryObjectType: formatMessage(
                          documentMessages.documents
                        )?.toLowerCase(),
                      }
                    )}
                    color="grey-4"
                  />
                </Row>
                <Row gutter={[24, 0]}>
                  <Col
                    span={16}
                    className={classNames(
                      styles[
                        "document-summary-panel__scrollable-area--container"
                      ],
                      "d-flex",
                      "flex-direction-column",
                      "jc-space-between"
                    )}
                  >
                    <DocumentGrid gridItems={gridItems} />
                  </Col>
                  <Col
                    span={8}
                    data-testid="document-information-panel"
                    className={
                      styles[
                        "document-summary-panel__scrollable-area--container"
                      ]
                    }
                  >
                    <InformationPanel items={selectedFiles} />
                  </Col>
                </Row>
              </Col>
            )}
          </Col>
        </Row>
      </DocumentSummaryPanelContext.Provider>
    </ErrorBoundary>
  );
};

export default DocumentSummaryPanel;
