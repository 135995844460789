import { Divider, Heading } from "@trace-one/design-system";
import RequirementDocumentContent from "pages/Specification/components/Details/components/SelectedChapter/components/Sections/components/Section/components/RequirementsContent/components/RequirementDocumentContent";
import { useEffect } from "react";
import { useFilesInfo } from "hooks";
import { useChapterRequirementDetails } from "pages/Specification/components/Details/components/SelectedChapter/components/Sections/components/Section/components/RequirementsContent/hooks";
import { isLibraryRequirementTypeText } from "utils/library";
import styles from "./styles.module.less";
import { SpecificationChapterRequirementData } from "models";

export interface RequirementContentProps {
  requirement: SpecificationChapterRequirementData;
}

const RequirementContent: React.FC<RequirementContentProps> = ({
  requirement,
}) => {
  const {
    requirementDetails,
    getRequirementDetails,
  } = useChapterRequirementDetails({
    requirementId: requirement?.requirementId,
    type: requirement?.type,
  });

  useEffect(() => {
    getRequirementDetails();
  }, []);

  const {
    files: [currentFile],
  } = useFilesInfo({ fileIds: [requirementDetails?.fileId] });

  return (
    <div className={styles.wrapper}>
      <Heading size="xxs">{requirement.name}</Heading>
      <Divider color="grey-2" height={16} />
      {isLibraryRequirementTypeText(requirement.type) ? (
        <div
          dangerouslySetInnerHTML={{
            __html: requirementDetails?.content,
          }}
        />
      ) : (
        <RequirementDocumentContent
          file={currentFile}
          requirementDetails={requirementDetails}
        />
      )}
    </div>
  );
};

export default RequirementContent;
