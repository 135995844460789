import React, { useEffect, useRef, useState } from "react";
import { BadgeList, Card } from "@trace-one/design-system";
import classNames from "classnames";
import { getIngredientNameProps } from "./utils";
import { Dictionary } from "types/general";
import styles from "../../styles.module.less";

export type SupplierCompanyCardProps = {
  name: string;
  code: string;
  ingredientIds: string[];
  materialId?: string;
  ingredientNameDictionary: Dictionary<string>;
};

const SupplierCompanyCard: React.FC<SupplierCompanyCardProps> = ({
  name,
  code,
  ingredientIds,
  materialId,
  ingredientNameDictionary,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const ingredientNameRef = useRef(null);
  const cardRef = useRef(null);

  const { ingredientName, sortedIngredientNames } = getIngredientNameProps({
    ingredientIds,
    ingredientNameDictionary,
    materialId,
  });

  useEffect(() => {
    const checkOverflow = () => {
      if (ingredientNameRef.current) {
        setIsOverflowing(
          ingredientNameRef.current.scrollHeight >
            ingredientNameRef.current.clientHeight
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <Card
      title={name}
      // \u00A0 to be removed when card component is updated
      subtitle={code ?? "\u00A0"}
      type="small"
      illustrationName="supplier"
      illustrationColor="primary"
      className={classNames(styles["supplier-card"], "overflow-wrap")}
      innerRef={cardRef}
    >
      <div className="d-flex ai-flex-end">
        <span
          ref={ingredientNameRef}
          className={styles["supplier-card__ingredient-name"]}
        >
          {ingredientName}
        </span>
        {isOverflowing && (
          <BadgeList options={sortedIngredientNames} trigger="hover" />
        )}
      </div>
    </Card>
  );
};

export default SupplierCompanyCard;
