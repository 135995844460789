import { fetchUsers } from "apis/CUMD";
import { FileData } from "models";
import { useEffect, useState } from "react";
import { getFilesInfo } from "utils/files";
import { isListEmpty } from "utils/general";
import { FileViewModel } from "viewModels";

const useFilesInfo = ({
  fileIds,
  shouldHaveUserInformation,
  useV2,
}: {
  fileIds: string[];
  shouldHaveUserInformation?: boolean;
  useV2?: boolean;
}) => {
  const [files, setFiles] = useState<FileData[]>([]);
  const [filesWithUserInfo, setFilesWithUserInfo] = useState<FileViewModel[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getFiles = async () => {
      setIsLoading(true);
      try {
        const files = await getFilesInfo({ fileIds, useV2 });
        setFiles(files);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };

    if (!isListEmpty(fileIds)) {
      getFiles();
    } else {
      setFiles([]);
    }
  }, [JSON.stringify(fileIds)]);

  const getUserInfo = async (ids: string[]) => {
    try {
      setIsLoading(true);
      const { data: users } = await fetchUsers({ ids });

      const usersMap = users?.reduce(
        // @ts-ignore
        (acc, { userId, userName, owningCompanyName }) => {
          return {
            ...acc,
            [userId]: {
              userName,
              owningCompanyName,
            },
          };
        },
        {}
      );

      const filesWithUserInfo = files.map(file => {
        const user = usersMap[file.createdByUserId];
        if (user) {
          return {
            ...file,
            createdByUserName: user.userName,
            owningCompanyName: user.owningCompanyName,
          };
        }
        return file;
      });

      setFilesWithUserInfo(filesWithUserInfo);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isListEmpty(files) && shouldHaveUserInformation) {
      const uniqueUserIds = Array.from(
        new Set(files.map(file => file.createdByUserId))
      );

      getUserInfo(uniqueUserIds);
    }
  }, [files, shouldHaveUserInformation]);

  return { files, filesWithUserInfo, isLoading };
};

export default useFilesInfo;
