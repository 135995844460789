import { useIntl } from "react-intl";
import classNames from "classnames";
import { Illustration, Typography } from "@trace-one/design-system";
import { translationManagementMessages } from "messages";
import styles from "./styles.module.less";

const TranslationOnHold = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={classNames(styles["translation-on-hold"])}>
      <Illustration
        name="locked"
        color="grey"
        className={classNames("mt-3", "mb-3")}
      />
      <Typography variant="heading-s" color="grey-5">
        {formatMessage(translationManagementMessages.translationOnHoldTitle)}
      </Typography>
      <Typography variant="body-regular" color="grey-5" className="mt-0-5">
        {formatMessage(
          translationManagementMessages.translationOnHoldDescription
        )}
        <br />
        {formatMessage(
          translationManagementMessages.translationOnHoldDescriptionStartOver
        )}
      </Typography>
    </div>
  );
};

export default TranslationOnHold;
