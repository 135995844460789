import AllergensArrayFieldTemplate from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/AllergensArrayFieldTemplate";
import FreeFromAllergensArrayFieldTemplate from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/FreeFromAllergensArrayFieldTemplate";
import {
  ArraySchema,
  FormContext,
} from "components/SpecificationSection/types";
import { UiSchemaInfo } from "types/general";

export type RecipeAllergenDetailsProps = {
  formContext?: FormContext;
  crossContaminationFormData?: any;
  crossContaminationUISchema?: UiSchemaInfo;
  freeFromAllergenFormData?: any;
  freeFromAllergenUISchema?: UiSchemaInfo;
  freeFromAllergenSchema?: ArraySchema;
};

const RecipeAllergenDetails: React.FC<RecipeAllergenDetailsProps> = ({
  crossContaminationFormData,
  crossContaminationUISchema,
  formContext,
  freeFromAllergenFormData,
  freeFromAllergenSchema,
  freeFromAllergenUISchema,
}) => {
  return (
    <div className="mt-4">
      {/* @ts-ignore */}
      <AllergensArrayFieldTemplate
        formContext={formContext}
        formData={crossContaminationFormData}
        uiSchema={crossContaminationUISchema}
      />

      {/* @ts-ignore */}
      <FreeFromAllergensArrayFieldTemplate
        formContext={formContext}
        schema={freeFromAllergenSchema}
        uiSchema={freeFromAllergenUISchema}
        formData={freeFromAllergenFormData}
      />
    </div>
  );
};

export default RecipeAllergenDetails;
