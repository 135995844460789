import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCompanies } from "apis/CUMD";
import { fetchSpecification } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { useUserInfo } from "hooks";
import { SpecificationData } from "models";
import { UseSpecificationDetailsProps } from "./types";
import { CompaniesDetail } from "types/general";

const useSpecificationDetails = ({
  specificationId,
}: UseSpecificationDetailsProps) => {
  const [specification, setSpecification] = useState<SpecificationData>({});
  const [companyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(true);
  const [companiesDetail, setCompaniesDetail] = useState<CompaniesDetail>({
    supplierName: "",
    retailerName: "",
  });

  const languageCode = useSelector(selectLanguageCode);

  const { isRetailer } = useUserInfo();

  const getSpecification = (withLoading: boolean) => async () => {
    try {
      setIsLoading(withLoading);
      setHasError(false);

      const { data } = await fetchSpecification({
        id: specificationId,
        languageCode,
      });

      setSpecification(data);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSpecification(true)();
  }, [specificationId]);

  useEffect(() => {
    const companiesDetail = {
      supplierName: "",
      retailerName: "",
    };

    const getCompanies = async () => {
      try {
        const { data } = await fetchCompanies({
          ids: [specification.supplierId, specification.ownerCompanyId],
        });

        data.forEach(company => {
          if (company.companyId === specification.supplierId) {
            companiesDetail["supplierName"] = company.companyDisplayName;
          } else {
            companiesDetail["retailerName"] = company.companyDisplayName;
          }
        });

        if (isRetailer) {
          if (specification.supplierId) {
            setCompanyName(companiesDetail.supplierName);
          }
        } else {
          if (specification.ownerCompanyId) {
            setCompanyName(companiesDetail.retailerName);
          }
        }

        setCompaniesDetail(companiesDetail);
      } catch (error) {}
    };

    if (specification.supplierId && specification.ownerCompanyId) {
      getCompanies();
    }
  }, [specification.supplierId, specification.ownerCompanyId]);

  return {
    specification,
    companyName,
    isLoading,
    hasError,
    refetchSpecification: getSpecification(false),
    companiesDetail,
  };
};

export default useSpecificationDetails;
