import { useState, useEffect } from "react";
import useBoolean from "hooks/useBoolean";
import { isListEmpty } from "utils/general";
import { fetchMaterialsByIds, fetchMaterialsByIdsAndType } from "apis/SPEC";
import { MaterialData, MaterialListItemData } from "models";
import { LibraryItemApiType } from "types/library";

export type UseMaterialCollectionProps = {
  materialIds: string[];
  materialType?: LibraryItemApiType;
};

const useMaterialCollection = ({
  materialIds,
  materialType,
}: UseMaterialCollectionProps) => {
  const [materials, setMaterials] = useState<MaterialListItemData[]>([]);
  const [materialsByType, setMaterialsByType] = useState<MaterialData[]>([]);

  const {
    value: loading,
    setTrue: setLoadingTrue,
    setFalse: setLoadingFalse,
  } = useBoolean(false);
  const { value: error, setTrue: setErrorTrue } = useBoolean(false);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        setLoadingTrue();
        const { data } = await fetchMaterialsByIds({ materialIds });

        setMaterials(data);
      } catch {
        setErrorTrue();
      } finally {
        setLoadingFalse();
      }
    };

    const fetchMaterialsByType = async () => {
      try {
        setLoadingTrue();
        const { data } = await fetchMaterialsByIdsAndType({
          materialIds,
          materialType,
        });

        setMaterialsByType(data);
      } catch {
        setErrorTrue();
      } finally {
        setLoadingFalse();
      }
    };

    if (!isListEmpty(materialIds)) {
      if (materialType) {
        fetchMaterialsByType();
      } else {
        fetchMaterials();
      }
    }
  }, [JSON.stringify(materialIds), materialType]);

  return { materials, materialsByType, loading, error };
};

export default useMaterialCollection;
