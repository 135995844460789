import { useContext } from "react";
import RequirementDocumentContent from "../RequirementDocumentContent";
import ExpandableCardWithToggleAndDelete from "components/ExpandableCardWithToggleAndDelete";
import Spin from "components/Spin";
import { useFilesInfo } from "hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useChapterRequirementDetails } from "../../hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isLibraryRequirementTypeText } from "utils/library";
import { RequirementCardContentProps } from "./types";
import styles from "./styles.module.less";
import { RequirementsContentContext } from "../../contexts";

const RequirementCardContent: React.FC<RequirementCardContentProps> = ({
  requirement,
}) => {
  const { onValidate, onRemove, isTargetMode } = useContext(
    RequirementsContentContext
  );

  const {
    requirementDetails,
    getRequirementDetails,
  } = useChapterRequirementDetails({
    requirementId: requirement.requirementId,
    type: requirement.type,
  });

  const {
    files: [currentFile],
    isLoading: isFileLoading,
  } = useFilesInfo({ fileIds: [requirementDetails?.fileId] });

  const { getTranslationForKey } = useFormSchemaTranslation();

  const showShowSpinner =
    !requirementDetails || (requirementDetails.fileId && isFileLoading);

  return (
    <ExpandableCardWithToggleAndDelete
      title={requirement.name}
      onEntered={getRequirementDetails}
      className={styles.requirementsListItem}
      removeConfirmationText={getTranslationForKey(
        "unlinkRequirementConfirmation"
      )}
      isChecked={requirement.validated}
      {...(onRemove && {
        onRemove: () => onRemove(requirement.requirementId),
      })}
      {...(onValidate && {
        onToggle: () =>
          onValidate({
            requirementId: requirement.requirementId,
            requirementType: requirement.type,
          }),
      })}
      isTargetMode={isTargetMode}
      libraryObjectType={LIBRARY_OBJECT_TYPES.REQUIREMENTS}
    >
      {showShowSpinner ? (
        <Spin size="small" />
      ) : (
        <>
          {isLibraryRequirementTypeText(requirement.type) ? (
            <div
              dangerouslySetInnerHTML={{
                __html: requirementDetails?.content,
              }}
            />
          ) : (
            <RequirementDocumentContent
              file={currentFile}
              requirementDetails={requirementDetails}
            />
          )}
        </>
      )}
    </ExpandableCardWithToggleAndDelete>
  );
};

export default RequirementCardContent;
