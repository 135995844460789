export const getProgressBarColor = (percentage: number = 0) => {
  let color: string;

  if (percentage >= 0 && percentage <= 33) {
    color = "orange";
  } else if (percentage >= 34 && percentage <= 66) {
    color = "blue";
  } else {
    color = "green";
  }

  return color;
};
