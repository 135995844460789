import { COMPANY_ACTIVITY_ID } from "utils/constants";
import { GetProductOriginParamsProps } from "./types";

export const getProductOriginParams = <T extends GetProductOriginParamsProps>({
  type,
  owningCompanyId,
}: T) => {
  switch (type) {
    case "tradeItemSuppliers":
      return {
        withoutSpecificationsOnly: true,
      };
    case "suppliers":
      return {
        companyActivityId: COMPANY_ACTIVITY_ID.SUPPLIER,
        relatedOwnerCompanyId: owningCompanyId,
      };
    case "translators":
      return {
        companyActivityId: COMPANY_ACTIVITY_ID.TRANSLATOR,
        relatedOwnerCompanyId: owningCompanyId,
        isCompanyRelationStatusEnabled: true,
      };
    default:
      return {
        companyActivityId: COMPANY_ACTIVITY_ID.RETAILER,
        relatedAssociatedCompanyId: owningCompanyId,
      };
  }
};

export const getPlaceholderMessageKey = (type: string) => {
  switch (type) {
    case "tradeItemSuppliers":
    case "suppliers":
      return "selectSuppliersPlaceholder";
    default:
      return "selectRetailersPlaceholder";
  }
};

export const getEmptyDescriptionKey = (type: string) => {
  switch (type) {
    case "tradeItemSuppliers":
    case "suppliers":
      return "noSuppliers";
    default:
      return "noRetailers";
  }
};

export const getOptions = ({ options, selectedValues }) => {
  return options
    .filter(option => {
      const index = selectedValues.findIndex(value => {
        return value === option["value"];
      });

      return index > -1 ? false : true;
    })
    .map(user => ({
      name: user["name"],
      value: user["value"],
    }));
};
