import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { CHAPTER_TYPES_URL_API } from "utils/constants";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectTemplatesType,
} from "utils/library";

export const getLibraryItemInfo = ({
  type,
  libraryItemDetails,
}: {
  type: string;
  libraryItemDetails: LibraryItemDetails;
}) => {
  if (isLibraryObjectChapterSubType(CHAPTER_TYPES_URL_API[type])) {
    return libraryItemDetails?.generalInfo;
  }
  if (
    isLibraryObjectTemplatesType(libraryItemDetails?.generalInformation?.type)
  ) {
    return {
      ...libraryItemDetails?.generalInfo,
      type: libraryItemDetails?.generalInformation?.type,
    };
  }

  if (isLibraryObjectClaimType(libraryItemDetails?.generalInformation?.type)) {
    return {
      ...libraryItemDetails,
      type: libraryItemDetails?.generalInformation?.type,
    };
  }
  return libraryItemDetails;
};
