export const fetchAll = async <T>({
  ids,
  dataFetcher,
  chunkSize = 100,
}: {
  ids: string[];
  dataFetcher: (ids: string[]) => Promise<T[]>;
  chunkSize?: number;
}): Promise<T[]> => {
  let allData: T[] = [];

  // Function to fetch data in chunks
  const fetchDataInChunks = async (idsChunk: string[]): Promise<void> => {
    const chunkData = await dataFetcher(idsChunk);
    allData = allData.concat(chunkData);
  };

  // Create an array of promises for each chunk of ids
  const promises = [];
  for (let i = 0; i < ids.length; i += chunkSize) {
    const chunk = ids.slice(i, i + chunkSize);
    promises.push(fetchDataInChunks(chunk));
  }

  await Promise.all(promises);

  return allData;
};
