import { fetchFilesByIds, fetchFilesByIdsV2 } from "apis/DOCUMENT";
import { FileData } from "models";
import { isListEmpty } from "./general";
import { AxiosResponse } from "axios";
import { DOCUMENT_SUMMARY_TABS } from "./constants";

export function formatFileSize(bytes) {
  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  let i = 0;

  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }

  return `${bytes.toFixed(2)} ${units[i]}`;
}

const fetchAllFiles = async (
  fileIds: string[],
  fileFetcher: ({
    ids,
  }: {
    ids: string[];
  }) => Promise<AxiosResponse<FileData[]>>
): Promise<FileData[]> => {
  const MAX_FILES_PER_REQUEST = 100;
  let allData: any[] = [];

  // function to fetch data in chunks
  const fetchDataInChunks = async (ids: string[]): Promise<void> => {
    const { data } = await fileFetcher({ ids });
    allData = allData.concat(data);
  };

  // Create an array of promises for each chunk of 100 file IDs
  const promises = [];
  for (let i = 0; i < fileIds.length; i += MAX_FILES_PER_REQUEST) {
    const chunk = fileIds.slice(i, i + MAX_FILES_PER_REQUEST);
    promises.push(fetchDataInChunks(chunk));
  }

  await Promise.all(promises);

  return allData;
};

export const getFilesInfo = async ({
  fileIds,
  initialFiles = [],
  useV2,
}: {
  fileIds: FileData["fileId"][];
  initialFiles?: FileData[];
  useV2?: boolean;
}): Promise<FileData[]> => {
  let files = initialFiles;
  const fileFetcher = useV2 ? fetchFilesByIdsV2 : fetchFilesByIds;

  if (!isListEmpty(fileIds)) {
    try {
      const allFiles = await fetchAllFiles(fileIds, fileFetcher);

      files = allFiles.map(
        ({
          fileId,
          fileName,
          fileSizeInBytes,
          fileTotalLength,
          fileTypeName,
          fileTypeId,
          createdByUserId,
          createdBy,
          ownerCompanyId,
          uploadedAt,
          uploadedBy,
          fileExtension,
        }) => ({
          id: fileId,
          uid: fileId,
          name: fileName,
          size: fileSizeInBytes ?? fileTotalLength,
          fileTypeId,
          fileType: fileTypeName,
          createdByUserId: createdByUserId ?? createdBy,
          ownerCompanyId,
          uploadedAt,
          uploadedBy,
          fileExtension,
        })
      );
    } catch (_) {}
  }

  return files;
};

export const downloadFileWithCustomName = ({
  data,
  fileName,
  fileType,
}: {
  data: BlobPart;
  fileType: string;
  fileName?: string;
}) => {
  if (!data) return;

  const file = new Blob([data], { type: `application/${fileType}` });
  const fileURL = URL.createObjectURL(file);

  const link = document.createElement("a");
  link.href = fileURL;
  link.download = `${fileName ? fileName : "download"}.${fileType}`;
  link.target = "_blank";
  link.click();

  // Clean up by revoking the object URL
  URL.revokeObjectURL(fileURL);
};

export const getFileNameWithoutExtension = (fileName: FileData["name"]) => {
  if (!fileName) {
    return "";
  }

  const lastDotIndex = fileName.lastIndexOf(".");

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return fileName;
  }

  return fileName.substring(0, lastDotIndex);
};

export const isTabLinkedToChapters = (tabKey: DOCUMENT_SUMMARY_TABS) =>
  tabKey === DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_CHAPTERS;

export const isTabLinkedToSpecification = (tabKey: DOCUMENT_SUMMARY_TABS) =>
  tabKey === DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_SPECIFICATION;
