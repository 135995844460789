import { Row, Col } from "antd";
import { Button, Heading } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { TableHeaderProps } from "./types";
import generalMessages from "messages/general";
import styles from "./styles.module.less";

const TableHeader: React.FC<TableHeaderProps> = ({
  listResult,
  tableActions,
  extraComponent,
  color = "primary",
}) => {
  return (
    <div className={styles["table-header"]}>
      <Heading size="xxs" color={color}>
        {listResult}
      </Heading>
      <Row align="middle" gutter={[40, 0]}>
        {extraComponent}
        {!!tableActions && (
          <Col>
            <Button
              type="tertiary"
              iconPlacement="right"
              iconName="kebab"
              disabled={!tableActions.length}
              className={"table-header-actions-icon"}
            >
              <FormattedMessage {...generalMessages.actions} />
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TableHeader;
