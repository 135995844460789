import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Paragraph } from "@trace-one/design-system";
import { Form } from "antd";
import FormCard from "../../../FormCard";
import EmptyState from "components/Library/components/EmptyState";
import { SubstanceSelect } from "components";
import { useSubstanceInformation } from "hooks";
import { MaterialType } from "types/library";
import { isMaterialAdditiveType } from "utils/library";
import { isObjectEmpty } from "utils/general";
import { messages, substanceInformationFormMessages } from "./messages";
import libraryMessages from "messages/library";
import { prepareSubstanceFormDataFromApi } from "dtos/material";
import { MaterialData } from "models";
import styles from "./styles.module.less";
import { AREA_CATEGORY_TYPES } from "utils/constants";

const SubstanceInformation = ({
  materialType,
  displayActions,
  substanceId,
}: {
  materialType: MaterialType;
  substanceId?: MaterialData["substanceId"];
  displayActions?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const {
    isLoading,
    options,
    selectedSubstance,
    onSearch,
    onSelect,
    onClear,
    onDropdownVisibleChange,
  } = useSubstanceInformation({
    areaCategory: AREA_CATEGORY_TYPES[materialType],
    substanceId,
    shouldUpdateMaterialDetails: true,
  });

  const formData = prepareSubstanceFormDataFromApi(selectedSubstance);

  const {
    placeholder,
    substanceInDescription,
    emptyStateDescription,
  } = useMemo(
    () => ({
      placeholder: isMaterialAdditiveType(materialType)
        ? formatMessage(messages.selectAdditivePlaceholder)
        : formatMessage(messages.selectProcessingAidPlaceholder),
      emptyStateDescription: isMaterialAdditiveType(materialType)
        ? formatMessage(
            displayActions
              ? messages.selectAdditiveEmptyState
              : messages.noAdditive
          )
        : formatMessage(
            displayActions
              ? messages.selectProcessingAidEmptyState
              : messages.noProcessingAid
          ),
      substanceInDescription: isMaterialAdditiveType(materialType)
        ? formatMessage(
            libraryMessages.typeAdditiveOptionLabel
          ).toLocaleLowerCase()
        : formatMessage(
            libraryMessages.typeProcessingAidOptionLabel
          ).toLocaleLowerCase(),
    }),
    [materialType]
  );

  return (
    <>
      <SubstanceSelect
        options={options}
        onSearch={onSearch}
        onChange={onSearch}
        onSelect={onSelect}
        onClear={onClear}
        onDropdownVisibleChange={onDropdownVisibleChange}
        materialType={materialType}
        placeholder={placeholder}
        isLoading={isLoading}
        displayActions={displayActions}
        selectedSubstance={selectedSubstance}
        label={formatMessage(messages.standardizedNameLabel)}
      />
      <FormCard
        title={formatMessage(
          isMaterialAdditiveType(materialType)
            ? messages.additiveInformationCardTitle
            : messages.processingAidInformationCardTitle
        )}
        name="substanceInformation"
      >
        {isObjectEmpty(formData) ? (
          <EmptyState description={emptyStateDescription} />
        ) : (
          <>
            <div className={styles.cardDescription}>
              <Paragraph color="grey-4">
                {formatMessage(messages.cardDescription, {
                  substance: substanceInDescription,
                })}
              </Paragraph>
            </div>
            <Form
              name="substance-information-form"
              layout="horizontal"
              form={form}
              labelAlign="left"
              labelCol={{ span: 4 }}
              initialValues={{
                ...formData,
              }}
            >
              <div className={styles.formItemsWrapper}>
                {formData?.eNumber ? (
                  <Form.Item
                    name="eNumber"
                    label={formatMessage(
                      substanceInformationFormMessages.eNumber
                    )}
                  >
                    <Paragraph data-test-id="e-number">
                      {formData?.eNumber}
                    </Paragraph>
                  </Form.Item>
                ) : null}
                {formData?.casNumbers ? (
                  <Form.Item
                    name="casNumbers"
                    label={formatMessage(
                      substanceInformationFormMessages.casNumbers
                    )}
                  >
                    <Paragraph data-test-id="cas-numbers">
                      {formData?.casNumbers}
                    </Paragraph>
                  </Form.Item>
                ) : null}
                {formData?.ceNumbers ? (
                  <Form.Item
                    name="ceNumbers"
                    label={formatMessage(
                      substanceInformationFormMessages.ceNumbers
                    )}
                  >
                    <Paragraph data-test-id="ce-numbers">
                      {formData?.ceNumbers}
                    </Paragraph>
                  </Form.Item>
                ) : null}
                {formData?.synonyms ? (
                  <Form.Item
                    name="synonyms"
                    label={formatMessage(
                      substanceInformationFormMessages.synonyms
                    )}
                  >
                    <Paragraph data-test-id="synonyms">
                      {formData?.synonyms}
                    </Paragraph>
                  </Form.Item>
                ) : null}
                {formData?.molecularFormula ? (
                  <Form.Item
                    name="molecularFormula"
                    label={formatMessage(
                      substanceInformationFormMessages.molecularFormula
                    )}
                  >
                    <Paragraph data-test-id="molecular-formula">
                      {formData?.molecularFormula}
                    </Paragraph>
                  </Form.Item>
                ) : null}
                {formData?.colorIndexes ? (
                  <Form.Item
                    name="colorIndexes"
                    label={formatMessage(
                      substanceInformationFormMessages.colorIndexes
                    )}
                  >
                    <Paragraph data-test-id="color-index">
                      {formData?.colorIndexes}
                    </Paragraph>
                  </Form.Item>
                ) : null}
                {formData?.iupacName ? (
                  <Form.Item
                    name="iupacName"
                    label={formatMessage(
                      substanceInformationFormMessages.iupacName
                    )}
                  >
                    <Paragraph data-test-id="iupac-name">
                      {formData?.iupacName}
                    </Paragraph>
                  </Form.Item>
                ) : null}
              </div>
            </Form>
          </>
        )}
      </FormCard>
    </>
  );
};

export default SubstanceInformation;
