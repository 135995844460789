import { Select } from "@trace-one/design-system";
import { Col, Form, Row } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { claimMessages } from "messages";
import { useHealthClaimConfiguration } from "./hooks";
import Spin from "components/Spin";
import ClaimSentenceAndLogo from "./ClaimSentenceAndLogo";
import { ErrorBoundary } from "react-error-boundary";
import WarningFallback from "components/WarningFallback";
import { selectClaimForm } from "store/claimForm/claimFormSlice";

const HealthClaimConfigureContent = () => {
  const { formatMessage } = useIntl();

  const { generalInfo } = useSelector(selectClaimForm);
  const { regulatoryHealthClaimId } = generalInfo;

  const {
    nutrientOptions,
    claimSentenceOptions,
    onNutrientSearch,
    onClaimChange,
    isLoading,
    selectedNutrientId,
    onNutrientClear,
    onNutrientChange,
    showNutrientError,
  } = useHealthClaimConfiguration();

  if (isLoading) {
    return (
      <Row align="middle">
        <Spin />
      </Row>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <Row>
        <Col md={14}>
          <Form.Item
            required
            name="nutrient"
            label={formatMessage(claimMessages.aNutrient)}
            rules={[{ required: true }]}
          >
            <Select
              isLoading={isLoading}
              options={nutrientOptions}
              placeholder={formatMessage(claimMessages.nutrientPlaceholder)}
              data-test-id="claim-nutrient"
              showSearch
              onSearch={onNutrientSearch}
              onChange={onNutrientChange}
              onClear={onNutrientClear}
              filterOption={false}
              error={showNutrientError}
              errorMessage={
                showNutrientError
                  ? formatMessage(claimMessages.nutrientMaxError)
                  : ""
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={14}>
          <Form.Item
            required
            label={formatMessage(claimMessages.claimSentenceTitle)}
            rules={[{ required: true }]}
            name="regulatoryHealthClaimId"
          >
            <Select
              options={claimSentenceOptions}
              placeholder={formatMessage(
                claimMessages.claimSentencePlaceholder
              )}
              disabled={!selectedNutrientId}
              data-test-id="claim-regulatoryHealthClaimId"
              onChange={onClaimChange}
              value={regulatoryHealthClaimId}
            />
          </Form.Item>
        </Col>
      </Row>
      <ClaimSentenceAndLogo
        claimLabel={formatMessage(claimMessages.additionalClaimSentences)}
        claimSentencePlaceholder={formatMessage(
          claimMessages.additionalClaimSentencesPlaceholder
        )}
        claimSentenceRequired={false}
      />
    </ErrorBoundary>
  );
};

export default HealthClaimConfigureContent;
