import { useIntl } from "react-intl";
import { LabeledValue } from "antd/lib/select";
import { TreeSelect } from "@trace-one/react-components";
import partDropdownMessages from "./messages";
import { CategoryTreeSelectProps } from "components/CategoryTreeSelect/types";
import { usePartDropdown } from "./hooks";

export interface PartDropdownProps
  extends Omit<CategoryTreeSelectProps, "value"> {
  value?: LabeledValue;
  name?: string;
  classificationId?: string;
}

const PartDropdown: React.FC<PartDropdownProps> = ({
  value,
  onChange: defaultOnChange,
  onDropdownVisibleChange: defaultOnDropdownVisibleChange,
  classificationId,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const {
    isLoading,
    valueWithLabel,
    treeData,
    onChange,
    onSearch,
    onDropdownVisibleChange,
    onLoadData,
    searchTerm,
    treeExpandedKeys,
    onTreeExpand,
  } = usePartDropdown({
    rawMaterialId: classificationId,
    value,
    onChange: defaultOnChange,
  });

  return (
    <TreeSelect
      {...rest}
      name={"part-dropdown"}
      placeholder={formatMessage(
        partDropdownMessages.rawMaterialDetailsPartPlaceholder
      )}
      loading={isLoading}
      treeExpandedKeys={treeExpandedKeys}
      loadData={onLoadData}
      value={valueWithLabel}
      treeData={treeData}
      searchValue={searchTerm}
      onChange={onChange}
      onSearch={onSearch}
      onTreeExpand={onTreeExpand}
      onDropdownVisibleChange={onDropdownVisibleChange}
      labelInValue
      filterTreeNode={false}
    />
  );
};

export default PartDropdown;
