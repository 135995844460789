import Container from "./components/Container";
import OwnersOrContacts from "./components/OwnersOrContacts";
import ProductTreeCategories from "./components/ProductTreeCategories";
import ProductTypes from "./components/ProductTypes";
import Statuses from "./components/Statuses";
import RequirementType from "./components/RequirementType";
import Document from "./components/Document";
import ChapterSection from "./components/ChapterSection";

const Filters = {
  Container,
  OwnersOrContacts,
  ProductTreeCategories,
  ProductTypes,
  Statuses,
  RequirementType,
  Document,
  ChapterSection,
};

export default Filters;
