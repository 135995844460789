import { useEffect, useState } from "react";
import { useMaterialCollection } from "hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isListEmpty } from "utils/general";
import { getUniqueFlatlistFromIngredients } from "dtos/material";
import { CharacteristicIngredientViewModel } from "viewModels";
import { CharacteristicIngredient } from "components/SpecificationSection/components/FormSchema/types";

export type UseTranslatorCharacteristicIngredientsProps = {
  characteristicIngredients: CharacteristicIngredient[];
};

const useTranslatorCharacteristicIngredients = ({
  characteristicIngredients,
}: UseTranslatorCharacteristicIngredientsProps) => {
  const [
    translatorCharacteristicIngredients,
    setTranslatorCharacteristicIngredients,
  ] = useState<CharacteristicIngredientViewModel[]>();

  const { materialIdsWithIngredients, materialIds } = (isListEmpty(
    characteristicIngredients
  )
    ? []
    : characteristicIngredients
  ).reduce(
    (acc, item) => {
      if (item.ingredientId) {
        acc.materialIdsWithIngredients.push(item?.materialId);
      }
      acc.materialIds.push(item?.materialId);

      return acc;
    },
    {
      materialIdsWithIngredients: [] as string[],
      materialIds: [] as string[],
    }
  );

  const { materials: libraryMaterials } = useMaterialCollection({
    materialIds: Array.from(new Set(materialIds)),
  });

  const { materialsByType: boughtComposites } = useMaterialCollection({
    materialIds: Array.from(new Set(materialIdsWithIngredients)),
    materialType: LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY,
  });

  useEffect(() => {
    const seenIngredientIds = new Set<string>();
    const boughtCompositeIngredients = boughtComposites?.reduce(
      (accumulator, item) => {
        const uniqueFlatListIngredients = getUniqueFlatlistFromIngredients(
          item?.recipe?.ingredients
        );

        uniqueFlatListIngredients.forEach(ingredient => {
          if (!seenIngredientIds.has(ingredient.ingredientId)) {
            seenIngredientIds.add(ingredient.ingredientId);
            accumulator.push({
              ingredientId: ingredient.ingredientId,
              materialId: ingredient.materialId,
              name: ingredient.name,
            });
          }
        });

        return accumulator;
      },
      [] as CharacteristicIngredientViewModel[]
    );

    const allIngredients = [
      ...(isListEmpty(libraryMaterials)
        ? []
        : libraryMaterials.map(material => ({
            materialId: material.id,
            ingredientId: undefined,
            name: material.name,
          }))),
      ...(isListEmpty(boughtCompositeIngredients)
        ? []
        : boughtCompositeIngredients),
    ];

    const ingredientsToBeDisplayed = characteristicIngredients?.map(
      ingredient => {
        const ingredientWholeData = allIngredients?.find(item =>
          ingredient.ingredientId
            ? item.ingredientId === ingredient.ingredientId
            : item.materialId === ingredient.materialId
        );

        return {
          ingredientId: ingredientWholeData?.ingredientId,
          materialId: ingredientWholeData?.materialId,
          name: ingredientWholeData?.name,
        };
      }
    );

    setTranslatorCharacteristicIngredients(ingredientsToBeDisplayed);
  }, [characteristicIngredients, libraryMaterials, boughtComposites]);

  return {
    translatorCharacteristicIngredients,
  };
};

export default useTranslatorCharacteristicIngredients;
