import { defineMessages } from "react-intl";

export const messages = defineMessages({
  additiveInformationCardTitle: {
    id: "library.materialForm.additiveInformationCard.title",
    defaultMessage: "Additive information",
  },
  processingAidInformationCardTitle: {
    id: "library.materialForm.processingAidInformationCard.title",
    defaultMessage: "Processing aid information",
  },
  cardDescription: {
    id: "library.materialForm.substanceInformationCard.description",
    defaultMessage:
      "This is the identity card of your {substance}. It provides up to date information such as identification numbers, names, molecular structure and more.",
  },
  selectAdditivePlaceholder: {
    id: "library.materialForm.additive.placeholder",
    defaultMessage: "Select an additive",
  },
  selectProcessingAidPlaceholder: {
    id: "library.materialForm.processingAid.placeholder",
    defaultMessage: "Select a processing aid",
  },
  noAdditive: {
    id: "library.materialForm.noAdditive",
    defaultMessage: "No additive",
  },
  noProcessingAid: {
    id: "library.materialForm.noProcessingAid",
    defaultMessage: "No processing aid",
  },
  selectAdditiveEmptyState: {
    id: "library.materialForm.additive.emptyState",
    defaultMessage: "Select an additive to display information.",
  },
  selectProcessingAidEmptyState: {
    id: "library.materialForm.processingAid.emptyState",
    defaultMessage: "Select a processing aid to display information.",
  },
  standardizedNameLabel: {
    id: "library.materialForm.standardizedName.label",
    defaultMessage: "Standardized name",
  },
});

export const substanceInformationFormMessages = defineMessages({
  eNumber: {
    id: "library.materialForm.substanceInformationForm.eNumber.label",
    defaultMessage: "E number",
  },
  casNumbers: {
    id: "library.materialForm.substanceInformationForm.casNumbers.label",
    defaultMessage: "CAS numbers",
  },
  ceNumbers: {
    id: "library.materialForm.substanceInformationForm.ceNumbers.label",
    defaultMessage: "CE numbers",
  },
  synonyms: {
    id: "library.materialForm.substanceInformationForm.synonyms.label",
    defaultMessage: "Synonyms",
  },
  molecularFormula: {
    id: "library.materialForm.substanceInformationForm.molecularFormula.label",
    defaultMessage: "Molecular formula",
  },
  colorIndexes: {
    id: "library.materialForm.substanceInformationForm.colorIndexes.label",
    defaultMessage: "Color indexes",
  },
  iupacName: {
    id: "library.materialForm.substanceInformationForm.iupacName.label",
    defaultMessage: "IUPAC name",
  },
});
