import { isUuidEmpty } from "utils/general";
import { MaterialRecipeIngredientViewModel } from "viewModels";

export const getCategoryIdsFromIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[] = []
) => {
  return ingredients.reduce(
    (previousState, { classificationId, functionId }) => {
      let newState = [...previousState];

      if (!isUuidEmpty(classificationId)) {
        newState.push(classificationId);
      }

      if (!isUuidEmpty(functionId)) {
        newState.push(functionId);
      }

      return newState;
    },
    []
  );
};
