import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Form } from "antd";
import { RcFile } from "antd/lib/upload";
import { isEqual } from "lodash";
import { Alert } from "@trace-one/design-system";
import ClaimGeneralInfo from "./components/ClaimGeneralInfo";
import ClaimConfigure from "./components/ClaimConfigure";
import DeleteAndKeepDraftModal from "../DeleteAndKeepDraftModal";
import { useClaimFormActions, useClaimFormInitialization } from "./hooks";
import { useLibraryFormActions } from "components/Library/hooks";
import { useErrorStatusCheck } from "hooks";
import { prepareClaimFormData } from "dtos/claim";
import {
  initialClaimForm,
  selectClaimForm,
  setClaimFormData,
  setClaimFormFile,
} from "store/claimForm/claimFormSlice";
import { useAppDispatch } from "store";
import { LIBRARY_OBJECTS_PAGES, MAX_TEXTAREA_LENGTH } from "utils/constants";
import libraryMessages from "messages/library";
import {
  isLibraryObjectCreatePage,
  isLibraryObjectEditPage,
} from "utils/library";
import { prepareClaimFormDataForComparison } from "./utils";
import { isClaimTypeHealth } from "utils/claim";
import { ClaimFormContext } from "./contexts/ClaimFormContext";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { claimMessages } from "messages/claim";
import generalMessages from "messages/general";

export type PageType = `${LIBRARY_OBJECTS_PAGES}`;

export interface ClaimFormProps {
  updateActionsInformation?: Function;
  updateSecondaryActionInformation?: Function;
  page: PageType;
  initialValues?: LibraryItemDetails;
}

const ClaimForm: React.FC<ClaimFormProps> = ({
  updateActionsInformation,
  updateSecondaryActionInformation,
  page,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [logoFile, setLogoFile] = useState<RcFile>(null);
  const [rawClaimData, setRawClaimData] = useState(initialValues);
  const [previousClaimData, setPreviousClaimData] = useState(
    prepareClaimFormData({
      libraryItemDetails: initialValues,
      shouldSaveTagAsArrayOfString: true,
    })
  );

  const claimForm = useSelector(selectClaimForm);
  const { generalInfo, sentenceTranslations } = claimForm;

  const { isDesiredErrorStatus: isConflictErrorStatus } = useErrorStatusCheck({
    statusCode: 412,
    scrollToTopOfPage: true,
  });

  const dispatch = useAppDispatch();

  const claimBaseRequiredFields = ["name", "productTypeId", "claimType"];
  const claimRequiredFields = isClaimTypeHealth(generalInfo.claimType)
    ? [...claimBaseRequiredFields, "regulatoryHealthClaimId"]
    : claimBaseRequiredFields;

  const hasClaimDataChanged = useMemo(
    () =>
      !isEqual(previousClaimData, prepareClaimFormDataForComparison(claimForm)),
    [previousClaimData, claimForm]
  );

  const state = initialValues?.state;

  const isClaimEmptyIfRequired = useMemo(() => {
    if (isClaimTypeHealth(generalInfo?.claimType)) {
      return !generalInfo?.regulatoryHealthClaimId;
    }

    const translationsAreValid = sentenceTranslations.every(item => {
      const trimmedText = item.text.trim();
      return trimmedText !== "" && trimmedText.length <= MAX_TEXTAREA_LENGTH;
    });

    return !translationsAreValid;
  }, [claimForm, form]);

  const { isLoading } = useClaimFormInitialization({
    initialValues: rawClaimData,
    form,
  });

  const { areActionButtonsDisabled, onFieldsChange } = useLibraryFormActions({
    requiredFields: claimRequiredFields,
    libraryItem: generalInfo,
    isUpdateInProgress: false,
    disabled: isClaimEmptyIfRequired,
  });

  const {
    onKeepAsDraftFromDropdown,
    onKeepAsDraft,
    onPublish,
    onPublishDraftClaim,
    onSaveClaim,
    isClaimCreating,
    isClaimPublishing,
    isClaimSaving,
  } = useClaimFormActions({
    claimForm,
    logoFile,
    form,
    setPreviousClaimData,
    setRawClaimData,
  });

  const actionButtonItems = isLibraryObjectCreatePage(page)
    ? [
        {
          name: formatMessage(libraryMessages.publishButton),
          label: formatMessage(libraryMessages.publishButton),
          onClick: onPublish,
        },
        {
          name: formatMessage(libraryMessages.keepAsDraftButton),
          label: formatMessage(libraryMessages.keepAsDraftButton),
          onClick: onKeepAsDraftFromDropdown,
        },
      ]
    : null;

  useEffect(() => {
    return () => {
      dispatch(setClaimFormData(initialClaimForm));
      dispatch(setClaimFormFile(initialClaimForm.file));
    };
  }, []);

  useEffect(() => {
    const isPrimaryActionDisabled =
      areActionButtonsDisabled ||
      !hasClaimDataChanged ||
      isLoading ||
      isClaimPublishing;

    updateActionsInformation({
      props: {
        disabled: isPrimaryActionDisabled,
        items: actionButtonItems,
        loading: isClaimSaving,
      },
      onClick: onSaveClaim,
    });

    if (isLibraryObjectEditPage(page)) {
      const isSecondaryActionDisabled =
        areActionButtonsDisabled || isLoading || isClaimSaving;

      updateSecondaryActionInformation({
        props: {
          disabled: isSecondaryActionDisabled,
          loading: isClaimPublishing,
        },
        onClick: onPublishDraftClaim,
      });
    }
  }, [
    areActionButtonsDisabled,
    logoFile,
    claimForm,
    hasClaimDataChanged,
    isClaimPublishing,
    isClaimSaving,
    isLoading,
  ]);

  return (
    <ClaimFormContext.Provider value={{ setLogoFile, form, isLoading }}>
      {isConflictErrorStatus && (
        <Alert
          type="warning"
          showIcon={true}
          closable={false}
          description={formatMessage(libraryMessages.conflictAlert, {
            libraryObjectType: formatMessage(libraryMessages.claim),
          })}
        />
      )}

      <Form
        name="claimForm"
        layout="vertical"
        form={form}
        initialValues={generalInfo}
        onFieldsChange={onFieldsChange}
        validateMessages={{
          required: formatMessage(libraryMessages.formRequired),
          whitespace: formatMessage(libraryMessages.formRequired),
          string: {
            max: formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_TEXTAREA_LENGTH,
            }),
          },
        }}
      >
        <ClaimGeneralInfo form={form} page={page} state={state} />
        <ClaimConfigure
          claimType={generalInfo?.claimType}
          productTypeId={generalInfo?.productTypeId}
        />
      </Form>
      {isLibraryObjectCreatePage(page) && (
        <DeleteAndKeepDraftModal
          title={formatMessage(claimMessages.cancelClaimCreationTitle)}
          description={formatMessage(claimMessages.cancelCreationDescription)}
          primaryButtonText={formatMessage(claimMessages.deleteClaim)}
          secondaryButtonText={formatMessage(claimMessages.saveAsDraft)}
          primaryButtonProps={{ disabled: isClaimCreating }}
          secondaryButtonProps={{ loading: isClaimCreating }}
          onKeepAsDraftAction={onKeepAsDraft}
          onDeleteAction={null}
          shouldScrollToTop={false}
        />
      )}

      {isLibraryObjectEditPage(page) && hasClaimDataChanged && (
        <DeleteAndKeepDraftModal
          title={formatMessage(claimMessages.cancelSaveModalTitle)}
          description={formatMessage(claimMessages.cancelSaveDescription)}
          primaryButtonText={formatMessage(generalMessages.confirm)}
          secondaryButtonText={formatMessage(generalMessages.cancel)}
          onKeepAsDraftAction={() => {
            // this will hit catch block which prevents leaving the page
            throw Error();
          }}
          onDeleteAction={null}
          shouldScrollToTop={false}
        />
      )}
    </ClaimFormContext.Provider>
  );
};

export default ClaimForm;
