import { Row, Col } from "antd";
import { Datatable } from "@trace-one/design-system";
import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import { useHierarchyData } from "hooks";
import { isParentHalfChecked } from "utils/hierarchy";
import ButtonWithModalTree from "components/ButtonWithModalTree";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { FreeFromAllergensArrayFieldTemplateProvider } from "./contexts";
import { getRowKeyForTable, getTableSize } from "utils/table";
import { useFreeFromAllergensColumns, useTableActions } from "./hooks";
import { getDefaultCheckedParents, getDefaultDisabledItems } from "./utils";
import styles from "components/SpecificationSection/components/FormSchema/styles.module.less";

const FreeFromAllergensArrayFieldTemplate: React.FC<ArrayFieldTemplateProps> = ({
  formContext,
  schema,
  uiSchema,
  noItemsMessage,
  formData,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const {
    displayActions,
    catalogDictionary,
    extraActions,
    chapterData,
    ownerCompanyId,
    isTargetMode,
  } = formContext;

  const defaultCheckedParents = getDefaultCheckedParents(formData);

  const allergensInRecipe = [
    ...(chapterData?.rawMaterialsAllergens ?? []),
    ...(chapterData?.crossContaminationAllergens ?? []),
  ];

  const defaultDisabledItems = getDefaultDisabledItems(allergensInRecipe);

  const {
    onAddAllergens,
    onRemoveAllergen,
    onUploadFile,
    onRemoveFile,
    onFetchAllergens,
  } = useTableActions({
    addAllergen: extraActions?.addFreeFromAllergens,
    removeAllergen: extraActions?.removeFreeFromAllergen,
    uploadFile: extraActions?.updateFreeFromAllergen,
    removeFile: extraActions?.updateFreeFromAllergen,
    fetchAllergens: extraActions?.fetchAllergens,
  });

  const { columns } = useFreeFromAllergensColumns({
    uiSchema,
    schema,
    catalogDictionary,
    displayActions,
  });

  const { treeData, isLoading } = useHierarchyData({
    defaultDisabledItems: [...defaultCheckedParents, ...defaultDisabledItems],
    isReadyToFetch: true,
  });

  const treeDataWithDisabledParents = treeData?.map(parent => {
    if (isParentHalfChecked({ children: parent.children, key: "disabled" })) {
      return { ...parent, disabled: true };
    }
    return parent;
  });

  return (
    <FreeFromAllergensArrayFieldTemplateProvider
      onRemoveAllergen={onRemoveAllergen}
      onUploadFile={onUploadFile}
      onRemoveFile={onRemoveFile}
      displayActions={displayActions}
      ownerCompanyId={ownerCompanyId}
    >
      <>
        <RowTitle
          title={getTranslationForKey(uiSchema["ui:title"])}
          displayActions={displayActions}
        />
        <Datatable
          tableLayout="fixed"
          columns={columns}
          dataSource={formData}
          pagination={false}
          rowKey={getRowKeyForTable()}
          emptyText={noItemsMessage}
          size={getTableSize(displayActions)}
        />

        {displayActions && !isLoading && !isTargetMode ? (
          <Row justify="center" className={styles.rowAddButton}>
            <Col>
              <ButtonWithModalTree
                modalTitle={getTranslationForKey("freeFromModalTitle")}
                openBtnLabel={getTranslationForKey("addAnAllergen")}
                onOpenModal={onFetchAllergens}
                onConfirmModal={onAddAllergens}
                defaultCheckedItems={defaultCheckedParents}
                defaultDisabledItems={defaultDisabledItems}
                treeData={treeDataWithDisabledParents}
              />
            </Col>
          </Row>
        ) : null}
      </>
    </FreeFromAllergensArrayFieldTemplateProvider>
  );
};

export default FreeFromAllergensArrayFieldTemplate;
