import { defineMessages } from "react-intl";

export const substancesTableMessages = defineMessages({
  substanceName: {
    id: "substance.name",
    defaultMessage: "Substance name",
  },
  nanoPresence: {
    id: "substance.nanoPresence",
    defaultMessage: "Nano presence",
  },
  casNumber: {
    id: "substance.casNumber",
    defaultMessage: "CAS number",
  },
  endocrineDisruptor: {
    id: "substance.endocrineDisruptor",
    defaultMessage: "Endocrine disruptor",
  },
  FRFApplicable: {
    id: "substance.FRFApplicable",
    defaultMessage: "FRF applicable",
  },
  SML: {
    id: "substance.SML",
    defaultMessage: "SML [mg/kg]",
  },
});
