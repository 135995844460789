import { CLAIM_VALIDATION_RULE_TYPE, ClaimValidationRule } from "models";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { isListEmpty } from "utils/general";
import { ClaimInformationViewModel } from "viewModels/claim";

export const getClaimInformationFormData = (
  libraryItemDetails: LibraryItemDetails
) => {
  let formData: ClaimInformationViewModel = {};

  formData.name = libraryItemDetails?.generalInformation?.name;
  formData.productTypeId = libraryItemDetails?.productTypeId;
  formData.requiresSupportingDocuments =
    libraryItemDetails?.generalInformation?.requiresSupportingDocuments;
  formData.claimSentence = libraryItemDetails?.sentenceTranslations?.find(
    ({ isDefault }) => isDefault
  )?.text;
  formData.validationRule = libraryItemDetails?.validationRule;
  formData.sentenceTranslations = libraryItemDetails?.sentenceTranslations;
  formData.additionalSentenceTranslations =
    libraryItemDetails?.additionalSentenceTranslations;
  formData.conditionOfUseTranslations =
    libraryItemDetails?.conditionOfUseTranslations;

  return formData;
};

export const getNutrientSubstanceIdsFromValidationRule = (
  validationRule: ClaimValidationRule[]
) => {
  if (isListEmpty(validationRule)) {
    return {
      nutrientIds: [],
      substanceIds: [],
    };
  }

  const { nutrientIds, substanceIds } = validationRule.reduce(
    (acc, currentRule) => {
      if (currentRule.itemType === CLAIM_VALIDATION_RULE_TYPE.NUTRIENT) {
        acc.nutrientIds.push(currentRule.id);
      } else if (
        currentRule.itemType === CLAIM_VALIDATION_RULE_TYPE.SUBSTANCE
      ) {
        acc.substanceIds.push(currentRule.id);
      }
      return acc;
    },
    { nutrientIds: [], substanceIds: [] }
  );

  return {
    nutrientIds,
    substanceIds,
  };
};
