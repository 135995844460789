import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { fetchChapterTypes } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { getDictionary } from "utils/general";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectChaptersType,
} from "utils/library";
import { Dictionary } from "types/general";
import { LibraryObjectType } from "types/library";
import { messages } from "./messages";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { ChapterTypeData } from "models";

const useTypeDictionary = ({ type }: { type: LibraryObjectType }) => {
  const languageCode = useSelector(selectLanguageCode);
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [chapterTypes, setChapterTypes] = useState<ChapterTypeData[]>([]);

  const [typeDictionary, setTypeDictionary] = useState<Dictionary>(
    Object.keys(messages).reduce((previousState, currentKey) => {
      return {
        ...previousState,
        [currentKey]: formatMessage(messages[currentKey]),
      };
    }, {})
  );

  const getChapterTypes = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchChapterTypes({ languageCode });

      setTypeDictionary(previousDictionary => ({
        ...previousDictionary,
        ...getDictionary({
          data: data,
          key: "chapterType",
          value: "chapterName",
        }),
      }));
      setChapterTypes(data);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      isLibraryObjectChaptersType(type) ||
      isLibraryObjectChapterSubType(type)
    ) {
      getChapterTypes();
    }

    if (type === LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS) {
      setTypeDictionary(previousDictionary => ({
        ...previousDictionary,
        [LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS]: formatMessage(
          messages["packagingSystem"]
        ),
      }));
    }
  }, [type]);

  return {
    isLoading,
    hasError,
    typeDictionary,
    chapterTypes,
  };
};

export default useTypeDictionary;
