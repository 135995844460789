import { FormattedMessage, useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import { Col } from "antd";
import { Paragraph, ToggleButtons } from "@trace-one/design-system";
import ResultTable from "components/ResultTable";
import { WarningFallback } from "components";
import TranslationFilters from "../TranslationFilters";
import { useTranslationPaginatedList } from "hooks";
import {
  CHAPTER_TRANSLATION_STATE,
  ChapterTranslationState,
} from "utils/constants";
import { errorMessages, generalMessages, statusMessages } from "messages";
import styles from "./styles.module.less";

const TranslationList = () => {
  const { formatMessage } = useIntl();

  const {
    isLoading,
    hasError,
    specifications,
    totalNumberOfSpecifications,
    pagination,
    onTableChange,
    filters,
    onUpdateFilters,
  } = useTranslationPaginatedList();

  const onToggleButtonsChange = (value: ChapterTranslationState) =>
    onUpdateFilters({
      toggleButtonsValue: value,
      statuses: [],
    });

  const extraComponent: React.ReactNode = (
    <ToggleButtons
      value={filters.toggleButtonsValue}
      onChange={onToggleButtonsChange}
      options={[
        {
          name: formatMessage(generalMessages.all),
          value: CHAPTER_TRANSLATION_STATE.all,
          "data-testid": "toggle-all-translations",
          "data-test-id": "toggle-all-translations",
        },
        {
          name: formatMessage(statusMessages.inProgress),
          value: CHAPTER_TRANSLATION_STATE.inProgress,
          "data-testid": "toggle-inProgress-translations",
          "data-test-id": "toggle-inProgress-translations",
        },
      ]}
      disabled={isLoading}
    />
  );

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <Col>
        <TranslationFilters
          onUpdateFilters={onUpdateFilters}
          filters={filters}
        />
        {hasError && (
          <Paragraph
            color="red"
            data-testid="searchErrorMessage"
            className="mt-2"
          >
            <FormattedMessage {...errorMessages.searchError} />
          </Paragraph>
        )}
        <div className={styles["translation-list-container"]}>
          <ResultTable
            onTableChange={onTableChange}
            pagination={pagination}
            isLoading={isLoading}
            specifications={specifications}
            totalNumberOfSpecifications={totalNumberOfSpecifications}
            onUpdateFilters={null}
            extraComponent={extraComponent}
          />
        </div>
      </Col>
    </ErrorBoundary>
  );
};

export default TranslationList;
