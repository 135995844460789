import {
  CHAPTER_TRANSLATION_STATE,
  SPECIFICATION_STATE,
} from "utils/constants";

const getStatuses = ({ type }: { type?: string }) => {
  const defaultStatuses = [
    SPECIFICATION_STATE.draft,
    SPECIFICATION_STATE.inProgress,
  ];

  switch (type) {
    case "folder":
      return [...defaultStatuses, "completed"];
    case "specification":
      return [
        "inRevision",
        ...defaultStatuses,
        SPECIFICATION_STATE.validatedByRetailer,
        SPECIFICATION_STATE.validatedBySupplier,
        SPECIFICATION_STATE.signedByRetailer,
        SPECIFICATION_STATE.signedBySupplier,
      ];
    case "translation":
      return [
        CHAPTER_TRANSLATION_STATE.toStart,
        CHAPTER_TRANSLATION_STATE.inProgress,
        CHAPTER_TRANSLATION_STATE.completed,
      ];

    default:
      return defaultStatuses;
  }
};

export default getStatuses;
