import { useState } from "react";
import { Row, Col } from "antd";
import {
  Paragraph,
  Select,
  Typography,
  InputNumber,
} from "@trace-one/design-system";
import NutriscoreDetails from "./components/NutriscoreDetails";
import NutriscoreReadOnlyWidget from "./components/NutriscoreReadOnlyWidget";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import useNutriscoreWidget from "./useNutriscoreWidget";
import { NutriscoreFormData } from "models";
import { debounce } from "lodash";
import { getInputNumberProps } from "../utils";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { isListEmpty, isObjectEmpty } from "utils/general";

import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";

export const nutriscorePropertiesName = {
  displayOnPack: "displayOnPack",
  productType: "productType",
  presenceOfNonNutritiveSweeteners: "presenceOfNonNutritiveSweeteners",
  percentageOfFruitsVegetablesAndLegumes:
    "percentageOfFruitsVegetablesAndLegumes",
  nutriscoreCalculationMissingReason: "nutriscoreCalculationMissingReason",
};

const NutriscoreWidget: React.FC<
  ObjectFieldTemplateProps<NutriscoreFormData>
> = ({ schema, properties, formData, uiSchema, formContext }) => {
  const {
    isWidgetEditable,
    extraActions: { onUpdateNutriscore },
    extraErrors,
    displayActions,
  } = formContext;
  const {
    productType,
    percentageOfFruitsVegetablesAndLegumes,
    nutriscore,
    nutriscoreCalculationMissingReason = "",
  } = formData;

  const { validationRequired } = schema;

  const [fruitsVegetableSchemaError, setFruitsVegetableSchemaError] = useState(
    undefined
  );
  const { getTranslationForKey } = useFormSchemaTranslation();
  const {
    productTypeOptions,
    hasError: hasCatalogApiError,
  } = useNutriscoreWidget();

  const propertyObjects = getPropertiesObjectFromArray(properties);
  const {
    min: minPercentage,
    max: maxPercentage,
    precision,
  } = getInputNumberProps(
    schema?.properties?.percentageOfFruitsVegetablesAndLegumes
  );

  const displayEmptyState =
    isObjectEmpty(nutriscore) ||
    !nutriscore.grade ||
    !!nutriscoreCalculationMissingReason;

  const areNonNutritiveSweetenersPresent =
    nutriscore?.presenceOfNonNutritiveSweeteners;

  const hasProductTypeError =
    !isListEmpty(
      extraErrors?.[nutriscorePropertiesName.productType]?.__errors
    ) || hasCatalogApiError;
  const productTypeErrorMessage =
    extraErrors?.[nutriscorePropertiesName.productType]?.__errors[0] ||
    getTranslationForKey("nutriscoreCatalogApiError");

  const hasPercentageOfFruitsVegetablesAndLegumesError =
    !isListEmpty(
      extraErrors?.[
        nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes
      ]?.__errors
    ) || fruitsVegetableSchemaError;
  const percentageOfFruitsVegetablesAndLegumesErrorMsg =
    fruitsVegetableSchemaError ||
    extraErrors?.[
      nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes
    ]?.__errors[0];

  if (!displayActions) {
    return (
      <NutriscoreReadOnlyWidget
        uiSchema={uiSchema}
        formData={formData}
        productTypeOptions={productTypeOptions}
        displayEmptyState={displayEmptyState}
      />
    );
  }

  return (
    <>
      {{
        ...propertyObjects[nutriscorePropertiesName.displayOnPack]?.content,
        props: {
          ...propertyObjects[nutriscorePropertiesName.displayOnPack]?.content
            ?.props,
          onChange: (value: boolean) => {
            onUpdateNutriscore({
              ...formData,
              [nutriscorePropertiesName.displayOnPack]: value,
            });
          },
        },
      }}

      <Row className="mb-2" align="middle">
        <Col md={10}>
          <Row>
            <Typography variant="heading-xxs" color="grey-5">
              {uiSchema?.[nutriscorePropertiesName.productType]?.["ui:title"]}
            </Typography>

            {validationRequired?.includes(
              nutriscorePropertiesName.productType
            ) && (
              <Typography variant="body-xxs" color="red">
                *
              </Typography>
            )}
          </Row>
        </Col>
        <Col md={10}>
          <Select
            allowClear={false}
            value={productType}
            data-test-id="nutri-score-product-type-select"
            options={productTypeOptions}
            placeholder={getTranslationForKey("refListPlaceholder")}
            onChange={(value: string) => {
              onUpdateNutriscore({
                ...formData,
                [nutriscorePropertiesName.productType]: value,
              });
            }}
            disabled={
              !isWidgetEditable(schema?.properties?.productType?.propertyId) ||
              hasCatalogApiError
            }
            error={hasProductTypeError}
            errorMessage={productTypeErrorMessage}
          />
        </Col>
      </Row>

      {(areNonNutritiveSweetenersPresent === true ||
        areNonNutritiveSweetenersPresent === false) && (
        <Row className="mb-2" align="middle">
          <Col md={10}>
            <Row>
              <Typography variant="heading-xxs" color="grey-5">
                {
                  uiSchema?.["nutriscore"]?.[
                    nutriscorePropertiesName.presenceOfNonNutritiveSweeteners
                  ]?.["ui:title"]
                }
              </Typography>
              {validationRequired?.includes(
                nutriscorePropertiesName.presenceOfNonNutritiveSweeteners
              ) && (
                <Typography variant="body-xxs" color="red">
                  *
                </Typography>
              )}
            </Row>
          </Col>
          <Col>
            <Paragraph>
              {getTranslationForKey(
                areNonNutritiveSweetenersPresent ? "yes" : "no"
              )}
            </Paragraph>
          </Col>
        </Row>
      )}

      <Row className="mb-2" align="middle">
        <Col md={10}>
          <Row>
            <Typography variant="heading-xxs" color="grey-5">
              {
                uiSchema?.[
                  nutriscorePropertiesName
                    .percentageOfFruitsVegetablesAndLegumes
                ]?.["ui:title"]
              }
            </Typography>
            {validationRequired?.includes(
              nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes
            ) && (
              <Typography variant="body-xxs" color="red">
                *
              </Typography>
            )}
          </Row>
        </Col>
        <Col md={10}>
          <InputNumber
            disabled={
              !isWidgetEditable(
                schema?.properties?.percentageOfFruitsVegetablesAndLegumes
                  ?.propertyId
              )
            }
            defaultValue={percentageOfFruitsVegetablesAndLegumes}
            onChange={debounce((value: number) => {
              setFruitsVegetableSchemaError(undefined);
              if (value < minPercentage) {
                setFruitsVegetableSchemaError(getTranslationForKey("minimum"));
                return;
              }
              if (value > maxPercentage) {
                setFruitsVegetableSchemaError(getTranslationForKey("maximum"));
                return;
              }

              onUpdateNutriscore({
                ...formData,
                [nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes]: value,
              });
            }, 300)}
            type="number"
            precision={precision}
            error={!!hasPercentageOfFruitsVegetablesAndLegumesError}
            errorMessage={percentageOfFruitsVegetablesAndLegumesErrorMsg}
          />
        </Col>
      </Row>

      <NutriscoreDetails
        displayActions={displayActions}
        displayEmptyState={displayEmptyState}
        uiSchema={uiSchema}
        nutriscoreCalculationMissingReason={nutriscoreCalculationMissingReason}
        nutriscore={nutriscore}
      />
    </>
  );
};

export default NutriscoreWidget;
