import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Form } from "antd";
import classNames from "classnames";
import { Heading, Paragraph, Divider } from "@trace-one/design-system";
import { useCatalogDictionary } from "hooks";
import { messages } from "components/Library/components/ChapterForm/messages";
import { selectLanguageCode } from "store/user/selectors";
import {
  getClaimInformationFormData,
  getNutrientSubstanceIdsFromValidationRule,
} from "./utils";
import { isProductTypeFood } from "utils/library";
import {
  getDefaultTranslationLanguageCode,
  getTranslationTextByLanguageCode,
  isClaimTypeHealth,
} from "utils/claim";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import libraryMessages, { tabMessages } from "messages/library";
import { claimMessages, generalMessages } from "messages";
import claimFormStyles from "../../styles.module.less";

const ClaimInformation = ({
  libraryItemDetails,
}: {
  libraryItemDetails: LibraryItemDetails;
}) => {
  const { formatMessage } = useIntl();

  const languageCode = useSelector(selectLanguageCode);

  const [form] = Form.useForm();

  const formData = getClaimInformationFormData(libraryItemDetails);
  const {
    nutrientIds,
    substanceIds,
  } = getNutrientSubstanceIdsFromValidationRule(formData.validationRule);

  const { catalogDictionary } = useCatalogDictionary({
    nutrientIds,
    substanceIds,
  });

  const isHealthClaim = isClaimTypeHealth(libraryItemDetails?.claimType);

  const defaultTranslationLanguageCode = getDefaultTranslationLanguageCode(
    formData?.sentenceTranslations
  );

  return (
    <div
      className={classNames(claimFormStyles["section-wrapper"], "mr-3", "mb-3")}
    >
      <div className="p-3">
        <Form
          name="claim-information"
          layout="horizontal"
          form={form}
          initialValues={formData}
          labelAlign="left"
          labelCol={{ span: 4 }}
          labelWrap
        >
          <Heading size="xs" color="grey-5" className="mb-3">
            {formatMessage(tabMessages.generalInformation)}
          </Heading>

          <Form.Item
            name="name"
            label={formatMessage(libraryMessages.nameLabel)}
            className="mb-2"
          >
            <Paragraph className="overflow-wrap" data-test-id="claim-name">
              {formData?.name}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="name"
            label={formatMessage(messages.productTypeLabel)}
            className="mb-2"
          >
            <Paragraph data-test-id="claim-product-type">
              {formatMessage(
                isProductTypeFood(formData?.productTypeId)
                  ? generalMessages.productTypeFood
                  : generalMessages.productTypeFruitsAndVegetables
              )}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="requiresSupportingDocuments"
            label={formatMessage(claimMessages.requiresSupportingDocument)}
            className="mb-0"
          >
            <Paragraph data-test-id="claim-requires-supporting-documents">
              {formatMessage(
                formData?.requiresSupportingDocuments
                  ? generalMessages.yes
                  : generalMessages.no
              )}
            </Paragraph>
          </Form.Item>

          <Divider color="grey-3" height={24} />

          <Heading size="xs" color="grey-5" className="mb-3">
            {formatMessage(claimMessages.claimTitle)}
          </Heading>

          {isHealthClaim && (
            <Form.Item
              name="nutrientSubstances"
              label={formatMessage(claimMessages.nutrientSubstances)}
              className="mb-2"
            >
              <Paragraph
                className="overflow-wrap white-space-break"
                data-test-id="nutrient-substances"
                data-testid="nutrient-substances"
              >
                {catalogDictionary?.[nutrientIds[0]]}
              </Paragraph>
            </Form.Item>
          )}

          <Form.Item
            name="claim-sentence"
            label={formatMessage(claimMessages.claimSentenceTitle)}
            className="mb-2"
          >
            <Paragraph
              className="overflow-wrap white-space-break"
              data-test-id="claim-sentence"
            >
              {formData?.claimSentence}
            </Paragraph>
          </Form.Item>

          {isHealthClaim && (
            <Form.Item
              name="additionalClaimSentence"
              label={formatMessage(claimMessages.additionalClaimSentence)}
              className="mb-2"
            >
              <Paragraph
                className="overflow-wrap white-space-break"
                data-test-id="additional-claim-sentence"
                data-testid="additional-claim-sentence"
              >
                {getTranslationTextByLanguageCode({
                  translations: formData?.additionalSentenceTranslations,
                  languageCode: defaultTranslationLanguageCode,
                })}
              </Paragraph>
            </Form.Item>
          )}

          {isHealthClaim && (
            <>
              <Divider color="grey-3" height={24} />
              <Heading size="xs" color="grey-5" className="mb-3">
                {formatMessage(claimMessages.conditionOfUseTranslation)}
              </Heading>
              <Form.Item
                name="conditionOfUseTranslation"
                label={formatMessage(claimMessages.conditionOfUseTranslation)}
                className="mb-2"
              >
                <Paragraph
                  className="overflow-wrap white-space-break"
                  data-test-id="condition-of-use-translation"
                  data-testid="condition-of-use-translation"
                >
                  {getTranslationTextByLanguageCode({
                    translations: formData?.conditionOfUseTranslations,
                    languageCode,
                  })}
                </Paragraph>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ClaimInformation;
