import React from "react";
import { useIntl } from "react-intl";
import EmptyState from "components/Library/components/EmptyState";
import StepTitle from "components/Library/components/StepTitle";
import { claimMessages } from "messages/claim";
import { isClaimTypeMarketing } from "utils/claim";
import ClaimSentenceAndLogo from "../ClaimConfigureContent/ClaimSentenceAndLogo";
import HealthClaimConfigureContent from "../ClaimConfigureContent";

export interface ClaimConfigureProps {
  claimType: string;
  productTypeId: string;
}

const ClaimConfigure: React.FC<ClaimConfigureProps> = ({
  claimType,
  productTypeId,
}) => {
  const { formatMessage } = useIntl();

  const renderContent = () => {
    if (!productTypeId || !claimType) {
      return (
        <EmptyState
          description={formatMessage(claimMessages.stepTwoDescription)}
        />
      );
    }
    if (isClaimTypeMarketing(claimType)) {
      return (
        <ClaimSentenceAndLogo
          claimLabel={formatMessage(claimMessages.claimSentenceLabel)}
          claimSentenceRequired={true}
        />
      );
    }

    return <HealthClaimConfigureContent />;
  };

  return (
    <>
      <StepTitle title={formatMessage(claimMessages.stepTwoTitle)} step={2} />
      {renderContent()}
    </>
  );
};

export default ClaimConfigure;
