import {
  PackagingSubstance,
  SubstanceData,
  SubstanceRegulationData,
} from "models";
import { useMemo } from "react";
import { isListEmpty } from "utils/general";
import { PackagingSubstanceTableViewModel } from "viewModels";

export interface UseSubstanceWidgetProps {
  substanceInformationCollection: SubstanceData[];
  substances: PackagingSubstance[];
  substanceRegulations: SubstanceRegulationData[];
}

export const useSubstanceWidget = ({
  substanceInformationCollection,
  substances,
  substanceRegulations,
}: UseSubstanceWidgetProps) => {
  const substancesViewModal: PackagingSubstanceTableViewModel[] = useMemo(() => {
    if (
      isListEmpty(substanceInformationCollection) ||
      isListEmpty(substances) ||
      isListEmpty(substanceRegulations)
    ) {
      return [];
    }

    let substanceMap = substances.reduce((acc, substace) => {
      return {
        ...acc,
        [substace.substanceId]: substace,
      };
    }, {});

    substanceInformationCollection.forEach(({ id, name, casNumbers }) => {
      const substanceInMap = substanceMap[id];

      substanceMap[id] = {
        ...substanceInMap,
        name,
        casNumbers,
      };
    });

    substanceRegulations.forEach(({ id, regulations: { reach, eu, agec } }) => {
      const substanceInMap = substanceMap[id];
      const isRestricted =
        (reach && reach.isRestricted) ||
        (eu && eu.isRestricted) ||
        (agec && agec.isRestricted);

      const { sml, isFrfApplicable } = eu || {};
      const { isHighConcernByArticle59 } = agec || {};

      substanceMap[id] = {
        ...substanceInMap,
        isRestricted,
        sml,
        isFrfApplicable,
        isEndocrineDisruptor: isHighConcernByArticle59,
      };
    });

    return Object.values(substanceMap);
  }, [substanceInformationCollection, substances, substanceRegulations]);

  return {
    restrictedSubstances: substancesViewModal.filter(
      substance => substance.isRestricted
    ),
    unRestrictedSubstances: substancesViewModal.filter(
      substance => !substance.isRestricted
    ),
  };
};
