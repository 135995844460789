import { useContext, useEffect, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { MAX_CLAIM_NUTRIENT_SIZE, isObjectEmpty } from "utils/general";
import { fetchAll } from "utils/api";
import { fetchNutrientByIds } from "apis/CATALOGUE";
import {
  CLAIM_VALIDATION_RULE_TYPE,
  NutrientFamilyData,
  RegulatoryHealthClaim,
} from "models";
import { fetchHealthRegulatoryClaims } from "apis/SPEC";
import { getNutrientsForCatalogDictionary } from "utils/nutrition";
import { useAppDispatch } from "store";
import {
  selectClaimForm,
  setClaimFormData,
} from "store/claimForm/claimFormSlice";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { ClaimFormContext } from "components/Library/components/ClaimForm/contexts/ClaimFormContext";

const HEALTH_CLAIM_TAKE_SIZE = 100;

const useHealthClaimConfiguration = () => {
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const claimForm = useSelector(selectClaimForm);
  const { generalInfo } = claimForm;

  const { form } = useContext(ClaimFormContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [hasLoadedAllClaims, setHasLoadedAllClaims] = useState(false);

  const [healthClaims, setHealthClaims] = useState<RegulatoryHealthClaim[]>([]);
  const [allNutrients, setAllNutrients] = useState<LabeledValue[]>([]);
  const [uniqueNutrientIds, setUniqueNutrientIds] = useState(undefined);

  const [nutrientMap, setNutrientMap] = useState(undefined);
  const [healthClaimsMap, setHealthClaimsMap] = useState(undefined);

  const [selectedNutrientId, setSelectedNutrientId] = useState(undefined);
  const [nutrientOptions, setNutrientOptions] = useState<LabeledValue[]>([]);
  const [claimSentenceOptions, setClaimSentenceOptions] = useState<
    LabeledValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showNutrientError, setShowNutrientError] = useState(false);

  /**
   * Nutrient: Methods
   */
  const onNutrientSearch = (value: string) => {
    const foundList = allNutrients.filter(nutrient =>
      nutrient.label.toString().toLowerCase().includes(value?.toLowerCase())
    );
    setNutrientOptions(foundList);
  };

  const resetRegulatoryClaims = () => {
    form.setFieldValue("requiresSupportingDocuments", null);
    form.setFieldValue("regulatoryHealthClaimId", null);
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...generalInfo,
          regulatoryHealthClaimId: null,
          requiresSupportingDocuments: null,
        },
      })
    );
  };

  const onNutrientChange = (id: string) => {
    setSelectedNutrientId(id);
    resetRegulatoryClaims();
  };

  const onNutrientClear = () => {
    setSelectedNutrientId(undefined);
    setNutrientOptions(allNutrients);
    resetRegulatoryClaims();
  };

  const fetchAllNutrients = async () => {
    const claimSentencesMap = {};

    try {
      setIsLoading(true);

      const nutrientIds = healthClaims.reduce((acc, claim) => {
        const nutrientIds = claim.validationRule
          .filter(rule => rule.itemType === CLAIM_VALIDATION_RULE_TYPE.NUTRIENT)
          .map(rule => rule.id);

        nutrientIds.forEach(id => {
          const nutrientIdAlreadyInMap = claimSentencesMap[id];

          if (nutrientIdAlreadyInMap) {
            claimSentencesMap[id] = [...nutrientIdAlreadyInMap, claim];
          } else {
            claimSentencesMap[id] = [claim];
          }
        });

        return [...acc, ...nutrientIds];
      }, []);

      setHealthClaimsMap(claimSentencesMap);

      let uniqeNutrientIds = Array.from(new Set(nutrientIds));

      const hasMoreNutrientThanAllowed =
        uniqeNutrientIds.length > MAX_CLAIM_NUTRIENT_SIZE;

      if (hasMoreNutrientThanAllowed) {
        uniqeNutrientIds = uniqeNutrientIds.slice(0, MAX_CLAIM_NUTRIENT_SIZE);
      }

      setUniqueNutrientIds(uniqeNutrientIds);
      setShowNutrientError(hasMoreNutrientThanAllowed);

      const fetchNutrientByIdsWrapper = async (
        ids: string[]
      ): Promise<NutrientFamilyData[]> => {
        const response = await fetchNutrientByIds({
          nutrientIds: ids,
          languageCode,
        });

        dispatch(
          updateCatalogDictionary(
            getNutrientsForCatalogDictionary(response.data)
          )
        );

        return response.data;
      };

      const data = await fetchAll<NutrientFamilyData>({
        ids: uniqeNutrientIds,
        dataFetcher: fetchNutrientByIdsWrapper,
      });

      const flatNutrientObject = getNutrientsForCatalogDictionary(data);

      setNutrientMap(flatNutrientObject);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Regulatory Claims: Methods
   */

  const onClaimChange = (id: string, claim) => {
    const { value, requiresSupportingDocuments } = claim || {};

    form.setFieldValue(
      "requiresSupportingDocuments",
      requiresSupportingDocuments
    );

    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...generalInfo,
          regulatoryHealthClaimId: value,
          requiresSupportingDocuments,
        },
      })
    );
  };

  const fetchAllHealthClaims = async () => {
    try {
      setIsLoading(true);
      const {
        data: { items, totalNumberOfItems },
      } = await fetchHealthRegulatoryClaims({
        params: {
          skip: (currentPage - 1) * HEALTH_CLAIM_TAKE_SIZE,
          take: HEALTH_CLAIM_TAKE_SIZE,
        },
      });

      const totalClaimsTillNow = healthClaims.length + items.length;

      setHealthClaims([...healthClaims, ...items]);

      if (totalNumberOfItems > totalClaimsTillNow) {
        setCurrentPage(page => page + 1);
      } else {
        setHasLoadedAllClaims(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Nutrient: Hooks
   */

  useEffect(() => {
    if (!isObjectEmpty(nutrientMap) && uniqueNutrientIds) {
      const nutrientList = uniqueNutrientIds.map(key => ({
        key,
        value: key,
        label: nutrientMap[key],
      }));

      setNutrientOptions(nutrientList);
      setAllNutrients(nutrientList);
    }
  }, [nutrientMap, uniqueNutrientIds]);

  /**
   * Claims: Hooks
   */

  useEffect(() => {
    if (selectedNutrientId && healthClaimsMap) {
      const claims: RegulatoryHealthClaim[] =
        healthClaimsMap[selectedNutrientId];

      const claimOptions = claims?.map(claim => {
        const { id, sentenceTranslations, requiresSupportingDocuments } = claim;

        const claimInSelectedLanguage = sentenceTranslations.filter(
          sentence => sentence.languageCode === languageCode
        );

        return {
          key: id,
          value: id,
          label: claimInSelectedLanguage?.[0].text,
          requiresSupportingDocuments: requiresSupportingDocuments,
        };
      });

      const uniqueClaimOptions = claimOptions.filter(
        (item, index, self) =>
          index === self.findIndex(t => t.value === item.value)
      );

      setClaimSentenceOptions(uniqueClaimOptions);
    }
  }, [selectedNutrientId, healthClaimsMap, languageCode]);

  useEffect(() => {
    fetchAllHealthClaims();
  }, [currentPage]);

  useEffect(() => {
    if (hasLoadedAllClaims) {
      fetchAllNutrients();
    }
  }, [hasLoadedAllClaims]);

  return {
    healthClaims,
    nutrientOptions,
    claimSentenceOptions,
    onNutrientSearch,
    onClaimChange,
    onNutrientChange,
    selectedNutrientId,
    isLoading,
    onNutrientClear,
    showNutrientError,
  };
};

export default useHealthClaimConfiguration;
