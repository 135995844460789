import { useState } from "react";
import { NutritionServingData } from "models";

const useRemoveServing = ({
  onDeleteNutritionServing,
  servingDefinitions,
}: {
  onDeleteNutritionServing: Function;
  servingDefinitions: NutritionServingData[];
}) => {
  const [showRemoveServingModal, setShowRemoveServingModal] = useState<boolean>(
    false
  );
  const [servingId, setServingId] = useState<string>(undefined);

  const onRemoveServing = (servingId: string) => {
    setShowRemoveServingModal(true);
    setServingId(servingId);
  };

  const onConfirmRemoveServing = async () => {
    try {
      const updatedServing = servingDefinitions.filter(
        ({ servingId: id, defaultServing }) =>
          !defaultServing && id !== servingId
      );

      await onDeleteNutritionServing({
        servingData: updatedServing,
        setShowRemoveServingModal,
      });
    } catch (e) {}
  };

  return {
    showRemoveServingModal,
    onRemoveServing,
    onConfirmRemoveServing,
    onCloseRemoveServing: setShowRemoveServingModal,
  };
};

export default useRemoveServing;
