import { useIntl } from "react-intl";
import { UiSchema } from "@rjsf/core";
import { ObjectSchema } from "components/SpecificationSection/types";
import SubstancesTableInput from "../../components/SubstancesTableInput";
import { EMPTY_VALUE } from "utils/constants";
import {
  findPropertyByKey,
  getFieldTitleFromUiSchemaArrayTemplate,
  getFieldTitleFromUiSchemaObjectTemplate,
  isObjectEmpty,
} from "utils/general";
import { generalMessages } from "messages";
import { substancesTableMessages } from "components/SubstancesTable/messages";
import { PackagingSubstanceTableViewModel } from "viewModels";

export type UseSubstancesTableProps = {
  schema: ObjectSchema;
  uiSchema: UiSchema;
  isRestricted?: boolean;
};

const useSubstancesTable = ({
  schema,
  uiSchema,
  isRestricted,
}: UseSubstancesTableProps) => {
  const { formatMessage } = useIntl();
  const percentageSchema = findPropertyByKey(schema, "percentage");
  const sizeSchema = findPropertyByKey(schema, "size");
  const specificSurfaceSchema = findPropertyByKey(schema, "specificSurface");

  let columns = [
    {
      title: formatMessage(substancesTableMessages.substanceName),
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (name: PackagingSubstanceTableViewModel["name"]) => {
        return name || EMPTY_VALUE;
      },
    },

    {
      title: formatMessage(substancesTableMessages.casNumber),
      dataIndex: "casNumbers",
      key: "casNumbers",
      width: "10%",
      render: (casNumbers: PackagingSubstanceTableViewModel["casNumbers"]) => {
        const CASNumber = casNumbers?.join("; ");

        return CASNumber || EMPTY_VALUE;
      },
    },

    {
      title: getFieldTitleFromUiSchemaArrayTemplate(
        uiSchema?.substances,
        "percentage"
      ),
      dataIndex: "percentage",
      key: "percentage",
      width: "10%",
      render: (percentage: PackagingSubstanceTableViewModel["percentage"]) => {
        return (
          <SubstancesTableInput
            value={percentage}
            key={percentageSchema?.propertyId}
            schema={percentageSchema}
          />
        );
      },
    },
    {
      title: formatMessage(substancesTableMessages.nanoPresence),
      dataIndex: "presentAtNanoscale",
      key: "presentAtNanoscale",
      width: "10%",
      render: (
        presentAtNanoscale: PackagingSubstanceTableViewModel["presentAtNanoscale"]
      ) => {
        const message = presentAtNanoscale ? "yes" : "no";

        return formatMessage(generalMessages[message]);
      },
    },
    {
      title: getFieldTitleFromUiSchemaObjectTemplate(
        uiSchema?.substances?.items?.presenceAtNanoscale,
        "size"
      ),
      dataIndex: "presenceAtNanoscale",
      key: "size",
      width: "10%",
      render: (
        presenceAtNanoscale: PackagingSubstanceTableViewModel["presenceAtNanoscale"]
      ) => {
        if (isObjectEmpty(presenceAtNanoscale)) return EMPTY_VALUE;

        const { size } = presenceAtNanoscale;

        return (
          <SubstancesTableInput
            value={size}
            key={sizeSchema?.propertyId}
            schema={sizeSchema}
          />
        );
      },
    },
    {
      title: getFieldTitleFromUiSchemaObjectTemplate(
        uiSchema?.substances?.items?.presenceAtNanoscale,
        "specificSurface"
      ),
      dataIndex: "presenceAtNanoscale",
      key: "specificSurface",
      width: "10%",
      render: (
        presenceAtNanoscale: PackagingSubstanceTableViewModel["presenceAtNanoscale"]
      ) => {
        if (isObjectEmpty(presenceAtNanoscale)) return EMPTY_VALUE;

        const { specificSurface } = presenceAtNanoscale;

        return (
          <SubstancesTableInput
            value={specificSurface}
            key={specificSurfaceSchema?.propertyId}
            schema={specificSurfaceSchema}
          />
        );
      },
    },
  ];

  if (isRestricted) {
    columns = [
      ...columns,
      {
        title: formatMessage(substancesTableMessages.endocrineDisruptor),
        dataIndex: "isEndocrineDisruptor",
        key: "isEndocrineDisruptor",
        width: "10%",
        render: (
          isEndocrineDisruptor: PackagingSubstanceTableViewModel["isEndocrineDisruptor"]
        ) => {
          if (isEndocrineDisruptor) return formatMessage(generalMessages.yes);
          return formatMessage(generalMessages.no);
        },
      },
      {
        title: formatMessage(substancesTableMessages.FRFApplicable),
        dataIndex: "isFrfApplicable",
        key: "isFrfApplicable",
        width: "10%",
        render: (
          isFrfApplicable: PackagingSubstanceTableViewModel["isFrfApplicable"]
        ) => {
          if (isFrfApplicable === undefined) return EMPTY_VALUE;
          if (isFrfApplicable) return formatMessage(generalMessages.yes);
          return formatMessage(generalMessages.no);
        },
      },
      {
        title: formatMessage(substancesTableMessages.SML),
        dataIndex: "sml",
        key: "sml",
        width: "12%",
        render: (sml: PackagingSubstanceTableViewModel["sml"]) => {
          if (sml) return sml;
          return EMPTY_VALUE;
        },
      },
    ];
  }

  return {
    columns,
  };
};

export default useSubstancesTable;
