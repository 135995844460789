import { useIntl } from "react-intl";
import StepTitle from "components/Library/components/StepTitle";
import EmptyState from "components/Library/components/EmptyState";
import MaterialDetails from "../MaterialDetails";
import { messages } from "./messages";
import { ConfigureProps } from "./types";

const Configure: React.FC<ConfigureProps> = ({
  rawMaterial,
  recipe,
  allergens,
  suppliers,
  materialId,
  materialType,
  recipeType,
  state,
  fileIds,
  files,
  substanceId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StepTitle
        title={formatMessage(messages.configureMaterialTitle)}
        step={2}
      />
      {!!materialId ? (
        <MaterialDetails
          rawMaterial={rawMaterial}
          recipe={recipe}
          suppliers={suppliers}
          allergens={allergens}
          materialId={materialId}
          materialType={materialType}
          recipeType={recipeType}
          state={state}
          fileIds={fileIds}
          files={files}
          substanceId={substanceId}
        />
      ) : (
        <EmptyState description={formatMessage(messages.selectMaterialType)} />
      )}
    </>
  );
};

export default Configure;
