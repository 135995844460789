import { FormInstance } from "antd";
import { useFilesInfo } from "hooks";
import { ClaimData } from "models";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import {
  setClaimFormData,
  setClaimFormFile,
} from "store/claimForm/claimFormSlice";
import { isObjectEmpty } from "utils/general";
import { prepareClaimFormData } from "dtos/claim";

const useClaimFormInitialization = ({
  initialValues,
  form,
}: {
  initialValues: ClaimData;
  form: FormInstance;
}) => {
  const dispatch = useAppDispatch();

  const {
    isLoading,
    files: [imageDetails],
  } = useFilesInfo({ fileIds: [initialValues?.logoFileId] });

  useEffect(() => {
    const prepareClaim = () => {
      if (!isObjectEmpty(initialValues)) {
        const {
          generalInfo = {},
          sentenceTranslations = {},
        } = prepareClaimFormData({
          libraryItemDetails: initialValues,
          file: imageDetails,
        });

        dispatch(
          setClaimFormData({
            generalInfo: generalInfo,
            sentenceTranslations: sentenceTranslations,
          })
        );

        dispatch(
          setClaimFormFile({
            file: {
              id: imageDetails?.id,
              fileName: imageDetails?.name,
              fileSize: imageDetails?.size,
            },
          })
        );

        form?.setFieldsValue(generalInfo);
      }
    };

    prepareClaim();
  }, [imageDetails, initialValues]);

  return { isLoading };
};

export default useClaimFormInitialization;
