import { useEffect } from "react";
import classNames from "classnames";
import { Container, Button } from "@trace-one/design-system";
import { ModalProps } from "./types";
import styles from "./styles.module.less";

const Modal: React.FC<ModalProps> = ({
  onClose,
  showHeader,
  children,
  footer,
  allowScroll,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles["wrapper-with-header"]]: showHeader,
      })}
      data-test-id="overlay"
      data-testid="overlay"
    >
      <span className={styles.close}>
        <Button
          data-testid="close-box"
          data-test-id="close-box"
          type="tertiary"
          iconName="close"
          size="large"
          color="grey"
          onClick={onClose}
        />
      </span>
      <Container
        className={classNames({
          [styles["container-scroll-y"]]: allowScroll,
          "hide-scrollbar": allowScroll,
        })}
      >
        {children}
      </Container>

      {footer && <footer>{footer}</footer>}
    </div>
  );
};
export default Modal;
