import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isObjectEmpty, isListEmpty } from "utils/general";
import { useAppDispatch } from "store";
import {
  useChapterAllergens,
  useRecipe,
  useUserInfo,
  useValidation,
} from "hooks";
import { selectUserId } from "store/user/selectors";
import { validateChapterSection } from "apis/SPEC";
import { validateLabelingTranslationSection } from "apis/SPEC/labeling-translations";
import SpecificationSection from "components/SpecificationSection";
import Spin from "components/Spin";
import TranslationManagement from "components/TranslationManagement";
import { useTranslationCompleted } from "components/TranslationManagement/hooks";
import { CHAPTER_TYPES_API } from "utils/constants";
import { isTranslatorContact } from "utils/specifications";
import { isChapterTranslationOnHold } from "utils/chapters";
import {
  isLibraryObjectDetailsPage,
  isLibraryObjectLocked,
} from "utils/library";
import { getChapter } from "store/chapterForm/asyncActions";
import SelectedSectionContext from "./contexts";
import SubSections from "./components/SubSections";
import { SelectedSectionProps } from "./types";

const SelectedSection = ({
  selectedSection,
  currentSectionsRefs,
  page,
  generalInfo,
  languageTabComponent,
  retailerCompnayId,
  supplierCompnayId,
  translatorCompanyId,
  isLoading,
  getTranslation,
  getLabelingTranslationLanguages,
  productData,
}: SelectedSectionProps) => {
  const userId = useSelector(selectUserId);
  const [
    labelingTranslationLanguages,
    setLabelingTranslationLanguages,
  ] = useState(null);

  const { isTranslator } = useUserInfo();
  const dispatch = useAppDispatch();

  const { sectionName, sections, sectionId, sectionStatus } = selectedSection;

  useEffect(() => {
    if (!isTranslator) {
      return;
    }

    const fetchTranslationLanguages = async () => {
      const translationLanguages = await getLabelingTranslationLanguages?.();
      setLabelingTranslationLanguages(translationLanguages);
    };

    fetchTranslationLanguages();
  }, [sectionStatus, isTranslator]);

  const { allTranslationsCompleted } = useTranslationCompleted({
    labelingTranslationLanguages,
  });

  const {
    fetchRecipeIngredients,
    recipeIngredients,
    sortRecipeIngredient,
    recipeSorter,
    totalPercentageOfRecipeIngredients,
    updateRecipeFiles,
  } = useRecipe({
    id: generalInfo?.id,
    chapterType: generalInfo?.type,
    shouldCallApi: true,
  });

  const {
    fetchAllergens,
    rawMaterialsAllergens,
    crossContaminationAllergens,
  } = useChapterAllergens({
    id: generalInfo?.id,
    chapterType: generalInfo?.type,
    shouldCallApi: true,
  });

  const translationId = generalInfo?.translationId;

  const onValidate = translationId
    ? validateLabelingTranslationSection(translationId)
    : validateChapterSection({
        chapterId: generalInfo?.id,
        chapterType: CHAPTER_TYPES_API[generalInfo?.type],
      });

  const afterValidationCallback = translationId
    ? () => getTranslation(translationId, false)
    : () => dispatch(getChapter());

  const {
    validateHandler,
    validationErrors,
    isSectionValidationLoading,
    selectSectionValidationErrors,
    resetSectionValidationErrors,
    setSectionBlockValidationError,
  } = useValidation({
    // @ts-ignore
    onValidate,
    chapterId: generalInfo?.id,
    afterValidationCallback,
  });

  const translationContacts = generalInfo?.contacts ?? [];
  const isTranslationContact =
    translationContacts.findIndex(
      ({ contactType, userId: id }) =>
        isTranslatorContact(contactType) && id === userId
    ) > -1;

  const selectedSectionContent = isChapterTranslationOnHold(
    generalInfo?.state
  ) ? (
    <TranslationManagement.TranslationOnHold />
  ) : (
    <SubSections
      subSections={sections}
      currentSectionsRefs={currentSectionsRefs}
    />
  );

  return (
    <SelectedSectionContext.Provider
      value={{
        validateHandler,
        isSectionValidationLoading,
        setSectionBlockValidationError,
        resetSectionValidationErrors,
        selectSectionValidationErrors,
        fetchRecipeIngredients,
        recipeIngredients,
        sortRecipeIngredient,
        fetchAllergens,
        rawMaterialsAllergens,
        crossContaminationAllergens,
        chapterState: generalInfo?.state,
        page,
        recipeSorter,
        totalPercentageOfRecipeIngredients,
        retailerCompnayId,
        supplierCompnayId,
        translatorCompanyId,
        translationId,
        isTranslationContact,
        getTranslation,
        isTranslator,
        getLabelingTranslationLanguages,
        allTranslationsCompleted,
        productData,
        updateRecipeFiles,
      }}
    >
      <SpecificationSection.Title
        sectionName={sectionName}
        sectionStatus={sectionStatus}
        onValidate={validateHandler()}
        isValidationDisabled={isLibraryObjectLocked(generalInfo?.state)}
        hasValidationErrors={!isObjectEmpty(validationErrors)}
        isValidationLoading={isSectionValidationLoading(sectionId)}
        hasValidationPermission={true}
        hasValidation={!isLibraryObjectDetailsPage(page)}
        isTranslator={isTranslator}
        getTranslation={getTranslation}
        translationId={translationId}
        labelingTranslationLanguages={labelingTranslationLanguages}
        getLabelingTranslationLanguages={getLabelingTranslationLanguages}
        setLabelingTranslationLanguages={setLabelingTranslationLanguages}
      />
      {languageTabComponent}
      {isLoading && <Spin />}
      {!isListEmpty(sections) && !isLoading && selectedSectionContent}
    </SelectedSectionContext.Provider>
  );
};

export default SelectedSection;
