import { LabelingTranslationLaguageData, TranslationChapterData } from "models";
import { axiosInstance } from "./index";
import { ValidationResponse } from "hooks/useValidation/types";

const BASE_URL = "/api/labeling-translations";

export const fetchLabelingTranslationLanguages = ({
  specificationId,
}: {
  specificationId: string;
}) =>
  axiosInstance.get<LabelingTranslationLaguageData[]>(`${BASE_URL}`, {
    params: {
      specificationId,
    },
  });

export const fetchLabelingTranslationById = ({
  translationId,
  params,
}: {
  translationId: string;
  params: {
    languageCode?: string;
  };
}) =>
  axiosInstance.get<TranslationChapterData>(`${BASE_URL}/${translationId}`, {
    params,
  });

export const updateLabelingTranslationBlock = ({
  translationId,
  blockId,
  json,
}: {
  translationId: string;
  blockId: string;
  json: string;
}) =>
  axiosInstance.put<Promise<void>>(`${BASE_URL}/${translationId}`, {
    blockId,
    json,
  });

export const completeLabelingTranslations = ({
  translationIds,
}: {
  translationIds: string[];
}) =>
  axiosInstance.post<void>(`${BASE_URL}/complete`, {
    translationIds,
  });

export const validateLabelingTranslationSection = (translationId: string) => (
  sectionId: string
) =>
  axiosInstance.post<Promise<ValidationResponse>>(
    `${BASE_URL}/${translationId}/validate`,
    {},
    {
      params: {
        sectionId,
      },
    }
  );
