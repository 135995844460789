import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Divider } from "antd";
import { Card } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import {
  selectErrors,
  selectFolderDetails,
  selectSpecificationsForProduct,
} from "store/folderCreation/selectors";
import {
  fetchExistingSpecificationsForProduct,
  removeProductSpecification,
  removeProductSupplier,
  setFolderSpecification,
  setProductSupplier,
} from "store/folderCreation/asyncActions";
import { getOnToggle } from "utils/card";
import { createNameForProduct } from "utils/form";
import { hasProductError } from "utils/general";
import CreationWizzard from "components/CreationWizzard";
import CompanySelect from "components/CompanySelect";
import Info from "../Info";
import ProductSpecifications from "../ProductSpecifications";
import { loadExistingSpecificationsForProduct } from "../utils";
import {
  getOnChange,
  getOnSelectSupplier,
  getRemoveNewSpecification,
  prepareSpecifications,
} from "./utils";
import { COMPANY_ACTIVITY_ID } from "utils/constants";
import { messages } from "../messages";
import generalMessages from "messages/general";
import styles from "../styles.module.less";

const Folder = ({ displayActions, onRemoveClick, product }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);
  const productSpecifications = useSelector(
    selectSpecificationsForProduct(product.id)
  );
  const { folderId } = useSelector(selectFolderDetails);
  const {
    removeProduct: hasRemoveProductErrors,
    addProductSupplier: hasAddProductSupplierErrors,
    addProductSpecifications: hasAddProductSpecificationsErrors,
    removeProductSpecifications: removeProductSpecificationsErrors,
  } = useSelector(selectErrors);
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isSpecificationsLoading, setIsSpecificationsLoading] = useState(false);

  const productName = useMemo(
    () => createNameForProduct(product, unitsOfMeasure),
    [product, unitsOfMeasure]
  );

  const hasWarning = useMemo(
    () =>
      hasProductError({
        folderId,
        productSpecifications,
      }),
    [productSpecifications]
  );

  const { newSpecifications, specifications } = useMemo(
    () =>
      prepareSpecifications({
        productSpecifications,
        folderId,
      }),
    [productSpecifications]
  );

  useEffect(() => {
    loadExistingSpecificationsForProduct({
      setIsSpecificationsLoading,
      dispatch,
      productId: product.id,
      fetchExistingSpecificationsForProduct,
    });
  }, [dispatch, product.id]);

  return (
    <Card
      collapsible
      className={styles.card}
      title={<Heading size="xs">{productName}</Heading>}
      warningMessage={intl.formatMessage(messages.noSupplierAssigned)}
      hasWarning={hasWarning}
      collapseProps={{
        in: isCardOpen,
        onToggle: getOnToggle({
          setIsCardOpen,
        }),
      }}
      {...(displayActions && {
        extra: (
          <>
            <span>{intl.formatMessage(generalMessages.remove)}</span>
            <span className={styles.icon}>-</span>
          </>
        ),
        extraProps: {
          actionType: "button",
          danger: true,
          onClick: onRemoveClick,
        },
      })}
      hasError={hasRemoveProductErrors[product.id]}
      errorMessage={intl.formatMessage(messages.errorRemoveProduct)}
    >
      <>
        <Info product={product} />
        <Divider className={styles.divider} />
        {displayActions && (
          <CreationWizzard.Item
            label={intl.formatMessage(messages.selectSuppliers)}
          >
            <CompanySelect
              values={[]}
              onSelect={getOnSelectSupplier({
                dispatch,
                setProductSupplier,
                tradeItemId: product.id,
              })}
              type="tradeItemSuppliers"
              labelInValue
              tradeItemId={product.id}
              error={hasAddProductSupplierErrors[product.id]}
              errorMessage={intl.formatMessage(messages.errorFieldMessage)}
              productSpecifications={productSpecifications}
              companyActivityId={COMPANY_ACTIVITY_ID.SUPPLIER}
            />
          </CreationWizzard.Item>
        )}
        <ProductSpecifications
          displayActions={displayActions}
          displayCheckbox={displayActions}
          productId={product.id}
          isLoading={isSpecificationsLoading}
          onChange={getOnChange({
            dispatch,
            removeProductSpecification,
            setFolderSpecification,
            tradeItemId: product.id,
          })}
          removeNewSpecification={getRemoveNewSpecification({
            dispatch,
            removeProductSupplier,
            tradeItemId: product.id,
          })}
          newSpecifications={newSpecifications}
          specifications={specifications}
          removeSpecificationErrors={
            removeProductSpecificationsErrors[product.id]
          }
          addSpecificationErrors={hasAddProductSpecificationsErrors[product.id]}
        />
      </>
    </Card>
  );
};

Folder.defaultProps = {
  displayActions: true,
  onRemoveClick: () => {},
};

Folder.propTypes = {
  product: PropTypes.object.isRequired,
  displayActions: PropTypes.bool,
  onRemoveClick: PropTypes.func,
};

export default Folder;
