import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import Modal from "components/Modal";
import RecipeSummary from "./components/RecipeSummary";
import { useModalVisibility, useRbac } from "hooks";
import { PERMISSIONS } from "utils/constants";
import { isSpecificationDetailsPage } from "utils/specifications";
import { PageType } from "types/specification";
import { FormContext } from "components/SpecificationSection/types";
import { messages } from "./messages";

export type SeeRecipeSummaryProps = {
  page?: PageType;
  isTargetMode?: boolean;
  formContext?: FormContext;
};

const SeeRecipeSummary: React.FC<SeeRecipeSummaryProps> = ({
  page,
  isTargetMode,
  formContext,
}) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useRbac();
  const { isModalOpen, onCloseModal, onOpenModal } = useModalVisibility();

  if (
    !hasPermission(PERMISSIONS.SPECIFICATION.READ) ||
    !isSpecificationDetailsPage(page)
  ) {
    return null;
  }

  return (
    <>
      <Button
        onClick={onOpenModal}
        disabled={isTargetMode}
        data-test-id="see-recipe-summary-btn"
        data-testid="see-recipe-summary-btn"
      >
        {formatMessage(messages.seeRecipeSummary)}
      </Button>

      {isModalOpen && (
        <Modal onClose={onCloseModal} showHeader>
          <RecipeSummary formContext={formContext} />
        </Modal>
      )}
    </>
  );
};

export default SeeRecipeSummary;
