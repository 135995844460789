import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { useBoolean, useLanguages } from "hooks";
import { LabelingTranslationLaguageData, TranslationChapterData } from "models";
import {
  fetchLabelingTranslationById,
  fetchLabelingTranslationLanguages,
} from "apis/SPEC/labeling-translations";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { CHAPTER_TRANSLATION_STATE } from "utils/constants";

export interface UseTranslationDetailsProps {
  specificationId: string;
}

const useTranslationDetails = ({
  specificationId,
}: UseTranslationDetailsProps) => {
  const [tabs, setTabs] = useState<LabelingTranslationLaguageData[]>([]);
  const [selectedTranslationId, setSelectedTranslationId] = useState(undefined);
  const [translation, setTranslation] = useState<TranslationChapterData>({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { value: isTranslationFetching, setFalse, setTrue } = useBoolean();

  const { languagesMap, isLoading: isLanguagesLoading } = useLanguages({
    preFetch: true,
  });

  const languageCode = useSelector(selectLanguageCode);

  const updateLanguageTabs = tabsData => {
    let sortedTabs = [];

    if (!isListEmpty(tabsData) && languagesMap) {
      const tabs = tabsData.map(language => {
        return {
          key: language.translationId,
          value: language.translationId,
          label: languagesMap[language.languageCode]?.label as string,
          hasIcon: true,
          iconName: [
            CHAPTER_TRANSLATION_STATE.completed,
            CHAPTER_TRANSLATION_STATE.validated,
          ].includes(language?.state as CHAPTER_TRANSLATION_STATE)
            ? "check"
            : "progress",
        };
      });

      sortedTabs = tabs?.sort((a, b) => a?.label?.localeCompare(b?.label));

      setTabs(sortedTabs);
    }
    return sortedTabs;
  };

  const getLanguagesTabs = async () => {
    try {
      setIsLoading(true);
      const { data: tabsData } = await fetchLabelingTranslationLanguages({
        specificationId,
      });

      const sortedTabs = updateLanguageTabs(tabsData);

      await getTranslation(sortedTabs[0]?.value);

      setSelectedTranslationId(sortedTabs[0]?.value);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getLabelingTranslationLanguages = async () => {
    let labelingTranslationLanguages: LabelingTranslationLaguageData[] = tabs;
    try {
      const { data } = await fetchLabelingTranslationLanguages({
        specificationId,
      });

      labelingTranslationLanguages = data;
      updateLanguageTabs(data);
    } catch {}

    return labelingTranslationLanguages;
  };

  const getTranslation = async (
    translationId: string,
    withLoading: boolean = true
  ) => {
    try {
      withLoading && setTrue();
      const { data: translation } = await fetchLabelingTranslationById({
        translationId,
        params: {
          languageCode,
        },
      });

      setTranslation(translation);
    } catch (error) {
      setHasError(true);
    } finally {
      withLoading && setFalse();
    }
  };

  const onLanguageChange = async (translationId: string) => {
    setSelectedTranslationId(translationId);
    await getTranslation(translationId);
  };

  useEffect(() => {
    if (specificationId && !isObjectEmpty(languagesMap)) {
      getLanguagesTabs();
    }
  }, [specificationId, languagesMap]);

  return {
    tabs,
    translation,
    isLoading: isLoading || isLanguagesLoading,
    hasError,
    selectedTranslationId,
    onLanguageChange,
    isTranslationFetching,
    getTranslation,
    getLabelingTranslationLanguages,
  };
};

export default useTranslationDetails;
