import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import {
  fetchPackagingComponentCollection,
  fetchPackagingSystemSmartDoc,
} from "apis/PKG";
import {
  fetchChapter,
  fetchMaterial,
  fetchRequirement,
  fetchClaim,
} from "apis/SPEC";
import { fetchTags as fetchTagsApi } from "apis/TAGS";
import { useGetTemplate } from "apis/SPEC/templates";
import { prepareClaimDetailsData } from "dtos/claim";
import { LibraryObjectType } from "types/library";
import {
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_OBJECT_STATES,
  LIBRARY_OBJECT_TYPES,
  LIBRARY_REQUIREMENT_TYPES,
  MATERIAL_TYPES,
  RECIPE_TYPES,
} from "utils/constants";
import { isListEmpty } from "utils/general";
import { isLibraryObjectEditPage } from "utils/library";
import { LibraryItemDetails } from "../../types";
import { PageType } from "components/Library/components/types";

const mapParamsTypeToLibraryObjectType = {
  [LIBRARY_OBJECT_TYPES.RAW_MATERIALS]: MATERIAL_TYPES.RAW_MATERIAL,
  [LIBRARY_OBJECT_TYPES.ADDITIVES]: MATERIAL_TYPES.ADDITIVE,
  [LIBRARY_OBJECT_TYPES.PROCESSING_AIDS]: MATERIAL_TYPES.PROCESSING_AID,
  [LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY]:
    RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY,
  [LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE]: RECIPE_TYPES.MADE_IN_HOUSE,
  [LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS]: LIBRARY_REQUIREMENT_TYPES.TEXT,
  [LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS]:
    LIBRARY_REQUIREMENT_TYPES.DOCUMENT,
  [LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS]:
    LIBRARY_OBJECT_API_TYPES["packaging-systems"],
};

const useLibraryItemDetails = ({
  id,
  type,
  shouldRefetch,
  page,
}: {
  id: string;
  type: LibraryObjectType;
  shouldRefetch?: boolean;
  page?: PageType;
}) => {
  const [
    libraryItemDetails,
    setLibraryItemDetails,
  ] = useState<LibraryItemDetails>({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [tagIds, setTagIds] = useState<string[]>([]);

  const languageCode = useSelector(selectLanguageCode);

  const [fetchTemplate] = useGetTemplate();

  const fetchLibraryItemDetails = async () => {
    try {
      let libraryObject = {};

      setIsLoading(true);

      switch (type) {
        case LIBRARY_OBJECT_TYPES.RAW_MATERIALS:
        case LIBRARY_OBJECT_TYPES.ADDITIVES:
        case LIBRARY_OBJECT_TYPES.PROCESSING_AIDS:
        case LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY:
        case LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE:
          const { data: materialData } = await fetchMaterial({
            materialId: id,
            languageCode,
            materialType: type,
          });

          libraryObject = {
            ...materialData,
            versionNumber: materialData?.version?.number,
            generalInformation: {
              name: materialData?.name,
              type: mapParamsTypeToLibraryObjectType[type],
              state: materialData.state,
              status: materialData.active,
              lastModificationDate: materialData.lastUpdateDateUtc,
              internalCode: materialData?.internalCode,
              tags: [],
            },
          };
          setTagIds(materialData.tagIds);

          break;

        case LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS:
        case LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS:
          const { data: requirementData } = await fetchRequirement({
            requirementId: id,
            requirementType: type,
          });

          libraryObject = {
            ...requirementData,
            versionNumber: requirementData?.version?.number,
            generalInformation: {
              name: requirementData?.name,
              type: mapParamsTypeToLibraryObjectType[type],
              state: requirementData.state,
              lastModificationDate: requirementData.lastUpdateDateUtc,
              tags: [],
            },
            requirementType: mapParamsTypeToLibraryObjectType[type],
          };

          setTagIds(requirementData.tagIds);

          break;

        case LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS:
          const {
            data: packagingSystemData,
          } = await fetchPackagingSystemSmartDoc({
            packagingSystemId: id,
            languageCode,
          });

          const { componentIds } = packagingSystemData || {};

          const {
            data: components = [],
          } = await fetchPackagingComponentCollection({
            componentIds,
          });

          libraryObject = {
            ...packagingSystemData,
            components,
            versionNumber: packagingSystemData?.version?.number,
            generalInformation: {
              name: packagingSystemData?.name,
              type: mapParamsTypeToLibraryObjectType[type],
              state: packagingSystemData?.allowsUpdate
                ? null
                : LIBRARY_OBJECT_STATES.LOCKED,
              lastModificationDate: null,
              tags: [],
            },
          };
          break;

        case LIBRARY_OBJECT_TYPES.TEMPLATES:
          const { data: templateData } = await fetchTemplate({
            templateId: id,
            params: {
              languageCode,
              excludeHiddenSections: !isLibraryObjectEditPage(page),
            },
          });

          libraryObject = {
            ...templateData,
            versionNumber: templateData?.generalInfo?.version?.number,
            generalInformation: {
              name: templateData?.generalInfo?.name,
              type: LIBRARY_OBJECT_TYPES.TEMPLATES,
              state: templateData?.generalInfo?.state,
              lastModificationDate:
                templateData?.generalInfo?.lastUpdateDateUtc,
              tags: [],
            },
          };

          setTagIds(templateData?.generalInfo?.tagIds);

          break;

        case LIBRARY_OBJECT_TYPES.HEALTH_CLAIMS:
        case LIBRARY_OBJECT_TYPES.MARKETING_CLAIMS:
          const { data: claimData } = await fetchClaim({
            claimType: type,
            id,
            languageCode,
          });

          libraryObject = prepareClaimDetailsData(claimData);

          setTagIds(claimData?.tagIds);

          break;

        default:
          const { data: chapterData } = await fetchChapter({
            chapterId: id,
            chapterType: type,
            languageCode,
          });

          libraryObject = {
            ...chapterData,
            versionNumber: chapterData?.generalInfo?.version?.number,
            generalInformation: {
              name: chapterData?.generalInfo?.name,
              type: chapterData?.generalInfo?.type,
              state: chapterData?.generalInfo?.state,
              lastModificationDate: chapterData?.generalInfo?.lastUpdateDateUtc,
              tags: [],
            },
          };

          setTagIds(chapterData?.generalInfo?.tagIds);

          break;
      }

      setLibraryItemDetails(prev => {
        return { ...prev, ...libraryObject };
      });
    } catch (_) {
      setHasError(true);
      setLibraryItemDetails({});
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const { data } = await fetchTagsApi({
        ids: tagIds,
      });

      setLibraryItemDetails(previousState => ({
        ...previousState,
        generalInformation: {
          ...previousState.generalInformation,
          tags: data.map(({ tagText }) => tagText),
        },
      }));
    } catch (e) {}
  };

  useEffect(() => {
    fetchLibraryItemDetails();
  }, [id, type, languageCode, shouldRefetch]);

  useEffect(() => {
    if (!isListEmpty(tagIds)) {
      fetchTags();
    }
  }, [tagIds]);

  return {
    libraryItemDetails,
    isLoading,
    hasError,
  };
};

export default useLibraryItemDetails;
