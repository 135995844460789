import React from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Heading, Paragraph, EmptyState } from "@trace-one/design-system";
import { Row } from "antd";
import Spin from "components/Spin";
import { useFilesInfo, useImageUrl } from "hooks";
import { claimMessages } from "messages/claim";
import claimFormStyles from "../../styles.module.less";
import styles from "./styles.module.less";

const ClaimImage = ({ fileId }: { fileId: string }) => {
  const { formatMessage } = useIntl();

  const { imageUrl, isDownloading } = useImageUrl({ fileId });

  const {
    files: [imageDetails],
    isLoading,
  } = useFilesInfo({ fileIds: [fileId] });

  let claimImageContent: React.ReactNode;

  if (fileId) {
    if (isDownloading || isLoading) {
      claimImageContent = <Spin />;
    } else {
      claimImageContent = (
        <>
          <div className={styles["claim-image-wrapper"]}>
            <img src={imageUrl} alt="claim-logo" />
          </div>
          <Paragraph
            variant="body-s"
            color="grey-5"
            className={classNames("mt-1", "text-center", "overflow-wrap")}
          >
            {imageDetails?.name}
          </Paragraph>
        </>
      );
    }
  } else {
    claimImageContent = (
      <Row justify="center">
        <EmptyState
          heading={formatMessage(claimMessages.claimLogoEmptyState)}
        />
      </Row>
    );
  }

  return (
    <div className={classNames(claimFormStyles["section-wrapper"], "p-3")}>
      <Heading size="xs" color="grey-5" className="mb-1">
        {formatMessage(claimMessages.claimLogoTitle)}
      </Heading>
      {claimImageContent}
    </div>
  );
};

export default ClaimImage;
