import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchReferenceListItemsByName } from "apis/RLMD";
import { AsyncThunkConfig } from "store";
import { NET_CONTENT_NAME } from "utils/constants";
import { ReferenceListItemData } from "models";
import { Dictionary } from "types/general";

export const fetchUnitsOfMeasure = createAsyncThunk<
  ReferenceListItemData[],
  void,
  AsyncThunkConfig
>("generalData/fetchUnitsOfMeasure", async (_, { getState }) => {
  const {
    user: { userLanguagePreference },
  } = getState();

  const { data } = await fetchReferenceListItemsByName({
    name: NET_CONTENT_NAME,
    languageCode: userLanguagePreference,
  });

  return data;
});

export const updateCategoryDictionary = createAsyncThunk<
  { list: Dictionary; languageCode: string },
  Dictionary,
  AsyncThunkConfig
>("generalData/updateCategoryDictionary", async (list, { getState }) => {
  const {
    user: { userLanguagePreference: languageCode },
  } = getState();

  return {
    list,
    languageCode,
  };
});

export const updateRefListDictionary = createAsyncThunk<
  { list: Dictionary; languageCode: string },
  Dictionary,
  AsyncThunkConfig
>("generalData/updateRefListDictionary", async (list, { getState }) => {
  const {
    user: { userLanguagePreference: languageCode },
  } = getState();

  return {
    list,
    languageCode,
  };
});

export const updateCatalogDictionary = createAsyncThunk<
  { list: Dictionary; languageCode: string },
  Dictionary,
  AsyncThunkConfig
>("generalData/updateCatalogDictionary", async (list, { getState }) => {
  const {
    user: { userLanguagePreference: languageCode },
  } = getState();

  return {
    list,
    languageCode,
  };
});

export const updateServingDictionary = createAsyncThunk<
  { list: Dictionary; languageCode: string },
  Dictionary,
  AsyncThunkConfig
>("generalData/updateServingDictionary", async (list, { getState }) => {
  const {
    user: { userLanguagePreference: languageCode },
  } = getState();

  return {
    list,
    languageCode,
  };
});

export const updateFileTypeDictionary = createAsyncThunk<
  { list: Dictionary; languageCode: string },
  Dictionary,
  AsyncThunkConfig
>("generalData/updateFileTypeDictionary", async (list, { getState }) => {
  const {
    user: { userLanguagePreference: languageCode },
  } = getState();

  return {
    list,
    languageCode,
  };
});
