import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { LabeledValue } from "antd/lib/select";
import { useDebounce } from "@trace-one/business-components";
import { useTreeSelect } from "hooks";
import { selectLanguageCode } from "store/user/selectors";
import { numberOfCharactersForAutoComplete } from "utils/constants";
import { isListEmpty } from "utils/general";
import { fetchRawMaterialParts, filterRawMaterialParts } from "apis/CATALOGUE";
import { CategoryTreeItem } from "components/CategoryTreeSelect/types";
import { RawMaterialPartItemData, RawMaterialPartSearchData } from "models";

export type UsePartDropdownProps = {
  rawMaterialId?: string;
  value?: LabeledValue;
  onChange?: Function;
  shouldCurrentValueBeUpdated?: boolean;
};

const usePartDropdown = ({
  value,
  onChange: defaultOnChange,
  rawMaterialId,
  shouldCurrentValueBeUpdated = true,
}: UsePartDropdownProps) => {
  const languageCode = useSelector(selectLanguageCode);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const isFirstRender = useRef(true);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const {
    isLoading,
    setLoadingTrue,
    setLoadingFalse,
    onChange,
    onTreeExpand,
    prepareTreeData,
    prepareSearchTreeItemData,
    treeData,
    setTreeData,
    treeExpandedKeys,
    setTreeExpandedKeys,
    valueWithLabel,
  } = useTreeSelect<RawMaterialPartItemData>({
    value,
    onChange: defaultOnChange,
    shouldCurrentValueBeUpdated,
    getIsSelectable: () => true,
  });

  const prepareSearchTreeData = async (data: RawMaterialPartSearchData[]) => {
    const buildTree = async (item: RawMaterialPartSearchData) => {
      const { id, hasChildren } = item;
      const treeItem = prepareSearchTreeItemData(item);

      if (hasChildren && !isListEmpty(item?.children)) {
        setTreeExpandedKeys(prevState => [...prevState, item.id]);

        treeItem.children = await Promise.all(
          item?.children?.map(childNode => buildTree(childNode))
        );
      } else if (hasChildren && isListEmpty(item.children)) {
        const { data } = await fetchRawMaterialParts({
          params: {
            rawMaterialId,
            parentId: id,
            languageCode,
          },
        });

        treeItem.children = await Promise.all(
          data?.items?.map(childNode => buildTree(childNode))
        );
      }

      return treeItem;
    };

    const treeData = await Promise.all(
      data?.map(async item => {
        if (!item?.parentId) {
          return await buildTree(item);
        }
      })
    );

    return treeData;
  };

  const getPart = async () => {
    try {
      setTreeExpandedKeys([]);
      setLoadingTrue();

      const { data } = await fetchRawMaterialParts({
        params: {
          rawMaterialId,
          languageCode,
        },
      });

      prepareTreeData(data?.items);
    } catch (error) {
    } finally {
      setLoadingFalse();
    }
  };

  const searchRawMaterialParts = async () => {
    if (
      debouncedSearchTerm?.trim().length >= numberOfCharactersForAutoComplete
    ) {
      setLoadingTrue();

      try {
        const { data } = await filterRawMaterialParts({
          params: {
            searchTerm: debouncedSearchTerm,
            rawMaterialId,
            includeParents: false,
            languageCode,
          },
        });

        const searchTreeData = await prepareSearchTreeData(data);

        setTreeData(searchTreeData);
      } catch (_) {
      } finally {
        setLoadingFalse();
      }
    } else if (!debouncedSearchTerm?.trim().length) {
      setTreeData([]);
      getPart();
    }
  };

  const fetchData = async (id: string) => {
    const pageSize = 200;
    let pageNumber = 1;
    let data = [];

    while (true) {
      const {
        data: { items, totalPages },
      } = await fetchRawMaterialParts({
        params: {
          parentId: id,
          rawMaterialId,
          languageCode,
          pageNumber,
          pageSize,
        },
      });

      data = data?.concat(items);

      if (totalPages === pageNumber) {
        break;
      }

      pageNumber = pageNumber + 1;
    }

    return data;
  };

  const onSearch = (searchValue: string) => {
    setSearchTerm(searchValue);
  };

  const onLoadData = async ({ id, hasChildren }: CategoryTreeItem) => {
    if (!hasChildren) {
      return;
    }

    try {
      setLoadingTrue();

      const data = await fetchData(id);

      prepareTreeData(data, false);
    } catch {
    } finally {
      setLoadingFalse();
    }
  };

  const onDropdownVisibleChange = (visible: boolean) => {
    if (visible) {
      getPart();
    } else {
      setTreeData([]);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    searchRawMaterialParts();
  }, [debouncedSearchTerm, rawMaterialId, languageCode]);

  return {
    onChange,
    onLoadData,
    onSearch,
    onTreeExpand,
    onDropdownVisibleChange,
    searchTerm,
    isLoading,
    treeData,
    treeExpandedKeys,
    valueWithLabel,
  };
};

export default usePartDropdown;
