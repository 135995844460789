import { useMemo, useRef } from "react";
import classNames from "classnames";
import { utils } from "@rjsf/core";
import { InputNumber } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import ErrorsList from "components/ErrorsList";
import { isListEmpty } from "utils/general";
import { useDual, useInputNumberProps, useWidgetActions } from "../../hooks";
import { useCompactWidget } from "./hooks";
import { useAppDispatch } from "store";
import { Property } from "components/SpecificationSection/types";
import { CompactWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { REQUEST_MODIFICATION_MODAL_WIDTH } from "components/RequestModificationPopover";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
} from "utils/modificationRequest";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import { getDataFieldId } from "../../utils";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { addInternationalSystemSourceId } from "../utils";

const { getUiOptions } = utils;

const CompactWidget: React.FC<CompactWidgetProps> = ({
  formData,
  schema,
  properties: widgetProperties,
  uiSchema,
  formContext,
  required,
  idSchema,
  onOpenModal,
  setSelectedField,
  setModalStyle,
  setRequestPopoverOptions,
  ...props
}) => {
  const { properties } = schema;
  const valueRef = useRef(null);
  const unitIdRef = useRef(null);

  const dispatch = useAppDispatch();

  const {
    refListDictionary,
    isWidgetEditable,
    templateBlockId,
    sectionId,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
    isTargetMode,
    modificationRequests,
    ownerCompanyId,
    chapterData,
    arraySchemaItemKey,
  } = formContext;

  const uiOptions = getUiOptions(uiSchema);

  const readonly = props.readonly || uiOptions.readonly;

  const { compactData, errors, onChangeHandle } = useCompactWidget({
    formData,
    widgetProperties,
  });

  const { getTranslationForKey } = useFormSchemaTranslation();

  const { propertyId: propertyIdValue } = properties.value;

  const unitKey = properties.hasOwnProperty("unit") ? "unit" : "unitId";

  const {
    propertyId: propertyIdUnit,
    referenceList: { listId, parentItemIds },
  } = properties[unitKey] as Property;

  const inputNumberProps = useInputNumberProps({
    properties: properties.value,
    onChange: onChangeHandle("value"),
    value: compactData?.value !== undefined ? compactData.value : "",
    id: idSchema.$id,
    key: "value",
    isTypingInProgress,
    onUpdateIsTypingInProgress,
  });

  const { items, buttonProps } = useDual({
    listId,
    parentItemIds: addInternationalSystemSourceId(parentItemIds),
    onChange: onChangeHandle(unitKey),
  });

  const isInputDisabled = !isWidgetEditable(propertyIdValue) || readonly;
  const isButtonDisabled = !isWidgetEditable(propertyIdUnit) || readonly;

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const listItemKey = formData?.[arraySchemaItemKey];

  const onClick = (event: React.MouseEvent<HTMLElement>, propertyId, ref) => {
    event.stopPropagation();

    const canClick =
      canTargetField({
        isTargetMode,
        specOwnerCompanyId: ownerCompanyId,
        chapterOwnerCompanyId: chapterData?.ownerCompanyId,
      }) &&
      !doesFieldHaveModificationRequest({
        modificationRequests,
        propertyId,
        listItemKey,
      });

    if (!canClick) return;

    setSelectedField({
      propertyId,
      listItemKey: listItemKey ?? null,
    });

    const clickedElement = event.target as HTMLElement;

    const buttonElement = clickedElement.querySelector("button");
    if (buttonElement) {
      buttonElement.classList.add("target-mode__border");
    } else {
      clickedElement.classList.add("target-mode__border");
    }

    const { left, top } = ref?.current?.getBoundingClientRect();

    const isOverFlowing =
      event.clientX + REQUEST_MODIFICATION_MODAL_WIDTH > window.innerWidth;

    if (Array.from(clickedElement.classList).includes("pointer-event-none")) {
      setModalStyle({
        top: event.clientY - top,
        left: isOverFlowing
          ? "unset"
          : event.clientX +
            event.currentTarget.previousElementSibling.clientWidth -
            left,
        right: isOverFlowing ? 0 : "unset",
      });
    } else {
      setModalStyle({
        top: event.clientY - top,
        left: isOverFlowing ? "unset" : event.clientX - left,
        right: isOverFlowing ? 0 : "unset",
      });
    }

    onOpenModal();

    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  const renderPopoverIcon = (propertyId: string, ref, modalStyle = {}) => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
      listItemKey,
    });

    if (request) {
      return (
        <RequestModificationTooltipIcon
          key={`${propertyId}`}
          onOpenModal={onOpenModal}
          setModalStyle={setModalStyle}
          tableRef={ref}
          setRequestPopoverOptions={setRequestPopoverOptions}
          request={request}
          modalStyle={modalStyle}
        />
      );
    }
  };

  const hasDraftRequestForValue = useMemo(
    () =>
      getDraftRequestFromAllRequests({
        modificationRequests,
        propertyId: propertyIdValue,
        listItemKey,
      }),
    [modificationRequests, propertyIdValue, listItemKey]
  );

  const modificationRequestProps = (propertyId, ref) => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
      listItemKey,
    });

    return {
      "data-field-id": getDataFieldId(propertyId, listItemKey),
      onClick: event => onClick(event, propertyId, ref),
      wrapperRef: ref,
      extraAbsoluteContent: renderPopoverIcon(propertyId, ref, {
        right: 0,
        left: "unset",
      }),
      wrapperClassName: classNames({
        "field-has-modification-request": !!request,
        "width-auto": isTargetMode,
      }),
    };
  };

  return (
    <InputNumber.Dual
      className={classNames({
        [widgetStyle.inRevisionChange]:
          isWidgetChanged(propertyIdValue, idSchema.$id) ||
          isWidgetChanged(propertyIdUnit, idSchema.$id),
      })}
      id={idSchema.$id}
      {...inputNumberProps}
      {...(uiSchema["ui:text:placeholder"] && {
        placeholder: getTranslationForKey(uiSchema["ui:text:placeholder"]),
      })}
      error={!isListEmpty(errors)}
      disabledInput={isInputDisabled}
      buttonPlaceholder={getTranslationForKey(
        uiSchema["ui:list:placeholder"] || "refListPlaceholder"
      )}
      buttonValue={refListDictionary[compactData?.[unitKey]]}
      disabledButton={isButtonDisabled}
      onClearButton={onChangeHandle(unitKey)}
      buttonProps={{
        ...buttonProps,
        "data-test-id": `${idSchema.$id}_unit`,
        ...modificationRequestProps(propertyIdUnit, unitIdRef),
      }}
      items={items}
      errorMessage={<ErrorsList list={errors} />}
      disableFieldEnableWrapperClick={isTargetMode}
      onClick={event => onClick(event, propertyIdValue, valueRef)}
      data-field-id={getDataFieldId(propertyIdValue, listItemKey)}
      wrapperRef={valueRef}
      extraAbsoluteContent={renderPopoverIcon(propertyIdValue, valueRef)}
      wrapperClassName={classNames({
        "field-has-modification-request": hasDraftRequestForValue,
      })}
    />
  );
};

export default withRequestModificationPopover(CompactWidget);
