import { useIntl } from "react-intl";
import { Typography } from "@trace-one/design-system";
import Spin from "components/Spin";
import KPITabs, { KPITabType } from "components/KPITabs";
import DocumentSummaryPanel from "components/DocumentSummaryPanel";
import { useSpecificationDocumentSummary } from "./hooks";
import { ChapterStructureFormData } from "types/library";
import { specificationMessages, documentMessages } from "messages";
import { DOCUMENT_SUMMARY_TABS } from "utils/constants";

export interface SpecificationDocumentSummaryProps {
  specificationId: string;
  tradeItemName: string;
  supplierName: string;
  productTypeId: string;
  specificationStructure?: ChapterStructureFormData[];
}

const SpecificationDocumentSummary: React.FC<SpecificationDocumentSummaryProps> = ({
  specificationId,
  tradeItemName,
  supplierName,
  productTypeId,
  specificationStructure,
}) => {
  const { formatMessage } = useIntl();

  const {
    isLoading,
    allDocuments,
    documentsLinkedToTheSpecification,
    documentsLinkedToChapters,
    documentsLinkedToLibraryItems,
    chapters,
    filteredChapterTypes,
  } = useSpecificationDocumentSummary({ specificationId, productTypeId });

  const kpiTabs: KPITabType[] = [
    {
      title: formatMessage(documentMessages.allDocuments),
      kpiValue: allDocuments.length,
      tabKey: DOCUMENT_SUMMARY_TABS.ALL_DOCUMENTS,
      content: (
        <DocumentSummaryPanel
          tradeItemName={tradeItemName}
          supplierName={supplierName}
          files={allDocuments}
          tabKey={DOCUMENT_SUMMARY_TABS.ALL_DOCUMENTS}
          key={DOCUMENT_SUMMARY_TABS.ALL_DOCUMENTS}
        />
      ),
    },
    {
      title: formatMessage(documentMessages.documentsLinkedToTheSpecification),
      kpiValue: documentsLinkedToTheSpecification.length,
      tabKey: DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_SPECIFICATION,
      content: (
        <DocumentSummaryPanel
          tradeItemName={tradeItemName}
          supplierName={supplierName}
          files={documentsLinkedToTheSpecification}
          tabKey={DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_SPECIFICATION}
          key={DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_SPECIFICATION}
          chapters={chapters}
          filteredChapterTypes={filteredChapterTypes}
          specificationStructure={specificationStructure}
        />
      ),
    },
    {
      title: formatMessage(documentMessages.documentsLinkedToChapters),
      kpiValue: documentsLinkedToChapters.length,
      tabKey: DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_CHAPTERS,
      content: (
        <DocumentSummaryPanel
          tradeItemName={tradeItemName}
          supplierName={supplierName}
          files={documentsLinkedToChapters}
          tabKey={DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_CHAPTERS}
          key={DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_CHAPTERS}
          chapters={chapters}
          filteredChapterTypes={filteredChapterTypes}
          specificationStructure={specificationStructure}
        />
      ),
    },
    {
      title: formatMessage(documentMessages.documentsLinkedToLibraryItems),
      kpiValue: documentsLinkedToLibraryItems.length,
      tabKey: DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_LIBRARY_ITEMS,
      content: (
        <DocumentSummaryPanel
          tradeItemName={tradeItemName}
          supplierName={supplierName}
          files={documentsLinkedToLibraryItems}
          tabKey={DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_LIBRARY_ITEMS}
          key={DOCUMENT_SUMMARY_TABS.DOCUMENTS_LINKED_TO_LIBRARY_ITEMS}
        />
      ),
    },
  ];

  if (isLoading) return <Spin className="mt-4" />;

  return (
    <>
      <Typography variant="heading-l" color="grey-5" className="pr-5">
        {formatMessage(specificationMessages.documentSummary)}
      </Typography>

      <KPITabs
        kpiTabs={kpiTabs}
        defaultTabKey={DOCUMENT_SUMMARY_TABS.ALL_DOCUMENTS}
        className="mt-3 mt-3 gap-3"
        itemsPerRow={4}
      />
    </>
  );
};

export default SpecificationDocumentSummary;
